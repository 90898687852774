import React, {Component} from 'react';
import FileBrowser from "../../../Utilities/FileSelector/FileBrowser";

class TicketDevices extends Component {

    render() {

        return <FileBrowser type={'files_devices_' + this.props.match.params.deviceId}/>;
    }

}

export default TicketDevices;