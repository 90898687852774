import {Button, Typography, withStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import React from "react";

const useStyles = theme => ({
    root: {
        marginTop: theme.spacing(2),
        width: '100%'
    },
    error: {
        color: theme.palette.error.contrastText,
        backgroundColor: theme.palette.error.main
    },
    success: {
        color: theme.palette.success.contrastText,
        backgroundColor: theme.palette.success.main
    }
});

const Alert = (props) => {
    return   <Card className={[props.classes.root, props.classes[props.type]]}>
        <CardContent>
            <Typography variant="body2">
                {props.children}
            </Typography>
        </CardContent>
        {props.onClose && <CardActions>
            <Button color='inherit' onClick={props.onClose}>
                Schließen
            </Button>
        </CardActions>}
    </Card>
};

export default withStyles(useStyles)(Alert);
