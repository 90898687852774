import React, {Component} from 'react';
import {connect} from "react-redux";
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {bindActionCreators} from "redux";
import {Link} from 'react-router-dom'
import Filter from './Filter'
import List from './List'
import qs from 'query-string';
import {fetchTickets} from '../../../actions/ticketActions';


class TicketList extends Component {

    constructor(props) {
        super(props);

        this.setFilter = this.setFilter.bind(this);
        this.setOrderBy = this.setOrderBy.bind(this);
        this.runSearch = this.runSearch.bind(this);
        this.setPagination = this.setPagination.bind(this);

        this.state = {
            pagination: {
                page: 1,
                perPage: 50,
                total: 0
            },
            filter: {
                ticketData: '',
                customerData: '',
                deviceData: '',
                category: '',
                fromDate: null,
                toDate: null,
                hasRecurrence: '',
                status: ['1', '2']
            },
            orderBy: 's.priority_ASC',
        };

    };

    static getDerivedStateFromProps(props, state)
    {
        if(props.filter) {
            state.filter = Object.assign({}, state.filter, props.filter);
        }

        return state;
    }

    setFilter(filter) {
        this.setState({filter: filter})
    }

    setOrderBy(orderBy) {
        this.setState({orderBy: orderBy})
    }

    setPagination(pagination) {
        this.setState({pagination: pagination})
    }

    runSearch() {
        this.props.actions.fetchTickets(this.state.filter, this.state.orderBy, this.state.pagination.page, this.state.pagination.perPage)
    }

    render() {

        let params = {};
        if(this.state.filter.deviceId)
        {
            params.deviceId = this.state.filter.deviceId;
        }

        if(this.state.filter.customer)
        {
            params.customerId = this.state.filter.customer;
        }

        const link = '/tickets/new?' + qs.stringify(params);

        return (
            <div className='row'>
                <div className="col-md-3">
                    <Filter filter={this.state.filter} orderBy={this.state.orderBy} setFilter={this.setFilter} setOrderBy={this.setOrderBy} runSearch={this.runSearch}/>
                </div>
                <div className="col-md-9">
                    <div className='btn-bar'>
                        <Link className='btn btn-default' role='button' to={link}>
                            <FontAwesomeIcon
                                icon='plus'/> Neu Anlegen
                        </Link>
                    </div>
                    <List pagination={this.state.pagination} setPagination={this.setPagination} runSearch={this.runSearch}/>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchTickets}, dispatch),
    }
};

function mapStateToProps(state) {

    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketList);