import React, {Component} from 'react';
import {connect} from "react-redux";
import BigCalendarView from "../Utilities/Calendar/BigCalendarView";

class View extends Component {

    render() {

        return (
            <div>
                <BigCalendarView
                    displayRequests={true}
                    height='600px'
                    views={[
                        'WEEK',
                        'MONTH',
                        'AGENDA'
                    ]}
                />
            </div>

        );
    }
}

export default connect()(View);
