import React, {Component} from 'react';
import {LineChart} from 'react-chartkick'
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {fetchUserList} from "../../../actions/userActions";

class ArrangerProcessedTicketsPerTime extends Component {

    componentDidMount() {
        this.props.actions.fetchUserList();
    }

    render() {

        const {width, height, data} = this.props;

        const chartData = [];
        if (this.props.userList.userList) {
            this.props.userList.userList.forEach((user) => {


                const userData = {};

                Object.keys(data).forEach((date) => {
                    const row = data[date];
                    userData[date] = row[user.id] ? row[user.id] : 0;
                });

                chartData.push({
                    name: user.firstname + ' ' + user.lastname,
                    data: userData
                });

            })
        }

        const otherData = {};
        Object.keys(data).forEach((date) => {
            const row = data[date];
            otherData[date] = row.other ? row.other : 0;
        });

        chartData.push({
            name: 'Andere',
            data: otherData
        });

        return <LineChart
            download={true}
            legend={true}
            data={chartData}
            width={width}
            height={height}
        />
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        actions: bindActionCreators({fetchUserList}, dispatch)
    }
};

function mapStateToProps(state) {

    const {userList} = state;
    return {userList}
}

export default connect(mapStateToProps, mapDispatchToProps)(ArrangerProcessedTicketsPerTime);
