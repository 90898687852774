import {CALL_API} from '../middleware/api'
import {push} from 'connected-react-router';

export const TICKET_OWN_REQUEST = 'TICKET_OWN_REQUEST';
export const TICKET_OWN_SUCCESS = 'TICKET_OWN_SUCCESS';
export const TICKET_OWN_FAILURE = 'TICKET_OWN_FAILURE';

// Uses the API middlware to get a TICKET
export function fetchOwnTickets(page, perPage, filter = {
}) {
    const user = JSON.parse(localStorage.getItem('user')) || null;

    if (!user) {
        return dispatch => {

            // We dispatch requestLogin to kickoff the call to the API
            dispatch(push('/login'));
        }
    }

    return {
        [CALL_API]: {
            endpoint: 'users/'+user.id+'/tickets',
            method: 'GET',
            parameter: {
                perPage: perPage,
                page: page,
                filter: filter
            },
            types: [TICKET_OWN_SUCCESS, TICKET_OWN_FAILURE, TICKET_OWN_REQUEST]
        }
    }
}

export const TICKET_REQUEST = 'TICKET_REQUEST';
export const TICKET_SUCCESS = 'TICKET_SUCCESS';
export const TICKET_FAILURE = 'TICKET_FAILURE';

export function fetchTickets(filter = [],orderBy, page, perPage)
{

    return {
        [CALL_API]: {
            endpoint: 'tickets',
            method: 'GET',
            parameter: {
                perPage: perPage,
                page: page,
                filter: filter,
                orderBy: orderBy
            },
            types: [TICKET_SUCCESS, TICKET_FAILURE, TICKET_REQUEST]
        }
    }
}

export const SINGLE_TICKET_REQUEST = 'SINGLE_TICKET_REQUEST';
export const SINGLE_TICKET_SUCCESS = 'SINGLE_TICKET_SUCCESS';
export const SINGLE_TICKET_FAILURE = 'SINGLE_TICKET_FAILURE';

export function fetchTicket(id)
{

    return {
        [CALL_API]: {
            endpoint: 'tickets/'+id,
            method: 'GET',
            parameter: {
                with: ['ticketActions', 'customerAddresses']
            },
            types: [SINGLE_TICKET_SUCCESS, SINGLE_TICKET_FAILURE, SINGLE_TICKET_REQUEST]
        }
    }
}
