import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {fetchExportFileTypeList, fetchExportTypeList} from "../../../actions/exportActions";
import Rest from "../../../core/Rest";
import store from "../../../core/store";
import FileBrowser from "../../Utilities/FileSelector/FileBrowser";
import moment from "moment/moment";

class Export extends Component {

    intervalId;

    constructor(props) {
        super(props);

        this.state = {
            exportType: 0,
            fileType: 0,
            isSaving: false,
            lastRun: null,
            queueLength: 0,
            completeQueueLength: 0
        };

        this.runExport = this.runExport.bind(this);
        this.onModelChange = this.onModelChange.bind(this);
        this.getQueue = this.getQueue.bind(this);
        this.checkInterval = this.checkInterval.bind(this);
    }

    componentDidMount() {
        this.props.actions.fetchExportTypeList();
        this.props.actions.fetchExportFileTypeList();
        this.getQueue();
    }

    onModelChange(event) {
        const state = this.state;
        state[event.target.name] = event.target.value;
        this.setState(state);
    }

    checkInterval()
    {

        const intervalId = setInterval(() => {
            this.getQueue();

            if(this.state.queueLength <= 0)
            {
                clearInterval(intervalId);
            }
        }, 5000);
       this.intervalId = intervalId;
    }

    componentWillUnmount()
    {
        if (this.intervalId) {
            clearInterval(this.intervalId)
        }
    }


    getQueue() {
        Rest.fetch({
            endpoint: 'system/queue/status/export_data_command',
            method: 'GET'
        }).then(
            response => {

                const oldCount = this.state.queueLength;

                this.setState({
                    queueLength: response.response.count,
                    completeQueueLength: response.response.complete,
                    lastRun: response.response.last
                });

                if(response.response.count <= 0 && oldCount > 0)
                {
                    this.refs.fileBrowser.getFiles();
                }

                if(oldCount <= 0 && response.response.count > 0)
                {
                    this.checkInterval()
                }

            },
            error => {
            }
        );

    }



    runExport() {
        this.setState({isSaving: true});

        Rest.fetch({
            endpoint: 'data/export/queue',
            method: 'POST',
            body: {
                exportType: this.state.exportType,
                fileType: this.state.fileType
            }
        }).then(
            response => {
                store.dispatch({
                    type: 'ADD_ALERT',
                    message: 'Export wurde der Warteschlange hinzugefügt.',
                    style: 'success'
                });
            },
            error => {
            }
        ).then(() => {
            this.setState({
                    isSaving: false,
                    exportType: 0,
                    fileType: 0
                }
            );

            this.getQueue();
        });
    }


    render() {

        let typeListOptions = [
            <option key={0} value={0}>Bitte Wählen</option>
        ];

        if (this.props.exportTypes.typeList) {
            typeListOptions = typeListOptions.concat(this.props.exportTypes.typeList.map((type) => {
                return <option key={type.id} value={type.id}>{type.name}</option>
            }));
        }

        let fileTypeListOptions = [
            <option key={0} value={0}>Bitte Wählen</option>
        ];
        if (this.props.exportFileTypes.typeList) {
            fileTypeListOptions = fileTypeListOptions.concat(this.props.exportFileTypes.typeList.map((type) => {
                return <option key={type.id} value={type.id}>{type.name}</option>
            }));
        }

        let last = 'Nicht vorhanden';
        if (this.state.lastRun) {

            last = moment(this.state.lastRun).format('DD/MM/YYYY HH:mm:ss')
        }

        return (
            <div>
                <div className="panel-default panel">
                    <div className="panel-heading">
                        <h4 className="panel-title">
                            Abgleich Felder
                        </h4>
                    </div>
                    <div className='panel-body'>
                        <div className='form-group'>
                            <label>Format</label>
                            <select className='form-control' value={this.state.exportType} name='exportType' onChange={this.onModelChange}>
                                {typeListOptions}
                            </select>
                        </div>
                        <div className='form-group'>
                            <label>Dateityp</label>
                            <select className='form-control' value={this.state.fileType} name='fileType' onChange={this.onModelChange}>
                                {fileTypeListOptions}
                            </select>
                        </div>
                    </div>
                    <div className='panel-footer'>
                        <button className='btn btn-default' disabled={
                            this.state.isSaving ||
                            this.state.fileType === 0 ||
                            this.state.exportType === 0
                        } onClick={this.runExport}
                        >
                            <FontAwesomeIcon icon='play'/> Export einreihen
                        </button>
                    </div>
                </div>
                <div className='panel panel-default'>
                    <div className='panel-body'>
                        <span style={{marginRight: '1em'}}>
                            Warteschlange Exporte (Komplette Warteschlange): {this.state.queueLength} ({this.state.completeQueueLength})
                        </span>
                        <span>
                        Letzte Bearbeitung: {last}
                        </span>
                    </div>
                </div>
                <FileBrowser type={'export'} ref='fileBrowser'/>
            </div>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchExportFileTypeList, fetchExportTypeList}, dispatch)
    }
};

function mapStateToProps(state) {

    const {exportTypes, exportFileTypes} = state;

    return {exportFileTypes, exportTypes}
}

export default connect(mapStateToProps, mapDispatchToProps)(Export);
