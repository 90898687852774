import {CALL_API} from '../middleware/api'

export const SYSTEM_STATUS_REQUEST = 'SYSTEM_STATUS_REQUEST';
export const SYSTEM_STATUS_SUCCESS = 'SYSTEM_STATUS_SUCCESS';
export const SYSTEM_STATUS_FAILURE = 'SYSTEM_STATUS_FAILURE';

export const SYSTEM_QUEUE_STATUS_REQUEST = 'SYSTEM_QUEUE_STATUS_REQUEST';
export const SYSTEM_QUEUE_STATUS_SUCCESS = 'SYSTEM_QUEUE_STATUS_SUCCESS';
export const SYSTEM_QUEUE_STATUS_FAILURE = 'SYSTEM_QUEUE_STATUS_FAILURE';

export const SYSTEM_CHANGELOG_REQUEST = 'SYSTEM_CHANGELOG_REQUEST';
export const SYSTEM_CHANGELOG_SUCCESS = 'SYSTEM_CHANGELOG_SUCCESS';
export const SYSTEM_CHANGELOG_FAILURE = 'SYSTEM_CHANGELOG_FAILURE';

export const SYSTEM_MODULES_REQUEST = 'SYSTEM_MODULES_REQUEST';
export const SYSTEM_MODULES_SUCCESS = 'SYSTEM_MODULES_SUCCESS';
export const SYSTEM_MODULES_FAILURE = 'SYSTEM_MODULES_FAILURE';
export function fetchStatus() {

    return {
        [CALL_API]: {
            endpoint: 'system/status',
            types: [SYSTEM_STATUS_SUCCESS, SYSTEM_STATUS_FAILURE, SYSTEM_STATUS_REQUEST]
        }
    }
}


export function fetchModules() {

    return {
        [CALL_API]: {
            endpoint: 'system/modules',
            types: [SYSTEM_MODULES_SUCCESS, SYSTEM_MODULES_FAILURE, SYSTEM_MODULES_REQUEST]
        }
    }
}

export function fetchQueueStatus() {

    return {
        [CALL_API]: {
            endpoint: 'system/queue/status',
            types: [SYSTEM_QUEUE_STATUS_SUCCESS, SYSTEM_QUEUE_STATUS_FAILURE, SYSTEM_QUEUE_STATUS_REQUEST]
        }
    }
}

export function fetchChangelog() {

    return {
        [CALL_API]: {
            endpoint: 'system/changelog',
            types: [SYSTEM_CHANGELOG_SUCCESS, SYSTEM_CHANGELOG_FAILURE, SYSTEM_CHANGELOG_REQUEST]
        }
    }
}
