import React, {Component} from 'react';
import {connect} from "react-redux";
import cx from 'classnames'

class Alerts extends Component {

    constructor(props)
    {
        super(props);

        this.removeAlert = this.removeAlert.bind(this);

        if(this.props.interval){
            setInterval(() => {
                this.removeAlert();
            },this.props.interval);
        }
    }

    removeAlert() {
        let {dispatch, alert} = this.props;
        dispatch({
            type: 'REMOVE_ALERT',
            id: alert.id
        })
    }

    render() {

        let {alert, style} = this.props;

        let alertStyle = 'alert-' + alert.style;

        return (
            <div className={cx(
                'alert',
                alertStyle
            )} key={alert.id} onClick={this.removeAlert} style={style}>
                <div className="flex">
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    {alert.message}<br />
                    <small>{alert.datetime.format('HH:mm:ss')}<small>{alert.datetime.format(':SSS')}</small></small>
                </div>
            </div>
        );

    }

}


export default connect(null, null)(Alerts);
