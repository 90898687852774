import React, {Component, Fragment} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import logo from '../../images/logo.png';
import {externalLoginUser, loginUser, logoutUser} from '../../actions/sessionActions';
import Rest from "../../core/Rest";
import qs from "query-string";
import {withStyles} from '@material-ui/core/styles';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Link,
    Paper,
    TextField,
    Typography,
    withWidth
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";

const useStyles = theme => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(https://cdn.vecodesk.com/storage/master/loginImage.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    resetForm: {
        width: '100%', // Fix IE 11 issue.
    },
    logo: {
        marginBottom: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    loginLoading: {
        color: theme.palette.primary.contrastText
    },
    alertSuccess: {
        marginTop: theme.spacing(2),
        color: theme.palette.success.contrastText,
        backgroundColor: theme.palette.success.main,
        width: '100%'
    },
    alert: {
        marginTop: theme.spacing(2),
        color: theme.palette.error.contrastText,
        padding: theme.spacing(1),
        backgroundColor: theme.palette.error.main,
        width: '100%'
    }
});

const Copyright = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://vecodesk.com/">
                vecodesk.com
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
};

class Login extends Component {

    constructor(props) {
        super(props);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onResetChange = this.onResetChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.sendResetRequest = this.sendResetRequest.bind(this);
        this.getRedirect = this.getRedirect.bind(this);

        this.state = {
            isFetching: false,
            showModal: false,
            credentials: {username: '', password: ''},
            reset: {username: '', email: ''},
            resetSend: false,
            errorFields: []
        }

    }

    componentDidMount() {
        this.props.actions.logoutUser();

        const query = qs.parse(this.props.location.search);
        if (query.token) {
            this.props.actions.externalLoginUser({
                token: query.token
            }, this.getRedirect())
        }
    }

    handleClose() {
        this.setState({showModal: false});
    }

    handleShow() {
        this.setState({showModal: true});
    }

    onChange(event) {
        const field = event.target.id;
        const credentials = this.state.credentials;
        credentials[field] = event.target.value;

        return this.setState({credentials: credentials});
    }

    onResetChange(event) {
        const field = event.target.id;
        const resetFields = this.state.reset;
        resetFields[field] = event.target.value;

        return this.setState({reset: resetFields});
    }

    getRedirect() {
        const locationHistory = this.props.locationHistory.filter((object) => {
            return (object.location.pathname !== '/login' && object.location.pathname.indexOf('/password_reset') === -1)
        });

        let redirectPath = '/';

        if (locationHistory.length) {
            redirectPath = locationHistory[0].location.pathname;
        }

        return redirectPath;
    }

    onSave(event) {
        event.preventDefault();

        this.props.actions.loginUser(this.state.credentials, this.getRedirect());
    }

    sendResetRequest() {
        this.setState({isFetching: true});
        Rest.fetch({
            endpoint: 'users/password_reset/token',
            method: 'POST',
            auth: false,
            body: {
                email: this.state.reset.email,
                username: this.state.reset.username
            }
        }).then(
            (response) => {
                this.setState({
                    resetSend: true, errorFields: [], reset: {
                        username: '',
                        email: ''
                    }
                });
                this.handleClose();
            },
            (error) => {
                try {
                    if (error.code === 400 && error.raw.error && error.raw.error.validation_errors) {
                        this.setState({errorFields: Object.keys(error.raw.error.validation_errors)})
                    }
                } catch (e) {
                }
            }
        ).then(() => {
            this.setState({isFetching: false});
        })
    }

    render() {
        const {isFetching, errorMessage} = this.props;

        const classes = this.props.classes;

        const alerts = [];
        if (errorMessage) {
            alerts.push(
                <Paper fullWidth className={classes.alert}>
                    <Typography component="p">
                        {errorMessage}
                    </Typography>
                </Paper>
            )
        }

        if (this.state.resetSend) {
            alerts.push(
                <Card className={classes.alertSuccess}>
                    <CardContent>
                        <Typography variant="body2">
                            Anfrage zum Zurücksetzen des Passworts wurde gesendent, sofern der Nutzer existiert.
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button color='inherit' onClick={() => {
                            this.setState({resetSend: false})
                        }}>
                            Schließen
                        </Button>
                    </CardActions>
                </Card>
            );
        }


        return <Fragment>
            <Grid container component="main" className={classes.root}>
                <Grid item xs={false} sm={4} md={7} className={classes.image}/>
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <div className={classes.paper}>
                        <img src={logo} width="80%" alt={"logo"} className={classes.logo}/>
                        {alerts}
                        <form className={classes.form} noValidate>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Nutzername"
                                autoComplete="username"
                                value={this.state.credentials.username}
                                autoFocus
                                onChange={this.onChange}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label="Passwort"
                                type="password"
                                id="password"
                                value={this.state.credentials.password}
                                onChange={this.onChange}
                                autoComplete="current-password"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={this.onSave}
                                disabled={isFetching}
                            >
                                {isFetching ? <CircularProgress className={classes.loginLoading} size={24}/> : 'Einloggen'}
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link onClick={this.handleShow} variant="body2">
                                        Passwort vergessen?
                                    </Link>
                                </Grid>
                            </Grid>
                            <Box mt={5}>
                                <Copyright/>
                            </Box>
                        </form>
                    </div>
                </Grid>
            </Grid>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={this.state.showModal}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Passwort Vergessen"}</DialogTitle>
                <DialogContent>
                    <form className={classes.resetForm} noValidate>
                        <TextField
                            error={this.state.errorFields.indexOf('username') !== -1}
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Nutzername"
                            autoComplete="username"
                            autoFocus
                            onChange={this.onResetChange}
                            value={this.state.reset.username}
                        />
                        <TextField
                            error={this.state.errorFields.indexOf('email') !== -1}
                            margin="normal"
                            required
                            fullWidth
                            label="E-Mail"
                            type="email"
                            id="email"
                            onChange={this.onResetChange}
                            value={this.state.reset.email}
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Grid container justify='flex-end' spacing={1}>
                        <Grid item xs={12} sm={"auto"}>
                    <Button fullWidth={this.props.width === 'xs'} disabled={this.state.isFetching} variant="outlined"
                            onClick={this.handleClose}
                            color="primary">
                        Schließen
                    </Button>
                        </Grid>
                        <Grid item xs={12} sm={"auto"}>
                    <Button fullWidth={this.props.width === 'xs'} disabled={this.state.isFetching} variant="contained"
                            onClick={this.sendResetRequest}
                            color="primary" autoFocus>
                        {this.state.isFetching ? <CircularProgress className={classes.loginLoading} size={24}/> : 'Neues Passwort anfodern'}
                    </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </Fragment>;
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({loginUser, logoutUser, externalLoginUser}, dispatch),
    }
};

function mapStateToProps(state) {

    const {auth, locationHistory} = state;
    const {isAuthenticated, isFetching, errorMessage} = auth;

    return {
        isAuthenticated,
        isFetching,
        errorMessage,
        locationHistory
    }
}

export default compose(
    withWidth(),
    withStyles(useStyles),
    connect(mapStateToProps, mapDispatchToProps))(Login);