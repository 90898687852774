import React from 'react';

const NoDeviceAlert = () => {

    return (
        <div className='alert alert-info' >
            In diesem Bereich werden geöffnete Geräte angezeigt. Links klicken um ein Gerät zu öffnen
        </div>
    );
};

export default NoDeviceAlert;

