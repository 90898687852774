import React from 'react';
import {I18n} from 'react-i18next';

export function getMessage(validationErrors) {
    return Object.keys(validationErrors).map((key) => {

        const list = validationErrors[key].join(', ');

        return <I18n ns={['validation']}>
            {
                (t) => (
                    <div>
                        <li>{t('keys.' + key)}: {list}</li>
                    </div>
                )
            }
        </I18n>;
    });

}