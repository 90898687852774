import React, {Component} from 'react';
import FileBrowser from "../../../Utilities/FileSelector/FileBrowser";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

class TicketDevices extends Component {

    render() {

        const ticketId = this.props.match.params.ticketId;

        return <FileBrowser type={'files_tickets_' + ticketId}/>;
    }

}

const mapDispatchToProps = (dispatch) => {

    return {
        actions: bindActionCreators({}, dispatch)
    }
};

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketDevices);