import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {fetchNotificationList} from "../../actions/notificationActions";
import Spinner from "../Utilities/Spinner";
import {Button} from "react-bootstrap";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import Pagination from "react-js-pagination";
import Notification from "./Notification";
import Rest from "../../core/Rest";

class Notifications extends Component {


    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            perPage: 10,
            total: 0
        };

        this.fetchNotifications = this.fetchNotifications.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.notifications.notificationList) {
            const result = props.notifications.notificationList;
            state.perPage = result.itemsPerPage;
            state.page = result.page;
            state.total = result.total;
        }

        return state;
    }

    componentDidMount() {
        this.fetchNotifications()
    }

    fetchNotifications(page = this.state.page, perPage = this.state.perPage) {
        this.props.actions.fetchNotificationList(page, perPage);
    }

    handlePageChange(page) {
        this.fetchNotifications(page)
    };

    readAll = () => {
        this.setState({readAllLoading: true});

        Rest.fetch({
            endpoint: 'notifications/read_all',
            method: 'POST'
        }).then(
            response => {
                this.setState({
                    page: 1
                }, () => {
                    this.fetchNotifications()
                });
            },
            error => {}
        ).then(() => {
            this.setState({readAllLoading: false});
        })
    };

    render() {

        let loading = null;
        if (this.props.notifications.isFetching) {
            loading = (<Spinner/>)
        }

        let notifications = [];
        if (this.props.notifications.notificationList) {
            notifications = this.props.notifications.notificationList.results.map((notification) => {

                return <Notification key={notification.id} notification={notification} onChange={() => {
                    this.fetchNotifications();
                }
                }/>

            });
        }

        return (
            <div>
                <div className='text-right' style={{marginBottom: '0.5em'}}>
                    <Button  onClick={this.readAll} disabled={this.state.readAllLoading}>
                        <FontAwesomeIcon icon='check'/> Alles Gelesen
                    </Button>
                </div>
                <div className='panel panel-default'>
                    <div className='panel-heading'>
                        <div className='pull-right'>
                            <Button bsSize="xsmall" disabled={!!loading} onClick={() => this.fetchNotifications()}><FontAwesomeIcon
                                icon='sync'/></Button>
                        </div>
                        <h3 className='panel-title'> Benachrichtigungen {loading}</h3>
                    </div>
                    <table className='table table-hover'>
                        <tbody>
                        {notifications}
                        </tbody>
                    </table>
                    <div className='panel-footer text-center'>
                        <Pagination
                            activePage={this.state.page}
                            itemsCountPerPage={this.state.perPage}
                            totalItemsCount={this.state.total}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchNotificationList}, dispatch)
    }
};

function mapStateToProps(state) {

    const {notifications} = state;

    return {notifications}
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);