import React, {Component} from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {Button} from 'react-bootstrap';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Spinner from '../../../Utilities/Spinner'

import {fetchUserList, fetchGroupList} from "../../../../actions/userActions";
import Rest from "../../../../core/Rest";
import UserRow from './UserRow';

class TicketUser extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isAdding: false,
            selectedUser: 0,
            selectedGroup: 0,
            arranger: {
                isFetching: false,
                list: []
            }
        };

        this.fetchArranger = this.fetchArranger.bind(this);
        this.onUserSelectChange = this.onUserSelectChange.bind(this);
        this.addLink = this.addLink.bind(this);
        this.addGroupLink = this.addGroupLink.bind(this);
        this.onUserGroupChange = this.onUserGroupChange.bind(this);

    };

    componentDidMount()
    {
        this.props.actions.fetchUserList();
        this.props.actions.fetchGroupList();
        this.fetchArranger();
    }

    onUserSelectChange(event)
    {
        this.setState({selectedUser: parseInt(event.target.value, 10)})
    }

    onUserGroupChange(event)
    {
        this.setState({selectedGroup: parseInt(event.target.value, 10)})
    }

    render() {

        let loading = null;
        if (this.state.arranger.isFetching) {
            loading = (<Spinner/>)
        }

        const userList = this.props.userList.userList.map((user) => {
            return <option key={user.id} value={user.id}>{user.firstname} {user.lastname} ({user.username})</option>
        });

        let addButton = <button className="btn btn-default btn-block" disabled={this.state.selectedUser <= 0} onClick={this.state.selectedUser <= 0?null:this.addLink}>
            <FontAwesomeIcon icon='plus'/> Nutzer Hinzufügen
        </button>;
        if(this.props.userList.isFetching || this.state.isAdding)
        {
            addButton = <button className="btn btn-default btn-block" disabled={true}>
                Loading...
            </button>
        }

        const groupList = this.props.groupList.groupList.map((group) => {
            return <option key={group.id} value={group.id}>{group.title}</option>
        });

        let addGroupButton = <button className="btn btn-default btn-block" disabled={this.state.selectedGroup <= 0} onClick={this.state.selectedGroup <= 0?null:this.addGroupLink}>
            <FontAwesomeIcon icon='plus'/> Gruppe Hinzufügen
        </button>;
        if(this.props.groupList.isFetching || this.state.isAdding)
        {
            addGroupButton = <button className="btn btn-default btn-block" disabled={true}>
                Loading...
            </button>
        }

        const arrangerList = this.state.arranger.list.map((arranger) => {

            return  <UserRow key={arranger.id} arranger={arranger} afterDelete={() => { this.fetchArranger()} } />
        });





        return <div className="panel panel-default">
            <div className="panel-heading">
                <div className='pull-right'>
                    <Button bsSize="xsmall" disabled={!!loading} onClick={this.fetchArranger}><FontAwesomeIcon
                        icon='sync'/></Button>
                </div>
                <h4 className="panel-title">Verknüpfte Nutzer {loading}</h4>
            </div>
            <div className="panel-body" style={{padding: 0}}>
                <div className='table-responsive'>
                    <table className="table" style={{marginBottom: 0}}>
                        <thead>
                        <tr>
                            <th className='col-md-9'>Nutzer</th>
                            <th className='col-md-2'>Aktion</th>
                            <th className='col-md-1'>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <select className="form-control" onChange={this.onUserSelectChange} value={this.state.selectedUser}>
                                    <option key={0} value={0} >Bitte Wählen</option>
                                    {userList}
                                </select>
                            </td>
                            <td>
                                {addButton}
                            </td>
                            <td/>
                        </tr>
                        <tr>
                            <td>
                                <select className="form-control" onChange={this.onUserGroupChange} value={this.state.selectedGroup}>
                                    <option key={0} value={0} >Bitte Wählen</option>
                                    {groupList}
                                </select>
                            </td>
                            <td>
                                {addGroupButton}
                            </td>
                            <td/>
                        </tr>
                        {arrangerList}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    }

    addLink()
    {

        Rest.fetch({
            endpoint: 'tickets/' + this.props.match.params.ticketId + '/user',
            method: 'POST',
            body: {
                userId: this.state.selectedUser
            },
        }).then(
            response => {
            },
            error => {
            }
        ).then(() => { this.fetchArranger() });
    }

    addGroupLink()
    {
        this.setState({isAdding: true});

        const groupId = this.state.selectedGroup;

        const object = this.props.groupList.groupList.find((value) => {
            return value.id === groupId;
        });

        let promises = [];

        object.users.forEach((user) => {

            let connected = false;
            this.state.arranger.list.forEach((connectedUser) => {
                if(connectedUser.user.id === user.user.id)
                {
                    connected = true;
                }
            });

            if(connected)
            {
                return;
            }

            const promise = Rest.fetch({
                endpoint: 'tickets/' + this.props.match.params.ticketId + '/user',
                method: 'POST',
                body: {
                    userId: user.user.id
                },
            });

            promises.push(promise)
        });

        Promise.all(promises).then(() => {
        }, () => {
        }).then(() => {
            this.setState({isAdding: false, selectedGroup: 0});
            this.fetchArranger();
        });

    }

    fetchArranger()
    {
        const arranger = this.state.arranger;

        arranger.isFetching = true;

        this.setState({arranger: arranger});

        Rest.fetch({
            endpoint: 'tickets/' + this.props.match.params.ticketId + '/user',
            method: 'GET',
        }).then(
            response => {
                arranger.isFetching = false;
                arranger.list = response.response;
                this.setState({arranger: arranger, selectedUser: 0});
            },
            error => {
                arranger.isFetching = false;
                this.setState({arranger: arranger, selectedUser: 0});
            }
        );
    }

}


const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchUserList, fetchGroupList}, dispatch),
    }
};

function mapStateToProps(state) {

    const {userList, groupList} = state;

    return {userList, groupList}
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketUser);