import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import moment from 'moment';
import {push} from "connected-react-router";
import {fetchOwnTickets} from "../../actions/ticketActions";
import {fetchUserOwnTicketTableConfig} from "../../actions/configActions";
import {store} from '../../core';
import {fetchStatusList} from "../../actions/statusActions";
import Rest from "../../core/Rest";
import {
    CardHeader,
    Chip,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Toolbar,
    Tooltip,
    Typography,
    withStyles
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MultiSelect from "../Utilities/MultiSelect";
import FilterDrawer from "../Utilities/FilterDrawer";
import Card from "@material-ui/core/Card";

const useStyles = theme => ({
    root: {
        marginBottom: theme.spacing(2)
    },
    title: {
        flex: '1 1 100%',
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    rowInfo: {
        backgroundColor: '#80D6FF',
    },
    rowWarning: {
        backgroundColor: '#FFD95B',
    },
    rowDanger: {
        backgroundColor: '#FF7961',
    },
    filterChip: {
        maxWidth: '250px'
    },
    filterFormControl: {}
});

class TicketTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            showFilter: false,
            isSaving: false,
            filter: {},
            pagination: {perPage: 5, total: 0, page: 1}
        };

        this.reload = this.reload.bind(this);
        this.reloadTableConfig = this.reloadTableConfig.bind(this);
        this.saveConfig = this.saveConfig.bind(this);
    }

    handleMenuClose = () => {
        this.setState({
            anchorEl: null
        })
    };

    handleMenuOpen = (event) => {
        this.setState({
            anchorEl: event.currentTarget
        })
    };

    componentDidMount() {
        this.props.actions.fetchStatusList();
        this.reloadTableConfig();
        this.componentConfig(this.props);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.componentConfig(this.props, prevProps);
    }

    handleSaveAndClose = () => {
        this.setState({showFilter: false}, () => {
            this.saveConfig()
        });
    }

    handleOpen = () => {
        this.setState({showFilter: true});
    }

    toggleFilterPanel = () => {
        const before = this.state.showFilter;
        this.setState({showFilter: !this.state.showFilter}, () => {
            if (before) {
                this.saveConfig();
            }
        })
    }

    async saveConfig() {
        this.setState({isSaving: true});

        await Rest.fetch({
            method: 'PUT',
            endpoint: 'users/' + this.props.userObject.id + '/config/ownTicketTable',
            body: {
                filter: {
                    status: this.state.filter.status
                }
            }
        }).then(
            response => {
                this.setState({isSaving: false});
                this.reloadTableConfig()
            },
            error => {
            }
        );
    }

    onStatusArrayChange = (values) => {
        let filter = this.state.filter;
        filter.status = values;
        this.setState({filter: filter});
    }

    componentConfig(data, prevProps = null) {

        if (data.tickets && (!prevProps || (!data.isFetching && (prevProps && prevProps.isFetching)))) {
            const {itemsPerPage, total, page} = data.tickets;

            let pagination = this.state.pagination;
            pagination.page = page;
            pagination.perPage = itemsPerPage;
            pagination.total = total;

            this.setState({pagination: pagination});
        }

        if (
            data.ownTicketTableConfig &&
            (!prevProps || (prevProps.ownTicketTableConfig.isFetching && !data.ownTicketTableConfig.isFetching))
            && typeof data.ownTicketTableConfig.config !== 'undefined') {
            const config = data.ownTicketTableConfig.config;

            let filter = this.state.filter;

            if (typeof config.filter.status !== 'undefined') {
                filter.status = config.filter.status;
            }

            this.setState({filter: filter}, () => {
                this.reload()
            });
        }
    }

    render() {

        const classes = this.props.classes

        let content = [];
        if (this.props.tickets) {
            const {results} = this.props.tickets;

            content = results.map((result) => {
                return Row(result, classes);
            })
        }

        const selectedStatus = [];
        let status = [];
        if (this.props.status) {
            status = this.props.status.map((data, id) => {
                    let checked = false
                    if (this.state.filter.status) {
                        checked = this.state.filter.status.indexOf(data.id) !== -1;
                    }

                    if (checked) {
                        selectedStatus.push(data.title);
                    }

                    return {label: data.title, value: data.id}
                }
            );
        }

        return <React.Fragment>
            <Card className={classes.root}>
                <CardHeader
                    title='Deine Tickets'
                    action={[
                        <Tooltip title='Filter' key={1}>
                            <IconButton onClick={this.toggleFilterPanel} disabled={this.props.statusIsFetching}>
                                <FilterListIcon/>
                            </IconButton>
                        </Tooltip>,
                        <Tooltip title='Mehr Optionen' key={2}>
                            <IconButton onClick={this.handleMenuOpen}>
                                <MoreVertIcon/>
                            </IconButton>
                        </Tooltip>
                    ]
                    }
                />
                <Toolbar variant='dense'>
                    {<Chip label={<Typography className={classes.filterChip} variant='body2'
                                              noWrap>Status: {Boolean(selectedStatus.length) ? selectedStatus.join(', ') : 'Alle'}</Typography>}/>}
                </Toolbar>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} size={'small'}>
                        <TableHead>
                            <TableRow>
                                <TableCell key={'id'}>ID</TableCell>
                                <TableCell key={'category'}>Kategorie</TableCell>
                                <TableCell key={'customer'}>Kunde</TableCell>
                                <TableCell key={'shortDescription'}>Kurzbeschreibung</TableCell>
                                <TableCell key={'status'}>Status</TableCell>
                                <TableCell key={'date'}>Datum</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {content}
                        </TableBody>
                    </Table>
                </div>
                <div className={classes.tableWrapper}>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        labelDisplayedRows={({ from, to, count }) => {
                            return `${from}-${to === -1 ? count : to} von ${count}`
                        }}
                        labelRowsPerPage={'Zeilen pro Seite:'}
                        component="div"
                        count={this.state.pagination.total}
                        rowsPerPage={this.state.pagination.perPage}
                        page={this.state.pagination.page - 1}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </div>
            </Card>
            <FilterDrawer open={this.state.showFilter} onClose={this.handleSaveAndClose}>
                <MultiSelect id='statusFilter' label='Status' placeholder='Status Filter' options={status}
                             className={classes.filterFormControl} values={this.state.filter.status ? this.state.filter.status : []}
                             onChange={(values) => this.onStatusArrayChange(values)}/>
            </FilterDrawer>
            <Menu
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleMenuClose}
            >
                <MenuItem disabled={this.props.isFetching} onClick={(event) => {
                    this.handleMenuClose(event);
                    this.reload();
                }}>Neu Laden</MenuItem>
            </Menu>
        </React.Fragment>;
    }

    reload() {
        this.props.actions.fetchOwnTickets(this.state.pagination.page, this.state.pagination.perPage, this.state.filter)
    }

    reloadTableConfig() {
        this.props.actions.fetchUserOwnTicketTableConfig()
    }

    handleChangePage = (event, pageNumber) => {
        let pagination = this.state.pagination;
        pagination.page = pageNumber + 1;
        this.setState({pagination: pagination});
        this.reload();
    }

    handleChangeRowsPerPage = event => {
        let pagination = this.state.pagination;
        pagination.page = 1;
        pagination.perPage = parseInt(event.target.value, 10);
        this.setState({pagination: pagination});
        this.reload();
    };
}

const Row = (result, classes) => {

    let className = null;
    if (result.status.id === 1) {
        if (result.importance >= 8) {
            className = 'rowDanger';
        } else if (result.importance >= 5) {
            className = 'rowWarning';
        } else if (result.importance >= 2) {
            className = 'rowInfo';
        }
    }

    return <TableRow
        className={className ? classes[className] : ''}
        hover
        onClick={() => {
            store.dispatch(push('/tickets/' + result.id))
        }}
        key={result.id}
    >
        <TableCell>{result.id}</TableCell>
        <TableCell>{result.category.title}</TableCell>
        <TableCell>{result.customer.customerName} ({result.customer.customerNo})</TableCell>
        <TableCell>{result.shortDescription}</TableCell>
        <TableCell>{result.status.title}</TableCell>
        <TableCell>{moment(result.inserted).format('DD/MM/YYYY HH:mm')}</TableCell>
    </TableRow>
};


const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchStatusList, fetchOwnTickets, fetchUserOwnTicketTableConfig}, dispatch),
    }
};

function mapStateToProps(state) {

    const {ticketOwn, config, statusList, user} = state;

    const status = statusList.status;
    const statusIsFetching = statusList.isFetching;

    const {ownTicketTableConfig} = config;
    const {userObject} = user;

    const {isFetching, tickets} = ticketOwn;

    return {isFetching, tickets, status, ownTicketTableConfig, userObject, statusIsFetching}
}

export default compose(
    withStyles(useStyles),
    connect(mapStateToProps, mapDispatchToProps))(TicketTable);
