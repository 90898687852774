import React, {Component} from 'react';
import {history} from "../../core";
import { Link as RouterLink } from 'react-router-dom';
import {translate} from '../../service/locationService';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import {withStyles} from "@material-ui/core";
import MuiBreadcrumbs from "@material-ui/core/Breadcrumbs";
const useStyles = theme => ({
    paper: {
        padding: theme.spacing(1, 2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
});

const InnerLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);

class Breadcrumbs extends Component {

    render() {

        const classes = this.props.classes;
        const pathname = history.location.pathname;

        let link = '';

        const paths = pathname.split('/');

        const breadcrumbs = paths.map((data, i) => {

            if (!data.length) {
                return '';
            }

            const name = translate(data);

            link += '/' + data;

            if (pathname !== link) {
                return <Link color="inherit" component={InnerLink} to={link} key={i}>
                    {name}
                </Link>
            }

            return <Typography key={i} color="textPrimary">{name}</Typography>;
        });

        let dashboard = <Typography key={-1} color="textPrimary">Dashboard</Typography>;

        if (pathname !== '/') {
            dashboard = <Link color="inherit" to="/" component={InnerLink}>
                Dashboard
            </Link>
        }


        return (
            <Paper elevation={0} className={classes.paper}>
                <MuiBreadcrumbs aria-label="breadcrumb">
                    {dashboard}
                    {breadcrumbs}
                </MuiBreadcrumbs>
            </Paper>
        );
    }
}

export default withStyles(useStyles)(Breadcrumbs);
