import React, {Component, Fragment} from 'react';
import moment from 'moment';
import {Modal} from 'react-bootstrap';
import ReactJson from 'react-json-view';

class LogEntry extends Component {


    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    };

    handleShow() {
        this.setState({isOpen: true});
    }

    handleClose() {
        this.setState({isOpen: false});
    }


    render() {
        const result = this.props.result;

        let userName = result.user;
        if (result.user === 1) {
            userName = 'SYSTEM'
        }
        else if (this.props.userList && result.user) {
            const user = this.props.userList.find((obj) => {
                return obj.id === result.user;
            });
            if (user) {
                userName = user.firstname + ' ' + user.lastname + ' (' + user.username + ')';
            }
        }

        let content = <div className='alert-info alert'>Keine weiteren Infos.</div>;
        if (result.context) {
            content = <ReactJson collapsed={3} src={JSON.parse(result.context)}/>
        }

        return <Fragment>
            <tr onClick={this.handleShow}>
                <td>{result.level.name}</td>
                <td>{result.message}</td>
                <td>{userName}</td>
                <td>{moment(result.timestamp).format('DD/MM/YYYY HH:mm:ss')}</td>
            </tr>
            <Modal backdropClassName='bootstrap' className='bootstrap' show={this.state.isOpen} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{result.message}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{overflow: 'auto'}}>
                    {content}
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-default" onClick={this.handleClose}>Schließen
                    </button>
                </Modal.Footer>
            </Modal>
        </Fragment>;


    }


}

export default LogEntry;
