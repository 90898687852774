import React, {Component} from 'react';
import Select from 'react-select';

class FieldRow extends Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(val) {
        const func = this.props.onChange;

        const data = this.props.data;
        data.source = val.value;

        if (typeof func === 'function') {
            func(data)
        }
    }

    render() {

        let value = null;
        const options = this.props.preview.header.map((header) => {
            const op = {value: header, label: header};

            if(op.value === this.props.data.source)
            {
                value = op;
            }

            return op
        });

        let example = '';
        if(this.props.data.source) {
            example = this.props.preview.content.filter((row) => {
                return typeof row[this.props.data.source] !== 'undefined'
            }).map((row) =>
            {
                let ex = row[this.props.data.source].toString();

                if(ex.length > 50)
                {
                    ex = ex.substr(0, 50) + '...';
                }

                return ex
            }).join(', ');
        }


        let name = this.props.data.target;
        let badge = '';
        if(this.props.fieldData)
        {
            name = this.props.fieldData.name;
            if(this.props.displayRequired && this.props.fieldData.required)
            {
                badge = <span className='label label-danger'>Pflichtfeld</span>
            }
        }

        return (
            <tr>
                <td>{name} {badge}</td>
                <td>
                    <Select
                        onChange={this.onChange}
                        value={value}
                        options={options}
                    />
                </td>
                <td>{example}</td>
            </tr>
        );
    }
}

export default FieldRow;
