import React, {Component} from 'react';
import {Button} from 'react-bootstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Rest from "../../../../core/Rest";
import Spinner from "../../../Utilities/Spinner";
import {Link} from 'react-router-dom'

class Settings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            active: false,
            categoryId: 0,
            importance: 0,
            linkUser: [],
            isFetching: false,
            isSaving: false,
            categories: [],
            users: [],
        };

        this.handleShow = this.handleShow.bind(this);
        this.fetchConfig = this.fetchConfig.bind(this);
        this.onModelChange = this.onModelChange.bind(this);
        this.onActiveChange = this.onActiveChange.bind(this);
        this.saveConfig = this.saveConfig.bind(this);
        this.onUserLinkChange = this.onUserLinkChange.bind(this);
    }

    componentDidMount()
    {
        this.fetchConfig();
    }

    handleShow() {
        this.modal.handleShow();
    }

    render() {

        let loading = null;
        if (this.state.isFetching) {
            loading = (<Spinner/>)
        }

        let categories = '';
        if (this.state.categories) {
            categories = this.state.categories.map((data, id) => {
                return <option key={data.id} value={data.id}>{data.title}</option>
            });
        }

        let users = '';
        if (this.state.users) {
            users = this.state.users.map((data, id) => {

                const userId = data.id.toString();
                const checked = (this.state.linkUser.indexOf(userId) !== -1);

                return <div key={data.id} className='col-md-4'>
                    <input
                        type="checkbox"
                        value={userId}
                        checked={checked}
                        onChange={this.onUserLinkChange}
                    /> {data.firstname} {data.lastname}
                    </div>
            });
        }

        return (
            <div className="panel panel-default">
                <div className="panel-heading">
                    <div className='pull-right'>
                        <Button bsSize="xsmall" disabled={!!loading} onClick={this.fetchConfig}><FontAwesomeIcon
                            icon='sync'/></Button>
                    </div>
                    <h4 className="panel-title">Support Tickets {loading}</h4>
                </div>
                <div className="panel-body">
                    <div className="alert alert-info">
                        <FontAwesomeIcon icon='info-circle'/>
                        Support Tickets ermöglichen es
                        nicht angemeldeten Nutzern unter&nbsp;
                        <Link target="_blank" to="/support/tickets/new" >
                            <b>/support/tickets/new</b>
                        </Link>
                        &nbsp;Tickets zu eröffenen.
                    </div>
                        <div className="form-group">
                            <input type="checkbox" onChange={this.onActiveChange} checked={this.state.active}/> <label>Aktiv</label>
                        </div>
                        <div className="form-group">
                            <label>Vordefinierte Kategorie der Angelegten Tickets</label>
                            <select className="form-control" value={this.state.categoryId} id='categoryId' onChange={this.onModelChange}>
                                <option value={0} >Bitte Wählen</option>
                                {categories}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Vordefinierte Priorität der Angelegten Tickets</label>
                            <div className="row" style={{marginTop: '10px'}}>
                                <div className='col-xs-11'>
                                    <input
                                        list='tickmarks'
                                        className='colorful-slider'
                                        id='importance'
                                        type='range'
                                        onChange={this.onModelChange}
                                        value={this.state.importance}
                                        min={0}
                                        max={10}
                                        step={1}/>
                                </div>
                                <div className='col-xs-1' style={{lineHeight: '5px'}}>
                                    <label className='label label-default'>
                                        {this.state.importance}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Vordefinierte Verknüpfte Nutzer</label>
                            <div className="row">
                                {users}
                            </div>
                        </div>
                        <button className="btn btn-primary"
                                disabled={this.state.isSaving || this.state.isFetching}
                                onClick={!this.state.isSaving ? this.saveConfig : null}
                        >Speichern</button>
                </div>
            </div>
        );
    }

    onUserLinkChange(event) {

        const value = event.target.value;
        const checked = event.target.checked;

        let linkUser = this.state.linkUser;

        if (checked) {
            linkUser.push(value);
        }
        else {
            linkUser = linkUser.filter((statusKey) => {

                return statusKey !== value
            });
        }

        this.setState({linkUser: linkUser});

    }

    onActiveChange(event)
    {
        this.setState({active: !!event.target.checked});
    }

    onModelChange(event) {

        const model = this.state;

        model[event.target.id] = event.target.value;

        this.setState(model);
    }

    fetchConfig()
    {
        this.setState({isFetching: true});

        let promises = [];

       promises.push(Rest.fetch({
            method: 'GET',
            endpoint: 'config/supportTicket',
        }).then(
            response => {

                const config = response.response.config;

                this.setState({
                    active: config.active,
                    categoryId: (config.categoryId > 0)?config.categoryId:0,
                    importance: config.importance,
                    linkUser: config.linkUser,
                })
            }
        ));

        promises.push(Rest.fetch({
            method: 'GET',
            endpoint: 'tickets/categories',
        }).then(
            response => {

                this.setState({
                    categories: response.response
                })
            }
        ));


        promises.push(Rest.fetch({
            method: 'GET',
            endpoint: 'users',
        }).then(
            response => {

                this.setState({
                    users: response.response
                })
            }
        ));

        Promise.all(promises).then(() => {
            this.setState({isFetching: false})
        }, ( err) => {
            console.log(err);
        });

    }

    saveConfig()
    {
        this.setState({isSaving: true});

        Rest.fetch({
            method: 'PUT',
            endpoint: 'config/supportTicket',
            body: {
                active: this.state.active,
                categoryId: this.state.categoryId,
                importance: this.state.importance,
                linkUser: this.state.linkUser,
            }
        }).then(
            response => {
                this.fetchConfig();
                this.setState({isSaving: false});
            },
            error => {
                this.fetchConfig();
                this.setState({isSaving: false});
            }
        );
    }
}

export default Settings;
