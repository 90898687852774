const map = {
    'login': 'Login',
    'tickets': 'Tickets',
    'customer': 'Kunden',
    'devices': 'Geräte',
    'settings': 'Einstellungen',
    'webspace': 'Webspace',
    'new': 'Neu',
    'edit': 'Bearbeiten',
    'user': 'Nutzer',
    'groups': 'Gruppen',
    'actions': 'Aktionen',
    'tasks': 'Aufgaben',
    'pdf': 'Pdf',
    'support_ticket': 'Support Ticket',
    'status': 'Status',
    'categories': 'Kategorien',
    'types': 'Typen ',
    'component_types': 'Komponenten Typen',
    'number_range': 'Nummernkreis',
    'additional_fields': 'Zusätzliche Felder',
    'map': 'Karte',
    'events': 'Termine',
    'import': 'Import',
    'email': 'Email',
    'vendidata': '4Vending',
    'data': 'Daten',
    'recurrence': 'Wiederkehrend',
    'export': 'Export',
    'calendar': 'Kalender',
    'component_events': 'Komponenten Termine',
    'components': 'Komponenten',
    'log': 'Log',
    'self': 'Eigene Daten',
    'password': 'Passwort',
    'files': "Dateien",
    'locations': 'Standplätze',
    'location': 'Standplatz',
    'search': 'Suche',
    'notifications': 'Benachrichtigungen',
    'requests': 'Anfragen',
    'statistic': 'Statistik',
    'addresses': 'Adressen',
    'profile': 'Profil'
};

export function translate(path) {
    if (map.hasOwnProperty(path)) {
        return map[path]
    }

    return path;
}
