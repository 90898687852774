import React, {Component} from 'react';
import TextEditor from "../Utilities/TextEditor";
import qs from "query-string";
import Rest from "../../core/Rest";
import Spinner from "../Utilities/Spinner";
import store from "../../core/store";
import ReCAPTCHA from "react-google-recaptcha";

const siteKey = '6LdL0RoUAAAAAEHogNPqF7lHo-HKYmOJ9inFglW1';

class New extends Component {

    constructor(props) {
        super(props);

        this.state = {
            deviceId: null,
            device: null,
            deviceIsFetching: false,
            isSaving: false,
            model: {
                deviceNo: '',
                firstName: '',
                lastName: '',
                telephoneNumber: '',
                email: '',
                shortDescription: '',
                description: '',
                captcha: ''
            }
        };

        this.parseQuery = this.parseQuery.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onModelChange = this.onModelChange.bind(this);
        this.onDescriptionChange = this.onDescriptionChange.bind(this);
        this.onCaptchaChange = this.onCaptchaChange.bind(this);
    }

    componentDidMount() {
        this.parseQuery()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevProps.match.params.deviceId !== this.props.match.params.deviceId)
        {
            this.parseQuery();
        }
        if (prevProps.location.search !== this.props.location.search) {
            this.parseQuery();
        }
    }

    parseQuery() {
        const query = qs.parse(this.props.location.search);

        if(this.props.match.params.deviceId > 0)
        {
            this.setState({deviceId: parseInt(this.props.match.params.deviceId, 10)}, () => {
                this.fetchDevice();
            });
            return;
        }

        if (query.deviceId > 0) {
            this.setState({deviceId: parseInt(query.deviceId, 10)}, () => {
                this.fetchDevice();
            });
        }
    }

    fetchDevice() {

        this.setState({
            deviceIsFetching: true
        });

        Rest.fetch({
            endpoint: 'support/devices/' + this.state.deviceId,
            auth: false,
        }).then(
            (response) => {
                this.setState({
                    device: response.response
                })
            },
            () => {
            }
        ).then(() => {
            this.setState({
                deviceIsFetching: false
            });
        })
    }

    onSubmit(event) {
        event.preventDefault();

        this.setState({
            isSaving: true
        });

        const body = Object.assign({}, this.state.model);

        if(this.state.deviceId && this.state.device)
        {
            body.deviceId = this.state.deviceId;
            delete body.deviceNo
        }

        if(!body.telephoneNumber.length)
        {
            delete body.telephoneNumber
        }

        if(!body.email.length)
        {
            delete body.email
        }

        Rest.fetch({
            endpoint: 'support/tickets',
            method: 'POST',
            body: body,
            auth: false,
        }).then(
            (response) => {
                store.dispatch({
                    type: 'ADD_ALERT',
                    message: 'Anfrage wurde versendet. Vielen Dank!',
                    style: 'success'
                });

                this.setState({
                    model: {
                        deviceNo: '',
                        firstName: '',
                        lastName: '',
                        telephoneNumber: '',
                        email: '',
                        shortDescription: '',
                        description: ''
                    }
                })
            },
            () => {
            }
        ).then(() => {
            this.setState({
                isSaving: false
            });

            this.refs.recaptcha.reset();
        })
    }

    onDescriptionChange(value) {
        const model = this.state.model;

        model.description = value;

        this.setState({model: model})
    }

    onCaptchaChange(value)
    {
        const model = this.state.model;

        model.captcha = value;

        this.setState({model: model})
    }

    onModelChange(event) {
        const model = this.state.model;

        model[event.target.name] = event.target.value;

        this.setState({model: model})
    }

    render() {

        let device = <div className="form-group">
            <label htmlFor="form_deviceNo">
                Gerätenummer <span className="badge">Pflicht</span>
            </label>
            <input type="text" className="form-control" placeholder="00000"
                   onChange={this.onModelChange} name='deviceNo' value={this.state.model.deviceNo}
                   required/>

        </div>;


        if (this.state.deviceIsFetching) {
            device = <div className="well well-sm">
                Gerät wird geladen <Spinner/>
            </div>
        }

        const deviceObject = this.state.device;
        if (deviceObject) {
            device = <div className="well well-sm">
                <b>{deviceObject.brand}</b> vom Typ <b>{deviceObject.type.title}</b> bei <b>{deviceObject.customer.customerName}</b> mit der Nummer <b>{deviceObject.deviceNo}</b>
            </div>
        }

        return (
            <div className='container'>
                <h1>Störung Melden</h1>
                <form onSubmit={this.onSubmit}>
                    {device}
                    <div className="form-group row">
                        <div className="col-xs-12 col-md-6">
                            <label>
                                Vorname <span className="badge">Pflicht</span>
                            </label>
                            <input className="form-control" placeholder="Max" required
                                   onChange={this.onModelChange} name='firstName' value={this.state.model.firstName}
                            />
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <label>
                                Nachname <span className="badge">Pflicht</span>
                            </label>
                            <input className="form-control" placeholder="Mustermann" required
                                   onChange={this.onModelChange} name='lastName' value={this.state.model.lastName}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-xs-12 col-md-6">
                            <label>
                                Telefonnummer
                            </label>
                            <input className="form-control" placeholder="+49 123 467890"
                                   onChange={this.onModelChange} name='telephoneNumber' value={this.state.model.telephoneNumber}
                            />
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <label htmlFor="form_email">
                                Email-Adresse
                            </label>
                            <input className="form-control" placeholder="max@mustermann.de"
                                   onChange={this.onModelChange} name='email' value={this.state.model.email}
                            />
                        </div>
                    </div>
                    <div className="alert alert-info">
                        Bitte geben Sie eine Telefonnummer oder Email-Adresse für eventuelle Rückfragen an.
                    </div>
                    <div className="form-group">
                        <label htmlFor="form_short">Kurzfassung des Problems (max. 200 zeichen) <span className="badge">Pflicht</span>
                        </label>
                        <textarea className="form-control" maxLength="200" onChange={this.onModelChange} name='shortDescription'
                                  value={this.state.model.shortDescription}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="form_description">Beschreibung des Problems <span className="badge">Pflicht</span></label>
                        <TextEditor
                            value={this.state.model.description}
                            onChange={this.onDescriptionChange}
                        />
                    </div>
                    <div className='form-group'>
                        <ReCAPTCHA
                            ref='recaptcha'
                            sitekey={siteKey}
                            onChange={this.onCaptchaChange}
                        />
                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary" onClick={this.onSubmit} disabled={this.state.isSaving}>
                            {this.state.isSaving ? 'Wird gesendet...' : 'Problem melden'}</button>
                    </div>
                </form>
            </div>
        );
    }
}

export default New;
