import {CALL_API} from '../middleware/api'

export const TASKS_LIST_REQUEST = 'TASKS_LIST_REQUEST';
export const TASKS_LIST_SUCCESS = 'TASKS_LIST_SUCCESS';
export const TASKS_LIST_FAILURE = 'TASKS_LIST_FAILURE';


export function fetchTasksList() {

    return {
        [CALL_API]: {
            endpoint: 'tickets/tasks',
            method: 'GET',
            types: [TASKS_LIST_SUCCESS, TASKS_LIST_FAILURE, TASKS_LIST_REQUEST]
        }
    }
}

