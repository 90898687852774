import {
    REQUEST_LIST_FAILURE, REQUEST_LIST_REQUEST, REQUEST_LIST_SUCCESS
} from '../actions/requestsActions'

export function requestReducer(state = {
    isFetching: false,
}, action) {

    switch (action.type) {
        case REQUEST_LIST_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case REQUEST_LIST_FAILURE:
            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch(e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case REQUEST_LIST_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                requestList: action.response
            });
        default:
            return state
    }
}

