import {CALL_API} from "../middleware/api";
import {push} from 'connected-react-router';

export const USER_REQUEST = 'USER_REQUEST';
export const USER_CLEAR = 'USER_CLEAR';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAILURE = 'USER_LIST_FAILURE';

export const GROUP_LIST_REQUEST = 'GROUP_LIST_REQUEST';
export const GROUP_LIST_SUCCESS = 'GROUP_LIST_SUCCESS';
export const GROUP_LIST_FAILURE = 'GROUP_LIST_FAILURE';

export function clearUser()
{
    return dispatch => {

        // We dispatch requestLogin to kickoff the call to the API
        dispatch({
            type: USER_CLEAR
        });
    }
}

// Uses the API middlware to get a USER
export function fetchUser() {
    const user = JSON.parse(localStorage.getItem('user')) || null;

    if (!user) {
        return dispatch => {

            // We dispatch requestLogin to kickoff the call to the API
            dispatch(push('/login'));
        }
    }

    return {
        [CALL_API]: {
            endpoint: 'users/' + user.id,
            parameter: {
                with: ['userPermissions']
            },
            types: [USER_SUCCESS, USER_FAILURE, USER_REQUEST]
        }
    }
}

export function fetchUserList() {

    return {
        [CALL_API]: {
            endpoint: 'users',
            types: [USER_LIST_SUCCESS, USER_LIST_FAILURE, USER_LIST_REQUEST]
        }
    }
}

export function fetchGroupList() {

    return {
        [CALL_API]: {
            endpoint: 'users/groups',
            types: [GROUP_LIST_SUCCESS, GROUP_LIST_FAILURE, GROUP_LIST_REQUEST]
        }
    }
}