import {
    EVENT_LIST_SUCCESS, EVENT_LIST_FAILURE, EVENT_LIST_REQUEST
} from '../actions/calendarActions'

export function eventListReducer(state = {
    isFetching: false,
}, action) {
    switch (action.type) {
        case EVENT_LIST_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case EVENT_LIST_FAILURE:

            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch(e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case EVENT_LIST_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                events: action.response
            });
        default:
            return state
    }
}

