import {CALL_API} from '../middleware/api'

export const EVENT_LIST_REQUEST = 'EVENT_LIST_REQUEST';
export const EVENT_LIST_SUCCESS = 'EVENT_LIST_SUCCESS';
export const EVENT_LIST_FAILURE = 'EVENT_LIST_FAILURE';

export function fetchCalendarEvents(start, end, link = null ) {

    const filter = {
        start: start,
        end: end,
    };

    if(link)
    {
        filter.link = link;
    }

    return {
        [CALL_API]: {
            endpoint: 'calendar/events',
            method: 'GET',
            parameter: {
                filter: filter
            },
            types: [EVENT_LIST_SUCCESS, EVENT_LIST_FAILURE, EVENT_LIST_REQUEST]
        }
    }
}
