import React, {Component} from 'react';
import moment from "moment/moment";
import {Button} from 'react-bootstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Rest from "../../../core/Rest";

class File extends Component {

    constructor(props) {
        super(props);

        this.onOpen = this.onOpen.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    render() {

        const file = this.props.file;

        const isFolder = (file.path.substr(file.path.length - 1) === '/') ? <FontAwesomeIcon icon='folder' style={{marginRight: '10px'}}/> : '';

        const preview = (['jpg','png','jpeg'].indexOf(file.path.split('.').pop()) !== -1)?<img src={file.url} alt='' height="50px"/>:'';

        return (
            <tr className='row'>
                <td className='col-md-2' onClick={(event) => {
                    this.props.onSelect(this.props.file)
                }} >
                    {preview}
                </td>
                <td className='col-md-7' onClick={(event) => {
                    this.props.onSelect(this.props.file)
                }} >
                    {isFolder}
                    {file.path}
                </td>
                <td className='col-md-2' onClick={(event) => {
                    this.props.onSelect(this.props.file)
                }} >
                    {moment(file.lastModified).format('DD/MM/YYYY HH:mm')}
                </td>
                <td className='col-md-1'>
                    <Button bsSize="xsmall" onClick={this.onOpen} style={{marginRight: '5px'}}>
                        <FontAwesomeIcon icon='external-link-alt'/>
                    </Button>
                    <Button bsSize="xsmall" bsStyle="danger" onClick={this.onDelete}>
                        <FontAwesomeIcon icon='trash'/>
                    </Button>
                </td>
            </tr>
        );
    }

    onOpen()
    {
        var win = window.open(this.props.file.url, '_blank');
        win.focus();
    }

    onDelete() {
        Rest.fetch({
            method: 'DELETE',
            endpoint: 'storage/'+this.props.file.type+'/' + this.props.file.path,
        }).then(
            response => {
            },
            error => {
            }
        );

        this.props.afterDelete();

    }

}

export default File;
