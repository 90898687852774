import React, {Component} from 'react';
import {Button} from 'react-bootstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Rest from "../../../../core/Rest";
import Spinner from "../../../Utilities/Spinner";
import store from "../../../../core/store";

class Settings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isFetching: false,
            isTesting : false,
            active: false,
            validationCode: null,
            chatId: null,
            isDetermine: false
        };

        this.fetchConfig = this.fetchConfig.bind(this);
        this.saveConfig = this.saveConfig.bind(this);
        this.testMessage = this.testMessage.bind(this);
        this.getChatId = this.getChatId.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
    }

    componentDidMount() {
        this.fetchConfig();
    }

    onCheckboxChange(event)
    {
        if(event.target.checked === this.state.active)
        {
            return;
        }
        this.setState({active: event.target.checked}, () => {
            this.saveConfig();
        })

    }


    render() {

        let loading = null;
        if (this.state.isFetching) {
            loading = (<Spinner/>)
        }
        return (
            <div className="panel panel-default">
                <div className="panel-heading">
                    <div className='pull-right'>
                        <Button bsSize="xsmall" disabled={!!loading} onClick={this.fetchConfig}><FontAwesomeIcon
                            icon='sync'/></Button>
                    </div>
                    <h4 className="panel-title">Telegram {loading}</h4>
                </div>
                <div className="panel-body">
                    <div className='form-group'>
                        <input
                            checked={this.state.active} onChange={this.onCheckboxChange}
                            type='checkbox' style={{marginRight: '1em'}}/>
                        <label>Aktiv</label>
                    </div>
                    <div className='form-group'>
                        <label>Chat ID</label>
                        <div className="alert alert-info">
                            Füge den Telegram Nutzer @VeCoDeskBot deinem Gruppenchat hinzu und schicke eine Nachricht mit
                            <code>@VeCoDeskBot {this.state.validationCode}</code>. Dann klicke auf Anfordern.
                        </div>
                        <div className="input-group">
                            <input disabled={true} value={this.state.chatId} className='form-control'/>
                            <span className="input-group-btn">
                            <button disabled={this.state.isDetermine} className="btn btn-default" onClick={this.getChatId}>Anfodern</button>
                        </span>
                        </div>
                    </div>
                    <button className='btn btn-primary btn-block'
                            disabled={this.state.isTesting} onClick={this.testMessage}>
                        Test Nachricht
                    </button>
                </div>
            </div>
        );
    }

    testMessage()
    {
        this.setState({isTesting: true});
        Rest.fetch({
            method: 'POST',
            endpoint: 'integrations/telegram/test_message',
        }).then(
            response => {
                store.dispatch({
                    type: 'ADD_ALERT',
                    message: 'Test Nachricht verschickt.',
                    style: 'success'
                });
            },
            error => {
            }
        ).then(() => {
            this.setState({isTesting: false});
        });
    }

    getChatId() {
        this.setState({isDetermine: true});
        Rest.fetch({
            method: 'GET',
            endpoint: 'integrations/telegram/chat_id',
        }).then(
            response => {
               this.fetchConfig();
            },
            error => {
            }
        ).then(() => {
            this.setState({isDetermine: false});
        });
    }

    fetchConfig() {
        this.setState({isFetching: true});
        Rest.fetch({
            method: 'GET',
            endpoint: 'config/telegram',
        }).then(
            response => {
                this.setState({
                    active: response.response.config.active,
                    chatId: response.response.config.chatId ? response.response.config.chatId : '',
                    validationCode: response.response.config.validationCode,
                })
            },
            error => {
            }
        ).then(() => {
            this.setState({isFetching: false});
        });
    }

    saveConfig() {
        this.setState({isSaving: true});

        Rest.fetch({
            method: 'PUT',
            endpoint: 'config/telegram',
            body: {
                active: this.state.active,
                chatId: this.state.chatId,
            }
        }).then(response => {
            }, error => {
            }
        ).then(() => {
            this.fetchConfig();
            this.setState({isSaving: false});
        });

    }
}

export default Settings;
