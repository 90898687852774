import React, {Component} from 'react';
import {connect} from "react-redux";
import {Button} from 'react-bootstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Rest from "../../../../core/Rest";
import Spinner from "../../../Utilities/Spinner";

class Settings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            start: 0,
            max: null,
            current: 0,
            isFetching: false,
            isSaving: false
        };

        this.fetchConfig = this.fetchConfig.bind(this);
        this.saveConfig = this.saveConfig.bind(this);
        this.onMaxCheckChange = this.onMaxCheckChange.bind(this);
        this.onIntChange = this.onIntChange.bind(this);
        this.resetCurrent = this.resetCurrent.bind(this);
    }

    componentDidMount() {
        this.fetchConfig();
    }

    onIntChange(event) {
        const state = this.state;
        state[event.target.name] = parseInt(event.target.value, 10);
        this.setState(state)
    }

    onMaxCheckChange(event) {

        let value = null;
        if (event.target.checked) {
            value = this.state.current;
        }
        this.setState({max: value})
    }

    resetCurrent()
    {
        this.setState({current: this.state.start})
    }

    render() {

        let loading = null;
        if (this.state.isFetching) {
            loading = (<Spinner/>)
        }

        return (
            <div className="panel panel-default">
                <div className="panel-heading">
                    <div className='pull-right'>
                        <Button bsSize="xsmall" disabled={!!loading} onClick={this.fetchConfig}><FontAwesomeIcon
                            icon='sync'/></Button>
                    </div>
                    <h4 className="panel-title">Nummernkreis {loading}</h4>
                </div>
                <div className="panel-body">
                    <div className='form-group'>
                        <label>Start</label>
                        <input name='start' onChange={this.onIntChange} className='form-control' type='number' placeholder='Start' value={this.state.start}/>
                    </div>
                    <div className='form-group'>
                        <label>Maximaler Wert</label>
                        <div className="input-group">
                        <span className="input-group-addon">
                            <input type="checkbox" onClick={this.onMaxCheckChange} checked={this.state.max !== null}/>
                        </span>
                            <input name='max' onChange={this.onIntChange} disabled={this.state.max === null} className='form-control' type='number' placeholder='Maximaler Wert'
                                   value={this.state.max ? this.state.max : ''}/>
                        </div>
                    </div>
                    <div className='form-group'>
                        <label>Aktueller Wert</label>
                        <div className="input-group">
                            <input disabled={true} className='form-control' type='number' placeholder='Aktueller Wert'
                                   value={this.state.current}/>
                            <span className="input-group-btn">
                            <button className="btn btn-danger" onClick={this.resetCurrent} type="button">Reset</button>
                        </span>
                        </div>
                    </div>
                </div>
                <div className='panel-footer'>
                    <button
                        type="submit"
                        className="btn btn-default"
                        disabled={this.state.isSaving || this.state.isFetching}
                        onClick={!this.state.isSaving ? this.saveConfig : null}
                    >
                        Speichern
                    </button>
                </div>
            </div>
        );
    }

    fetchConfig() {
        this.setState({isFetching: true});
        Rest.fetch({
            method: 'GET',
            endpoint: 'config/device',
        }).then(
            response => {

                const config = response.response.config.rangeConfig ? response.response.config.rangeConfig : {
                    start: 10000,
                    max: null,
                    current: 10000
                };


                this.setState({
                    start: config.start,
                    max: config.max ? config.max : null,
                    current: config.current,
                    isFetching: false
                })
            },
            error => {
                this.setState({isFetching: false});
            }
        );
    }

    saveConfig() {
        this.setState({isSaving: true});

        Rest.fetch({
            method: 'PUT',
            endpoint: 'config/device',
            body: {
                rangeConfig: {
                    max: this.state.max,
                    current: this.state.current,
                    start: this.state.start
                }
            }
        }).then(
            response => {
                this.fetchConfig();
                this.setState({isSaving: false});
            },
            error => {
                this.fetchConfig();
                this.setState({isSaving: false});
            }
        );
    }
}

export default connect()(Settings);
