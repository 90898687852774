import React, {Component} from 'react';
import {connect} from "react-redux";

class User extends Component {

    constructor(props) {
        super(props);

        this.onModelChange = this.onModelChange.bind(this);
        this.onActiveChange = this.onActiveChange.bind(this);
        this.onRoleChange = this.onRoleChange.bind(this);
    }

    render() {

        let role = 'ROLE_USER';
        if(this.props.user.roles)
        {
            role = this.props.user.roles[0];
        }

        let passwordField = null;
        if(this.props.passwordField)
        {
            passwordField =   <div className='form-group'>
                <label>Passwort</label>
                <input
                    type="password"
                    id='password'
                    placeholder="Password"
                    value={this.props.user.password ? this.props.user.password : ''}
                    onChange={this.onModelChange}
                    className='form-control'/>
            </div>
        }

        return (
            <div className="panel panel-default">
                <div className="panel-heading">
                    <h4 className="panel-title">User</h4>
                </div>
                <div className="panel-body">
                    <div className='form-group'>
                        <label>Nutzername</label>
                        <input
                            type="text"
                            id='username'
                            placeholder="Nutzername"
                            value={this.props.user.username ? this.props.user.username : ''}
                            onChange={this.onModelChange}
                            className='form-control'/>
                    </div>
                    <div className='form-group'>
                        <label>Vorname</label>
                        <input
                            type="text"
                            id='firstname'
                            placeholder="Vorname"
                            value={this.props.user.firstname ? this.props.user.firstname : ''}
                            onChange={this.onModelChange}
                            className='form-control'/>
                    </div>
                    <div className='form-group'>
                        <label>Nachname</label>
                        <input
                            type="text"
                            id='lastname'
                            placeholder="Nachname"
                            value={this.props.user.lastname ? this.props.user.lastname : ''}
                            onChange={this.onModelChange}
                            className='form-control'/>
                    </div>
                    <div className='form-group'>

                        <label>E-Mail</label>
                        <input
                            type="text"
                            id='email'
                            placeholder="E-Mail"
                            value={this.props.user.email ? this.props.user.email : ''}
                            onChange={this.onModelChange}
                            className='form-control'/>
                    </div>
                    {passwordField}
                    <div className='form-group'>
                        <input id='isActive' type="checkbox" checked={!!this.props.user.isActive} onChange={this.onActiveChange} />
                        &nbsp;<label>Aktiv</label>
                    </div>
                    <div className='form-group'>
                        <label>Rolle</label>
                        <select value={role} onChange={this.onRoleChange} className='form-control' >
                            <option value="ROLE_USER">Mitarbeiter</option>
                            <option value="ROLE_ADMIN" >Administrator</option>

                        </select>
                    </div>
                </div>
            </div>
        );
    }

    onModelChange(event) {
        this.props.onChange(event.target.id, event.target.value);
    }

    onRoleChange(event)
    {
        this.props.onChange('roles', [event.target.value]);
    }

    onActiveChange(event) {
        this.props.onChange(event.target.id, event.target.checked);
    }
}


export default connect()(User);
