import React, {Component} from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {push} from "connected-react-router";
import Rest from "../../../core/Rest";
import {Button, Modal} from 'react-bootstrap';
import {connect} from "react-redux";
import {fetchDeviceConfig} from "../../../actions/configActions";
import {bindActionCreators} from "redux";
import Spinner from "../../Utilities/Spinner";
import CustomerSelect from "../../Tickets/New/CustomerSelect";
import Select from 'react-select';

class CreateDevice extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showDeleteModal: false,
            showMoveModal: false,
            locationsFetching: false,
            typesFetching: false,
            isSaving: false,
            deviceFetching: false,
            locationList: [],
            typeList: [],
            deviceId: null,
            number: '',
            model: '',
            brand: '',
            typeId: 0,
            locationId: 0,
            customerId: null,
            newCustomerId: null,
            newLocationId: null,
            notes: '',
            newLocationsFetching: false,
            newLocationList: [],
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.saveDevice = this.saveDevice.bind(this);
        this.onModelChange = this.onModelChange.bind(this);
        this.onModelIntChange = this.onModelIntChange.bind(this);
        this.onModelCheckboxChange = this.onModelCheckboxChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.deleteDevice = this.deleteDevice.bind(this);
        this.handleMoveShow = this.handleMoveShow.bind(this);
        this.handleMoveClose = this.handleMoveClose.bind(this);
        this.onCustomerChange = this.onCustomerChange.bind(this);
        this.saveMove = this.saveMove.bind(this);
        this.fetchDevice = this.fetchDevice.bind(this);
    }

    handleClose() {
        this.setState({showDeleteModal: false});
    }

    handleShow() {
        this.setState({showDeleteModal: true});
    }

    handleMoveClose() {
        this.setState({showMoveModal: false});
    }

    handleMoveShow() {
        this.setState({showMoveModal: true});
    }

    componentDidMount() {
        this.props.actions.fetchDeviceConfig().then(() => {
            this.fetchDevice();
        });
    }

    onSubmit(event) {
        event.preventDefault();

        this.saveDevice()
    }

    onModelChange(event) {
        let state = this.state;

        state[event.target.name] = event.target.value;

        this.setState(state);
    }

    onModelIntChange(event) {
        let state = this.state;

        state[event.target.name] = parseInt(event.target.value, 10);

        this.setState(state);
    }

    onModelCheckboxChange(event) {
        let state = this.state;

        state[event.target.name] = event.target.checked;

        this.setState(state);
    }

    deleteDevice() {
        this.setState({isSaving: true});

        Rest.fetch({
            endpoint: 'devices/' + this.props.match.params.deviceId,
            method: 'DELETE'
        }).then(
            response => {
                this.handleClose();
                this.props.redirectToCustomer(this.state.customerId);
            }
        ).then(() => {
            this.setState({isSaving: false});
        });
    }

    saveMove() {
        this.setState({isSaving: true});

        const body = {
            locationId: this.state.newLocationId,
            customerId: this.state.newCustomerId,
        };

        Rest.fetch({
            endpoint: 'devices/' + this.props.match.params.deviceId,
            method: 'PATCH',
            body: body
        }).then(
            response => {
                this.handleMoveClose();
                this.fetchDevice();
            },
            error => {

            }
        ).then(() => {
            this.setState({isSaving: false});
        });
    }

    saveDevice() {
        this.setState({isSaving: true});

        const body = {
            deviceNo: this.state.number,
            model: this.state.model,
            brand: this.state.brand,
            typeId: this.state.typeId,
            locationId: this.state.locationId,
            notes: this.state.notes,
        };

        if (this.props.deviceConfig && this.props.deviceConfig.config) {
            const fieldConfig = this.props.deviceConfig.config.fieldConfig;

            Object.keys(
                fieldConfig
            ).filter((key) => {
                return fieldConfig[key] !== false
            }).forEach((key) => {
                if (typeof this.state['free' + key] !== 'undefined') {
                    body['free' + key] = this.state['free' + key]
                }
            });
        }

        Rest.fetch({
            endpoint: 'devices/' + this.props.match.params.deviceId,
            method: 'PATCH',
            body: body
        }).then(
            response => {
                this.fetchDevice();
            },
            error => {

            }
        ).then(() => {
            this.setState({isSaving: false});
        });
    }

    fetchDevice() {
        this.setState({deviceFetching: true});

        Rest.fetch({
            endpoint: 'devices/' + this.props.match.params.deviceId,
        }).then(
            response => {

                const device = response.response;

                const state = {
                    deviceId: device.id,
                    customerId: device.customer.id,
                    locationId: device.location.id,
                    typeId: device.type.id,
                    number: device.deviceNo,
                    model: device.model,
                    brand: device.brand,
                    notes: device.notes,
                };

                if (this.props.deviceConfig && this.props.deviceConfig.config) {
                    const fieldConfig = this.props.deviceConfig.config.fieldConfig;

                    Object.keys(
                        fieldConfig
                    ).filter((key) => {
                        return fieldConfig[key] !== false
                    }).forEach((key) => {
                        if (typeof device['free' + key] !== 'undefined') {
                            const value = fieldConfig[key];
                            if (value.type === 'bool') {
                                state['free' + key] = device['free' + key] === 'true';
                                return;
                            }
                            state['free' + key] = device['free' + key]
                        }
                    });
                }

                this.setState(state, () => {
                    this.fetchTypes();
                    this.fetchLocations();
                });
            },
            error => {
            }
        ).then(() => {
            this.setState({deviceFetching: false});
        });
    }

    fetchTypes() {
        this.setState({typesFetching: true});

        Rest.fetch({
            endpoint: 'devices/types',
        }).then(
            response => {
                this.setState({typeList: response.response})
            }
        ).then(() => {
            this.setState({typesFetching: false});
        });

    }

    onCustomerChange(value) {
        this.setState({newCustomerId: value}, () => {
            this.fetchNewLocations();
        });
    }

    fetchNewLocations() {
        if (!this.state.newCustomerId) {
            return;
        }

        this.setState({newLocationsFetching: true});

        const filter = {
            customer: this.state.newCustomerId
        };

        Rest.fetch({
            endpoint: 'customers/locations',
            parameter: {
                filter: filter
            }
        }).then(
            response => {
                this.setState({newLocationList: response.response})
            }
        ).then(() => {
            this.setState({newLocationsFetching: false});
        });

    }

    fetchLocations() {
        if (!this.state.customerId) {
            return;
        }

        this.setState({locationsFetching: true});

        const filter = {
            customer: this.state.customerId
        };

        Rest.fetch({
            endpoint: 'customers/locations',
            parameter: {
                filter: filter
            }
        }).then(
            response => {
                this.setState({locationList: response.response})
            }
        ).then(() => {
            this.setState({locationsFetching: false});
        });

    }

    onSelectChange(value, name)
    {
        let state = this.state;

        state[name] = parseInt(value, 10);

        this.setState(state);
    }

    render() {

        let loading = null;
        if (this.state.deviceFetching) {
            loading = (<Spinner/>)
        }

        const locationSelectList = {};
        const locationSelectListCache = {};
        this.state.locationList.sort((a, b) => {
            const textA = a.title.toUpperCase();
            const textB = b.title.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }).forEach((location) => {

            const addressId = location.address.id;

            if(!locationSelectList[addressId])
            {
                locationSelectList[addressId] = {
                    label: location.address.identifier,
                    options: []
                }
            }

            const object =  {
                value: location.id,
                label: location.title
            };

            locationSelectListCache[object.value] = object;
            locationSelectList[addressId].options.push(object)

        });


        const newLocationList = {};
        const newLocationListCache = {};
        this.state.newLocationList.sort((a, b) => {
            const textA = a.title.toUpperCase();
            const textB = b.title.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }).forEach((location) => {

            const addressId = location.address.id;

            if(!newLocationList[addressId])
            {
                newLocationList[addressId] = {
                    label: location.address.identifier,
                    options: []
                }
            }

            const object =  {
                value: location.id,
                label: location.title
            };

            newLocationListCache[object.value] = object;
            newLocationList[addressId].options.push(object)

        });

        const typeList = this.state.typeList.sort((a, b) => {
            const textA = a.title.toUpperCase();
            const textB = b.title.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }).map((type) => {
            return <option key={type.id} value={type.id}>{type.title}</option>
        });

        let fields = [];
        if (this.props.deviceConfig && this.props.deviceConfig.config) {
            const fieldConfig = this.props.deviceConfig.config.fieldConfig;

            fields = Object.keys(
                fieldConfig
            ).filter((key) => {
                return fieldConfig[key] !== false
            }).map((key) => {
                const value = fieldConfig[key];

                const selected = this.state['free' + key];

                if (value.type === 'bool') {
                    return <div className="form-group" key={'free' + key}>
                        <input name={'free' + key} onChange={this.onModelCheckboxChange} checked={typeof selected === 'undefined'  ? false : selected}
                               type='checkbox'/>
                        &nbsp;
                        <label>{value.name}</label>
                    </div>;
                }

                return <div className="form-group" key={'free' + key}>
                    <label>{value.name}</label>
                    <input name={'free' + key} onChange={this.onModelChange} value={typeof selected === 'undefined' ? '' : selected} type='text'
                           className='form-control'/>
                </div>;
            });
        }

        return (
            <div>
                <div className="btn-bar">
                    <button
                        className='btn btn-default'
                        disabled={!this.state.customerId || !this.state.deviceId}
                        onClick={() => {
                            this.props.redirectToDevice(this.state.customerId, this.state.deviceId)
                        }}
                    >
                        <FontAwesomeIcon icon='angle-left'/> Zurück
                    </button>
                </div>
                <div className="panel-default panel">
                    <div className="panel-heading">
                        <div className='pull-right'>
                            <Button bsSize="xsmall" disabled={!!loading}
                                    onClick={this.fetchDevice}><FontAwesomeIcon
                                icon='sync'/></Button>
                        </div>
                        <h4 className="panel-title">Gerät Bearbeiten {loading}</h4>
                    </div>
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="deviceId">Gerätenummer</label>
                                    <input className="form-control" type="number" placeholder="Gerätenummer." name='number'
                                           value={this.state.number} onChange={this.onModelChange}
                                           required={true}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="brand">Marke</label>
                                    <input className="form-control" name='brand' type="text" onChange={this.onModelChange}
                                           value={this.state.brand} placeholder="Marke" required={true}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="model">Modell</label>
                                    <input className="form-control" name="model" type="text" onChange={this.onModelChange}
                                           value={this.state.model} placeholder="Model" required={true}/>
                                </div>
                                <fieldset>
                                    <legend>Zusätzliche Felder</legend>
                                    {fields}
                                </fieldset>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="location">Standplatz</label>
                                    <Select
                                        onChange={(val) => {
                                            this.onSelectChange(val.value, 'locationId');
                                        }}
                                        isDisabled={this.state.locationsFetching}
                                        value={locationSelectListCache[this.state.locationId]}
                                        options={Object.values(locationSelectList)}
                                        formatGroupLabel={(data) => <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}>
                                            <span>{data.label}</span>
                                        </div>}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="type">Typ</label>
                                    <select className="form-control" name="typeId" value={this.state.typeId}
                                            onChange={this.onModelIntChange} required>
                                        <option value={0}>Bitte Wählen</option>
                                        {typeList}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="notes">Notizen</label>
                                    <textarea name="notes" className="form-control" onChange={this.onModelChange} value={this.state.notes}
                                              style={{resize: 'none', height: '200px'}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel-footer">
                        <button className="btn btn-default" style={{marginRight: '5px'}} onClick={this.saveDevice}
                                disabled={this.state.isSaving}>
                            <FontAwesomeIcon icon='save'/> Speichern
                        </button>
                        <button className="btn btn-default" onClick={this.handleMoveShow} disabled={this.state.isSaving}>
                            <FontAwesomeIcon icon='arrows-alt'/> Umziehen
                        </button>
                        <div className='pull-right'>
                            <Button onClick={this.handleShow} bsStyle='danger' type='button' disabled={this.state.isSaving}>
                                <FontAwesomeIcon icon='trash'/> Löschen
                            </Button>
                        </div>
                    </div>
                </div>
                <Modal backdropClassName='bootstrap' className='bootstrap' show={this.state.showMoveModal} onHide={this.handleMoveClose} bsSize='large'>
                    <Modal.Header closeButton>
                        <Modal.Title>Umziehen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CustomerSelect value={this.state.newCustomerId} onChange={this.onCustomerChange}/>
                        <div className="form-group">
                            <label htmlFor="location">Standplatz</label>
                            <Select
                                onChange={(val) => {
                                    this.onSelectChange(val.value, 'newLocationId');
                                }}
                                isLoading={this.state.newLocationsFetching}
                                value={newLocationListCache[this.state.newLocationId]}
                                options={Object.values(newLocationList)}
                                formatGroupLabel={(data) => <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}>
                                    <span>{data.label}</span>
                                </div>}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-default" onClick={this.handleMoveClose}>Schließen
                        </button>
                        <Button
                            bsStyle="primary"
                            disabled={this.state.isSaving || this.state.isFetching}
                            onClick={this.saveMove}
                        >
                            <FontAwesomeIcon icon='arrows-alt'/> Umziehen
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal backdropClassName='bootstrap' className='bootstrap' show={this.state.showDeleteModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Löschen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Wirklich Löschen?
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-default" onClick={this.handleClose}>Schließen
                        </button>
                        <Button
                            bsStyle="danger"
                            disabled={this.state.isFetching}
                            onClick={this.deleteDevice}
                        >
                            <FontAwesomeIcon icon='trash'/> Löschen
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

const mapDispatchToProps = (dispatch) => {

    return {
        actions: bindActionCreators({fetchDeviceConfig}, dispatch),
        redirectToDevice: (customerId, deviceId) => {
            dispatch(push('/customer/' + customerId + '/devices/' + deviceId))
        },
        redirectToCustomer: (customerId) => {
            dispatch(push('/customer/' + customerId))
        }
    }
};

function mapStateToProps(state) {

    const {config} = state;
    const {deviceConfig} = config;

    return {deviceConfig}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateDevice);