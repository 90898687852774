import React, { Component } from 'react';

class NoMatch extends Component {

    render() {

        return (
           <div>Seite nicht gefunden.</div>
        );
    }
}

export default NoMatch;
