import React, {Component, Fragment} from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {Button, Modal} from 'react-bootstrap';

import Spinner from '../../../Utilities/Spinner';
import Rest from "../../../../core/Rest";
import Customer from './Customer';
import DeviceSelect from '../../Utilities/DeviceSelect';

class TicketDevices extends Component {

    constructor(props) {
        super(props);

        this.state = {
            deviceList: {
                isFetching: false,
                devices: []
            },
            isDeleting: false,
            isSaving: false,
            modalIsFetching: false,
            devicesToAdd: [],
            linkDeviceModal: false,
            deleteDeviceModal: false
        };

        this.fetchDeviceList = this.fetchDeviceList.bind(this);
        this.onDeviceChange = this.onDeviceChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCloseWarning = this.handleCloseWarning.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleShowWarning = this.handleShowWarning.bind(this);
        this.addDevices = this.addDevices.bind(this);
        this.onLoadingChange = this.onLoadingChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);

    };

    componentDidMount() {
        this.fetchDeviceList();
    }

    handleClose() {
        this.setState({linkDeviceModal: false});
    }

    handleShow() {
        this.setState({linkDeviceModal: true});
    }

    handleCloseWarning() {
        this.setState({deleteDeviceModal: false});
    }

    handleShowWarning() {
        this.setState({deleteDeviceModal: true});
    }

    onDeviceChange(device) {
        const devicesList = this.state.deviceList;

        devicesList.devices[device.key] = device;

        this.setState({devicesList: devicesList});
    }

    handleDelete() {

        let promises = [];
        this.setState({isDeleting: true});

        this.state.deviceList.devices.forEach((device) => {
            if (device.checked) {
                const promise = Rest.fetch({
                    endpoint: 'tickets/' + this.props.match.params.ticketId + '/devices/' + device.id,
                    method: 'DELETE'
                });

                promises.push(promise);

            }
        });

        Promise.all(promises).then(() => {
        }, () => {
        }).then(() => {
            this.setState({isDeleting: false});
            this.handleCloseWarning();
            this.fetchDeviceList();
        });

    }

    render() {

        let loading = null;
        if (this.state.deviceList.isFetching) {
            loading = (<Spinner/>)
        }

        let modalLoading = null;
        if (this.state.modalIsFetching) {
            modalLoading = (<Spinner/>)
        }

        let deleting = null;
        if (this.state.isDeleting) {
            deleting = (<Spinner/>)
        }

        const customer = {};

        this.state.deviceList.devices.forEach((device) => {

            if (!customer[device.device.customer.id]) {
                customer[device.device.customer.id] = {
                    customer: device.device.customer,
                    devices: []
                };
            }

            customer[device.device.customer.id].devices.push(device);

        });

        const onDeviceChange = this.onDeviceChange;

        const deviceList = Object.keys(customer).map(function (key) {
            return <Customer key={key} customer={customer[key]} onDeviceChange={onDeviceChange}/>
        });

        return <Fragment>
            <div className='panel panel-default'>
                <div className='panel-heading'>
                    <div className='pull-right'>
                        <Button bsSize="xsmall" disabled={!!loading} onClick={this.fetchDeviceList}><FontAwesomeIcon
                            icon='sync'/></Button>
                    </div>
                    <h4 className='panel-title'>Verknüpfte Geräte {loading}</h4>
                </div>
                {deviceList}
                <div className='panel-footer'>
                    <Button bsStyle='success' onClick={this.handleShow} style={{marginRight: '5px'}}>
                        <FontAwesomeIcon icon='plus'/> Geräte Hinzufügen
                    </Button>
                    <Button bsStyle='danger' onClick={this.handleShowWarning}>
                        <FontAwesomeIcon icon='minus'/> Geräte Entfernen
                    </Button>
                </div>
            </div>
            <Modal backdropClassName='bootstrap' className='bootstrap' show={this.state.linkDeviceModal} onHide={this.handleClose} bsSize="large"
                   dialogClassName="ticket-add-device-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Geräte Hinzufügen {modalLoading}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{padding: 0}}>
                    <DeviceSelect panel={false} onChange={(devices) => {
                        this.setState({devicesToAdd: devices})
                    }} onLoading={this.onLoadingChange}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" bsStyle='success' onClick={this.addDevices} disabled={this.state.isSaving}>
                        <FontAwesomeIcon icon='plus'/> Hinzufügen
                    </Button>
                    <button type="button" className="btn btn-default" onClick={this.handleClose}>
                        Schließen
                    </button>
                </Modal.Footer>
            </Modal>
            <Modal backdropClassName='bootstrap' className='bootstrap' show={this.state.deleteDeviceModal} onHide={this.handleCloseWarning}>
                <Modal.Header closeButton>
                    <Modal.Title>Geräte Entfernen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Ausgewählte Geräte wirklich löschen?
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" bsStyle='danger' onClick={this.handleDelete} disabled={!!deleting}>
                        <FontAwesomeIcon icon='minus'/> Entfernen {deleting}
                    </Button>
                    <button type="button" className="btn btn-default" onClick={this.handleCloseWarning}>
                        Schließen
                    </button>
                </Modal.Footer>
            </Modal>
        </Fragment>;
    }

    onLoadingChange(isLoading) {
        this.setState({modalIsFetching: isLoading})
    }

    addDevices() {

        this.setState({isSaving: true});

        this.state.devicesToAdd.forEach((id) => {

            Rest.fetch({
                endpoint: 'tickets/' + this.props.match.params.ticketId + '/devices',
                body: {
                    deviceId: id
                },
                method: 'POST',
            }).then(
                response => {
                    this.fetchDeviceList();
                    this.handleClose();
                },
                error => {
                }
            ).then(() => {
                this.setState({isSaving: false});
            });
        });
    }

    fetchDeviceList() {

        let ticketDevices = this.state.deviceList;

        ticketDevices.isFetching = true;

        this.setState({deviceList: ticketDevices});

        Rest.fetch({
            endpoint: 'tickets/' + this.props.match.params.ticketId + '/devices',
            parameter: {
                with: ['customerAddresses']
            },
            method: 'GET',
        }).then(
            response => {
                ticketDevices.isFetching = false;
                ticketDevices.devices = response.response;

                ticketDevices.devices.map((device, key) => {
                    device.checked = false;
                    device.key = key;
                    return device;
                });

                this.setState({deviceList: ticketDevices});

            },
            error => {
                ticketDevices.isFetching = false;
                this.setState({deviceList: ticketDevices});
            }
        );

    }

}


export default TicketDevices;