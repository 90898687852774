import {
    USER_CONFIG_ownTicketTableConfig_REQUEST, USER_CONFIG_ownTicketTableConfig_FAILURE, USER_CONFIG_ownTicketTableConfig_SUCCESS,
    CONFIG_mapConfig_REQUEST, CONFIG_mapConfig_FAILURE, CONFIG_mapConfig_SUCCESS,
    CONFIG_deviceConfig_REQUEST, CONFIG_deviceConfig_FAILURE, CONFIG_deviceConfig_SUCCESS,
    USER_CONFIG_defaultBookmark_FAILURE, USER_CONFIG_defaultBookmark_REQUEST, USER_CONFIG_defaultBookmark_SUCCESS
} from '../actions/configActions'

function configReducer(state = {
    ownTicketTableConfig: {},
    defaultBookmarkConfig: {},
    mapConfig: {},
    deviceConfig: {}
}, action) {

    switch (action.type) {
        case USER_CONFIG_ownTicketTableConfig_REQUEST: {
            let object = {
                ownTicketTableConfig: {
                    isFetching: true,
                }
            };

            return Object.assign({}, state, object);
        }
        case USER_CONFIG_ownTicketTableConfig_FAILURE: {
            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch (e) {
                error = action.error;
            }

            let object = {
                ownTicketTableConfig: {
                    isFetching: false,
                    error: error,
                }
            };

            return Object.assign({}, state, object);
        }
        case USER_CONFIG_ownTicketTableConfig_SUCCESS:

            const response = action.response;

            let object = {
                ownTicketTableConfig: {
                    isFetching: false,
                    config: response.config
                }
            };

            return Object.assign({}, state, object);
        case USER_CONFIG_defaultBookmark_REQUEST: {
            let object = {
                defaultBookmarkConfig: {
                    isFetching: true
                }
            };

            return Object.assign({}, state, object);
        }
        case USER_CONFIG_defaultBookmark_FAILURE: {
            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch (e) {
                error = action.error;
            }

            let object = {
                defaultBookmarkConfig: {
                    isFetching: false,
                    error: error,
                }
            };

            return Object.assign({}, state, object);
        }
        case USER_CONFIG_defaultBookmark_SUCCESS:

            const defResponse = action.response;

            let defObject = {
                defaultBookmarkConfig: {
                    isFetching: false,
                    config: Array.isArray(defResponse.config)?{}:defResponse.config
                }
            };

            return Object.assign({}, state, defObject);
        case CONFIG_mapConfig_REQUEST: {
            return Object.assign({}, state, {
                mapConfig: {
                    isFetching: true,
                }
            });
        }
        case CONFIG_mapConfig_FAILURE: {
            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch (e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                mapConfig: {
                    isFetching: false,
                    error: error,
                }
            });
        }
        case CONFIG_mapConfig_SUCCESS:

            return Object.assign({}, state, {
                mapConfig: {
                    isFetching: false,
                    config: action.response.config
                }
            });

        case CONFIG_deviceConfig_REQUEST: {
            return Object.assign({}, state, {
                deviceConfig: {
                    isFetching: true,
                }
            });
        }
        case CONFIG_deviceConfig_FAILURE: {
            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch (e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                deviceConfig: {
                    isFetching: false,
                    error: error,
                }
            });
        }
        case CONFIG_deviceConfig_SUCCESS:
            return Object.assign({}, state, {
                deviceConfig: {
                    isFetching: false,
                    config: action.response.config
                }
            });
        default:
            return state
    }
}

export default configReducer