import {CALL_API} from '../middleware/api'

export const BOOKMARK_LIST_REQUEST = 'BOOKMARK_LIST_REQUEST';
export const BOOKMARK_LIST_SUCCESS = 'BOOKMARK_LIST_SUCCESS';
export const BOOKMARK_LIST_FAILURE = 'BOOKMARK_LIST_FAILURE';


export function fetchBookmarkList() {

    return {
        [CALL_API]: {
            endpoint: 'tickets/filter_bookmarks',
            method: 'GET',
            types: [BOOKMARK_LIST_SUCCESS, BOOKMARK_LIST_FAILURE, BOOKMARK_LIST_REQUEST]
        }
    }
}

