import React, {Component} from 'react';
import {connect} from "react-redux";
import {Button, FormControl, Modal} from 'react-bootstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

class Row extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        }
    };

    render() {

        const tag = this.props.tag;

        return (
            <tr key={tag.id}>
                <td>{tag.id}</td>
                <td>
                    <FormControl
                        type="text"
                        value={tag.title}
                        name='title'
                        onChange={this.handleChange}
                    />
                </td>
                <td>
                    <FormControl
                        type="color"
                        value={tag.color}
                        name='color'
                        onChange={this.handleChange}
                    />
                </td>
                <td style={{width: '50px'}}>
                    <Button
                        bsStyle="danger"
                        onClick={this.handleShow}
                    >
                        <FontAwesomeIcon icon='trash'/> Löschen
                    </Button>
                    <Modal show={this.state.showModal} onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Löschen</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Tag wirklich löschen?
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-default" onClick={this.handleClose}>Schließen
                            </button>
                            <Button
                                bsStyle="danger"
                                onClick={this.handleDelete}
                            >
                                <FontAwesomeIcon icon='trash'/> Löschen
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </td>
            </tr>

        );
    }


    handleClose = () => {
        this.setState({showModal: false});
    }

    handleShow = () => {
        this.setState({showModal: true});
    }
    handleDelete = () => {
        this.handleClose();
        this.props.onDelete(this.props.tag.id);
    }

    handleChange = (event) => {
        this.props.onChange(this.props.tag.id, event.target.value, event.target.name)
    }

}

export default connect()(Row);
