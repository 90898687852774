import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Button} from 'react-bootstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {I18n} from "react-i18next";
import TextEditor from '../../Utilities/TextEditor';
import Rest from "../../../core/Rest";
import Spinner from '../../Utilities/Spinner'

const intervalList = [
    '1d',
    '7d',
    '1m',
    '3m',
    '6m',
    '1y'
];

class UserSelect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            categories: null,
            isFetching: false,
        };

        this.fetchCategories = this.fetchCategories.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.onModelChange = this.onModelChange.bind(this);
        this.onDescriptionChange = this.onDescriptionChange.bind(this);
    };

    componentDidMount() {
        this.fetchCategories()
    }

    render() {

        let loading = null;
        if (this.state.isFetching) {
            loading = (<Spinner/>)
        }

        let categories = null;
        if (this.state.categories) {
            categories = this.state.categories.sort((a, b) => {
                const textA = a.title.toUpperCase();
                const textB = b.title.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            }).map((result) => {
                return <option key={result.id} value={result.id}>{result.title}</option>
            })
        }

        let intervals = intervalList.map((interval) => {
            return <I18n key={interval} ns={['ticketInterval']}>{(t) => (
                <option key={interval} value={interval}>{t(interval)}</option>
            )}</I18n>
        });

        return <div className="panel panel-default">
            <div className="panel-heading">
                <div className='pull-right'>
                    <Button bsSize="xsmall" disabled={!!loading} onClick={this.fetchCategories}><FontAwesomeIcon
                        icon='sync'/></Button>
                </div>
                <h4 className="panel-title">Daten {loading}</h4>
            </div>
            <div className="panel-body newTicketBottomPanel">
                <div className="form-group">
                    <input
                        id="private" type='checkbox'
                        onChange={this.onCheckboxChange} checked={this.props.value.private}/>
                    &nbsp;<label>Unsichtbares Ticket</label>
                </div>
                <div className="form-group">
                    <label>Kategorie</label>
                    <select id="category" className="form-control" onChange={this.onModelChange} value={this.props.value.category}>
                        <option value="-1"> -- Bitte Wählen --</option>
                        {categories}
                    </select>
                </div>
                <div className="form-group">
                    <label>Gemeldet von</label>
                    <input id="reporter" className="form-control" onChange={this.onModelChange} value={this.props.value.reporter}/>
                </div>
                <div className="form-group">
                    <label>Kurzbeschreibung(max. 200)</label>
                    <textarea name="shortDescription" id="shortDescription" className="form-control"
                              maxLength="200"
                              style={{resize: 'none'}}
                              onChange={this.onModelChange}
                              value={this.props.value.shortDescription}
                    />
                </div>
                <div className="form-group">
                    <label>Beschreibung</label>
                    <TextEditor
                        id='description'
                        value={this.props.value.description}
                        onChange={this.onDescriptionChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="importance">Priorität</label>
                    <div className="row" style={{marginTop: '10px'}}>
                        <div className='col-xs-11'>
                            <input
                                list='tickmarks'
                                className='colorful-slider'
                                id='importance'
                                type='range'
                                onChange={this.onModelChange}
                                value={this.props.value.importance}
                                min={0}
                                max={10}
                                step={1}/>
                        </div>
                        <div className='col-xs-1' style={{lineHeight: '5px'}}>
                            <label className='label label-default'>
                                {this.props.value.importance}
                            </label>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label>Wiederkehrend</label>
                    <select id="recurrence" className="form-control" onChange={this.onModelChange}
                            value={this.props.value.recurrence}>
                        <option value={0}>Nie</option>
                        {intervals}
                    </select>
                </div>
            </div>
        </div>;
    }

    onDescriptionChange(value) {
        this.props.onChange('description', value)
    }

    onModelChange(event) {

        const field = event.target.id;
        const value = event.target.value;

        this.props.onChange(field, value)

    }

    onCheckboxChange(event) {

        const field = event.target.id;
        const value = event.target.checked;

        this.props.onChange(field, value)

    }

    fetchCategories() {

        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'tickets/categories',
        }).then(
            response => {
                this.setState({categories: response.response, isFetching: false})
            },
            error => {
                this.setState({isFetching: false})
            }
        )
    }


}


const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({}, dispatch),
    }
};

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSelect);