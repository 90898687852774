import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Collapse, Button, ButtonGroup, ButtonToolbar, Modal} from 'react-bootstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {store} from '../../../core';
import {fetchMapConfig} from "../../../actions/configActions";
import Pagination from 'react-js-pagination';
import Spinner from '../../Utilities/Spinner'
import {Link} from 'react-router-dom'
import Rest from "../../../core/Rest";
import {push} from "connected-react-router";
import {typeList} from '../../../service/qrTypeService'

class List extends Component {

    availableLimits = [
        1,
        10,
        20,
        50,
        100,
        200
    ];

    constructor(props) {
        super(props);

        this.state = {
            extendedSearch: false,
            customerSearchString: '',
            deviceSearchString: '',
            pagination: {
                page: 1,
                perPage: 50,
                total: 0
            },
            deviceList: {
                results: [],
                isFetching: false
            },
            groupFunctionModal: false,
            groupFunctions: [],
            selectedQrType: '',
            selectedDevices: [],
            groupFunctionExecuted: false
        };

        this.toggleExtendedSearch = this.toggleExtendedSearch.bind(this);
        this.onSearchStringChange = this.onSearchStringChange.bind(this);
        this.perPageHandler = this.perPageHandler.bind(this);
        this.fetchDevices = this.fetchDevices.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onGroupFunctionCheckboxChange = this.onGroupFunctionCheckboxChange.bind(this);
        this.handleQrTypeChange = this.handleQrTypeChange.bind(this);
        this.onDeviceCheckboxChange = this.onDeviceCheckboxChange.bind(this);
        this.executeGroupFunction = this.executeGroupFunction.bind(this);
        this.gfCallbacks = this.gfCallbacks.bind(this);
        this.onDeviceCheckboxAllChange = this.onDeviceCheckboxAllChange.bind(this);
    }

    handleOpen() {
        this.setState({groupFunctionModal: true});
    }

    handleClose() {
        this.setState({groupFunctionModal: false});
    }


    componentDidMount() {
        this.fetchDevices();
    }

    toggleExtendedSearch() {
        this.setState({extendedSearch: !this.state.extendedSearch});
    }

    onSearchStringChange(event) {
        const state = this.state;
        state[event.target.name] = event.target.value;
        this.setState(state);
    }

    perPageHandler(event) {
        let pagination = this.state.pagination;

        pagination.perPage = parseInt(event.target.value, 10);

        this.setState({pagination: pagination});

        this.fetchDevices();
    }

    onSubmit(event) {
        event.preventDefault();
        this.fetchDevices();
    }

    gfCallbacks() {
        return {
            generateQr: (promises) => {

                if (!this.state.selectedQrType) {
                    return;
                }

                const deviceList = [];

                this.state.selectedDevices.forEach((deviceId) => {
                    deviceList.push({
                        type: this.state.selectedQrType,
                        id: deviceId
                    })
                });

                promises.push(
                    Rest.fetch({
                        endpoint: 'devices/qr',
                        method: 'POST',
                        body: deviceList
                    }).then((response) => {
                        if (response.response.failed.length) {
                            store.dispatch({
                                type: 'ADD_ALERT',
                                message: 'Es sind einige Fehler aufgetreten',
                                style: 'danger'
                            });
                            return;
                        }

                        store.dispatch({
                            type: 'ADD_ALERT',
                            message: 'Qr Code werden generiert.',
                            style: 'success'
                        });

                    }));
            }
        }
    };

    executeGroupFunction() {
        this.setState({groupFunctionExecuted: true});

        const promises = [];

        const callbacks = this.gfCallbacks();

        this.state.groupFunctions.forEach((value) => {
            if (!callbacks.hasOwnProperty(value)) {
                return;
            }

            callbacks[value](promises);
        });

        Promise.all(promises).then(() => {
        }, () => {
        }).then(() => {
            this.setState({groupFunctionExecuted: false, groupFunctionModal: false, selectedDevices: [], groupFunctions: []});
        })

    }

    onGroupFunctionCheckboxChange(event) {
        const value = event.target.value;
        const checked = event.target.checked;

        let groupFunctions = this.state.groupFunctions;

        if (checked) {
            groupFunctions.push(value);
        }
        else {
            groupFunctions = groupFunctions.filter((key) => {

                return key !== value
            });
        }

        this.setState({groupFunctions: groupFunctions});
    }

    onDeviceCheckboxChange(event) {
        const value = event.target.value;
        const checked = event.target.checked;

        let selectedDevices = this.state.selectedDevices;

        if (checked) {
            selectedDevices.push(value);
        }
        else {
            selectedDevices = selectedDevices.filter((key) => {

                return key !== value
            });
        }

        this.setState({selectedDevices: selectedDevices});
    }

    onDeviceCheckboxAllChange(event) {
        const checked = event.target.checked;

        if (checked) {

            const selectedDevices = this.state.deviceList.results.map((device) => {
                return device.id.toString();
            });
            this.setState({selectedDevices: selectedDevices});
        }
        else {
            this.setState({selectedDevices: []});

        }
    }


    handleQrTypeChange(event) {
        this.setState({selectedQrType: event.target.value});
    }

    fetchDevices() {

        const deviceList = this.state.deviceList;
        const pagination = this.state.pagination;

        deviceList.isFetching = true;

        this.setState({deviceList: deviceList});

        let filter = {};
        if (this.state.customerSearchString) {
            filter.customerData = this.state.customerSearchString;
        }

        if (this.state.deviceSearchString) {
            filter.deviceData = this.state.deviceSearchString;
        }

        let parameter = {
            perPage: this.state.pagination.perPage,
            page: this.state.pagination.page,
            filter: filter
        };

        Rest.fetch({
            method: 'GET',
            endpoint: 'devices',
            parameter: parameter,
        }).then(
            response => {

                const result = response.response;

                pagination.perPage = result.itemsPerPage;
                pagination.total = result.total;
                pagination.page = result.page;
                deviceList.results = result.results;

                this.setState({deviceList: deviceList, pagination: pagination});
            },
            error => {
            }
        ).then(() => {
            deviceList.isFetching = false;
            this.setState({deviceList: deviceList});
        })
    }

    handlePageChange(pageNumber) {
        let pagination = this.state.pagination;
        pagination.page = pageNumber;
        this.setState({pagination: pagination});

        this.fetchDevices();
    }

    render() {

        let loading = null;
        if (this.state.deviceList.isFetching) {
            loading = (<Spinner/>)
        }

        let limits = '';
        if (this.availableLimits) {
            limits = this.availableLimits.map((data) => {
                return <option key={data} value={data}>{data}</option>
            })
        }

        const pageCount = Math.ceil(this.state.pagination.total / this.state.pagination.perPage);


        const content = this.state.deviceList.results.map((device) => {

            const onClick = () => {
                store.dispatch(push('/customer/' + device.customer.id + '/devices/' + device.id))
            };

            return <tr key={device.id}>
                <td>
                    <input type='checkbox' value={device.id.toString()}
                           onChange={this.onDeviceCheckboxChange}
                           checked={this.state.selectedDevices.indexOf(device.id.toString()) !== -1}
                    />
                </td>
                <td onClick={onClick}>{device.deviceNo}</td>
                <td onClick={onClick}>{device.brand}</td>
                <td onClick={onClick}>{device.model}</td>
                <td onClick={onClick}>{device.type.title}</td>
                <td onClick={onClick}>{device.location.address.identifier}</td>
                <td onClick={onClick}>{device.location.title}</td>
                <td onClick={onClick}>{device.customer.customerNo}</td>
                <td onClick={onClick}>{device.customer.customerName}</td>
            </tr>

        });


        const options = typeList('device').map((value, key) => {
            return <option key={key} value={value.type}>{value.name}</option>
        });

        return (
            <Fragment>
                <div className='row'>
                    <div className="col-md-3">
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <h4 className="panel-title">Suche</h4>
                            </div>
                            <div className="panel-body">
                                <form onSubmit={this.onSubmit}>
                                    <div className="form-group">
                                        <label>Geräte Daten</label>
                                        <input className="form-control" placeholder="Suche" name='deviceSearchString'
                                               value={this.state.deviceSearchString} onChange={this.onSearchStringChange}/>
                                    </div>
                                    <Collapse in={this.state.extendedSearch}>
                                        <div className="form-group">
                                            <label htmlFor="customerSearch">Kunden Daten</label>
                                            <input className="form-control" type="search" placeholder="Suche"
                                                   name='customerSearchString' value={this.state.customerSearchString}
                                                   onChange={this.onSearchStringChange}/>
                                        </div>
                                    </Collapse>
                                    <p>
                                        <button type="submit" value="search" className="btn btn-default"
                                                style={{marginRight: '5px'}} onClick={this.onSubmit}>Suchen
                                        </button>
                                        <Button bsStyle="primary" active={this.state.extendedSearch}
                                                onClick={this.toggleExtendedSearch}>
                                            Erweiterte Suche
                                        </Button>
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-9'>
                        <div className='btn-bar'>
                            <Link className='btn btn-default' role='button' to='/devices/new' style={{marginRight: '5px'}}>
                                <FontAwesomeIcon
                                    icon='plus'/> Neu Anlegen
                            </Link>
                            <button className='btn btn-default' onClick={this.handleOpen}>
                                Gruppenfunktion
                            </button>
                        </div>
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <div className='pull-right'>
                                    <ButtonToolbar>
                                        <ButtonGroup bsSize="xsmall">
                                            <Button disabled={!!loading} onClick={this.fetchDevices}>
                                                <FontAwesomeIcon icon='sync'/></Button>
                                        </ButtonGroup>
                                    </ButtonToolbar>
                                </div>
                                <div className="form-inline" style={{marginBottom: 0, marginRight: '5px', display: 'inline'}}>
                                    <select className="form-control form-inline" id="perPage" value={this.state.pagination.perPage}
                                            onChange={this.perPageHandler}>
                                        {limits}
                                    </select>
                                </div>
                                <span style={{marginRight: '5px'}}>{this.state.pagination.total} auf {pageCount} Seiten</span>
                                <span style={{marginBottom: 0, fontSize: '150%'}}>{loading}</span>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-hover">
                                    <thead>
                                    <tr>
                                        <th>
                                            <input type='checkbox' onChange={this.onDeviceCheckboxAllChange}
                                                   checked={this.state.selectedDevices.length === this.state.deviceList.results.length}
                                            />
                                        </th>
                                        <th>GeräteNr.</th>
                                        <th>Marke</th>
                                        <th>Modell</th>
                                        <th>Typ</th>
                                        <th>Standort</th>
                                        <th>Standplatz</th>
                                        <th>KundenNr.</th>
                                        <th>Kundenname</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {content}
                                    </tbody>
                                </table>
                            </div>
                            <div className='panel-footer text-center'>
                                <Pagination
                                    activePage={this.state.pagination.page}
                                    itemsCountPerPage={this.state.pagination.perPage}
                                    totalItemsCount={this.state.pagination.total}
                                    pageRangeDisplayed={5}
                                    onChange={this.handlePageChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Modal backdropClassName='bootstrap' className='bootstrap' show={this.state.groupFunctionModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Gruppenfunktion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                            <div className='col-md-1'>
                                <input type='checkbox' value='generateQr'
                                       checked={this.state.groupFunctions.indexOf('generateQr') !== -1}
                                       onChange={this.onGroupFunctionCheckboxChange}/>
                            </div>
                            <div className='col-md-3'>
                                QrCode generieren
                            </div>
                            <div className='col-md-8'>
                                <select className='form-control' value={this.state.selectedQrType} onChange={this.handleQrTypeChange}>
                                    <option value={''}>Bitte wählen</option>
                                    {options}
                                </select>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-default" onClick={this.handleClose}
                        >Schließen
                        </button>
                        <Button
                            bsStyle="primary"
                            onClick={this.executeGroupFunction}
                            disabled={this.state.groupFunctionExecuted}
                        >
                            Ausführen
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchMapConfig}, dispatch),
    }
};

function mapStateToProps(state) {

    const {config} = state;
    const {mapConfig} = config;

    return {mapConfig}
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
