import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormControl, Button,Modal} from 'react-bootstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

class Row extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);

        this.state = {categoryId: undefined, showModal: false}
    };

    render() {

        const category = this.props.category;

        let categories = '';
        if (this.props.categories) {
            categories = this.props.categories.map((data, id) => {

                if(data.id ===this.props.category.id){
                    return null;
                }

                return <option key={data.id} value={data.id}>{data.title}</option>
            });
        }

        return (
            <tr key={category.id}>
                <td>{category.id}</td>
                <td>
                    <FormControl
                        type="text"
                        value={category.title}
                        onChange={this.handleChange}
                    />
                </td>
                <td style={{width: '50px'}}>
                    <Button
                        bsStyle="danger"
                        onClick={this.handleShow}
                    >
                        <FontAwesomeIcon icon='trash'/> Löschen
                    </Button>
                    <Modal backdropClassName='bootstrap' className='bootstrap' show={this.state.showModal} onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Löschen</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <select value={this.state.categoryId} onChange={this.onSelectChange} id="category"
                                    className="form-control">
                                <option key={-1} value={undefined}>Bitte Wählen</option>
                                {categories}
                            </select>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-default" onClick={this.handleClose}>Schließen
                            </button>
                            <Button
                                bsStyle="danger"
                                onClick={this.handleDelete}
                            >
                                <FontAwesomeIcon icon='trash'/> Löschen
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </td>
            </tr>

        );
    }


    handleClose() {
        this.setState({showModal: false});
    }

    handleShow() {
        this.setState({showModal: true});
    }

    onSelectChange(event)
    {
        this.setState({categoryId: event.target.value});
    }

    handleDelete(event)
    {
        this.handleClose();
        this.props.onDelete(this.props.category.id,this.state.categoryId);
    }

    handleChange(event)
    {
       this.props.onChange(this.props.category.id,event.target.value)
    }

}

export default connect()(Row);
