import React, {Component} from 'react';
import {Route, Switch} from "react-router"
import NoMatch from "../NoMatch/NoMatch";
import ChangelogPanel from './ChangelogPanel';
import Alerts from "../Alerts/Alerts"
import Tickets from '../Tickets/Tickets';
import Menu from "../Settings/Menu";
import DataMenu from "../Data/Menu";
import {connect} from "react-redux";
import Customers from "../Customers/Customers";
import Search from "../Search/Search";
import Devices from "../Devices/Devices";
import Notifications from "../Notifications/Notifications";
import Requests from "../Requests/Requests";
import Router from "../Calendar/Router";
import StatisticRouter from "../Statistic/Router";
import Base from "../Profil/Base";
import {compose} from "redux";
import {Grid, Toolbar, withStyles} from "@material-ui/core";
import Breadcrumbs from "../Navigation/Breadcrumbs";
import Navigation from "../Navigation/Navigation";
import Sitebar from "../Help/Sitebar";
import clsx from "clsx";
import TicketTable from "./TicketTable";
import QueueStatus from './QueueStatus'
import StatusPanel from './StatusPanel'

const useStyles = theme => ({
    root: {
        // display: 'flex',
    },
    main: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginLeft: '56px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0
        },
    },
    content: {
        padding: theme.spacing(0),
    }
});

class Dashboard extends Component {

    render() {
        const match = this.props.match;
        const classes = this.props.classes;

        return (
            <div className={clsx(classes.root, 'main')}>
                <Navigation/>
                <main className={classes.main}>
                    <Alerts/>
                    <Toolbar variant='dense'/>
                    <Breadcrumbs/>
                    <div className={classes.content}>
                        <Switch>
                            <Route exact path={match.url} render={() => (
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TicketTable/>
                                        <QueueStatus/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <ChangelogPanel/>
                                        <StatusPanel/>
                                    </Grid>
                                </Grid>
                            )}/>
                            <Route path={`${match.url}tickets`} component={Tickets}/>
                            <Route component={() => (
                                <div className='bootstrap'>
                                    <Switch>
                                        <Route path={`${match.url}customer`} component={Customers}/>
                                        <Route path={`${match.url}devices`} component={Devices}/>
                                        <Route path={`${match.url}notifications`} component={Notifications}/>
                                        <Route path={`${match.url}requests`} component={Requests}/>
                                        <Route path={`${match.url}data`} component={DataMenu}/>
                                        <Route path={`${match.url}search`} component={Search}/>
                                        <Route path={`${match.url}settings`} component={Menu}/>
                                        <Route path={`${match.url}calendar`} component={Router}/>
                                        <Route path={`${match.url}statistic`} component={StatisticRouter}/>
                                        <Route path={`${match.url}profile`} component={Base}/>
                                        <Route component={NoMatch}/>
                                    </Switch>
                                </div>
                            )}/>
                        </Switch>
                    </div>
                </main>
                <Sitebar/>
            </div>
        );
    }
}

function mapStateToProps(state) {

    const {helpPanel} = state;

    return {helpPanel}
}

export default compose(
    withStyles(useStyles),
    connect(mapStateToProps, null))(Dashboard);