import React, {Component} from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {CardHeader, IconButton, Menu, MenuItem, Tooltip} from "@material-ui/core";
import MuiCard from '@material-ui/core/Card';

class Card extends Component {

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null
        };
    }

    handleMenuClose = () => {
        this.setState({
            anchorEl: null
        })
    };

    handleMenuOpen = (event) => {
        this.setState({
            anchorEl: event.currentTarget
        })
    };

    render() {

        const actions = (this.props.actions || []).slice();
        const classes = this.props.classes
        const menu = (this.props.menu || []).slice();

        actions.push(<Tooltip title='Mehr Optionen'>
            <IconButton onClick={this.handleMenuOpen}>
                <MoreVertIcon/>
            </IconButton>
        </Tooltip>);

        menu.push(<MenuItem disabled={this.props.isFetching} onClick={(event) => {
            this.handleMenuClose(event);
            this.reload();
        }}>Neu Laden</MenuItem>)

        return <React.Fragment>
            <MuiCard className={classes}>
                <CardHeader
                    title={this.props.title}
                    subheader={this.props.subtitle || null}
                    action={actions}
                />
                {this.props.children}
            </MuiCard>
            <Menu
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleMenuClose}
            >
                {menu}
            </Menu>
        </React.Fragment>
    }

    reload = () => {
        if(this.props.reload) {
            this.props.reload()
        }
    }

}

export default Card
