import React, {Component} from 'react';
import {connect} from "react-redux";
import {Route, Switch} from "react-router";
import CustomerList from './List/List';
import NoMatch from "../NoMatch/NoMatch";
import ShowCustomer from "./Show/ShowCustomer";
import CreateCustomer from "./Create/CreateCustomer";
import EditCustomer from "./Edit/EditCustomer";

class Customers extends Component {
    render() {
        const match = this.props.match;

        return (
            <Switch>
                <Route exact path={match.url} component={CustomerList} />
                <Route exact path={`${match.url}/new`} component={CreateCustomer} />
                <Route exact path={`${match.url}/:id/edit`} component={EditCustomer} />
                <Route path={`${match.url}/:id`} component={ShowCustomer} />
                <Route component={NoMatch}/>
            </Switch>

        );
    }
}

export default connect()(Customers);
