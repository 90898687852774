import React, {Component} from 'react';
import {connect} from "react-redux";
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import {Button, Modal} from 'react-bootstrap';
import {push} from "connected-react-router";

import Spinner from '../../../Utilities/Spinner'
import Rest from "../../../../core/Rest";
import Permissions from './Permissions';
import User from './User';
import Groups from './Groups';
import {store} from "../../../../core";

class Edit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: {},
            isFetching: false,
            showDeleteModal: false
        };

        this.fetchUser = this.fetchUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.saveUser = this.saveUser.bind(this);
        this.onChangeGroups = this.onChangeGroups.bind(this);
        this.onChangePermissions = this.onChangePermissions.bind(this);
        this.onChangeUser = this.onChangeUser.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
    };

    componentDidMount() {
        this.fetchUser();
    }

    handleClose() {
        this.setState({showDeleteModal: false});
    }

    handleShow() {
        this.setState({showDeleteModal: true});
    }

    render() {

        let loading = null;
        if (this.state.isFetching) {
            loading = (<Spinner/>)
        }

        let permissions = [];
        if (this.state.user.permissions) {
            permissions = this.state.user.permissions;
        }

        let groups = [];
        if (this.state.user.groups) {
            groups = this.state.user.groups;
        }

        let isAdmin = false;
        if (this.state.user.roles) {
            isAdmin = this.state.user.roles.indexOf('ROLE_ADMIN') !== -1;
        }


        return (
            <div>
                <div className='btn-bar'>
                    <Link className='btn btn-default' role='button' to='/settings/user'>
                        <FontAwesomeIcon icon='angle-left'/> Zurück
                    </Link>
                </div>
                <div className="row">
                    <div className="col-md-7">
                        <User user={this.state.user} onChange={this.onChangeUser}/>
                    </div>
                    <div className="col-md-5">
                        <Groups groups={groups} onChange={this.onChangeGroups}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Permissions permissions={permissions} onChange={this.onChangePermissions} checkAll={isAdmin}
                                     disable={isAdmin}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="panel panel-default">
                            <div className="panel-heading">

                            </div>
                            <div className="panel-body">
                                <Button
                                    style={{marginRight: '5px'}}
                                    bsStyle="default"
                                    disabled={this.state.isFetching}
                                    onClick={!this.state.isFetching ? this.saveUser : null}
                                >
                                    <FontAwesomeIcon icon='save'/> Speichern
                                </Button>
                                <Button disabled={!!loading} onClick={this.fetchUser}><FontAwesomeIcon
                                    icon='sync'/></Button>
                                <div className='pull-right'>
                                    <Button
                                        bsStyle="danger"
                                        disabled={this.state.isFetching}
                                        onClick={!this.state.isFetching ? this.handleShow : null}
                                    >
                                        <FontAwesomeIcon icon='trash'/> Löschen
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal backdropClassName='bootstrap' className='bootstrap' show={this.state.showDeleteModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Löschen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Wirklich Löschen?
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-default" onClick={this.handleClose}>Schließen
                        </button>
                        <Button
                            bsStyle="danger"
                            onClick={this.deleteUser}
                        >
                            <FontAwesomeIcon icon='trash'/> Löschen
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

    onChangeUser(key, value)
    {
        let user = Object.assign({},this.state.user);

        user[key] = value;
        this.setState({user: user});
    }

    onChangePermissions(permissions) {
        let user = this.state.user;

        user.permissions = permissions;

        this.setState({user: user});
    }

    onChangeGroups(groups) {

        let user = this.state.user;

        user.groups = groups;

        this.setState({user: user});
    }

    fetchUser() {
        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'users/' + this.props.match.params.id,
            parameter: {
                with: ['userPermissions','userGroups']
            }
        }).then(
            response => {
                this.setState({user: response.response, isFetching: false})
            },
            error => {
                this.setState({isFetching: false})
            }
        )

    }

    deleteUser(event) {
        this.setState({isFetching: true, showDeleteModal: false});

        Rest.fetch({
            endpoint: 'users/' + this.props.match.params.id,
            method: 'DELETE'
        }).then(
            response => {
                this.setState({isFetching: false})
                store.dispatch(push('/settings/user'))
            },
            error => {
                this.setState({isFetching: false})
            }
        )

    }
    saveUser(event) {

        this.setState({isFetching: true});

        let permissions = [];
        if(this.state.user.permissions) {
            permissions = this.state.user.permissions.map((value) => {
                return value.permissionKey
            });
        }
        let groups = [];
        if(this.state.user.groups) {
            groups = this.state.user.groups.map((value) => {
                return value.id
            });
        }

        Rest.fetch({
            endpoint: 'users/' + this.props.match.params.id,
            method: 'PATCH',
            body: {
                permissions: [...new Set(permissions)],
                email: this.state.user.email,
                username: this.state.user.username,
                firstname: this.state.user.firstname,
                isActive: this.state.user.isActive,
                lastname: this.state.user.lastname,
                roles: this.state.user.roles,
                groups: groups
            }
        }).then(
            response => {
                this.setState({isFetching: false})
            },
            error => {
                this.setState({isFetching: false})
            }
        ).then(() => {
            this.fetchUser()
        })
    }
}


export default connect()(Edit);
