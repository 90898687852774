import React, {Component} from 'react';
import {connect} from "react-redux";
import {Route, Switch} from "react-router"
import {Link} from 'react-router-dom';
import NoMatch from "../NoMatch/NoMatch";
import Webspace from "./Webspace";
import Import from "./Import/Import";
import Search from "./Log/Search";
import Export from "./Export/Export";

class Menu extends Component {

    render() {
        const match = this.props.match;

        return (
            <div className='row'>
                <div className="col-md-2">
                    <div className="panel-group">
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <h4 className="panel-title">
                                    <Link to={match.url + '/webspace'}>Webspace</Link>
                                </h4>
                            </div>
                        </div>
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <h4 className="panel-title">
                                    <Link to={match.url + '/import'}>Import</Link>
                                </h4>
                            </div>
                        </div>
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <h4 className="panel-title">
                                    <Link to={match.url + '/export'}>Export</Link>
                                </h4>
                            </div>
                        </div>
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <h4 className="panel-title">
                                    <Link to={match.url + '/log'}>Log</Link>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-10">
                    <Switch>
                        <Route exact path={match.url} render={() => (
                            <div/>
                        )}/>
                        <Route path={`${match.url}/import`} component={Import}/>
                        <Route path={`${match.url}/export`} component={Export}/>
                        <Route path={`${match.url}/webspace`} component={Webspace}/>
                        <Route path={`${match.url}/log`} component={Search}/>
                        <Route component={NoMatch}/>
                    </Switch>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {

    const {user} = state;

    const {isFetching, userObject} = user;

    return {isFetching, userObject}
}

export default connect(mapStateToProps, null)(Menu);
