import React, {Component} from 'react';
import {connect} from "react-redux";
import Rest from "../../../core/Rest";

class ShowRequest extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isFetching: false,
            link: null
        };

        this.fetchArranger = this.fetchArranger.bind(this);
        this.acceptRequest = this.acceptRequest.bind(this);
        this.declineRequest = this.declineRequest.bind(this);
    };

    componentDidMount() {
        this.fetchArranger();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.ticketId && this.props.ticketId) {
            this.fetchArranger();
        }
    }


    fetchArranger() {
        if (!this.props.ticketId) {
            return;
        }

        this.setState({isFetching: true, link: null});

        Rest.fetch({
            endpoint: 'tickets/' + this.props.ticketId + '/user',
            method: 'GET',
        }).then(
            response => {

                response.response.forEach((link) => {
                    if (!link.confirmed && link.user.id === this.props.userObject.id) {
                        this.setState({link: link})
                    }
                });
            },
            error => {
            }
        ).then(() => {
            this.setState({isFetching: false});
        });
    }

    acceptRequest() {
        const link = this.state.link;
        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'tickets/' + link.ticket.id + '/user/' + link.id + '/confirm',
            method: 'PATCH',
        }).then(
            response => {
            },
            error => {
            }
        ).then(() => {
            this.setState({isFetching: false});
            this.fetchArranger();
        });
    }

    declineRequest() {
        const link = this.state.link;
        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'tickets/' + link.ticket.id + '/user/' + link.id,
            method: 'DELETE',
        }).then(
            response => {
            },
            error => {
            }
        ).then(() => {
            this.setState({isFetching: false});
            this.fetchArranger();
        });
    }

    render() {

        if (!this.state.link) {
            return <div/>
        }

        return <div className="alert alert-info">
            <h4>Ticketanfrage annehmen?</h4>
            <p>
                <button disabled={this.state.isFetching} onClick={this.acceptRequest} className="btn btn-primary" style={{marginRight: '5px'}}>Ja</button>
                <button disabled={this.state.isFetching} onClick={this.declineRequest} className="btn btn-danger">Nein</button>
            </p>
        </div>;
    }


}

function mapStateToProps(state) {

    const {user} = state;

    const {userObject} = user;

    return {userObject}
}

export default connect(mapStateToProps, null)(ShowRequest);