import React, {Component} from 'react';
import {connect} from "react-redux";
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {Button} from 'react-bootstrap';

import Rest from "../../../../../core/Rest";
import Spinner from '../../../../Utilities/Spinner'
import Row from './Row';
import CreateModal from './CreateModal';

class List extends Component {

    constructor(props) {
        super(props);

        this.state = {
            locations: [],
            isFetching: false,
        };

        this.fetchLocations = this.fetchLocations.bind(this);
        this.onChange = this.onChange.bind(this);
        this.save = this.save.bind(this);
        this.deleteLocation = this.deleteLocation.bind(this);


        this.handleShow = this.handleShow.bind(this);
    };


    handleShow() {
        this.modal.handleShow();
    }

    componentDidMount() {
        this.fetchLocations();
    }

    render() {

        let loading = null;
        if (this.state.isFetching) {
            loading = (<Spinner/>)
        }

        const rows = this.state.locations.map((location, key) => {
            return <Row location={location} key={key} onDelete={this.deleteLocation} onChange={this.onChange}/>
        });

        return (
            <div>
                <div className='btn-bar'>
                    <Button
                        style={{marginRight: '5px'}}
                        onClick={this.handleShow}
                    >
                        <FontAwesomeIcon icon='plus'/> Hinzufügen
                    </Button>
                    <Button
                        bsStyle="success"
                        disabled={this.state.isFetching}
                        onClick={!this.state.isFetching ? this.save : null}
                    >
                        <FontAwesomeIcon icon='save'/> Speichern
                    </Button>
                </div>
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <div className='pull-right'>
                            <Button bsSize="xsmall" disabled={!!loading} onClick={this.fetchLocations}><FontAwesomeIcon
                                icon='sync'/></Button>
                        </div>
                        <h4 className="panel-title">Standplätze {loading}</h4>
                    </div>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Titel</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </table>
                </div>
                <CreateModal addressId={this.props.match.params.addressId} ref={(modal) => { this.modal = modal; }} afterSave={this.fetchLocations} />
            </div>
        );
    }

    onChange(id, value) {
        let locations = this.state.locations.slice();

        for (let i in locations) {

            let location = locations[i];

            if (location.id === id) {

                location.title = value;
                location.updated = true;

                locations[i] = Object.assign({}, location);

                this.setState({locations: locations});
                break;
            }
        }
    }

    save() {

        let promises = [];
        this.setState({isFetching: true});


        const locations = this.state.locations.map((location) => {
            if (!location.updated) {
                return location;
            }

            const promise = Rest.fetch({
                endpoint: 'customers/locations/' + location.id,
                method: 'PATCH',
                body: {
                    title: location.title
                }
            });

            promises.push(promise);

            location.updated = false;

            return location;
        });


        Promise.all(promises).then(() => {
            this.setState({isFetching: false})
        }, (err) => {
            this.fetchLocations();
        });

        this.setState({locations: locations});

    }

    deleteLocation(id) {

        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'customers/locations/'+id,
            method: 'DELETE'
        }).then(
            response => {
            },
            error => {
            }
        ).then(() => {
            this.setState({isFetching: false});
            this.fetchLocations();
        });

    }


    fetchLocations() {

        this.setState({isFetching: true});

        const filter = {
            address: this.props.match.params.addressId
        };

        Rest.fetch({
            endpoint: 'customers/locations',
            parameter: {
                filter: filter
            }
        }).then(
            response => {

                const locationObjects = response.response.map((location) => {
                    location.updated = false;

                    return location;
                });

                this.setState({locations: locationObjects})
            },
            error => {
            }
        ).then(() => {
            this.setState({isFetching: false})
        })

    }


}

export default connect()(List);
