import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Link} from 'react-router-dom';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {Button, Nav, NavItem, Modal} from 'react-bootstrap'
import {LinkContainer} from 'react-router-bootstrap';
import {Route, Switch} from 'react-router'
import SignatureCanvas from 'react-signature-canvas'

import Rest from "../../../core/Rest";
import Spinner from '../../Utilities/Spinner'
import TicketActions from './Actions/TicketActions'
import Redirect from "./Redirect";
import TicketDevices from "./Devices/TicketDevices";
import store from "../../../core/store";
import TicketFiles from "./Files/TicketFiles";
import {fetchTicket} from '../../../actions/ticketActions'
import TicketUser from "./User/TicketUser";
import ShowRequest from "./ShowRequest";
import TicketEvents from "./Events/TicketEvents";
import AccessDenied from "../../Utilities/AccessDenied";
import moment from "moment/moment";
import {I18n} from 'react-i18next';

class ShowTicket extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showSignatureModal: false,
            addCustomerSignature: false,
            addEmployeeSignature: false,
            isSavePdf: false
        };

        this.fetchTicket = this.fetchTicket.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.savePdf = this.savePdf.bind(this);
    };

    handleClose() {
        this.setState({showSignatureModal: false});
    }

    handleShow() {
        this.setState({showSignatureModal: true});
    }

    componentDidMount() {
        this.fetchTicket();
    }

    render() {

        if (this.props.singleTicket.accessDenied) {
            return <AccessDenied/>
        }

        let loading = null;
        if (this.props.singleTicket.isFetching) {
            loading = (<Spinner/>)
        }

        let loadingPdf = null;
        if (this.state.isSavePdf) {
            loadingPdf = (<Spinner/>)
        }

        const ticket = this.props.singleTicket.ticket ? this.props.singleTicket.ticket : {};

        let status = {};
        if (ticket.status) {
            status = ticket.status;
        }

        let customer = {};
        if (ticket.customer) {
            customer = ticket.customer;
        }

        const primaryAddress = customer.addresses ? customer.addresses.find((value) => {
            return value.primary
        }) : {};

        let customerAdditional = '';
        if (primaryAddress.additional && primaryAddress.additional.length) {
            customerAdditional = <span><br/>({primaryAddress.additional})</span>;
        }

        let category = {};
        if (ticket.category) {
            category = ticket.category;
        }

        let creator = {};
        if (ticket.creator) {
            creator = ticket.creator;
        }

        let distance = 0;
        let time = 0;
        let hours = 0;

        if (ticket.actions && ticket.actions.length) {
            ticket.actions.forEach((action) => {
                if (action.workMinutes && action.workMinutes > 0) {
                    time += action.workMinutes;
                }

                if (action.distance && action.distance > 0) {
                    distance += action.distance;
                }
            })
        }

        if (time >= 60) {
            hours = Math.floor(time / 60);
            time -= hours * 60
        }
        let description = '';
        if (ticket.description && ticket.description.replace(/<(?:.|\n)*?>/gm, '').length) {
            description =
                <div className='panel panel-default'>
                    <div className='panel-heading'>
                        <h4 className='panel-title'>Beschreibung</h4>
                    </div>
                    <div
                        className='panel-body ticket-description-panel'
                        dangerouslySetInnerHTML={{__html: ticket.description}}
                    />
                </div>;

        }

        const NavLink = ({exact, to, eventKey, children}) =>
            <LinkContainer exact={exact} to={to} eventKey={eventKey}>
                <NavItem>{children}</NavItem>
            </LinkContainer>;

        const eventRoute = this.props.moduleList.calendar
            ? <NavLink to={'/tickets/' + this.props.match.params.id + '/events'}>Termine</NavLink>
            : null;

        let className = 'label-default';
        if (ticket.importance >= 8) {
            className = 'label-danger';
        }
        else if (ticket.importance >= 5) {
            className = 'label-warning';
        }
        else if (ticket.importance >= 2) {
            className = 'label-info';
        }

        let parent = '';
        if(ticket.parent) {
            parent = <Link className='normal-link' to={'/tickets/' + ticket.parent.id}>(Kind von ID {ticket.parent.id}) </Link>;
        }

        return (
            <div>
                <div className="btn-bar">
                    <Link
                        className='btn btn-default'
                        role='button'
                        to={'/tickets'}
                        style={{marginRight: '5px'}}
                    >
                        <FontAwesomeIcon icon='angle-left'/> Zurück
                    </Link>
                    <Button onClick={this.handleShow}>
                        <FontAwesomeIcon icon='print'/> Pdf erstellen
                    </Button>
                </div>
                <ShowRequest ticketId={ticket.id}/>
                <div className="row">
                    <div className="col-md-3">
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <div className='pull-right'>
                                    <Button bsSize="xsmall" disabled={!!loading}
                                            onClick={this.fetchTicket}><FontAwesomeIcon
                                        icon='sync'/></Button>
                                </div>
                                <h4 className="panel-title">Ticket Info {loading}</h4>
                            </div>
                            <div className="panel-body" id="ticketPanel">
                                <div>
                                    <dl>
                                        <dt>TicketId</dt>
                                        <dd>{ticket.id} {parent}</dd>
                                        <dt>Kategorie</dt>
                                        <dd>{category.title}</dd>
                                        <dt>Erstellt am</dt>
                                        <dd>{moment(ticket.inserted).format('DD/MM/YYYY HH:mm')}</dd>
                                        <dt>Bearbeitet am</dt>
                                        <dd>{moment(ticket.updatedAt).format('DD/MM/YYYY HH:mm')}</dd>
                                        <dt>Abgeschlossen am</dt>
                                        <dd>{ticket.completedAt ? moment(ticket.completedAt).format('DD/MM/YYYY HH:mm') : '-'}</dd>
                                        <dt>Bearbeiter</dt>
                                        <dd>
                                            {creator.firstname} {creator.lastname}
                                        </dd>
                                        <dt>Priorität (0-10)</dt>
                                        <dd>
                                            <span className={'label ' + className}>{ticket.importance}</span>
                                        </dd>
                                        <dt>Status</dt>
                                        <dd>{status.title}</dd>
                                        <dt>Gemeldet von:</dt>
                                        <dd>{ticket.reporter}</dd>
                                        <dt>Kurzbeschreibung</dt>
                                        <dd>{ticket.shortDescription}</dd>
                                    </dl>
                                    {description}
                                    <dl>
                                        <dt>Gesamt gefahrene Strecke</dt>
                                        <dd>{distance / 1000} Km</dd>
                                        <dt>Gesamt gebrauchte Zeit</dt>
                                        <dd>{hours ? hours + ' Stunden' : ''} {time} Minuten</dd>
                                        <dt>Unsichtbares Ticket?</dt>
                                        <dd>{ticket.private ?
                                            <Fragment><FontAwesomeIcon icon='check'/> Ja</Fragment> :
                                            <Fragment><FontAwesomeIcon icon='times'/> Nein</Fragment>}</dd>
                                        <dt>Wiederkehrend?</dt>
                                        <dd>{ticket.recurrence ?
                                            <Fragment> <I18n ns={['ticketInterval']}>{(t) => (
                                                <div>
                                                    {t(ticket.recurrence)}
                                                </div>
                                            )}</I18n>
                                            </Fragment> :
                                            <Fragment><FontAwesomeIcon icon='times'/> Nein</Fragment>}</dd>
                                    </dl>
                                </div>

                            </div>
                            <div className="panel-footer">
                                <Link
                                    className='btn btn-default'
                                    role='button'
                                    to={'/tickets/' + ticket.id + '/edit'}
                                    disabled={!ticket.id}
                                >
                                    <FontAwesomeIcon icon='pencil-alt'/> Bearbeiten
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <div className='pull-right'>
                                    <Button bsSize="xsmall" disabled={!!loading}
                                            onClick={this.fetchTicket}><FontAwesomeIcon
                                        icon='sync'/></Button>
                                </div>
                                <h4 className="panel-title">Kunden Daten {loading}</h4>
                            </div>
                            <div className="panel-body" id="customerPanel">
                                <div className="col-md-6">
                                    <dl>
                                        <dt>Kundennummer</dt>
                                        <dd>{customer.customerNo}</dd>
                                        <dt>Name</dt>
                                        <dd>{customer.customerName}</dd>
                                    </dl>
                                </div>
                                <div className="col-md-6">
                                    <address>
                                        <strong>Hauptstandort ({primaryAddress.identifier})</strong><br/>
                                        {primaryAddress.street}<br/>
                                        {primaryAddress.plz} {primaryAddress.city}
                                        {customerAdditional}
                                    </address>
                                    <dl>
                                        <dt>Telefonnummer</dt>
                                        <dd>
                                            <a href={'tel:' + customer.telephoneNumber}>{customer.telephoneNumber}</a>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                            <div className="panel-footer">
                                <Link
                                    className='btn btn-default'
                                    role='button'
                                    to={'/customer/' + customer.id}
                                    disabled={!customer.id}>
                                    <FontAwesomeIcon icon='share'/> Öffnen
                                </Link>
                            </div>
                        </div>
                        <Nav bsStyle='pills' style={{marginBottom: '10px'}}>
                            <NavLink to={'/tickets/' + this.props.match.params.id + '/actions'}>Aktionen</NavLink>
                            <NavLink to={'/tickets/' + this.props.match.params.id + '/devices'}>Geräte</NavLink>
                            <NavLink to={'/tickets/' + this.props.match.params.id + '/user'}>Verknüpfte Nutzer</NavLink>
                            <NavLink to={'/tickets/' + this.props.match.params.id + '/files'}>Dateien</NavLink>
                            {eventRoute}
                        </Nav>
                        <Switch>
                            <Route exact path={`/tickets/:ticketId/actions`} component={TicketActions}/>
                            <Route exact path={`/tickets/:ticketId/devices`} component={TicketDevices}/>
                            <Route exact path={`/tickets/:ticketId/files`} component={TicketFiles}/>
                            <Route exact path={`/tickets/:ticketId/user`} component={TicketUser}/>
                            <Route exact path={`/tickets/:ticketId/events`} component={TicketEvents}/>
                            <Route component={Redirect}/>
                        </Switch>
                        <Modal backdropClassName='bootstrap' className='bootstrap' show={this.state.showSignatureModal} onHide={this.handleClose} bsSize="large">
                            <Modal.Header closeButton>
                                <Modal.Title>Pdf Erstellen {loadingPdf}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='form-group'>
                                    <input style={{marginRight: '5px'}} type="checkbox"
                                           checked={this.state.addCustomerSignature} onChange={(event) => {
                                        this.setState({addCustomerSignature: event.target.checked})
                                    }}/>
                                    <label>Unterschrift Kunde hinzufügen</label>
                                    <SignatureCanvas canvasProps={{className: 'ticket-signature-canvas'}} ref={(ref) => {
                                        this.sigCanvasCustomer = ref
                                    }} onBegin={() => {
                                        this.setState({addCustomerSignature: true})
                                    }}/>
                                    <Button onClick={(event) => this.sigCanvasCustomer.clear()}>Clear</Button>
                                </div>
                                <div className='form-group'>
                                    <input style={{marginRight: '5px'}} type="checkbox"
                                           checked={this.state.addEmployeeSignature} onChange={(event) => {
                                        this.setState({addEmployeeSignature: event.target.checked})
                                    }}/>
                                    <label>Unterschrift Mitarbeiter hinzufügen</label>
                                    <SignatureCanvas canvasProps={{className: 'ticket-signature-canvas'}} ref={(ref) => {
                                        this.sigCanvasEmployee = ref
                                    }} onBegin={() => {
                                        this.setState({addEmployeeSignature: true})
                                    }}/>
                                    <Button onClick={(event) => this.sigCanvasEmployee.clear()}>Clear</Button>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button disabled={!!(loading && loadingPdf)} onClick={this.savePdf}><FontAwesomeIcon
                                    icon='save'/> Speichern</Button>
                                <button type="button" className="btn btn-default" onClick={this.handleClose}>
                                    Schließen
                                </button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
        );
    }

    savePdf() {
        this.setState({isSavePdf: true});
        let body = {};

        if (this.state.addCustomerSignature) {
            body['customerSign'] = this.sigCanvasCustomer.getCanvas().toDataURL()
        }

        if (this.state.addEmployeeSignature) {
            body['employeeSign'] = this.sigCanvasEmployee.getCanvas().toDataURL()
        }

        Rest.fetch({
            endpoint: 'tickets/' + this.props.match.params.id + '/pdf',
            method: 'POST',
            body: body
        }).then(
            response => {
                this.setState({
                    isSavePdf: false,
                    showSignatureModal: false,
                    addCustomerSignature: false,
                    addEmployeeSignature: false,
                });
                this.sigCanvasEmployee.clear();
                this.sigCanvasCustomer.clear();

                store.dispatch({
                    type: 'ADD_ALERT',
                    message: 'Pdf wird generiert.',
                    style: 'success'
                });
            },
            error => {
                this.setState({isSavePdf: false});
            }
        )
    }

    fetchTicket() {
        this.props.actions.fetchTicket(this.props.match.params.id);
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchTicket}, dispatch),
    }
};

function mapStateToProps(state) {

    const {singleTicket, modules} = state;
    const {moduleList} = modules;
    return {singleTicket, moduleList}
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowTicket);
