import React, {Component} from 'react';
import {Button} from 'react-bootstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Rest from "../../../../core/Rest";
import Spinner from "../../../Utilities/Spinner";

class Settings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            address: '',
            zoom: 0
        };

        this.fetchConfig = this.fetchConfig.bind(this);
        this.saveConfig = this.saveConfig.bind(this);
        this.onAddressChange = this.onAddressChange.bind(this);
    }

    componentDidMount() {
        this.fetchConfig();
    }

    onAddressChange(event)
    {
        this.setState({
            address: event.target.value
        })
    }

    render() {

        let loading = null;
        if (this.state.isFetching) {
            loading = (<Spinner/>)
        }

        return (
            <div className="panel panel-default">
                <div className="panel-heading">
                    <div className='pull-right'>
                        <Button bsSize="xsmall" disabled={!!loading} onClick={this.fetchConfig}><FontAwesomeIcon
                            icon='sync'/></Button>
                    </div>
                    <h4 className="panel-title">Karten {loading}</h4>
                </div>
                <div className="panel-body">
                    <div className='form-group'>
                        <label>Addresse der Kartenmitte</label>
                        <input onChange={this.onAddressChange} value={this.state.address} className='form-control' />
                    </div>
                </div>
                <div className='panel-footer'>
                    <button
                        type="submit"
                        className="btn btn-default"
                        disabled={this.state.isSaving || this.state.isFetching}
                        onClick={!this.state.isSaving ? this.saveConfig : null}
                    >
                        Speichern
                    </button>
                </div>
            </div>
        );
    }

    fetchConfig() {
        this.setState({isFetching: true});
        Rest.fetch({
            method: 'GET',
            endpoint: 'config/map',
        }).then(
            response => {
                this.setState({
                    address: response.response.config.address,
                    zoom: response.response.config.zoom,
                })
            },
            error => {
            }
        ).then(() => {
            this.setState({isFetching: false});
        });
    }

    saveConfig() {
        this.setState({isSaving: true});

        Rest.fetch({
            method: 'POST',
            endpoint: 'map/coordinates',
            body: {
                address: this.state.address
            }
        }).then((response) => {
            Rest.fetch({
                method: 'PUT',
                endpoint: 'config/map',
                body: {
                    address: response.response.address,
                    center: {
                        lat: response.response.lat,
                        lng: response.response.lng
                    },
                    zoom: this.state.zoom,
                    lastChange: Math.round((new Date()).getTime() / 1000)
                }
            }).then(response => {
                }, error => {
                }
            ).then(() => {
                this.fetchConfig();
                this.setState({isSaving: false});
            });
        }, (error) => {
        });
    }
}

export default Settings;
