import React, {Component, Fragment} from 'react';
import PigeonMap from 'pigeon-maps'
import Marker from './Marker'


class Map extends Component {

    render() {

        const center = this.props.center ? this.props.center : [51.312711, 9.479746]

        const width = this.props.width;
        const zoom = this.props.zoom ? this.props.zoom : 12;

        const height = this.props.height;

        let marker = [];
        if (this.props.marker) {
            marker = this.props.marker.map((marker, i) => {

                return (<Marker popup={marker.popup ? marker.popup : undefined} key={i} anchor={marker.anchor} payload={marker.payload}
                                onClick={({event, anchor, payload}) => {

                                    if (typeof marker.onClick === 'function') {
                                        marker.onClick(event, anchor, payload);
                                    }
                                }}/>)
            })
        }

        return (
            <Fragment>
                <PigeonMap
                    center={center} zoom={zoom} width={width} height={height} zoomOnMouseWheel={false}
                >
                    {marker}
                </PigeonMap>
            </Fragment>
        );
    }
}


export default Map;
