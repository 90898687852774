import React, {Component} from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {Button} from 'react-bootstrap';

import Rest from "../../../../core/Rest";
import Spinner from '../../../Utilities/Spinner'
import Row from './Row';
import CreateModal from './CreateModal';

class List extends Component {

    constructor(props) {
        super(props);

        this.state = {
            componentTypes: [],
            isFetching: false,
        };

        this.fetchComponentTypes = this.fetchComponentTypes.bind(this);
        this.onChange = this.onChange.bind(this);
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);


        this.handleShow = this.handleShow.bind(this);
    };


    handleShow() {
        this.modal.handleShow();
    }

    componentDidMount() {
        this.fetchComponentTypes();
    }

    render() {

        let loading = null;
        if (this.state.isFetching) {
            loading = (<Spinner/>)
        }

        const rows = this.state.componentTypes.map((componentType, key) => {
            return <Row componentType={componentType} key={key} onDelete={this.delete} onChange={this.onChange} typeList={this.state.componentTypes}/>
        });

        return (
            <div>
                <div className='btn-bar'>
                    <Button
                        style={{marginRight: '5px'}}
                        onClick={this.handleShow}
                    >
                        <FontAwesomeIcon icon='plus'/> Hinzufügen
                    </Button>
                    <Button
                        bsStyle="success"
                        disabled={this.state.isFetching}
                        onClick={!this.state.isFetching ? this.save : null}
                    >
                        <FontAwesomeIcon icon='save'/> Speichern
                    </Button>
                </div>
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <div className='pull-right'>
                            <Button bsSize="xsmall" disabled={!!loading} onClick={this.fetchComponentTypes}><FontAwesomeIcon
                                icon='sync'/></Button>
                        </div>
                        <h4 className="panel-title">Komponenten Typen {loading}</h4>
                    </div>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Titel</th>
                            <th>Anzahl Komponenten</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </table>
                </div>
                <CreateModal  ref={(modal) => { this.modal = modal; }} afterSave={() => {this.fetchComponentTypes()}} />
            </div>
        );
    }

    onChange(id, name, value) {
        let componentTypes = this.state.componentTypes.slice();

        for (let i in componentTypes) {

            let componentType = componentTypes[i];

            if (componentType.id === id) {

                componentType[name] = value;
                componentType.updated = true;

                componentTypes[i] = Object.assign({}, componentType);

                this.setState({componentTypes: componentTypes});
                break;
            }
        }
    }

    save() {

        let promises = [];
        this.setState({isFetching: true});


        const componentTypes = this.state.componentTypes.map((componentType) => {
            if (!componentType.updated) {
                return componentType;
            }

            const promise = Rest.fetch({
                endpoint: 'devices/components/types/' + componentType.id,
                method: 'PATCH',
                body: {
                    title: componentType.title
                }
            });

            promises.push(promise);

            componentType.updated = false;

            return componentType;
        });


        Promise.all(promises).then(() => {
            this.setState({isFetching: false})
        }, ( err) => {
            console.log(err);
            this.fetchComponentTypes();
        });

        this.setState({componentTypes: componentTypes});

    }

    delete(id, newId) {

        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'devices/components/types/'+id,
            method: 'DELETE',
            body: {
                typeId: newId
            }
        }).then(
            response => {
                this.setState({isFetching: false})
            },
            error => {
                this.setState({isFetching: false})
            }
        ).then(() => {
            this.fetchComponentTypes();
        });

    }


    fetchComponentTypes() {

        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'devices/components/types',
        }).then(
            response => {

                const componentTypes = response.response.map((componentType) => {
                    componentType.updated = false;

                    return componentType;
                });

                this.setState({componentTypes: componentTypes, isFetching: false})
            },
            error => {
                this.setState({isFetching: false})
            }
        )

    }


}

export default List;
