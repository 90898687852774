import { LOCATION_CHANGE } from 'connected-react-router';
import {translate} from '../service/locationService'
export function locationHistoryReducer(state = [], action) {
    if (action.type === LOCATION_CHANGE) {

        let payload = action.payload;

        payload.name = payload.location.pathname.split('/').slice(1).map((path) => {
            return translate(path)
        }).join(' / ');

        if(payload.location.pathname === '/')
        {
            payload.name = 'Dashboard';

        }

        return [payload].concat(state).slice(0,100);
    }
    return state;
}