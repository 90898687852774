import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Rest from "../../../../core/Rest";

class ActionDeviceSelect extends Component {

    user;

    constructor(props) {
        super(props);

        this.user = JSON.parse(localStorage.getItem('user'));

        this.state = {
            showDeviceModal: false,
            deviceList: {
                isFetching: false,
                devices: []
            }
        };


        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.fetchDeviceList = this.fetchDeviceList.bind(this);
        this.addDevice = this.addDevice.bind(this);

    };

    componentDidMount() {
        this.fetchDeviceList();
    }

    handleClose() {
        this.setState({showDeviceModal: false});
    }

    handleShow() {
        this.setState({showDeviceModal: true});
    }

    addDevice(event)
    {
        const deviceObject = Object.assign({},this.state.deviceList.devices.find((value) => {
            return value.id === parseInt(event.target.value,10);
        }));

        this.props.addDevice(deviceObject);

        this.handleClose();
    }

    render() {

        let deviceTickets = '';
        if (this.state.deviceList.devices) {
            let selected = [];
            if (this.props.selected) {
                selected = this.props.selected;
            }

            deviceTickets = this.state.deviceList.devices.map((ticketDevice) => {

                if (selected.indexOf(ticketDevice.id) !== -1) {
                    return null;
                }

                const device = ticketDevice.device;

                return <tr key={ticketDevice.id}>
                    <td>{device.deviceNo}</td>
                    <td>{device.brand}</td>
                    <td>{device.model}</td>
                    <td>{device.customer.customerName} ({device.customer.customerNo})</td>
                    <td>{device.location.title}</td>
                    <td>
                        <Button value={ticketDevice.id} bsStyle='success' onClick={this.addDevice}>
                            <FontAwesomeIcon icon='plus'/> Hinzufügen
                        </Button>
                    </td>
                </tr>
            })
        }

       return   <Modal backdropClassName='bootstrap' className='bootstrap' show={this.state.showDeviceModal} onHide={this.handleClose} bsSize="large">
           <Modal.Header closeButton>
               <Modal.Title>Gerät Hinzufügen</Modal.Title>
           </Modal.Header>
           <Modal.Body style={{padding: '0px'}}>
               <div className='table-responsive'>
                   <table className="table table-striped" style={{marginBottom: '0px'}}>
                       <thead>
                       <tr>
                           <th>Gerätenummer</th>
                           <th>Marke</th>
                           <th>Model</th>
                           <th>Kunde</th>
                           <th>Standplatz</th>
                           <th/>
                       </tr>
                       </thead>
                       <tbody>
                       {deviceTickets}
                       </tbody>
                   </table>
               </div>
           </Modal.Body>
           <Modal.Footer>
               <button type="button" className="btn btn-default" onClick={this.handleClose}>
                   Schließen
               </button>
           </Modal.Footer>
       </Modal>
    }

    fetchDeviceList() {

        let ticketDevices = this.state.deviceList;

        ticketDevices.isFetching = true;

        this.setState({deviceList: ticketDevices});

        Rest.fetch({
            endpoint: 'tickets/' + this.props.ticketId + '/devices',
            method: 'GET',
        }).then(
            response => {
                ticketDevices.isFetching = false;
                ticketDevices.devices = response.response;

                this.setState({deviceList: ticketDevices});

            },
            error => {
                ticketDevices.isFetching = false;
                this.setState({deviceList: ticketDevices});
            }
        );

    }
}

export default ActionDeviceSelect;