import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Button, ButtonGroup, ButtonToolbar} from 'react-bootstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {fetchImportFormatList, fetchImportTypeList} from '../../../actions/importActions';
import Spinner from '../../Utilities/Spinner'
import {Link} from 'react-router-dom'
import {push} from "connected-react-router";

class List extends Component {

    constructor(props) {
        super(props);

        this.fetchFormats = this.fetchFormats.bind(this)
    }


    componentDidMount() {
        this.fetchFormats();
        this.fetchTypes();
    }

    fetchTypes() {
        this.props.actions.fetchImportTypeList();
    }


    fetchFormats() {
        this.props.actions.fetchImportFormatList()
    }

    render() {

        let loading = null;
        if (this.props.importFormats.isFetching) {
            loading = (<Spinner/>)
        }

        let rows = [];

        if (this.props.importFormats.formatList && this.props.importTypes.typeList) {
            rows = this.props.importFormats.formatList.map((format) => {

                let type = format.type;

                this.props.importTypes.typeList.forEach((importType) => {
                    if (importType.id === type) {
                        type = importType.name;
                    }
                });

                return <tr key={format.id} onClick={() => this.props.redirectToImportFormat(format.id)}>
                    <td>{format.name}</td>
                    <td>{type}</td>
                </tr>
            })
        }

        return (
            <div>
                <div className='btn-bar'>
                    <Link className='btn btn-default' role='button' to='/data/import/new' style={{marginRight: '5px'}}>
                        <FontAwesomeIcon
                            icon='plus'/> Neu Anlegen
                    </Link>
                </div>
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <div className='pull-right'>
                            <ButtonToolbar>
                                <ButtonGroup bsSize="xsmall">
                                    <Button disabled={!!loading} onClick={this.fetchFormats}>
                                        <FontAwesomeIcon icon='sync'/></Button>
                                </ButtonGroup>
                            </ButtonToolbar>
                        </div>
                        <h4 className="panel-title">
                            Import Formate {loading}
                        </h4>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Format</th>
                            </tr>
                            </thead>
                            <tbody>
                            {rows}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchImportFormatList, fetchImportTypeList}, dispatch),
        redirectToImportFormat(id) {
            dispatch(push('/data/import/' + id))
        }
    }
};

function mapStateToProps(state) {
    const {importFormats, importTypes} = state;

    return {importTypes, importFormats}
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
