import React, {Component, Fragment} from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {Button, Modal} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Rest from "../../../../core/Rest";
import moment from "moment/moment";
import cx from 'classnames'
import {bindActionCreators} from "redux";
import {fetchDeviceConfig} from "../../../../actions/configActions";
import {connect} from "react-redux";

class Device extends Component {

    constructor(props) {
        super(props);

        this.state = {open: false, showHistoryModal: false, history: []};

        this.onCheckChange = this.onCheckChange.bind(this);
        this.toggleInfo = this.toggleInfo.bind(this);
        this.hideHistory = this.hideHistory.bind(this);
        this.showHistory = this.showHistory.bind(this);
    }

    toggleInfo() {
        this.setState({open: !this.state.open});
    }

    hideHistory() {
        this.setState({showHistoryModal: false});

    }

    showHistory() {
        this.setState({showHistoryModal: true});
    }

    componentDidMount() {
        const deviceId = this.props.device.device.id;

        Rest.fetch({
            endpoint: 'devices/' + deviceId + '/history',
            method: 'GET'
        }).then(
            response => {
                this.setState({history: response.response});
            },
            error => {

            }
        );

        this.props.actions.fetchDeviceConfig();
    }

    render() {
        const device = this.props.device;
        const address = device.device.location.address;

        let buttonText = <Fragment><FontAwesomeIcon icon='plus'/> Mehr</Fragment>;

        let history = [];
        if (Array.isArray(this.state.history)) {
            history = this.state.history.map((entry) => {
                return <tr key={entry.id} className={cx({
                    'success': entry.current
                })}>
                    <td>{entry.customer.customerNo}</td>
                    <td>{entry.customer.customerName}</td>
                    <td>{entry.customerLocation.title}</td>
                    <td>{moment(entry.insertedAt).format('DD/MM/YYYY')}</td>
                </tr>
            })
        }

        let fields = [];
        if (this.props.deviceConfig && this.props.deviceConfig.config) {
            const fieldConfig = this.props.deviceConfig.config.fieldConfig;

            fields = Object.keys(
                fieldConfig
            ).filter((key) => {
                return fieldConfig[key] !== false
            }).map((key) => {
                const value = fieldConfig[key];

                const fieldValueRaw = device['free' + key];

                let fieldValue = '-';
                if (value.type === 'bool') {
                    fieldValue = <FontAwesomeIcon icon='times' style={{color: 'red'}}/>;

                    if (fieldValueRaw === 'true') {
                        fieldValue = <FontAwesomeIcon icon='check' style={{color: 'green'}}/>
                    }
                }
                else if (value.type === 'text' && fieldValueRaw) {
                    fieldValue = fieldValueRaw;
                }

                return <Fragment key={key}>
                    <dt>{value.name}</dt>
                    <dd>{fieldValue}</dd>
                </Fragment>
            });
        }

        let info = null;
        if (this.state.open) {
            buttonText = <Fragment><FontAwesomeIcon icon='minus'/> Weniger</Fragment>;

            let addressAdditional = '';
            if (address.additional && address.additional.length) {
                addressAdditional = <span><br/>({address.additional})</span>;
            }

            info = <tr>
                <td className='row' colSpan={7}>
                    <div className='col-md-3'>
                        <img style={{maxWidth: '200px', maxHeight: '200px'}} src="https://cdn.vecodesk.com/storage/master/defaultDevice.png"
                             alt=''/>
                    </div>
                    <div className='col-md-3'>
                        {fields}
                    </div>
                    <div className='col-md-3'>
                        <address>
                            <strong>Addresse ({address.identifier})</strong><br/>
                            {address.street}<br/>
                            {address.plz} {address.city}
                            {addressAdditional}
                        </address>
                        <dl>
                            <dt>Notizen</dt>
                            <dd>{device.device.notes}</dd>
                        </dl>
                    </div>
                    <div className='col-md-3'>
                        <div className="btn-group-vertical" style={{width: '100%'}}>
                            <button type="button" className="btn btn-default btn-block" onClick={this.showHistory}>
                                <FontAwesomeIcon icon='list'/> Historie
                            </button>
                            <Link
                                className="btn btn-default btn-block"
                                role='button'
                                to={'/customer/' + device.device.customer.id + '/devices/' + device.device.id}
                            >
                                <FontAwesomeIcon icon='external-link-alt'/> Öffnen
                            </Link>
                        </div>
                    </div>
                </td>
            </tr>
        }

        return <Fragment>
            <tr>
                <td>
                    <input type='checkbox' checked={device.checked} onChange={this.onCheckChange}/>
                </td>
                <td>{device.device.deviceNo}</td>
                <td>{device.device.brand}</td>
                <td>{device.device.model}</td>
                <td>{device.device.type.title}</td>
                <td>{device.device.location.title}</td>
                <td>{address.identifier}</td>
                <td>
                    <Button bsSize="xsmall" onClick={this.toggleInfo}>{buttonText}</Button>
                </td>
            </tr>
            {info}
            <Modal backdropClassName='bootstrap' className='bootstrap' show={this.state.showHistoryModal} onHide={this.hideHistory}>
                <Modal.Header closeButton>
                    <Modal.Title>History</Modal.Title>
                </Modal.Header>
                <Modal.Body bsClass='table-responsive'>
                    <table className='table' style={{margin: 0}}>
                        <thead>
                        <tr>
                            <th>Kundennummer</th>
                            <th>Name</th>
                            <th>Standplatz</th>
                            <th>Datum</th>
                        </tr>
                        </thead>
                        <tbody>
                        {history}
                        </tbody>
                    </table>
                </Modal.Body>
            </Modal>
        </Fragment>;
    }

    onCheckChange(event) {
        const device = this.props.device;
        device.checked = event.target.checked;

        if (typeof this.props.onDeviceChange === 'function') {
            this.props.onDeviceChange(device)
        }
    }

}

const mapDispatchToProps = (dispatch) => {

    return {
        actions: bindActionCreators({fetchDeviceConfig}, dispatch)
    }
};

function mapStateToProps(state) {

    const {config} = state;
    const {deviceConfig} = config;

    return {deviceConfig}
}


export default connect(mapStateToProps, mapDispatchToProps)(Device);
