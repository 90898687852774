import React, {Component} from 'react';
import {connect} from "react-redux";
import {Route, Switch} from "react-router"
import TicketList from './List/TicketList';
import NewTicket from './New/NewTicket';
import ShowTicket from './Show/ShowTicket'
import EditTicket from "./Edit/EditTicket";
import store from "../../core/store";
import {push} from "connected-react-router";

class Tickets extends Component {
    render() {
        const match = this.props.match;

        return (
            <Switch>
                <Route exact path={match.url} component={TicketList} />
                <Route component={() => (
                    <div className='bootstrap'>
                        <Switch>
                            <Route exact path={`${match.url}/new`} component={NewTicket} />
                            <Route exact path={`${match.url}/:id/edit`} component={EditTicket} />
                            <Route path={`${match.url}/show/:id`} render={(props) => {
                                store.dispatch(push('/tickets/' + props.match.params.id));
                                return ''
                            }} />
                            <Route path={`${match.url}/:id`} component={ShowTicket} />
                        </Switch>
                    </div>
                )} />
            </Switch>

        );
    }
}

export default connect()(Tickets);
