import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import BigCalendar from 'react-big-calendar'
import moment from 'moment'
import {bindActionCreators, compose} from "redux";
import {fetchCalendarEvents} from "../../../actions/calendarActions";
import {fetchComponents} from "../../../actions/deviceComponentActions";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../../../styles/calendar-style.css';
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import {Button, Modal} from "react-bootstrap";
import {push} from "connected-react-router";
import {translate} from "react-i18next";
import Rest from "../../../core/Rest";
import Spinner from "../Spinner";

const localizer = BigCalendar.momentLocalizer(moment);

class BigCalendarView extends Component {
    constructor(props) {
        super(props);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleNewClose = this.handleNewClose.bind(this);
        this.handleNewShow = this.handleNewShow.bind(this);
        this.handleRequestClose = this.handleRequestClose.bind(this);
        this.handleRequestShow = this.handleRequestShow.bind(this);
        this.updateRequest = this.updateRequest.bind(this);
        this.getEvents = this.getEvents.bind(this);

        this.state = {
            saveRequest: false,
            showEventModal: false,
            showNewEventModal: false,
            showRequestModal: false,
            fetchRequests: false,
            newEvent: null,
            requests: [],
            selectedEvent: null,
            currentView: 'month',
            currentDate: moment()
        };
    }

    componentDidMount() {
        this.getEvents();
        this.fetchRequets();
    }

    fetchRequets() {
        this.setState({
            fetchRequests: true
        });
        Rest.fetch({
            endpoint: 'calendar/events/subscriber',
            method: 'GET',
            parameter: {
                filter: {
                    user: this.props.userObject.id,
                    status: 0
                },
            }
        }).then(
            response => {
                this.setState({requests: response.response});
            },
            error => {
            }
        ).then(() => {
            this.setState({
                fetchRequests: false
            });
        })
    }


    updateRequest(id, status) {
        this.setState({
            saveRequest: true
        });

        Rest.fetch({
            endpoint: 'calendar/events/subscriber/' + id,
            method: 'PATCH',
            body: {
                status: status
            }
        }).then(
            response => {
                this.fetchRequets();
                this.getEvents();
            },
            error => {
            }
        ).then(() => {
            this.setState({
                saveRequest: false
            });
        })
    }

    getEvents() {
        let view = this.state.currentView;
        let date = this.state.currentDate;
        let start = moment(0);
        let end = moment('2020-01-01');

        if (view === 'week') {
            start = moment(date).startOf('week');
            end = moment(date).endOf('week');
        }

        if (view === 'month') {
            start = moment(date).startOf('month');
            end = moment(date).endOf('month');
        }

        if (view === 'day') {
            start = moment(date).startOf('day');
            end = moment(date).endOf('day');
        }

        if (view === 'agenda') {
            start = moment(date);
            end = moment(start).add('1', 'M');
        }

        let link = null;
        if(this.props.linkType && this.props.linkId)
        {
            link = (this.props.linkType + ':' + this.props.linkId);
        }

        this.props.actions.fetchCalendarEvents(start, end, link);
        if(!this.props.hideComponentEvents) {
            const filter = {
                expireBefore: end,
                expireAfter: start,
            };

            if(this.props.linkType === 'device')
            {
                filter.deviceId = this.props.linkId
            }
            this.props.actions.fetchComponents(filter);
        }
    }

    handleNewClose() {
        this.setState({showNewEventModal: false});
    }

    handleNewShow() {
        this.setState({showNewEventModal: true});
    }


    handleClose() {
        this.setState({showEventModal: false});
    }

    handleShow() {
        this.setState({showEventModal: true});
    }

    handleRequestClose() {
        this.setState({showRequestModal: false});
    }

    handleRequestShow() {
        this.setState({showRequestModal: true});
    }

    render() {

        let allViews = Object.keys(BigCalendar.Views).filter((k) => {
            return this.props.views.indexOf(k) !== -1
        }).map(k => BigCalendar.Views[k]);

        let events = [];
        if (this.props.calendarEvents.events) {
            events = events.concat(this.props.calendarEvents.events.map((event) => {

                event.start = moment(event.start);
                event.end = moment(event.end);
                event.eventType = event.type;
                return {
                    id: event.id,
                    title: event.title,
                    allDay: event.fullDay,
                    start: event.start.toDate(),
                    end: event.end.toDate(),
                    resource: event
                };
            }));
        }

        if (this.props.componentList.components) {
            events = events.concat(this.props.componentList.components.map((component) => {

                const event = {
                    start: moment(component.expireTime),
                    end: moment(component.expireTime),
                    title: 'Komponenten Termin: ' + component.description + ' (' + component.type.title + ')',
                    eventType: 'component',
                    description: component.expireDescription + ' von ' + component.description + ' (' + component.type.title + ') bei ' + component.device.customer.customerName,
                    component: component
                };

                return {
                    id: 'c-' + component.id,
                    title: event.title,
                    allDay: true,
                    start: event.start.toDate(),
                    end: event.end.toDate(),
                    resource: event
                };
            }));
        }

        let eventModal = null;
        let newEventModal = null;

        if (this.state.selectedEvent) {
            const event = this.state.selectedEvent;

            let start = event.start.format('DD/MM/YYYY HH:mm');
            let end = event.end.format('DD/MM/YYYY HH:mm');
            if (event.fullDay) {
                start = event.start.format('DD/MM/YYYY') + ', Ganztägig';
                end = event.end.format('DD/MM/YYYY') + ', Ganztägig';
            }

            let button = <Button bsStyle='primary' onClick={() => {
                this.handleClose();
                this.props.redirectToEdit(event.id)
            }
            }>
                <FontAwesomeIcon icon='pencil-alt'/> Bearbeiten
            </Button>;

            if (event.eventType === 'component') {
                button = <Button bsStyle='primary' onClick={() => {
                    this.handleClose();
                    this.props.redirectToDevice(event.component.device.id)
                }}>
                    <FontAwesomeIcon icon='external-link-alt'/> Öffnen
                </Button>
            }

            let subscriberPanel = null;

            if (event.subscriber && event.subscriber.length) {
                const subscriberList = event.subscriber.map((sub) => {

                    let icon = <FontAwesomeIcon icon={['far', 'clock']}/>;
                    if (sub.status === 1) {
                        icon = <FontAwesomeIcon icon='check'/>;
                    }
                    if (sub.status === -1) {
                        icon = <FontAwesomeIcon icon='times'/>;
                    }


                    return <tr key={sub.id}>
                        <td>{sub.user.firstname} {sub.user.lastname}</td>
                        <td>{icon}</td>
                    </tr>;
                });


                subscriberPanel = <div className='panel panel-default'>
                    <div className='panel-heading'>
                        <h4 className='panel-title'>Teilnehmer</h4>
                    </div>
                    <table className='table'>
                        <tbody>
                        {subscriberList}
                        </tbody>
                    </table>
                </div>
            }

            let reminderPanel = null;
            if (event.reminder && event.reminder.length) {
                const reminderList = event.reminder.map((rem) => {

                    return <tr key={rem.id}>
                        <td>{this.props.t('periods.' + rem.period)}</td>
                    </tr>;
                });


                reminderPanel = <div className='panel panel-default'>
                    <div className='panel-heading'>
                        <h4 className='panel-title'>Erinnerungen</h4>
                    </div>
                    <table className='table'>
                        <tbody>
                        {reminderList}
                        </tbody>
                    </table>
                </div>
            }
            
            let linkPanel = null;
            if(event.links && event.links.length)
            {
                const linkList = [];
                event.links.forEach((link) => {
                        linkList.push(
                            <tr key={link.type + ':' + link.linkId}>
                                <td>
                                    {this.props.t('linkTypes.' + link.type)}
                                </td>
                                <td>
                                    {link.linkId}
                                </td>
                            </tr>
                        )
                });

                linkPanel= <div className="panel panel-default">
                    <div className='panel-heading'>
                        <h4 className='panel-title'>Verknüpfungen</h4>
                    </div>
                    <table className="table">
                        <tbody>
                        {linkList}
                        </tbody>
                    </table>
                </div>
            }

            eventModal = <Modal backdropClassName='bootstrap' className='bootstrap' show={this.state.showEventModal} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{event.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {event.description}
                    </p>
                    <div className='panel panel-default'>
                        <div className='panel-heading'>
                            <h4 className='panel-title'>Termindauer</h4>
                        </div>
                        <table className='table'>
                            <tbody>
                            <tr>
                                <td>Start</td>
                                <td>{start}</td>
                            </tr>
                            <tr>
                                <td>Ende</td>
                                <td>{end}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    {subscriberPanel}
                    {reminderPanel}
                    {linkPanel}
                </Modal.Body>
                <Modal.Footer>
                    {button}
                    <Button onClick={this.handleClose}>
                        <FontAwesomeIcon icon='times'/> Schließen
                    </Button>
                </Modal.Footer>
            </Modal>
        }

        if (this.state.newEvent) {
            const newEvent = this.state.newEvent;

            let start = moment(newEvent.start);
            let end = moment(newEvent.end);

            newEventModal = <Modal backdropClassName='bootstrap' className='bootstrap' show={this.state.showNewEventModal} onHide={this.handleNewClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Neuer Termin</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Neuer Termin von {start.format('DD/MM/YYYY HH:mm')} bis {end.format('DD/MM/YYYY HH:mm')}?
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle='primary' onClick={() => {
                        this.props.redirectToNewEvent(start, end, this.props.linkType, this.props.linkId)
                    }}>
                        <FontAwesomeIcon icon='plus'/> Neuer Termin
                    </Button>
                    <Button onClick={this.handleNewClose}>
                        <FontAwesomeIcon icon='times'/> Schließen
                    </Button>
                </Modal.Footer>
            </Modal>
        }

        const requests = this.state.requests.map((request) => {

            const start = moment(request.event.start);
            const end = moment(request.event.end);

            return <tr key={request.id}>
                <td>
                    <b>{request.event.title}</b>
                    <p>{request.event.description}</p>
                    <div className='nFooter'>
                        {request.event.fullDay ? 'Ganztägig, von ' : 'Von '}
                        {start.format('DD/MM/YYYY HH:mm')} bis {end.format('DD/MM/YYYY HH:mm')}
                    </div>
                </td>
                <td>
                    <button disabled={this.state.saveRequest} className='btn btn-success btn-block' onClick={() => {
                        this.updateRequest(request.id, 1);
                    }}>Annnehmen</button>
                    <button disabled={this.state.saveRequest} className='btn btn-danger btn-block' onClick={() => {
                        this.updateRequest(request.id, -1);
                    }}>Ablehnen</button>
                </td>
            </tr>
        });

        let requestsButton = null;
        if (this.props.displayRequests) {
            requestsButton = <button
                disabled={this.state.fetchRequests}
                className='btn btn-default btn-block'
                onClick={this.handleRequestShow}>
                Terminanfragen <span className='label label-default'>{parseInt(this.state.requests.length, 10)}</span>
            </button>
        }

        let modalLoading = null;
        if (this.state.fetchRequests) {
            modalLoading = (<Spinner/>)
        }

        return (
            <Fragment>
                <div className='row'>
                    <div className='col-md-2'>
                        <button className='btn btn-primary btn-block' onClick={() => {
                            this.props.redirectToNewEvent(null, null, this.props.linkType, this.props.linkId)
                        }}>
                            <FontAwesomeIcon icon='plus'/> Neuer Termin
                        </button>
                        {requestsButton}
                        <h3>Legende</h3>
                        <span className='badge badge-block' style={{
                            backgroundColor: '#9c9c9c'
                        }}>Termin</span>
                        <span className='badge badge-block' style={{
                            backgroundColor: '#BB8100'
                        }}>Termin mit Verlinkung</span>
                        <span className='badge badge-block' style={{
                            backgroundColor: '#3174ad'
                        }}>Angenommener Termin</span>
                        <span className='badge badge-block' style={{
                            backgroundColor: '#62a065'
                        }}>Komponenten Termin</span>

                    </div>
                    <div className='col-md-10' style={{height: this.props.height}}>
                        <BigCalendar
                            ref='calendar'
                            localizer={localizer}
                            events={events}
                            culture='de'
                            views={allViews}
                            startAccessor="start"
                            endAccessor="end"
                            selectable={true}
                            popup={true}
                            eventPropGetter={
                                (
                                    event,
                                    start,
                                    end,
                                    isSelected
                                ) => {

                                    const style = {
                                        backgroundColor: '#9c9c9c'
                                    };
                                    if (event.resource.links && event.resource.links.length) {
                                        style.backgroundColor = '#bb8100'
                                    }

                                    if (event.resource.subscriber) {
                                        event.resource.subscriber.forEach((sub) => {
                                            if (sub.status === 1 && sub.user.id === this.props.userObject.id) {
                                                style.backgroundColor = '#3174ad';
                                            }
                                        })
                                    }

                                    if (event.resource.eventType === 'component') {
                                        style.backgroundColor = '#62a065'
                                    }



                                    return {
                                        style: style
                                    }
                                }}
                            onNavigate={(date) => {
                                this.setState({
                                    currentDate: moment(date)
                                }, () => {
                                    this.getEvents();
                                });
                            }}
                            onView={(view) => {
                                this.setState({
                                    currentView: view
                                }, () => {
                                    this.getEvents();
                                });
                            }}
                            onSelectSlot={(slot) => {
                                this.setState({
                                    newEvent: slot
                                }, () => {
                                    this.handleNewShow();
                                });
                            }}

                            onSelectEvent={
                                (event) => {
                                    this.setState({
                                        selectedEvent: event.resource
                                    }, () => {
                                        this.handleShow();
                                    });
                                }
                            }
                            messages={{
                                date: 'Datum',
                                time: 'Zeit',
                                event: 'Termin',
                                allDay: 'Ganztägig',
                                week: 'Woche',
                                work_week: 'Arbeitswoche',
                                day: 'Tag',
                                month: 'Monat',
                                previous: <FontAwesomeIcon icon='chevron-left'/>,
                                next: <FontAwesomeIcon icon='chevron-right'/>,
                                yesterday: 'Gestern',
                                tomorrow: 'Morgen',
                                today: 'Heute',
                                agenda: 'Agenda',
                                noEventsInRange: 'Keine Termine in dieser Zeitspanne',
                                showMore: function showMore(total) {
                                    return "+" + total + " mehr";
                                }
                            }}
                        />
                    </div>
                </div>
                {eventModal}
                {newEventModal}
                <Modal backdropClassName='bootstrap' className='bootstrap' show={this.state.showRequestModal} onHide={this.handleRequestClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Terminanfragen {modalLoading}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{padding: 0}}>
                        <table className='table' style={{marginBottom: 0}}>
                            <tbody>
                            {requests}
                            </tbody>
                        </table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleRequestClose}>
                            <FontAwesomeIcon icon='times'/> Schließen
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchCalendarEvents, fetchComponents}, dispatch),
        redirectToNewEvent(start = null, end = null, linkType = null, linkId = null) {
            let link = '/calendar/new';
            if (start || end || linkType || linkId) {
                link += '?'
            }

            const params = [];

            if(start) {
                params.push('start=' + start.format('YYYY-MM-DDTHH:mm'));
            }
            if(end)
            {
                params.push('end=' + end.format('YYYY-MM-DDTHH:mm'));
            }
            if(linkType)
            {
                params.push('linkType=' + linkType);
            }
            if(linkId)
            {
                params.push('linkId=' + linkId);
            }

            link += params.join('&');

            dispatch(push(link))
        },
        redirectToDevice(deviceId) {
            let link = '/devices/' + deviceId + '/components';
            dispatch(push(link))
        },
        redirectToEdit(eventId) {
            let link = '/calendar/' + eventId;
            dispatch(push(link))
        }
    }
};

function mapStateToProps(state) {

    const {calendarEvents, componentList, user} = state;

    const {userObject} = user;

    return {calendarEvents, componentList, userObject}
}

export default compose(
    translate('calendar'),
    connect(mapStateToProps, mapDispatchToProps)
)(BigCalendarView);