import React, {Component} from 'react';
import moment from "moment/moment";
import {connect} from "react-redux";
import Rest from "../../core/Rest";
import {push} from "connected-react-router";

const typeMap = {
    ticket: {
        name: 'Ticket',
        linkCallback(value) {
            if (value) {
                return '/tickets/' + value;
            }
            return '/tickets';
        }
    },
    import: {
        name: 'Import',
        linkCallback(value) {
            if (value) {
                return '/data/import/' + value;
            }
            return '/data/import';
        }
    },
    export: {
        name: 'Export',
        linkCallback(value) {
            return '/data/export';
        }
    },
    event: {
        name: 'Termin',
        linkCallback(value) {
            return '/calendar';
        }
    }
};

class Notification extends Component {

    constructor(props)
    {
        super(props);
        this.toggleRead = this.toggleRead.bind(this);

        this.state = {
            isFetching: false
        };
    }

    toggleRead()
    {
        this.setState({isFetching: true});

        Rest.fetch({
            method: 'PATCH',
            endpoint: 'notifications/' + this.props.notification.id,
            body: {
                isRead: !this.props.notification.isRead
            }
        }).then((response) => {
            this.props.onChange()
        }).then(() => {
            this.setState({isFetching: false});
        });

    }

    render() {
        const notification = this.props.notification;
        let type = notification.type;
        if (typeMap[type]) {
            type = typeMap[type].name;
        }

        return <tr key={notification.id} className={notification.isRead ? '' : 'info'}>
            <td className='col-md-11' onClick={() => {
                this.props.redirectToNotificationReference(notification)
            }}>
                <p>
                    <b>{type}</b> {notification.message}
                </p>
                <span className='search-result-footer'>
                                    - {moment(notification.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                                </span>
            </td>
            <td className='col-md-1'>
                <div>
                    <button className='btn btn-default btn-block btn-xs' onClick={this.toggleRead} disabled={this.state.isFetching}>
                        Als {notification.isRead ? 'ungelesen' : 'gelesen'} makieren
                    </button>
                </div>
            </td>
        </tr>
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        redirectToNotificationReference(notification) {
            let link = '/notifications';
            if (typeMap[notification.type]) {
                link = typeMap[notification.type].linkCallback(notification.referenceValue);
            }

            Rest.fetch({
                method: 'PATCH',
                endpoint: 'notifications/' + notification.id,
                body: {
                    isRead: 1
                }
            });

            dispatch(push(link))
        }

    }
};

export default connect(null, mapDispatchToProps)(Notification);