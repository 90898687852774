import {
    LIST_DEVICE_COMPONENTS_TYPES_FAILURE, LIST_DEVICE_COMPONENTS_TYPES_REQUEST, LIST_DEVICE_COMPONENTS_TYPES_SUCCESS,
    LIST_DEVICE_COMPONENTS_REQUEST, LIST_DEVICE_COMPONENTS_SUCCESS, LIST_DEVICE_COMPONENTS_FAILURE,
LIST_COMPONENTS_FAILURE, LIST_COMPONENTS_REQUEST, LIST_COMPONENTS_SUCCESS
} from '../actions/deviceComponentActions'

export function listDeviceComponentTypesReducer(state = {
    isFetching: false,
}, action) {
    switch (action.type) {
        case LIST_DEVICE_COMPONENTS_TYPES_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case LIST_DEVICE_COMPONENTS_TYPES_FAILURE:

            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch (e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case LIST_DEVICE_COMPONENTS_TYPES_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                types: action.response
            });
        default:
            return state
    }
}

export function listDeviceComponentsReducer(state = {
    devices: {}
}, action) {

    let components = {};

    switch (action.type) {
        case LIST_DEVICE_COMPONENTS_REQUEST:
            components = Object.assign({}, state.devices);

            components[action.identifier] = {
                isFetching: true,
                components: components[action.identifier] ? components[action.identifier].components.slice(0) : []
            };
            return Object.assign({}, state, {
                devices: components
            });
        case LIST_DEVICE_COMPONENTS_FAILURE:

            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch (e) {
                error = action.error;
            }

            components = Object.assign({}, state.devices);

            components[action.identifier] = {
                isFetching: false,
                error: error,
                components: components[action.identifier] ? components[action.identifier].components.slice(0) : []
            };

            return Object.assign({}, state, {
                devices: components
            });
        case LIST_DEVICE_COMPONENTS_SUCCESS:

            components = Object.assign({}, state.devices);

            components[action.identifier] = {
                components: action.response,
                isFetching: false
            };

            return Object.assign({}, state, {
                devices: components
            });
        default:
            return state
    }
}

export function listComponentsReducer(state = {
    components: [],
    isFetching: false
}, action) {
    switch (action.type) {
        case LIST_COMPONENTS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case LIST_COMPONENTS_FAILURE:

            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch(e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case LIST_COMPONENTS_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                components: action.response
            });
        default:
            return state
    }
}

