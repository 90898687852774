import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {Button, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {bindActionCreators} from "redux";

import {fetchBookmarkList} from "../../../../actions/bookmarkActions";
import moment from "moment/moment";
import Rest from "../../../../core/Rest";
import {fetchUserDefaultBookmark} from "../../../../actions/configActions";

class Panel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            defaultSaving: false,
            showNewBookmarkModal: false,
            showDeleteBookmarkModal: false,
            newBookmarkName: '',
            deleteId: null,
            bookmarkSaving: false,
            defaultSearchExecuted: false
        };

        this.saveConfig = this.saveConfig.bind(this);

        this.showBookmarkModal = this.showBookmarkModal.bind(this);
        this.hideBookmarkModal = this.hideBookmarkModal.bind(this);
        this.hideDeleteModal = this.hideDeleteModal.bind(this);
        this.showDeleteModal = this.showDeleteModal.bind(this);
        this.saveBookmark = this.saveBookmark.bind(this);
        this.deleteBookmark = this.deleteBookmark.bind(this);
        this.onBookmarkNameChange = this.onBookmarkNameChange.bind(this);
        this.setFilterByBookmark = this.setFilterByBookmark.bind(this);

    }

    componentDidMount() {
        this.props.actions.fetchBookmarkList();
        this.props.actions.fetchUserDefaultBookmark();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (
            !this.state.defaultSearchExecuted &&
            typeof this.props.filterBookmarks.bookmarks !== 'undefined' &&
            Array.isArray(this.props.filterBookmarks.bookmarks) &&
            typeof this.props.defaultBookmarkConfig.config !== 'undefined'
        ) {
            this.setState({defaultSearchExecuted: true}, () => {

                if (this.props.defaultBookmarkConfig.config[this.props.identifier]) {
                    this.setFilterByBookmark(this.props.defaultBookmarkConfig.config[this.props.identifier]);
                }
                setTimeout(() => {
                    this.props.executeSearch();
                }, 100);
            })
        }
    }

    showDeleteModal() {
        this.setState({showDeleteBookmarkModal: true})
    }

    hideDeleteModal() {
        this.setState({showDeleteBookmarkModal: false})
    }

    hideBookmarkModal() {
        this.setState({showNewBookmarkModal: false})
    }

    onBookmarkNameChange(event) {
        this.setState({newBookmarkName: event.target.value})
    }

    deleteBookmark(id) {
        if (!id) {
            return;
        }

        Rest.fetch({
            endpoint: 'tickets/filter_bookmarks/' + id,
            method: 'DELETE',
        }).then(() => {
            this.setState({deleteId: null})
        }, () => {
        }).then(() => {
            this.props.actions.fetchBookmarkList();
        })
    }


    saveConfig(id) {
        this.setState({defaultSaving: true});

        let config = this.props.defaultBookmarkConfig.config;
        if (!config) {
            config = {}
        }

        config[this.props.identifier] = id;

        Rest.fetch({
            method: 'PUT',
            endpoint: 'users/' + this.props.userObject.id + '/config/defaultBookmark',
            body: config
        }).then(
            response => {
                this.props.actions.fetchUserDefaultBookmark();
                this.props.actions.fetchBookmarkList();
            },
            error => {
            }
        ).then(() => {
            this.setState({defaultSaving: false});
        });
    }

    saveBookmark() {
        this.setState({bookmarkSaving: true});

        const orderMap = {
            's.priority_ASC': 'o_status_asc',
            't.importance_DESC': 'o_importance_desc',
            't.inserted_DESC': 'o_inserted_desc',
            't.inserted_ASC': 'o_inserted_asc'
        };

        const filter = this.props.filter;


        Rest.fetch({
            endpoint: 'tickets/filter_bookmarks',
            method: 'POST',
            body: {
                name: this.state.newBookmarkName.trim(),
                ticketSearchString: filter.ticketData,
                deviceSearchString: filter.deviceData,
                customerSearchString: filter.customerData,
                categoryId: filter.category ? filter.category : null,
                dateFrom: moment.isMoment(filter.fromDate) ? filter.fromDate.format('YYYY/MM/DD') : null,
                dateTo: moment.isMoment(filter.toDate) ? filter.toDate.format('YYYY/MM/DD') : null,
                statusIds: filter.status,
                orderBy: orderMap[this.props.orderBy] ? orderMap[this.props.orderBy] : null,
                hasRecurrence: filter.hasRecurrence === '' ? null : filter.hasRecurrence
            }
        }).then(() => {
            this.setState({newBookmarkName: ''});
            this.hideBookmarkModal();
            this.props.actions.fetchBookmarkList();
        }, () => {
        }).then(() => {
            this.setState({bookmarkSaving: false});
        })

    }

    showBookmarkModal() {
        this.setState({showNewBookmarkModal: true})
    }

    setFilterByBookmark(id) {
        if (!this.props.filterBookmarks.bookmarks) {
            return;
        }

        const bookmark = this.props.filterBookmarks.bookmarks.find((object) => {
            return object.id === id;
        });

        if (!bookmark) {
            return;
        }
        const orderMap = {
            'o_status_asc': 's.priority_ASC',
            'o_importance_desc': 't.importance_DESC',
            'o_inserted_desc': 't.inserted_DESC',
            'o_inserted_asc': 't.inserted_ASC'
        };

        let orderBy = null;
        if (orderMap[bookmark.orderBy]) {
            orderBy = orderMap[bookmark.orderBy]
        }

        let hasRecurrence = '';
        if(bookmark.hasRecurrence !== null && bookmark.hasRecurrence)
        {
            hasRecurrence = 1;
        }
        else if(bookmark.hasRecurrence !== null && !bookmark.hasRecurrence)
        {
            hasRecurrence = 0;
        }

        let filter = {
            ticketData: bookmark.ticketSearchString,
            deviceData: bookmark.deviceSearchString,
            customerData: bookmark.customerSearchString,
            category: bookmark.categoryId ? bookmark.categoryId : '',
            fromDate: bookmark.dateFrom ? moment(bookmark.dateFrom) : null,
            toDate: bookmark.dateTo ? moment(bookmark.dateTo) : null,
            status: bookmark.statusIds ? bookmark.statusIds : [],
            hasRecurrence: hasRecurrence

        };


        this.props.setBookmark({filter: filter, orderBy: orderBy});

    }

    render() {

        const deleteTooltip = (
            <Tooltip id='deleteTooltip'>
                Löschen
            </Tooltip>
        );

        const defaultTooltip = (
            <Tooltip id='defaultTooltip'>
                Als Standard Filter für mich speichern
            </Tooltip>
        );


        let bookmarks = [];
        if (this.props.filterBookmarks.bookmarks) {

            let defaultId = null;
            if (this.props.defaultBookmarkConfig.config &&
                this.props.defaultBookmarkConfig.config[this.props.identifier]
            ) {
                defaultId = this.props.defaultBookmarkConfig.config[this.props.identifier];
            }

            bookmarks = this.props.filterBookmarks.bookmarks.map((data, id) => {

                let defaultButton = <OverlayTrigger placement='left' overlay={defaultTooltip}>
                    <button disabled={this.props.defaultBookmarkConfig.isFetching || data.id === defaultId}
                            onClick={() => {
                                this.saveConfig(data.id)
                            }} className='btn btn-default btn-xs'><FontAwesomeIcon
                        icon='check'/></button>
                </OverlayTrigger>;

                if (data.id === defaultId) {
                    defaultButton = <button
                        className='btn btn-primary btn-xs'><FontAwesomeIcon
                        icon='check'/></button>
                }

                return <tr key={data.id}>
                    <td onClick={() => this.setFilterByBookmark(data.id)}>
                        {data.name}
                    </td>
                    <td className='text-right'>
                        <OverlayTrigger placement='left' overlay={deleteTooltip}>
                            <button onClick={() => {
                                this.setState({deleteId: data.id}, () => {
                                    this.showDeleteModal();
                                });
                            }} className='btn btn-danger btn-xs' style={{marginRight: '1em'}}><FontAwesomeIcon
                                icon='trash'/></button>
                        </OverlayTrigger>
                        {defaultButton}
                    </td>
                </tr>;
            });
        }

        return (
            <Fragment>
                <div className='panel panel-default'>
                    <div className='panel-heading'>
                        <FontAwesomeIcon icon='bookmark'/> Gespeicherte Filter
                    </div>
                    <table className='table table-hover'>
                        <tbody>{bookmarks}</tbody>
                    </table>
                    <div className='panel-footer'>
                        <button className='btn btn-default' onClick={this.showBookmarkModal}>
                            <FontAwesomeIcon icon='plus'/> Filter Speichern
                        </button>
                    </div>
                </div>
                <Modal backdropClassName='bootstrap' className='bootstrap' show={this.state.showNewBookmarkModal} onHide={this.hideBookmarkModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Aktuelle Filter Einstellung Speichern</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label>Bezeichnung</label>
                        <input className='form-control' value={this.state.newBookmarkName} onChange={this.onBookmarkNameChange}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle='primary' disabled={this.state.bookmarkSaving} onClick={this.saveBookmark}><FontAwesomeIcon
                            icon='save'/> Speichern</Button>
                        <button type="button" className="btn btn-default" onClick={this.hideBookmarkModal}>
                            Schließen
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal backdropClassName='bootstrap' className='bootstrap' show={this.state.showDeleteBookmarkModal} onHide={this.hideBookmarkModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Gespeicherten Filter Löschen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Wirklich Löschen?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle='danger' disabled={this.state.bookmarkSaving} onClick={() => {
                            this.deleteBookmark(this.state.deleteId);
                            this.hideDeleteModal();
                        }}>
                            <FontAwesomeIcon icon='save'/> Löschen</Button>
                        <button type="button" className="btn btn-default" onClick={this.hideDeleteModal}>
                            Schließen
                        </button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchBookmarkList, fetchUserDefaultBookmark}, dispatch),
    }
};

function mapStateToProps(state) {

    const {filterBookmarks, config, user} = state;
    const {defaultBookmarkConfig} = config;
    const {userObject} = user;

    return {filterBookmarks, defaultBookmarkConfig, userObject}

}

export default connect(mapStateToProps, mapDispatchToProps)(Panel);