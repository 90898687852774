import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import Rest from "../../../core/Rest";
import {store}from '../../../core';
import {push} from "connected-react-router";

class CreateCustomer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isSaving: false,
            model: {
                customerNo: '',
                customerName: '',
                city: '',
                plz: '',
                additional: '',
                street: '',
                telephoneNumber: ''
            },
            validationErrorList: {}
        };

        this.onModelChange = this.onModelChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.saveCustomer = this.saveCustomer.bind(this);
    }

    onSubmit(event) {
        event.preventDefault();

        this.saveCustomer()
    }

    saveCustomer() {

        this.setState({isSaving: true, validationErrorList: {}});

        const body = Object.assign({}, this.state.model);

        body.plz = parseInt(body.plz, 10);
        body.customerNo = parseInt(body.customerNo, 10);

        Rest.fetch({
            endpoint: 'customers',
            method: 'POST',
            body: body
        }).then(
            response => {
                store.dispatch(push('/customer/' + response.response.id))
            },
            error => {
                if (error.code === 400 && error.raw.error && error.raw.error.validation_errors)
                {
                    this.setState({validationErrorList:  error.raw.error.validation_errors})
                }
            }
        ).then(() => {
            this.setState({isSaving: false});
        })
    }

    onModelChange(event)
    {
        const model = this.state.model;

        model[event.target.name] = event.target.value;

        this.setState({model: model})
    }

    getAlert(key)
    {
        if(this.state.validationErrorList[key] && Array.isArray(this.state.validationErrorList[key]))
        {
            const values = this.state.validationErrorList[key].map((message) => {
                return <li>{message}</li>
            });

            return <div className='alert alert-danger' style={{marginTop: '10px'}}>
                <ul>{values}</ul>
            </div>
        }

        return '';
    }

    render() {

        return (
            <div>
                <div className="btn-bar">
                    <Link
                        className='btn btn-default'
                        role='button'
                        to={'/customer'}
                    >
                        <FontAwesomeIcon icon='angle-left'/> Zurück
                    </Link>
                </div>
                <form onSubmit={this.onSubmit}>
                    <div className="panel-default panel">
                        <div className="panel-heading">
                            <h4 className="panel-title">
                                Neuer Kunde
                            </h4>
                        </div>
                        <div className="panel-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className={cx({
                                        'form-group': true,
                                        'has-error': !!this.state.validationErrorList.customerNo
                                    })}>
                                        <label>
                                            Kundennummer
                                        </label>
                                        <input type="text" placeholder="Kundennummer" name="customerNo"
                                               className="form-control"
                                               required
                                               onChange={this.onModelChange} value={this.state.model.customerNo}
                                        />
                                        {this.getAlert('customerNo')}
                                    </div>
                                    <div className={cx({
                                        'form-group': true,
                                        'has-error': !!this.state.validationErrorList.customerName
                                    })}>
                                        <label>
                                            Name
                                        </label>
                                        <input type="text" placeholder="Name" name="customerName"
                                               className="form-control"
                                               onChange={this.onModelChange} value={this.state.model.customerName}
                                               required
                                        />
                                        {this.getAlert('customerName')}

                                    </div>
                                    <div className={cx({
                                        'form-group': true,
                                        'has-error': !!this.state.validationErrorList.telephoneNumber
                                    })}>
                                        <label>
                                            Telefonnummer
                                        </label>
                                        <input type="tel" className="form-control" placeholder="Telefonnummer"
                                               onChange={this.onModelChange} value={this.state.model.telephoneNumber}
                                               name="telephoneNumber"
                                        />
                                        {this.getAlert('telephoneNumber')}

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row form-group">
                                        <div className={cx({
                                            'col-xs-4': true,
                                            'has-error': !!this.state.validationErrorList.plz
                                        })}>
                                            <label>
                                                Postleitzahl
                                            </label>
                                            <input type="text" className="form-control" placeholder="Postleitzahl" name="plz"
                                                   onChange={this.onModelChange} value={this.state.model.plz}
                                                   required
                                            />
                                            {this.getAlert('plz')}
                                        </div>
                                        <div className={cx({
                                            'col-xs-8': true,
                                            'has-error': !!this.state.validationErrorList.city
                                        })}>
                                            <label>
                                                Ort
                                            </label>
                                            <input type="text" className="form-control" placeholder="Ort" name="city"
                                                   onChange={this.onModelChange} value={this.state.model.city}
                                                   required
                                            />
                                            {this.getAlert('city')}
                                        </div>
                                    </div>
                                    <div className={cx({
                                        'form-group': true,
                                        'has-error': !!this.state.validationErrorList.street
                                    })}>
                                        <label htmlFor="street">
                                            Straße
                                        </label>
                                        <input type="text" className="form-control" placeholder="Straße" name="street"
                                               onChange={this.onModelChange} value={this.state.model.street}
                                               required
                                        />
                                        {this.getAlert('street')}
                                    </div>
                                    <div className={cx({
                                        'form-group': true,
                                        'has-error': !!this.state.validationErrorList.additional
                                    })}>
                                        <label>
                                            Zusatz
                                        </label>
                                        <input type="text" className="form-control" placeholder="Zusatz" name="additional"
                                               onChange={this.onModelChange} value={this.state.model.additional}
                                        />
                                        {this.getAlert('additional')}

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="panel-footer">
                            <Button type='submit' onClick={this.onSubmit} disabled={this.state.isSaving}>
                                {this.state.isSaving?
                                    <Fragment>Speichert...</Fragment>:<Fragment><FontAwesomeIcon icon='save'/> Speichern</Fragment>}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

}

export default CreateCustomer
