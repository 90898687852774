import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import Rest from "../../../core/Rest";
import {Button} from 'react-bootstrap';
import {translate} from 'react-i18next';
import Pagination from 'react-js-pagination';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Spinner from "../../Utilities/Spinner";
import {fetchUserList} from '../../../actions/userActions'
import {push} from "connected-react-router";
import LogEntry from './LogEntry'

class Search extends Component {

    availableLimits = [
        1,
        10,
        20,
        50,
        100,
        200
    ];

    constructor(props) {
        super(props);

        this.state = {
            isFetching: false,
            results: [],
            facets: [],
            filter: {},
            pagination: {
                page: 1,
                perPage: 10,
                total: 0
            },
        };

        this.perPageHandler = this.perPageHandler.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.addFilter = this.addFilter.bind(this);
        this.removeFilter = this.removeFilter.bind(this);
        this.querySearch = this.querySearch.bind(this);
    };

    componentDidMount() {
        this.querySearch();
        this.props.actions.fetchUserList();
    }

    addFilter(name, value) {
        const filter = this.state.filter;
        const pagination = this.state.pagination;
        pagination.page = 1;

        filter[name] = value;

        this.setState({filter: filter, pagination: pagination}, () => {
            this.search()
        })
    }

    removeFilter(name) {
        const filter = this.state.filter;
        const pagination = this.state.pagination;
        pagination.page = 1;

        delete filter[name];

        this.setState({filter: filter, pagination: pagination}, () => {
            this.search()
        })
    }

    onSubmit(event) {
        event.preventDefault();
        this.querySearch();
    }

    render() {

        let loading = null;
        if (this.state.isFetching) {
            loading = <Spinner/>
        }

        let limits = '';
        if (this.availableLimits) {
            limits = this.availableLimits.map((data) => {
                return <option key={data} value={data}>{data}</option>
            })
        }

        const facets = this.state.facets.map((facet, i) => {

            const values = facet.values.map((value, i2) => {

                const style = {display: 'inline-block'};

                let isSelected = false;
                if (this.state.filter[facet.name] && this.state.filter[facet.name] === value.key) {
                    isSelected = true;
                    style.fontWeight = 'bold';
                }


                return <div key={i2}>
                    <span style={style} className='filter-value' onClick={() => {
                        if (isSelected) {
                            return;
                        }
                        this.addFilter(facet.name, value.key)
                    }
                    }>
                        {this.props.t('facets.' + facet.name + '.values.' + value.key)}
                    </span>
                    <span style={{display: 'inline-block'}} className="pull-right label label-default">
                        {value.count}
                    </span>
                </div>
            });

            let deleteButton = '';
            if (this.state.filter[facet.name]) {
                deleteButton =
                    <button className="btn-danger btn-xs btn" style={{marginLeft: '5px'}} onClick={() => this.removeFilter(facet.name)}>
                        <FontAwesomeIcon icon='minus'/>
                    </button>
            }

            return <Fragment key={i}>
                <h4>
                    {this.props.t('facets.' + facet.name + '.title')}
                    {deleteButton}
                </h4>
                {values}
            </Fragment>;
        });

        const maxPage = Math.ceil(this.state.pagination.total / this.state.pagination.perPage);

        const results = this.state.results.map((result) => {
            return <LogEntry key={result.id} result={result} userList={this.props.userList.userList}/>
        });

        return (
            <div>
                <div className='panel panel-default'>
                    <div className='panel-body' style={{padding: '5px'}}>
                        <div className='pull-right'>
                            <Button disabled={!!loading}
                                    onClick={this.querySearch}><FontAwesomeIcon
                                icon='sync'/></Button>
                        </div>
                        <div className="form-inline" style={{marginBottom: 0, marginRight: '5px', display: 'inline'}}>
                            <select className="form-control form-inline" id="perPage" value={this.state.pagination.perPage}
                                    onChange={this.perPageHandler}>
                                {limits}
                            </select>
                        </div>
                        {this.state.pagination.total} Ergebnisse | Seite {this.state.pagination.page} von {maxPage}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className='panel panel-default'>
                            <div className='panel-heading'>
                                Filter
                            </div>
                            <div className='panel-body'>
                                {facets}
                            </div>
                            <div className='panel-footer'>
                                <button type="submit" className="btn btn-default" onClick={this.querySearch}>Suchen</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-9'>
                        <div className='panel panel-default'>
                            <div className='panel-heading'>
                                <h3 className='panel-title'>Log {loading}</h3>
                            </div>
                            <table className='table table-hover'>
                                <thead>
                                <tr>
                                    <th>Level</th>
                                    <th>Nachricht</th>
                                    <th>Benutzer</th>
                                    <th>Zeitstempel</th>
                                </tr>
                                </thead>
                                <tbody>
                                {results}
                                </tbody>
                            </table>
                        </div>
                        <div className='text-center'>
                            <Pagination
                                activePage={this.state.pagination.page}
                                itemsCountPerPage={this.state.pagination.perPage}
                                totalItemsCount={this.state.pagination.total}
                                pageRangeDisplayed={5}
                                onChange={this.handlePageChange}
                            />
                        </div>
                    </div>
                </div>

            </div>
        );
    }

    handlePageChange(pageNumber) {
        let pagination = this.state.pagination;
        pagination.page = pageNumber;
        this.setState({pagination: pagination});

        this.search();
    }

    perPageHandler(event) {
        let pagination = this.state.pagination;

        pagination.perPage = parseInt(event.target.value, 10);

        this.setState({pagination: pagination});

        this.search();
    }

    querySearch() {
        let pagination = this.state.pagination;

        pagination.page = 1;

        this.setState({pagination: pagination}, () => {
            this.search();
        })

    }

    search() {
        this.setState({isFetching: true});

        const pagination = this.state.pagination;

        const filter = this.state.filter;

        Rest.fetch({
            endpoint: 'log',
            method: 'GET',
            parameter: {
                with: ['facets'],
                page: pagination.page,
                perPage: pagination.perPage,
                filter: filter,
                query: this.state.term
            }
        }).then(
            response => {
                const result = response.response;

                pagination.perPage = result.itemsPerPage;
                pagination.total = result.total;
                pagination.page = result.page;

                let facets = [];

                if (result.facets) {

                    facets = Object.keys(result.facets).map((key) => {
                        return {
                            name: key,
                            values: result.facets[key]
                        }
                    });
                }

                this.setState({
                    results: result.results,
                    facets: facets,
                    pagination: pagination
                });
            },
            error => {
            }
        ).then(() => {
            this.setState({isFetching: false});
        })
    }


}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchUserList}, dispatch),
        resultLink(link) {
            dispatch(push(link))
        }
    }
};

function mapStateToProps(state) {
    const {userList} = state;

    return {userList}
}

export default compose(
    translate('search'),
    connect(mapStateToProps, mapDispatchToProps)
)(Search);
