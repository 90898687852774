import React, {Component} from 'react';
import {connect} from "react-redux";
import {Button} from 'react-bootstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Rest from "../../../../core/Rest";
import FileSelector from "../../../Utilities/FileSelector/FileModal";
import Spinner from "../../../Utilities/Spinner";

class Settings extends Component {

    previewImgRef;

    constructor(props) {
        super(props);

        this.state = {
            fileName: '',
            x: 0,
            y: 0,
            size: 20,
            isFetching: false,
            isSaving: false
        };

        this.handleShow = this.handleShow.bind(this);
        this.onReset = this.onReset.bind(this);
        this.fetchConfig = this.fetchConfig.bind(this);
        this.saveConfig = this.saveConfig.bind(this);
    }

    componentDidMount()
    {
        this.fetchConfig();
    }

    onModelChange = (event) => {
        const state = this.state;
        state[event.target.name] = event.target.value;
        this.setState(state)
    };

    handleShow() {
        this.modal.handleShow();
    }

    onReset(event)
    {
        this.setState({
            fileName: '',
            x: 0,
            y: 0
        })
    }

    render() {

        let fullWidth = 0;
        if(this.previewImgRef )
        {
            fullWidth = this.previewImgRef.getBoundingClientRect().width;
        }

        let loading = null;
        if (this.state.isFetching) {
            loading = (<Spinner/>)
        }

        return (
            <div className="panel panel-default">
                <div className="panel-heading">
                    <div className='pull-right'>
                        <Button bsSize="xsmall" disabled={!!loading} onClick={this.fetchConfig}><FontAwesomeIcon
                            icon='sync'/></Button>
                    </div>
                    <h4 className="panel-title">Optionales Hintergrundbild {loading}</h4>
                </div>
                <div className="panel-body">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Bild</label><br />
                            <FileSelector ref={(modal) => { this.modal = modal; }} onSelect={(file) => {
                                this.setState({fileName: file.url});
                                this.modal.handleClose();
                            }} />
                            <div className="input-group">
                                <span className="input-group-btn">
                                    <Button onClick={this.handleShow} >Datei Wählen</Button>
                                </span>
                                <input className='form-control' value={this.state.fileName} disabled={true} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Die Größe des QR Codes auf dem Bild</label>
                            <input className='form-control' name='size' value={this.state.size} onChange={this.onModelChange} />
                        </div>
                        <div className="form-group">
                            <label>Position X des QR Codes in Prozent</label>
                            <input className='form-control' name='x' value={this.state.x} onChange={this.onModelChange} />
                        </div>
                        <div className="form-group">
                            <label>Position Y des QR Codes in Prozent</label>
                            <input className='form-control' name='y' value={this.state.y} onChange={this.onModelChange} />
                        </div>
                        <div className="form-group">
                            <button
                                type="submit"
                                className="btn btn-default"
                                style={{marginRight: '5px'}}
                                disabled={this.state.isSaving || this.state.isFetching}
                                onClick={!this.state.isSaving ? this.saveConfig : null}
                            >
                                Speichern
                            </button>
                            <button type="submit" className="btn btn-danger" onClick={this.onReset}>
                                Reset
                            </button>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <fieldset>
                            <legend>Vorschau</legend>
                            <div>
                            <img style={{
                                border: '1px solid black'
                            }} src={this.state.fileName} width='100%' alt='' ref={(ref) => {
                                this.previewImgRef = ref;
                            }}/>
                            <div style={{
                                position: "absolute",
                                backgroundColor: 'black',
                                left: this.state.x + '%',
                                bottom: this.state.y + '%',
                                width: (fullWidth / 100 * this.state.size) + 'px',
                                height: (fullWidth / 100 * this.state.size) + 'px',
                            }} />
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        );
    }

    fetchConfig()
    {
        this.setState({isFetching: true});
        Rest.fetch({
            method: 'GET',
            endpoint: 'config/qr',
        }).then(
            response => {

                const config = response.response.config;

                this.setState({
                    useCustomPicture: config.useCustomPicture,
                    fileName: config.fileName?config.fileName:'',
                    x: config.x,
                    y: config.y,
                    size: config.size,
                    isFetching: false
                })
            },
            error => {
                this.setState({isFetching: false});
            }
        );
    }

    saveConfig()
    {
        this.setState({isSaving: true});

        Rest.fetch({
            method: 'PUT',
            endpoint: 'config/qr',
            body: {
                useCustomPicture: this.state.fileName !== '',
                fileName: this.state.fileName,
                x: this.state.x,
                y: this.state.y,
                size: this.state.size
            }
        }).then(
            response => {
                this.fetchConfig();
                this.setState({isSaving: false});
            },
            error => {
                this.fetchConfig();
                this.setState({isSaving: false});
            }
        );
    }
}

export default connect()(Settings);