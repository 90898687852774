import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import moment from 'moment';
import 'moment/locale/de'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {fetchChangelog} from "../../actions/systemActions";
import {CardHeader, Chip, Divider, IconButton, List, ListItem, ListItemText, Menu, MenuItem, withStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";

moment.locale('de');

const useStyles = theme => ({
    root: {
        marginBottom: theme.spacing(2)
    }
});

const ListItemLink = (props) => {
    const {primary, secondary, to} = props;

    const renderLink = React.useMemo(
        () =>
            React.forwardRef((itemProps, ref) => {
                // eslint-disable-next-line
                return <a href={to} target='_blank' ref={ref} rel="noopener noreferrer" {...itemProps} />
            }),
        [to],
    );

    return (
        <ListItem button component={renderLink} key={to}>
            <ListItemText primary={primary} secondary={secondary}/>
        </ListItem>
    );
};

const MenuItemLink = (props) => {
    const {children, to} = props;

    const renderLink = React.useMemo(
        () =>
            React.forwardRef((itemProps, ref) => {
                // eslint-disable-next-line
                return <a href={to} target='_blank' ref={ref} rel="noopener noreferrer" {...itemProps} />
            }),
        [to],
    );

    return (
        <MenuItem button component={renderLink} key={to}>
            {children}
        </MenuItem>
    );
};

class ChangelogPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null
        }
    }

    componentDidMount() {
        this.reload();
    }

    handleMenuClose = () => {
        this.setState({
            anchorEl: null
        })
    };

    handleMenuOpen = (event) => {
        this.setState({
            anchorEl: event.currentTarget
        })
    };

    render() {

        const classes = this.props.classes;

        let rows = '';
        if (this.props.changelogList) {

            rows = this.props.changelogList.map((data, id) => {
                return Row(id, data, id === this.props.changelogList.length - 1)
            });
        }

        return <React.Fragment>
            <Card className={classes.root} >
                <CardHeader
                    title='Changelog'
                    subheader='Letzte Änderungen'
                    action={
                        <IconButton aria-label="settings" onClick={this.handleMenuOpen}>
                            <MoreVertIcon/>
                        </IconButton>
                    }
                />
                <List disablePadding={true}>
                    <Divider variant='fullWidth'/>
                    {rows}
                </List>
            </Card>
            <Menu
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleMenuClose}
            >
                <MenuItem disabled={this.props.isFetching} onClick={(event) => {
                    this.handleMenuClose(event);
                    this.reload();
                }}>Neu Laden</MenuItem>
                <MenuItemLink onClick={this.handleMenuClose} to='https://vecodesk.freshdesk.com'>
                    Alle Anzeigen
                </MenuItemLink>
            </Menu>
        </React.Fragment>;
    }

    reload = () => {
        this.props.actions.fetchChangelog();
    }

}

const Row = (id, data, last = false) => {

    const time = moment(data.createdAt);

    const timeDiff = Math.abs((new Date()).getTime() - (new Date(data.createdAt)).getTime());

    return <React.Fragment><ListItemLink key={id}
                                         to={data.link}
                                         primary={<React.Fragment>{data.title} {timeDiff <= 7 ?
                                             <Chip color="primary" label={'Neu'}/> : ''}</React.Fragment>}
                                         secondary={time.fromNow()}
    />{!last ? <Divider variant='fullWidth'/> : ''}</React.Fragment>


};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchChangelog}, dispatch),
    }
};

function mapStateToProps(state) {

    const {changelog} = state;
    const {isFetching, changelogList} = changelog;

    return {isFetching, changelogList}
}

export default compose(
    withStyles(useStyles), connect(mapStateToProps, mapDispatchToProps))(ChangelogPanel);

