import React, {Component} from 'react';
import {Divider, Drawer, Typography, withStyles} from "@material-ui/core";

const useStyles = theme => (
    {
        drawer: {
            padding: theme.spacing(1),
            width: 300
        },
        form: {
            marginTop: theme.spacing(1)
        },
        title: {
            marginBottom: theme.spacing(1)
        }
    }
)

class FilterDrawer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };
    }

    render() {
        const classes = this.props.classes;

        return <Drawer
            onClose={this.closePanel}
            anchor="right"
            open={this.props.open}
        >
            <div
                className={classes.drawer}
            >
                <Typography className={classes.title} variant='h5'>Filter</Typography>
                <Divider/>
                <div className={classes.form}>
                    {this.props.children}
                </div>
            </div>
        </Drawer>
    }

    openPanel = () => {
        this.props.onOpen()
    }

    closePanel = () => {
        this.props.onClose()
    }
}

export default withStyles(useStyles)(FilterDrawer);
