import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Button} from 'react-bootstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {fetchCustomerList} from "../../../actions/customerActions";
import Spinner from '../../Utilities/Spinner'
import EventEmitter from 'eventemitter3'

class CustomerSelect extends Component {

    searchFinished = new EventEmitter();

    constructor(props) {
        super(props);

        this.state = {searchString: '', changedByUser: false};

        this.search = this.search.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeCustomer = this.onChangeCustomer.bind(this);

    };

    componentDidMount() {
        this.realSearch();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.isFetching && !this.props.isFetching) {
            this.searchFinished.emit('search-finished')
        }

        if (prevProps.value !== this.props.value && !this.state.changedByUser) {

            if (this.props.isFetching) {
                this.searchFinished.once('search-finished', () => {
                    this.setState({searchString: this.props.value}, () => {
                        this.realSearch();
                    });
                })
            } else {
                this.setState({searchString: this.props.value}, () => {
                    this.realSearch();
                });
            }
        }
    }


    onChange(event) {
        const value = event.target.value;
        const target = event.target.id;

        let state = this.state;

        state[target] = value;

        this.setState(state);

        if (event.key === 'Enter') {
            this.search();
        }
    }

    onChangeCustomer(event) {
        this.setState({changedByUser: true});
        this.props.onChange(parseInt(event.target.value, 10))
    }

    render() {

        let loading = null;
        if (this.props.isFetching) {
            loading = (<Spinner/>)
        }

        let content = '';
        if (this.props.customers) {
            const {results} = this.props.customers;

            content = results.map((result) => {
                return Row(result, this.props.value, this.onChangeCustomer);
            })
        }


        return (
            <div className="panel panel-default">
                <div className="panel-heading">
                    <div className='pull-right'>
                        <Button bsSize="xsmall" disabled={!!loading} onClick={this.search}><FontAwesomeIcon
                            icon='sync'/></Button>
                    </div>
                    <h4 className="panel-title">Kunde Wählen {loading}</h4>
                </div>
                <div className="panel-body new-ticket-customer-panel">
                    <div className="input-group">
                        <input type="text" className="form-control" placeholder="Suchen"
                               id="searchString"
                               value={this.state.searchString}
                               onChange={this.onChange}
                               onKeyPress={this.onChange}
                        />
                        <span className="input-group-btn">
                        <button className="btn btn-default" type="button" onClick={this.search}>Suchen</button>
                    </span>
                    </div>
                    <div className='table-responsive'>
                        <table className="table new-ticket-customer-table">
                            <thead>
                            <tr>
                                <th>KundenNr.</th>
                                <th>Kunde</th>
                                <th>Adresse</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {content.length > 0 && content}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='panel-footer'>
                </div>
            </div>
        );
    }

    search() {
        this.setState({changedByUser: true});
        this.realSearch();
    }

    realSearch() {
        this.props.actions.fetchCustomerList({customerData: this.state.searchString});
    }
}

const Row = (result, selected, onChange) => {

    let additional = '';
    if (result.additional && result.additional.length) {
        additional = <span><br/>({result.additional})</span>;
    }

    return <tr key={result.id}>
        <td>{result.customerNo}</td>
        <td>{result.customerName}</td>
        <td>
            {result.street}<br/>
            {result.plz} {result.city}
            {additional}
        </td>
        <td>
            <input type='radio' value={result.id} checked={result.id === selected} name="selectedCustomer" onChange={onChange}/>
        </td>
    </tr>

};


const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchCustomerList}, dispatch),
    }
};

function mapStateToProps(state) {

    const {customerList} = state;

    const {isFetching, customers} = customerList;

    return {isFetching, customers}
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSelect);