import fontawesome from '@fortawesome/fontawesome';

import faSpinner from '@fortawesome/fontawesome-free-solid/faSpinner';
import faSync from '@fortawesome/fontawesome-free-solid/faSync';
import faPencilAlt from '@fortawesome/fontawesome-free-solid/faPencilAlt';
import faInfoCircle from '@fortawesome/fontawesome-free-solid/faInfoCircle';
import faPlus from '@fortawesome/fontawesome-free-solid/faPlus';
import faMinus from '@fortawesome/fontawesome-free-solid/faMinus';
import faSave from '@fortawesome/fontawesome-free-solid/faSave';
import faShare from '@fortawesome/fontawesome-free-solid/faShare';
import faAngleDown from '@fortawesome/fontawesome-free-solid/faAngleDown';
import faAngleRight from '@fortawesome/fontawesome-free-solid/faAngleRight';
import faAngleLeft from '@fortawesome/fontawesome-free-solid/faAngleLeft';
import faPrint from '@fortawesome/fontawesome-free-solid/faPrint';
import faTrash from '@fortawesome/fontawesome-free-solid/faTrash';
import fasFolder from '@fortawesome/fontawesome-free-solid/faFolder';
import faCheck from '@fortawesome/fontawesome-free-solid/faCheck';
import faTimes from '@fortawesome/fontawesome-free-solid/faTimes';
import faDownload from '@fortawesome/fontawesome-free-solid/faDownload';
import faExternalLinkAlt from '@fortawesome/fontawesome-free-solid/faExternalLinkAlt';
import faRocket from '@fortawesome/fontawesome-free-solid/faRocket';
import faCalculator from '@fortawesome/fontawesome-free-solid/faCalculator';
import faLifeRing from '@fortawesome/fontawesome-free-solid/faLifeRing';
import faClock from '@fortawesome/fontawesome-free-regular/faClock';
import faList from '@fortawesome/fontawesome-free-solid/faList';
import faHistory from '@fortawesome/fontawesome-free-solid/faHistory';
import faArrowsAlt from '@fortawesome/fontawesome-free-solid/faArrowsAlt';
import faQrCode from '@fortawesome/fontawesome-free-solid/faQrcode';
import faUndo from '@fortawesome/fontawesome-free-solid/faUndo';
import faImage from '@fortawesome/fontawesome-free-solid/faImage';
import faCog from '@fortawesome/fontawesome-free-solid/faCog';
import faPlay from '@fortawesome/fontawesome-free-solid/faPlay';
import faDotCircle from '@fortawesome/fontawesome-free-solid/faDotCircle';
import faChevronUp from '@fortawesome/fontawesome-free-solid/faChevronUp';
import faChevronDown from '@fortawesome/fontawesome-free-solid/faChevronDown';
import faCircle from '@fortawesome/fontawesome-free-solid/faCircle';
import faChevronLeft from "@fortawesome/fontawesome-free-solid/faChevronLeft";
import faChevronRight from "@fortawesome/fontawesome-free-solid/faChevronRight";
import faBookmark from "@fortawesome/fontawesome-free-solid/faBookmark";
import faLocationArrow from "@fortawesome/fontawesome-free-solid/faLocationArrow";
import faUser from "@fortawesome/fontawesome-free-solid/faUser";
import faChartPie from "@fortawesome/fontawesome-free-solid/faChartPie";
import faExclamationTriangle from "@fortawesome/fontawesome-free-solid/faExclamationTriangle";

fontawesome.library.add(
    faChartPie,
    faUser,
    faLocationArrow,
    faTimes,
    faPlay,
    faArrowsAlt,
    faSpinner,
    faSync,
    faPencilAlt,
    faInfoCircle,
    faCog,
    faHistory,
    faPlus,
    faSave,
    faMinus,
    faAngleLeft,
    faAngleDown,
    faAngleRight,
    faShare,
    faPrint,
    faCheck,
    faTrash,
    fasFolder,
    faDownload,
    faClock,
    faExternalLinkAlt,
    faCalculator,
    faRocket,
    faLifeRing,
    faList,
    faQrCode,
    faUndo,
    faImage,
    faDotCircle,
    faCircle,
    faChevronUp,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faBookmark,
    faExclamationTriangle
);
