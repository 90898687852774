import React, {Component} from 'react';
import {compose} from "redux";
import {translate} from 'react-i18next';
import onClickOutside from "react-onclickoutside";
import cx from 'classnames'

class SuggestedInput extends Component {

    constructor(props) {
        super(props);

        this.state = {
            suggestionsOpen: false,
        };
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            (!prevProps.suggestions && this.props.suggestions) ||
            (!prevProps.numberSuggestions && this.props.numberSuggestions)
        ) {
            this.setState({suggestionsOpen: true})
        }
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    escFunction = (event) => {
        if (event.keyCode === 27) {
            this.setState({suggestionsOpen: false})
        }
    };

    render() {

        let suggestions = [];
        if (this.props.suggestions) {
            suggestions = this.props.suggestions.map((suggestion) => {
                return <li onClick={() => {
                    this.clickSuggestionTerm(suggestion.text)
                }}>{suggestion.text}</li>
            });
        }

        let numberSuggestions = [];
        if (this.props.numberSuggestions) {
            numberSuggestions = this.props.numberSuggestions.map((suggestion) => {
                return <li onClick={() => {
                    this.clickSuggestionTerm(suggestion.number)
                }}>{suggestion.number}
                    &nbsp;
                    <span className='badge'>{this.props.t('types.' + suggestion.type)}</span>
                </li>
            });
        }

        let suggestionPanel = null;
        if (this.state.suggestionsOpen && (suggestions.length || numberSuggestions.length)) {
            suggestionPanel = <div className='search-autocomplete-list'>
                <span className='autocomplete-list-title'>Vorschläge</span>
                <ul>
                    {suggestions}
                </ul>
                <span className='autocomplete-list-title'>Nummern</span>
                <ul>
                    {numberSuggestions}
                </ul>
            </div>;
        }

        return (
            <form className="form-group" onSubmit={this.onSubmit}>
                <div className={
                    cx({
                        'input-group': true,
                        'input-group-lg': this.props.isLarge,
                        'autocomplete': true
                    })}
                >
                    <input
                        onFocus={() => {
                            this.setState({suggestionsOpen: true})
                        }}
                        type="search" className="form-control"
                        placeholder='Suche' value={this.props.term}
                        onChange={this.onChange}
                    />
                    <span className="input-group-btn">
                                    <button type="submit" className="btn btn-default">Suchen</button>
                                    </span>
                    {suggestionPanel}
                </div>
            </form>);
    }


    onChange = (event) => {
        this.props.onChange(event);
    };

    onSubmit = (event) => {
        event.preventDefault();
        if (typeof this.props.onSubmit === 'function') {
            this.props.onSubmit(event);
        }
    };

    clickSuggestionTerm = (term) => {
        this.setState({suggestionsOpen: false}, () => {
            if (typeof this.props.suggestionClicked === 'function')
                this.props.suggestionClicked(term);
        })
    };

    handleClickOutside = evt => {
        this.setState({suggestionsOpen: false})
    };


}

export default compose(
    translate('search'),
    onClickOutside
)(SuggestedInput);
