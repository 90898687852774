import React, {Component} from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {push} from "connected-react-router";
import {Modal, Button} from 'react-bootstrap'
import Rest from "../../core/Rest";
import {connect} from "react-redux";
import moment from "moment";
import Spinner from "../Utilities/Spinner";
import DateTime from 'react-datetime'
import {bindActionCreators, compose} from "redux";
import {fetchUserList} from "../../actions/userActions";
import {translate} from "react-i18next";

class EditEvent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showDeleteModal: false,
            isFetching: false,
            isSaving: false,
            title: '',
            description: '',
            start: moment(),
            end: moment(),
            fullDay: false,
            subscriber: [],
            reminder: [],
            links: []
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onModelChange = this.onModelChange.bind(this);
        this.onModelCheckboxChange = this.onModelCheckboxChange.bind(this);
        this.onModelSelectChange = this.onModelSelectChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.deleteEvent = this.deleteEvent.bind(this);
        this.fetchEvent = this.fetchEvent.bind(this);
        this.saveEvent = this.saveEvent.bind(this);
        this.addReminder = this.addReminder.bind(this);
        this.removeReminder = this.removeReminder.bind(this);
        this.onModelReminderChange = this.onModelReminderChange.bind(this);
        this.redirectToCalendar = this.redirectToCalendar.bind(this);
    }

    handleClose() {
        this.setState({showDeleteModal: false});
    }

    handleShow() {
        this.setState({showDeleteModal: true});
    }

    componentDidMount() {
        this.props.actions.fetchUserList();
        this.fetchEvent();
    }

    onSubmit(event) {
        event.preventDefault();

        this.saveEvent()
    }

    onModelReminderChange(key, event) {
        let state = this.state;

        state.reminder[key] = event.target.value;

        this.setState(state);
    }

    onModelChange(event) {
        let state = this.state;

        state[event.target.name] = event.target.value;

        this.setState(state);
    }

    onDateChange(name, date) {
        let state = this.state;

        state[name] = date;

        this.setState(state);
    }

    onModelCheckboxChange(event) {
        let state = this.state;

        state[event.target.name] = event.target.checked;

        this.setState(state);
    }

    addReminder() {
        const reminder = this.state.reminder;
        reminder.push('- 5 minutes');
        this.setState({reminder: reminder})
    }

    removeReminder(key) {
        let state = this.state;

        delete state.reminder[key];

        state.reminder = state.reminder.filter(
            (value) => {
                return value
            }
        );

        this.setState(state);
    }

    onModelSelectChange(event) {
        let state = this.state;

        const value = parseInt(event.target.value,10);
        const checked = event.target.checked;

        let list = state[event.target.name];

        if (checked && list.indexOf(value) === -1) {
            list.push(value);
        }
        else {
            list = list.filter((userId) => {

                return userId !== value
            });
        }

        state[event.target.name] = list;

        this.setState(state);
    }


    deleteEvent() {
        this.setState({isSaving: true});

        Rest.fetch({
            endpoint: 'calendar/events/' + this.props.match.params.eventId,
            method: 'DELETE'
        }).then(
            response => {
                this.handleClose();
                this.redirectToCalendar();
            }
        ).then(() => {
            this.setState({isSaving: false});
        });
    }

    saveEvent() {
        this.setState({isSaving: true});

        const body = {
            fullDay: this.state.fullDay,
            title: this.state.title,
            description: this.state.description,
            start: this.state.start.format('YYYY-MM-DD HH:mm'),
            end: this.state.end.format('YYYY-MM-DD HH:mm'),
            subscriber: this.state.subscriber,
            reminder: this.state.reminder
        };


        Rest.fetch({
            endpoint: 'calendar/events/' + this.props.match.params.eventId,
            method: 'PATCH',
            body: body
        }).then(
            response => {
                this.fetchEvent();
            },
            error => {

            }
        ).then(() => {
            this.setState({isSaving: false});
        });
    }

    fetchEvent() {
        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'calendar/events/' + this.props.match.params.eventId,
        }).then(
            response => {

                const event = response.response;

                let subscriber = [];
                if(event.subscriber)
                {
                    subscriber = event.subscriber.map((sub) => {
                        return sub.user.id;
                    })
                }

                let reminder = [];
                if(event.reminder)
                {
                    reminder = event.reminder.map((rem) => {
                        return rem.period
                    })
                }

                const links = this.state.links;
                if(event.links)
                {
                    event.links.forEach((link) => {
                        if(!links[link.type])
                        {
                            links[link.type] = [];
                        }

                        links[link.type].push(link.linkId);

                    });
                }

                const state = {
                    title: event.title,
                    description: event.description,
                    start: moment(event.start),
                    end: moment(event.end),
                    fullDay: event.fullDay,
                    subscriber: subscriber,
                    reminder: reminder,
                    links: links
                };

                this.setState(state);
            },
            error => {
            }
        ).then(() => {
            this.setState({isFetching: false});
        });
    }

    render() {

        let loading = null;
        if (this.state.isFetching) {
            loading = (<Spinner/>)
        }


        let userList = null;
        if (this.props.userList.userList) {
            userList = this.props.userList.userList.map((result) => {

                const checked = this.state.subscriber.indexOf(result.id) !== -1;

                return <tr key={result.id}>
                    <td>{result.firstname} {result.lastname}</td>
                    <td><input name='subscriber' type="checkbox" value={result.id} checked={checked} onChange={this.onModelSelectChange} /></td>
                </tr>
            })
        }

        const reminderList = this.state.reminder.map((value, i) => {
            return <tr>
                <td>
                    <select className='form-control' value={value} onChange={(event) => {
                        this.onModelReminderChange(i, event)
                    }} >
                        <option value='- 5 minutes'>{this.props.t('periods.- 5 minutes')}</option>
                        <option value='- 30 minutes'>{this.props.t('periods.- 30 minutes')}</option>
                        <option value='- 1 hour'>{this.props.t('periods.- 1 hour')}</option>
                        <option value='- 2 hour'>{this.props.t('periods.- 2 hour')}</option>
                        <option value='- 1 day'>{this.props.t('periods.- 1 day')}</option>
                        <option value='- 2 day'>{this.props.t('periods.- 2 day')}</option>
                        <option value='- 1 week'>{this.props.t('periods.- 1 week')}</option>
                        <option value='- 2 week'>{this.props.t('periods.- 2 week')}</option>
                        <option value='- 1 month'>{this.props.t('periods.- 1 month')}</option>
                        <option value='- 2 month'>{this.props.t('periods.- 2 month')}</option>
                    </select>
                </td>
                <td>
                    <button className='btn btn-block btn-danger' onClick={() => this.removeReminder(i)}>
                        <FontAwesomeIcon icon='minus'/>
                    </button>
                </td>
            </tr>
        });

        const linkList = [];
        Object.keys(this.state.links).forEach((key) => {
            const links = this.state.links[key];
            if(Array.isArray(links))
            {
                links.forEach((linkId) => {
                    linkList.push(
                        <tr key={key + ':' + linkId}>
                            <td>
                                {this.props.t('linkTypes.' + key)}
                            </td>
                            <td>
                                {linkId}
                            </td>
                        </tr>
                    )
                });
            }
        });

        return (
            <div>
                <div className="btn-bar">
                    <button
                        className='btn btn-default'
                        onClick={() => {
                            this.redirectToCalendar();
                        }}
                    >
                        <FontAwesomeIcon icon='angle-left'/> Zurück
                    </button>
                </div>
                <form onSubmit={this.onSubmit}>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="panel-default panel">
                                <div className="panel-heading">
                                    <div className='pull-right'>
                                        <Button bsSize="xsmall" disabled={!!loading}
                                                onClick={this.fetchEvent}><FontAwesomeIcon
                                            icon='sync'/></Button>
                                    </div>
                                    <h4 className="panel-title">Termin bearbeiten {loading}</h4>
                                </div>
                                <div className="panel-body">
                                    <div className='form-group'>
                                        <label>Titel</label>
                                        <input className='form-control' name='title' value={this.state.title}
                                               onChange={this.onModelChange}/>
                                    </div>
                                    <div className='form-group'>
                                        <label>Beschreibung</label>
                                        <textarea name="description" className="form-control"
                                                  maxLength="200"
                                                  style={{resize: 'none'}}
                                                  onChange={this.onModelChange}
                                                  value={this.state.description}
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label>Beginn</label>
                                        <DateTime
                                            name="start"
                                            value={this.state.start}
                                            onChange={(date) => {
                                                this.onDateChange('start', date)
                                            }}
                                            timeFormat={true}
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label>Ende</label>
                                        <DateTime
                                            name="end"
                                            value={this.state.end}
                                            onChange={(date) => {
                                                this.onDateChange('end', date)
                                            }}
                                            timeFormat={true}
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <input type='checkbox' name='fullDay' checked={this.state.fullDay}
                                               onChange={this.onModelCheckboxChange}/>
                                        &nbsp;
                                        <label>Ganztägig</label>
                                    </div>
                                </div>
                                <div className="panel-footer">
                                    <button className="btn btn-default" onClick={this.saveEvent}
                                            disabled={this.state.isSaving}>
                                        <FontAwesomeIcon icon='save'/> Speichern
                                    </button>
                                    <div className='pull-right'>
                                        <Button onClick={this.handleShow} bsStyle='danger' type='button' disabled={this.state.isSaving}>
                                            <FontAwesomeIcon icon='trash'/> Löschen
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="panel panel-default">
                                <div className='panel-heading'>
                                    <div className='pull-right'>
                                        <Button bsSize="xsmall" disabled={this.props.userList.isFetching} onClick={this.props.actions.fetchUserList}><FontAwesomeIcon
                                            icon='sync'/></Button>
                                    </div>
                                    <h4 className='panel-title'>Teilnehmer</h4>
                                </div>
                                <table className="table">
                                    <tbody>
                                    {userList}
                                    </tbody>
                                </table>
                            </div>
                            <div className="panel panel-default">
                                <div className='panel-heading'>
                                    <h4 className='panel-title'>Erinnerungen</h4>
                                </div>
                                <table className="table">
                                    <tbody>
                                    {reminderList}
                                    <tr>
                                        <td colSpan={2}>
                                            <button onClick={this.addReminder} type='button' className='btn btn-default btn-block'>
                                                <FontAwesomeIcon icon='plus'/> Neue Erinnerung
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="panel panel-default">
                                <div className='panel-heading'>
                                    <h4 className='panel-title'>Verknüpfungen</h4>
                                </div>
                                <table className="table">
                                    <tbody>
                                    {linkList}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </form>
                <Modal backdropClassName='bootstrap' className='bootstrap' show={this.state.showDeleteModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Löschen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Wirklich Löschen?
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-default" onClick={this.handleClose}>Schließen
                        </button>
                        <Button
                            bsStyle="danger"
                            disabled={this.state.isSaving}
                            onClick={this.deleteEvent}
                        >
                            <FontAwesomeIcon icon='trash'/> Löschen
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

    redirectToCalendar()
    {
        const locationHistory = this.props.locationHistory.filter((object) => {
            return (
                object.pathname !== '/login' &&
                object.pathname.indexOf('/password_reset') === -1 &&
                object.pathname.indexOf('/calendar/') === -1)
        });

        let redirectPath = '/calendar';

        if (locationHistory.length > 1) {
            redirectPath = locationHistory[0].pathname;
        }

        this.props.redirectToCalendar(redirectPath);
    }

}

const mapDispatchToProps = (dispatch) => {

    return {
        actions: bindActionCreators({fetchUserList}, dispatch),
        redirectToCalendar: (redirect = '/calendar') => {
            dispatch(push(redirect))
        }
    }
};

function mapStateToProps(state) {

    const {userList, locationHistory} = state;
    return {userList, locationHistory}
}

export default compose(
    translate('calendar'),
    connect(mapStateToProps, mapDispatchToProps)
)(EditEvent);