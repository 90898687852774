import {applyMiddleware, combineReducers, createStore} from 'redux';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import thunkMiddleware from 'redux-thunk'
import authReducer from '../reducers/authReducer'
import history from './history';
import api from '../middleware/api'
import {groupListReducer, userListReducer, userReducer} from "../reducers/userReducer";
import {singleTicketReducer, ticketOwnReducer, ticketReducer} from "../reducers/ticketReducer";
import configReducer from "../reducers/configReducer"
import {changelogReducer, moduleReducer, queueStatusReducer, requestCountReducer, statusReducer} from "../reducers/systemReducer";
import alertReducer from "../reducers/alertReducer";
import {categoryListReducer} from "../reducers/categoryReducer";
import {listComponentsReducer, listDeviceComponentsReducer, listDeviceComponentTypesReducer} from "../reducers/deviceComponentReducer";
import {tasksListReducer} from "../reducers/tasksReducer";
import {customerListReducer} from "../reducers/customerReducer";
import {statusListReducer} from "../reducers/statusReducer";
import helpPanelReducer from "../reducers/helpReducer";
import {locationHistoryReducer} from "../reducers/locationHistoryReducer";
import {getDeviceReducer} from "../reducers/deviceReducer";
import {formatListReducer, typeListReducer} from "../reducers/importReducer";
import {fileTypeListReducer, typeListReducer as exportTypeListReducer} from "../reducers/exportReducer";
import {notificationReducer} from "../reducers/notificationReducer";
import {requestReducer} from "../reducers/requestReducer";
import {filterBookmarkReducer} from "../reducers/bookmarkReducer";
import {eventListReducer} from "../reducers/calendarReducer";


const store = createStore(
    combineReducers({
        router: connectRouter(history),
        auth: authReducer,
        user: userReducer,
        userList: userListReducer,
        groupList: groupListReducer,
        ticketOwn: ticketOwnReducer,
        ticket: ticketReducer,
        componentTypeList: listDeviceComponentTypesReducer,
        deviceComponentList: listDeviceComponentsReducer,
        componentList: listComponentsReducer,
        singleTicket: singleTicketReducer,
        device: getDeviceReducer,
        status: statusReducer,
        calendarEvents: eventListReducer,
        queueStatus: queueStatusReducer,
        changelog: changelogReducer,
        locationHistory: locationHistoryReducer,
        config: configReducer,
        alerts: alertReducer,
        helpPanel: helpPanelReducer,
        modules: moduleReducer,
        importTypes: typeListReducer,
        importFormats: formatListReducer,
        exportTypes: exportTypeListReducer,
        exportFileTypes: fileTypeListReducer,
        categoryList: categoryListReducer,
        customerList: customerListReducer,
        statusList: statusListReducer,
        tasksList: tasksListReducer,
        requestCount: requestCountReducer,
        filterBookmarks: filterBookmarkReducer,
        notifications: notificationReducer,
        requests: requestReducer
    }),
    applyMiddleware(
        routerMiddleware(history),
        thunkMiddleware,
        api
    )
);

export default store;
