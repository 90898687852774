import React, {Component, Fragment} from 'react';
import {Button} from 'react-bootstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Rest from "../../../core/Rest";
import Spinner from '../../Utilities/Spinner'
import SelectedDeviceRow from './SelectedDeviceRow'

class DeviceSelect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            searchExecuted: false,
            searchString: '',
            isFetching: false,
            customers: null,
            selectedCustomer: null,
            selectedDevices: []
        };

        this.fetchCustomerList = this.fetchCustomerList.bind(this);
        this.fetchCustomer = this.fetchCustomer.bind(this);
        this.onChange = this.onChange.bind(this);
        this.executeSearch = this.executeSearch.bind(this);
        this.onDeviceSelect = this.onDeviceSelect.bind(this);
        this.onDeviceRemove = this.onDeviceRemove.bind(this);
        this.fetchCustomerFromRow = this.fetchCustomerFromRow.bind(this);
        this.addDevice = this.addDevice.bind(this);

    };

    componentDidMount() {
        this.componentConfig(this.props);
        this.fetchCustomerList();
    }

    componentWillReceiveProps(nextProps) {
        this.componentConfig(nextProps);
    }

    componentConfig(props) {

        if (this.state.searchExecuted) {
            return
        }

        if(props.customerValue && props.customerValue > 0 && this.props.customerValue !== props.customerValue)
        {
            this.fetchCustomer(props.customerValue)
        }

        if(props.preselectedDevice && this.state.selectedDevices.indexOf(props.preselectedDevice) === -1)
        {
            this.addDevice(props.preselectedDevice)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if(prevState.isFetching !== this.state.isFetching)
        {
            this.publishLoadingChange(this.state.isFetching)
        }
    }

    onDeviceSelect(event) {
        const id = parseInt(event.target.value, 10);

        this.addDevice(id)
    }

    addDevice(id)
    {
        if (!id) {
            return;
        }

        let devices = this.state.selectedDevices;

        if (devices.indexOf(id) === -1) {
            devices.push(id);
        }

        this.setState({selectedDevices: devices});

        if (typeof this.props.onChange === "function") {
            this.props.onChange(devices);
        }
    }

    onChange(event) {
        const value = event.target.value;
        const target = event.target.id;

        let state = this.state;

        state[target] = value;

        this.setState(state);

        if (event.key === 'Enter') {
            this.executeSearch();
        }
    }

    onDeviceRemove(event) {
        const id = parseInt(event.target.value, 10);

        let devices = this.state.selectedDevices;

        devices = devices.filter((deviceId => {
            return deviceId !== id
        }));

        this.setState({selectedDevices: devices});

        if (typeof this.props.onChange === "function") {
            this.props.onChange(devices);
        }

    }

    render() {

        let loading = null;
        if (this.state.isFetching) {
            loading = (<Spinner/>)
        }

        let content = '';
        if (this.state.customers) {
            const {results} = this.state.customers;

            content = results.map((result) => {
                return Row(result, this.fetchCustomerFromRow);
            })
        }

        let devices = '';
        if (this.state.selectedCustomer &&
            this.state.selectedCustomer.devices) {

            devices = this.state.selectedCustomer.devices.map((result) => {
                return DeviceRow(result, this.onDeviceSelect);
            })
        }

        let selectedDevices = null;
        if (this.state.selectedDevices && this.state.selectedDevices.length) {
            selectedDevices = this.state.selectedDevices.map((device, id) => {
                return <SelectedDeviceRow key={device} value={device} onClick={this.onDeviceRemove}/>
            })
        }

        const inner = <Fragment>
            <div className="new-ticket-table-left">
                <div className="input-group">
                    <input type="text" className="form-control" placeholder="Suchen" id='searchString'
                           value={this.state.searchString}
                           onChange={this.onChange}
                           onKeyPress={this.onChange}
                    />
                    <span className="input-group-btn">
                        <button className="btn btn-default" type="button" onClick={this.executeSearch}>Suchen</button>
                    </span>
                </div>
                <table className="table table-hover" id="deviceSelect_customerTable">
                    <thead>
                    <tr>
                        <th>Kunden Nr.</th>
                        <th>Kunde</th>
                    </tr>
                    </thead>
                    <tbody>
                    {content.length > 0 && content}
                    </tbody>
                </table>
            </div>
            <div className="new-ticket-table-middle">
                <table className="table table-striped" id="deviceSelect_selectionDeviceTable">
                    <thead>
                    <tr>
                        <th>Geräte Nr.</th>
                        <th>Marke</th>
                        <th>Modell</th>
                        <th>Typ</th>
                        <th>Standplatz</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {devices.length > 0 && devices}
                    </tbody>
                </table>
            </div>
            <div className="new-ticket-table-right">
                <table className="table table-striped" id="deviceSelect_selectedDeviceTable">
                    <thead>
                    <tr>
                        <th>Geräte Nr.</th>
                        <th>Marke</th>
                        <th>Modell</th>
                        <th>Typ</th>
                        <th>Standplatz</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {selectedDevices}
                    </tbody>
                </table>
            </div>
        </Fragment>;

        if (this.props.panel === false) {
            return inner;
        }

        return (
            <div className="panel panel-default">
                <div className="panel-heading">
                    <h4 className="panel-title">Gerät Wählen {loading}</h4>
                </div>
                <div className="panel-body" style={{padding: '0px'}}>
                    {inner}
                </div>
                <div className="panel-footer">
                </div>
            </div>
        );
    }

    executeSearch() {
        this.setState({searchExecuted: true});

        this.fetchCustomerList();
    }

    fetchCustomerFromRow(id) {
        this.setState({searchExecuted: true});

        this.fetchCustomer(id);
    }

    fetchCustomerList() {
        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'customers',
            parameter: {
                filter: {
                    customerData: this.state.searchString
                }
            }
        }).then(
            response => {

                this.setState({customers: response.response, isFetching: false})

            },
            error => {
                this.setState({isFetching: false})
            }
        )
    }

    fetchCustomer(id) {
        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'customers/' + id,
            parameter: {
                with: ['customerDevices']
            }
        }).then(
            response => {

                this.setState({selectedCustomer: response.response, isFetching: false})

            },
            error => {
                this.setState({isFetching: false})
            }
        )
    }

    publishLoadingChange(isLoading)
    {
        if(typeof this.props.onLoading === 'function')
        {
            this.props.onLoading(isLoading)
        }
    }

}

const Row = (result, onClick) => {

    return <tr key={result.id} onClick={() => onClick(result.id)}>
        <td>{result.customerNo}</td>
        <td>{result.customerName}</td>
    </tr>

};

const DeviceRow = (result, onClick) => {
    return <tr key={result.id}>
        <td>{result.deviceNo}</td>
        <td>{result.brand}</td>
        <td>{result.model}</td>
        <td>{result.type.title}</td>
        <td>{result.location.title}</td>
        <td>
            <Button bsStyle="success" value={result.id} onClick={onClick}><FontAwesomeIcon icon='plus'/> Hinzufügen</Button>
        </td>
    </tr>

};


export default DeviceSelect;