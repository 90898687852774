import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Button} from 'react-bootstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {fetchImportTypeList} from "../../../actions/importActions";
import {Link} from 'react-router-dom'
import {push} from 'connected-react-router'
import FileSelector from "../../Utilities/FileSelector/FileModal";
import Rest from "../../../core/Rest";

class New extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            type: '',
            file: '',
            createIfNotFound: false,
            isSaving: false
        };

        this.fetchTypes = this.fetchTypes.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.onModelChange = this.onModelChange.bind(this);
        this.onCheckboxModelChange = this.onCheckboxModelChange.bind(this);
        this.saveFormat = this.saveFormat.bind(this);
    }


    componentDidMount() {
        this.fetchTypes();
    }

    fetchTypes() {
        this.props.actions.fetchImportTypeList();
    }

    handleShow() {
        this.modal.handleShow();
    }

    onModelChange(event) {
        const state = this.state;
        state[event.target.name] = event.target.value;
        this.setState(state);
    }

    onCheckboxModelChange(event) {
        const state = this.state;
        state[event.target.name] = event.target.checked;
        this.setState(state);
    }

    saveFormat() {
        this.setState({isSaving: true});

        const body = {
            name: this.state.name,
            type: this.state.type,
            file: this.state.file,
            importFields: [],
            mappingFields: [],
            options: [
                {
                    key: 'createIfNotFound',
                    value: this.state.createIfNotFound ? 1 : 0
                },
                {
                    key: 'deleteFileAfterImport',
                    value: this.state.deleteFileAfterImport ? 1 : 0
                }
            ]
        };

        Rest.fetch({
            endpoint: 'data/import/formats',
            method: 'POST',
            body: body
        }).then(
            response => {
                this.props.redirectToImport(response.response.id);
            },
            error => {

            }
        ).then(() => {
            this.setState({isSaving: false});
        });
    }

    render() {

        let options = [
            <option key={0} value={0}>Bitte Wählen</option>
        ];

        if (this.props.importTypes.typeList) {
            options = options.concat(this.props.importTypes.typeList.map((type) => {
                return <option key={type.id} value={type.id}>{type.name}</option>
            }));
        }

        return (
            <div>
                <div className='btn-bar'>
                    <Link className='btn btn-default' role='button' to='/data/import'>
                        <FontAwesomeIcon
                            icon='angle-left'/> Zurück
                    </Link>
                </div>
                <div className="panel-default panel">
                    <div className="panel-heading">
                        <h4 className="panel-title">
                            Neues Import Format
                        </h4>
                    </div>
                    <div className="panel-body">
                        <div className='form-group'>
                            <label>Name</label>
                            <input className='form-control' name='name' placeholder='Name'
                                   value={this.state.name} onChange={this.onModelChange}
                            />
                        </div>
                        <div className='form-group'>
                            <label>Format</label>
                            <select className='form-control' name='type'
                                    value={this.state.type} onChange={this.onModelChange}
                                    disabled={this.props.importTypes.isFetching}>
                                {options}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Datei</label>
                            <br/>
                            <FileSelector type={'import'} ref={(modal) => {
                                this.modal = modal;
                            }} onSelect={(file) => {
                                this.setState({file: file.url});
                                this.modal.handleClose();
                            }}/>
                            <div className="input-group">
                                <span className="input-group-btn">
                                    <Button onClick={this.handleShow}>Datei Wählen</Button>
                                </span>
                                <input className='form-control' value={this.state.file} disabled={true}/>
                            </div>
                        </div>
                        <div className='form-group'>
                            <input type='checkbox' name='deleteFileAfterImport'
                                   checked={this.state.deleteFileAfterImport} onChange={this.onCheckboxModelChange} />
                            &nbsp;
                            <label>Datei nach dem Import Löschen</label>
                        </div>
                        <div className='form-group'>
                            <input type='checkbox' name='createIfNotFound'
                                   checked={this.state.createIfNotFound} onChange={this.onCheckboxModelChange} />
                            &nbsp;
                            <label>Neue Datensätze anlegen</label>
                        </div>
                    </div>
                    <div className='panel-footer'>
                        <button className='btn btn-default' disabled={this.state.isSaving}
                                onClick={this.saveFormat}
                        >
                            <FontAwesomeIcon icon='save'/> Speichern
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchImportTypeList}, dispatch),
        redirectToImport(id) {
            dispatch(push('/data/import/' + id))
        }
    }
};

function mapStateToProps(state) {

    const {importTypes} = state;

    return {importTypes}
}

export default connect(mapStateToProps, mapDispatchToProps)(New);
