import React, {Component} from 'react';
import {connect} from "react-redux";
import {Route, Switch} from "react-router"
import NoMatch from "../NoMatch/NoMatch";
import List from "./List";
import Dashboard from "./Dashboard";

class Router extends Component {

    componentDidMount() {
        if (!this.props.moduleList.calendar) {

        }
    }

    render() {
        const match = this.props.match;

        if (this.props.moduleList.statistic) {
            return (
                <Switch>
                    <Route path={match.url + '/new'} component={NoMatch}/>
                    <Route path={match.url + '/:id'} component={Dashboard}/>
                    <Route exact path={match.url} component={List}/>
                    <Route component={NoMatch}/>
                </Switch>

            );
        }

        return (
            <Switch>
                <Route component={NoMatch}/>
            </Switch>

        );
    }
}


function mapStateToProps(state) {

    const {modules} = state;
    const {moduleList} = modules;

    return {moduleList}
}

export default connect(mapStateToProps, null)(Router);
