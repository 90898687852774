import {
    GROUP_LIST_FAILURE,
    GROUP_LIST_REQUEST,
    GROUP_LIST_SUCCESS,
    USER_CLEAR,
    USER_FAILURE,
    USER_LIST_FAILURE,
    USER_LIST_REQUEST,
    USER_LIST_SUCCESS,
    USER_REQUEST,
    USER_SUCCESS
} from '../actions/userActions'

export function userReducer(state = {
    isFetching: false
}, action) {

    switch (action.type) {
        case USER_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case USER_FAILURE:

            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch (e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
                permissions: []
            });
        case USER_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                userObject: action.response,
                permissions: action.response.permissions ? action.response.permissions.map(permission => permission.permissionKey) : []
            });
        case USER_CLEAR:
            return Object.assign({}, state, {
                isFetching: false,
                userObject: undefined,
                permissions: []
            });
        default:
            return state
    }
}

export function userListReducer(state = {
    isFetching: false,
    userList: []
}, action) {

    switch (action.type) {
        case USER_LIST_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case USER_LIST_FAILURE:

            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch (e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case USER_LIST_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                userList: action.response
            });
        default:
            return state
    }
}

export function groupListReducer(state = {
    isFetching: false,
    groupList: []
}, action) {

    switch (action.type) {
        case GROUP_LIST_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                userList: []
            });
        case GROUP_LIST_FAILURE:

            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch (e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case GROUP_LIST_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                groupList: action.response
            });
        default:
            return state
    }
}
