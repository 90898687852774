import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {fetchStatusList} from "../../../actions/statusActions";
import {fetchTicket} from "../../../actions/ticketActions";
import Rest from "../../../core/Rest";

class ChangeStatus extends Component {

    constructor(props) {
        super(props);

        this.state = {
            statusId: props.singleTicket.ticket.status?props.singleTicket.ticket.status:0,
            isSaving: false
        };

        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.saveStatus = this.saveStatus.bind(this);
    };

    componentDidMount() {
        this.props.actions.fetchStatusList();
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (prevProps.singleTicket.isFetching && !this.props.singleTicket.isFetching
            && this.props.singleTicket.ticket.status && this.props.singleTicket.ticket.status.id > 0) {
            this.setState({statusId: this.props.singleTicket.ticket.status.id})
        }
    }

    onChangeStatus(event) {
        this.setState({statusId: parseInt(event.target.value, 10)})
    }

    render() {

        const isLoading = this.props.statusList.isFetching ||
            parseInt(this.props.ticketId, 10) !== this.props.singleTicket.ticket.id ||
            this.state.isSaving
        ;

        let status = '';
        if (this.props.statusList.status) {
            status = this.props.statusList.status.map((data) => {
                return <option key={data.id} value={data.id}>{data.title}</option>
            });
        }

        let loading = '';
        if(isLoading)
        {
            loading = <option key={0} value={0} >Loading...</option>
        }

        return <div className='panel panel-default'>
            <div className='panel-heading'>
                <h4 className='panel-title'>Status ändern</h4>
            </div>
            <div className='panel-body'>
                <div className='form-inline' style={{display: 'inline'}}>
                    <select className='form-control' style={{marginRight: '10px'}} value={this.state.statusId} onChange={this.onChangeStatus}>
                        {loading}
                        {status}
                    </select>
                    <Button bsStyle="primary" disabled={isLoading} onClick={!isLoading?this.saveStatus:null}>Ändern</Button>
                </div>
            </div>
        </div>;
    }

    saveStatus() {
        this.setState({isSaving: true});
        Rest.fetch({
            method: 'PATCH',
            endpoint: 'tickets/' + this.props.singleTicket.ticket.id,
            body: {
                status: this.state.statusId,
            }
        }).then(
            response => {
                this.props.actions.fetchTicket(this.props.singleTicket.ticket.id);
            },
            error => {
            }
        ).then(() => {
            this.setState({isSaving: false});
        })
    }

}


const mapDispatchToProps = (dispatch) => {

    return {
        actions: bindActionCreators({fetchStatusList, fetchTicket}, dispatch)
    }
};

function mapStateToProps(state) {

    const {statusList, singleTicket} = state;

    if (!singleTicket.ticket) {
        singleTicket.ticket = {};
    }

    return {statusList, singleTicket};
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeStatus);