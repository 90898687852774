import React, {Component} from 'react';
import {LineChart} from 'react-chartkick'

class DeviceCount extends Component {

    render() {

        const {width, height, data} = this.props;

        return <LineChart
            data={data}
            width={width}
            height={height}
        />
    }
}

export default DeviceCount;