import React, {Component} from 'react';
import {Button} from 'react-bootstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Rest from "../../../core/Rest";
import Dropzone from 'react-dropzone'
import Spinner from '../Spinner'
import File from './File';

class FileModal extends Component {

    constructor(props) {
        super(props);

        this.type = props.type ? props.type : 'images';

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.getFiles = this.getFiles.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onSelect = this.onSelect.bind(this);

        this.state = {showModal: false, files: [], isFetching: false}

    }

    handleClose() {
        this.setState({showModal: false});
    }

    handleShow() {
        this.setState({showModal: true});
    }

    onSelect(file) {

        if (this.props.onSelect) {
            this.props.onSelect(file);
        }
    }

    render() {

        const rows = this.state.files.map((file, i) => {


            return (
                <File key={i} file={file} afterDelete={() => {
                    this.getFiles()
                }} onSelect={this.onSelect}/>
            );
        });

        let loading = null;
        if (this.state.isFetching) {
            loading = (<Spinner/>)
        }

        return (
            <div className="panel panel-default">
                <div className="panel-heading">
                    <div className='pull-right'>
                        <Button bsSize="xsmall" disabled={!!loading} onClick={this.getFiles}><FontAwesomeIcon
                            icon='sync'/></Button>
                    </div>
                    <h4 className="panel-title">Dateien {loading}</h4>
                </div>
                <div className='panel-body' style={{padding: '0px'}}>
                    <Dropzone
                        onDrop={this.onDrop}
                        style={{
                            backgroundColor: 'lightgrey',
                            padding: '10px',
                            textAlign: 'center'
                        }}
                    >
                        Hier Klicken zum Hochladen oder Datei ablegen
                    </Dropzone>
                    <div className='table-responsive'>
                        <table className="table table-hover" style={{marginBottom: '0px'}}>
                            <tbody>
                            {rows}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.getFiles()
    }

    onDrop(files) {

        const formData = new FormData();

        files.forEach((file, i) => {
            formData.append('file' + i, file);
        });

        Rest.fetch({
            endpoint: 'storage/' + this.type,
            method: 'POST',
            body: formData
        }).then(
            response => {
                this.getFiles()
            },
            error => {
                this.getFiles()
            }
        )

    }

    getFiles() {
        this.setState({isFetching: true});

        Rest.fetch({
            method: 'GET',
            endpoint: 'storage/' + this.type,
        }).then(
            response => {
                this.setState({files: response.response, isFetching: false})
            },
            error => {
                this.setState({isFetching: false})
            }
        )
    }

}

export default FileModal;
