import React, {Component} from 'react';
import {connect} from "react-redux";
import {Route, Switch} from "react-router"
import NoMatch from "../../../NoMatch/NoMatch";
import List from "./List";
import New from "./New";
import Edit from "./Edit";

class Router extends Component {
    render() {
        const match = this.props.match;

        return (
            <Switch>
                <Route exact path={match.url} component={List} />
                <Route exact path={`${match.url}/new`} component={New} />
                <Route exact path={`${match.url}/:id`} component={Edit} />
                <Route component={NoMatch}/>
            </Switch>

        );
    }
}

export default connect()(Router);
