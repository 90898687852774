import React, {Component, Fragment} from 'react';
import {geolocated} from 'react-geolocated';

class Calculator extends Component {

    componentDidUpdate(prevProps, prevState, snapshot)
    {

        if (this.props.coords &&
            (!prevProps.coords || (
                    prevProps.coords && (
                        this.props.coords.latitude !== prevProps.coords.latitude ||
                        this.props.coords.longitude !== prevProps.coords.longitude
                    ))
            )
        ) {
            this.props.onPositionChange({
                coords: this.props.coords,
                error: null
            });
        }

        if(!prevProps.positionError && this.props.positionError)
        {
            this.props.onPositionChange({
                coords: null,
                error: this.props.positionError
            });
        }
    }

    render() {

        return (
            <Fragment>
            </Fragment>
        );
    }
}

export default geolocated({
    watchPosition: true,
    positionOptions: {
        enableHighAccuracy: false,
    }
})(Calculator);
