import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Button} from 'react-bootstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {fetchImportTypeList} from "../../../actions/importActions";
import {Link} from 'react-router-dom'
import {push} from 'connected-react-router'
import FileSelector from "../../Utilities/FileSelector/FileModal";
import Rest from "../../../core/Rest";
import {Collapse, Modal} from 'react-bootstrap';
import Papa from 'papaparse';
import FieldRow from "./FieldRow";
import store from "../../../core/store";

class Show extends Component {

    constructor(props) {
        super(props);

        this.state = {
            typeContent: {},
            name: '',
            type: '',
            file: '',
            deleteFileAfterImport: false,
            createIfNotFound: false,
            isSaving: false,
            importFields: [],
            mappingFields: [],
            isFetching: false,
            showDeleteModal: false,
            preview: {
                header: [],
                content: []
            }
        };

        this.fetchTypes = this.fetchTypes.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.onModelChange = this.onModelChange.bind(this);
        this.onCheckboxModelChange = this.onCheckboxModelChange.bind(this);
        this.saveFormat = this.saveFormat.bind(this);
        this.loadCsv = this.loadCsv.bind(this);
        this.toggleShow = this.toggleShow.bind(this);
        this.fetchType = this.fetchType.bind(this);
        this.onImportFieldCheckbox = this.onImportFieldCheckbox.bind(this);
        this.onImportFieldChange = this.onImportFieldChange.bind(this);
        this.onMappingFieldChange = this.onMappingFieldChange.bind(this);
        this.runImport = this.runImport.bind(this);
        this.deleteFormat = this.deleteFormat.bind(this);
        this.handleCloseDelete = this.handleCloseDelete.bind(this);
        this.handleShowDelete = this.handleShowDelete.bind(this);
    }

    handleCloseDelete() {
        this.setState({showDeleteModal: false});
    }

    handleShowDelete() {
        this.setState({showDeleteModal: true});
    }

    toggleShow() {
        this.setState({showSettings: !this.state.showSettings});
    }

    componentDidMount() {
        this.fetchTypes();
        this.loadFormat();
    }

    loadCsv() {

        Papa.parse(this.state.file, {
            download: true,
            header: true,
            preview: 3,
            skipEmptyLines: true,
            complete: (results) => {

                let header = [];
                const content = [];

                results.data.forEach((row) => {
                    if (!header.length) {
                        header = Object.keys(row);
                    }
                    content.push(row);
                });

                const preview = {
                    header: header,
                    content: content
                };

                this.setState({
                    preview: preview
                })
            }
        });
    }

    loadFormat() {
        this.setState({isFetching: true});


        Rest.fetch({
            endpoint: 'data/import/formats/' + this.props.match.params.id,
            method: 'GET'
        }).then(
            response => {

                const result = response.response;

                let deleteFileAfterImport = false;
                let createIfNotFound = false;

                result.options.forEach((option) => {
                    if (option.key === 'deleteFileAfterImport') {
                        deleteFileAfterImport = option.value === 1
                    }
                    if (option.key === 'createIfNotFound') {
                        createIfNotFound = option.value === 1
                    }
                });

                this.setState({
                    name: result.name,
                    type: result.type,
                    file: result.file,
                    deleteFileAfterImport: deleteFileAfterImport,
                    createIfNotFound: createIfNotFound,
                    importFields: result.importFields,
                    mappingFields: result.mappingFields,
                }, () => {
                    this.loadCsv();
                    this.fetchType()
                })
            },
            error => {

            }
        ).then(() => {
            this.setState({isFetching: false});
        });
    }

    fetchTypes() {
        this.props.actions.fetchImportTypeList();
    }

    fetchType() {
        this.setState({typeIsFetching: false});

        Rest.fetch({
            endpoint: 'data/import/types/' + this.state.type,
            method: 'GET'
        }).then(
            response => {
                const mappingFields = response.response.mappingFields.map((field) => {

                    const found = this.state.mappingFields.find((innerField) => {
                        return innerField.target === field.value;
                    });

                    let source = '';
                    if (found) {
                        source = found.source;
                    }

                    return {source: source, target: field.value}
                });

                this.setState({typeContent: response.response, mappingFields: mappingFields});
            },
            error => {

            }
        ).then(() => {
            this.setState({typeIsFetching: false});
        });
    }

    handleShow() {
        this.modal.handleShow();
    }

    onModelChange(event) {
        const state = this.state;
        state[event.target.name] = event.target.value;
        this.setState(state);
    }

    onCheckboxModelChange(event) {
        const state = this.state;
        state[event.target.name] = event.target.checked;
        this.setState(state);
    }

    runImport() {
        this.setState({isSaving: true});

        Rest.fetch({
            endpoint: 'data/import/formats/' + this.props.match.params.id + '/queue',
            method: 'POST'
        }).then(
            response => {
                store.dispatch({
                    type: 'ADD_ALERT',
                    message: 'Import wurde der Warteschlange hinzugefügt.',
                    style: 'success'
                });
            },
            error => {
            }
        ).then(() => {
            this.setState({isSaving: false});
        });
    }

    saveFormat() {
        this.setState({isSaving: true});

        const body = {
            name: this.state.name,
            type: this.state.type,
            file: this.state.file,
            importFields: this.state.importFields,
            mappingFields: this.state.mappingFields,
            options: [
                {
                    key: 'deleteFileAfterImport',
                    value: this.state.deleteFileAfterImport ? 1 : 0
                },
                {
                    key: 'createIfNotFound',
                    value: this.state.createIfNotFound ? 1 : 0
                }
            ]
        };

        Rest.fetch({
            endpoint: 'data/import/formats/' + this.props.match.params.id,
            method: 'PATCH',
            body: body
        }).then(
            response => {
                this.loadFormat()
            },
            error => {

            }
        ).then(() => {
            this.setState({isSaving: false});
        });
    }

    deleteFormat() {
        this.setState({isSaving: true});

        Rest.fetch({
            endpoint: 'data/import/formats/' + this.props.match.params.id,
            method: 'DELETE'
        }).then(
            response => {
                this.props.redirectToList()
            },
            error => {

            }
        ).then(() => {
            this.setState({isSaving: false});
            this.handleCloseDelete()
        });
    }

    onImportFieldCheckbox(event) {
        const value = event.target.value;
        const checked = event.target.checked;

        const found = this.state.importFields.find((field) => {

            return field.target === value;
        });

        if (!found && checked) {
            const importFields = this.state.importFields;
            importFields.push({
                target: value,
                source: ''
            });
            this.setState({importFields: importFields});
        }

        if (found && !checked) {
            const importFields = this.state.importFields.filter((field) => {

                return field.target !== value;
            });

            this.setState({importFields: importFields});
        }

    }

    onImportFieldChange(object) {

        const importFields = this.state.importFields.map((importField) => {

            if (importField.target === object.target) {
                return object;
            }

            return importField;
        });

        this.setState({importFields: importFields});

    }

    onMappingFieldChange(object) {

        const mappingFields = this.state.mappingFields.map((importField) => {

            if (importField.target === object.target) {
                return object;
            }

            return importField;
        });

        this.setState({mappingFields: mappingFields});

    }

    render() {

        let options = [
            <option key={0} value={0}>Bitte Wählen</option>
        ];

        if (this.props.importTypes.typeList) {
            options = options.concat(this.props.importTypes.typeList.map((type) => {
                return <option key={type.id} value={type.id}>{type.name}</option>
            }));
        }

        let importFieldSelect = [];
        if (this.state.typeContent.importFields) {
            importFieldSelect = this.state.typeContent.importFields.map((field) => {

                const found = this.state.importFields.find((selectedField) => {

                    return selectedField.target === field.value;
                });

                const checked = typeof found !== 'undefined';

                let badge = <span className='label label-default'>Nein</span>;
                if (field.required) {
                    badge = <span className='label label-danger'>Ja</span>
                }

                return <tr key={field.value}>
                    <td>
                        <input type='checkbox' checked={checked} value={field.value} onChange={this.onImportFieldCheckbox}/>
                    </td>
                    <td>
                        {field.name}
                    </td>
                    <td style={{width: '150px'}}>
                        {field.description}
                    </td>
                    <td>
                        {badge}
                    </td>
                </tr>
            })
        }

        const importFields = this.state.importFields.map((field) => {
            let found = null;
            if (this.state.typeContent.importFields) {
                found = this.state.typeContent.importFields.find((innerField) => {
                    return innerField.value === field.target;
                });
            }

            return <FieldRow key={field.target} data={field} fieldData={found}
                             preview={this.state.preview} onChange={this.onImportFieldChange}/>
        });

        const mappingFields = this.state.mappingFields.map((field) => {

            let found = null;
            if (this.state.typeContent.mappingFields) {
                found = this.state.typeContent.mappingFields.find((innerField) => {
                    return innerField.value === field.target;
                });
            }

            return <FieldRow key={field.target} data={field} fieldData={found} displayRequired={true}
                             preview={this.state.preview} onChange={this.onMappingFieldChange}/>
        });

        return (
            <div>
                <div className='btn-bar'>
                    <Link className='btn btn-default' role='button' to='/data/import' style={{marginRight: '5px'}}>
                        <FontAwesomeIcon
                            icon='angle-left'/> Zurück
                    </Link>
                    <button className='btn btn-default' style={{marginRight: '5px'}}
                            onClick={this.toggleShow}
                    >
                        <FontAwesomeIcon icon='cog'/> Einstellungen
                    </button>
                    <button className='btn btn-primary' disabled={this.state.isSaving} style={{marginRight: '5px'}}
                            onClick={this.saveFormat}
                    >
                        <FontAwesomeIcon icon='save'/> Speichern
                    </button>
                    <button className='btn btn-primary' disabled={this.state.isSaving}
                            onClick={this.runImport}
                    >
                        <FontAwesomeIcon icon='play'/> Import einreihen
                    </button>

                    <div className='pull-right'>
                        <button className='btn btn-danger'
                                onClick={this.handleShowDelete}
                        >
                            <FontAwesomeIcon icon='trash'/> Löschen
                        </button>
                    </div>
                </div>
                <Collapse in={this.state.showSettings}>
                    <div className="panel-default panel">
                        <div className="panel-heading">
                            <h4 className="panel-title">
                                Format Bearbeiten
                            </h4>
                        </div>
                        <div className="panel-body">
                            <div className='form-group'>
                                <label>Name</label>
                                <input className='form-control' name='name' placeholder='Name'
                                       value={this.state.name} onChange={this.onModelChange}
                                />
                            </div>
                            <div className='form-group'>
                                <label>Format</label>
                                <select className='form-control' name='type'
                                        value={this.state.type} onChange={this.onModelChange}
                                        disabled={true}>
                                    {options}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Datei</label>
                                <br/>
                                <FileSelector type={'import'} ref={(modal) => {
                                    this.modal = modal;
                                }} onSelect={(file) => {
                                    this.setState({file: file.url}, () => {
                                        this.loadCsv();
                                    });
                                    this.modal.handleClose();
                                }}/>
                                <div className="input-group">
                                <span className="input-group-btn">
                                    <Button onClick={this.handleShow}>Datei Wählen</Button>
                                </span>
                                    <input className='form-control' value={this.state.file} disabled={true}/>
                                </div>
                            </div>
                            <div className='form-group'>
                                <input type='checkbox' name='deleteFileAfterImport'
                                       checked={this.state.deleteFileAfterImport} onChange={this.onCheckboxModelChange}/>
                                &nbsp;
                                <label>Datei nach dem Import Löschen</label>
                            </div>
                            <div className='form-group'>
                                <input type='checkbox' name='createIfNotFound'
                                       checked={this.state.createIfNotFound} onChange={this.onCheckboxModelChange}/>
                                &nbsp;
                                <label>Neue Datensätze anlegen</label>
                            </div>
                        </div>
                        <div className='panel-footer'>
                            <button className='btn btn-default' disabled={this.state.isSaving}
                                    onClick={this.saveFormat}
                            >
                                <FontAwesomeIcon icon='save'/> Speichern
                            </button>
                        </div>
                    </div>
                </Collapse>
                <div className="panel-default panel">
                    <div className="panel-heading">
                        <h4 className="panel-title">
                            Abgleich Felder
                        </h4>
                    </div>
                    <table className='table table-striped'>
                        <thead>
                        <tr>
                            <th style={{width: '30%'}}>Ziel</th>
                            <th style={{width: '30%'}}>Quelle</th>
                            <th>Beispiel</th>
                        </tr>
                        </thead>
                        <tbody>
                        {mappingFields}
                        </tbody>
                    </table>
                </div>
                <div className='row'>
                    <div className='col-md-7'>
                        <div className="panel-default panel">
                            <div className="panel-heading">
                                <h4 className="panel-title">
                                    Ausgewählte Felder
                                </h4>
                            </div>
                            <table className='table table-striped'>
                                <thead>
                                <tr>
                                    <th style={{width: '200px'}}>Ziel</th>
                                    <th style={{width: '200px'}}>Quelle</th>
                                    <th>Beispiel</th>
                                </tr>
                                </thead>
                                <tbody>
                                {importFields}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='col-md-5'>
                        <div className="panel-default panel">
                            <div className="panel-heading">
                                <h4 className="panel-title">
                                    Felder
                                </h4>
                            </div>
                            <div className="table-responsive">
                                <table className='table table-striped'>
                                    <thead>
                                    <tr>
                                        <th/>
                                        <th>Feld</th>
                                        <th>Beschreibung</th>
                                        <th>Pflichtfeld zum Datensatzanlegen</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {importFieldSelect}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal backdropClassName='bootstrap' className='bootstrap' show={this.state.showDeleteModal} onHide={this.handleCloseDelete}>
                    <Modal.Header closeButton>
                        <Modal.Title>Löschen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Wirklich Löschen?
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-default" onClick={this.handleCloseDelete}>Schließen
                        </button>
                        <Button
                            bsStyle="danger"
                            onClick={this.deleteFormat}
                            disabled={this.state.isSaving}
                        >
                            <FontAwesomeIcon icon='trash'/> Löschen
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchImportTypeList}, dispatch),
        redirectToList() {
            dispatch(push('/data/import'))
        }
    }
};

function mapStateToProps(state) {

    const {importTypes} = state;

    return {importTypes}
}

export default connect(mapStateToProps, mapDispatchToProps)(Show);
