import React, {Component, Fragment} from 'react';
import ReactResizeDetector from 'react-resize-detector';
import ReactChartkick from 'react-chartkick'
import Chart from 'chart.js'
import Rest from "../../core/Rest";
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import _ from "lodash";
import DeviceCount from './Types/DeviceCount'
import ArrangerProcessedTicketsTotal from "./Types/ArrangerProcessedTicketsTotal";
import ArrangerProcessedTicketsPerTime from "./Types/ArrangerProcessedTicketsPerTime";
import Spinner from "../Utilities/Spinner";

ReactChartkick.addAdapter(Chart);

class DashboardItem extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isFetching: false,
            data: null,
            editModal: false,
            width: 0,
            height: 0,
            item: null
        };

        this.handleResize = this.handleResize.bind(this);
        this.fetchStatistic = this.fetchStatistic.bind(this);
        this.checkItem = this.checkItem.bind(this);
        this.onItemChange = this.onItemChange.bind(this);
    }

    map = {
        deviceCount: DeviceCount,
        arrangerProcessedTicketsTotal: ArrangerProcessedTicketsTotal,
        arrangerProcessedTicketsPerTime: ArrangerProcessedTicketsPerTime
    };

    types = {
        deviceCount: 'Anzahl Geräte',
        arrangerProcessedTicketsTotal: 'Nutzer: Bearbeitete Tickets - Gesamt',
        arrangerProcessedTicketsPerTime: 'Nutzer: Bearbeitete Tickets - Zeitabhängig'
    };

    static getDerivedStateFromProps(props, state) {
        if (props.item && props.item !== state.item) {
            state.item = props.item;
        }
        return state;
    }

    componentDidMount() {
        this.handleResize();
        this.fetchStatistic();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.checkItem();

    }

    checkItem() {
        if (!_.isEqual(this.props.item, this.state.item)) {
            this.props.onChange(this.state.item);
        }
    }

    handleResize() {
        const specs = this.refs.container.getBoundingClientRect();
        this.setState({width: specs.width, height: specs.height});
    }

    onItemChange(event) {
        const item = this.state.item;

        item[event.target.name] = event.target.value;

        let callback = () => {
        };
        if (event.target.name === 'type') {
            callback = () => {
                this.fetchStatistic();
            };
        }

        this.setState({item: item}, callback)
    }

    render() {

        const types = Object.keys(this.types).map((key) => {
            return <option key={key} value={key}>{this.types[key]}</option>
        });

        let loading = null;
        if (this.state.isFetching) {
            loading = (<Spinner/>)
        }

        let chart = 'Daten werden geladen';
        if (this.state.data) {
            chart = React.createElement(this.map[this.state.item.type], {
                width: this.state.width,
                height: this.state.height,
                data: this.state.data
            })
        }
        let header = <h3 className='panel-title'>{this.state.item.title} - {this.types[this.state.item.type]} {loading}</h3>;
        const buttons = [];
        if (!this.props.o.static) {
            buttons.push(<button key={1} style={{marginRight: '5px'}} className='btn btn-danger btn-xs'
                                 onClick={this.props.onDelete}
            >
                <FontAwesomeIcon icon='minus'/>
            </button>);

            buttons.push(<button key={0} className='btn btn-default btn-xs dragMe'
            >
                <FontAwesomeIcon icon='arrows-alt'/>
            </button>);

            header = <div className='form-inline'>
                <div className='form-group' style={{marginRight: '5px'}}>
                    <input className='form-control input-sm' value={this.state.item.title} name='title' onChange={this.onItemChange}/>
                </div>
                <div className='form-group'>
                    <select className='form-control input-sm' value={this.state.item.type} name='type' onChange={this.onItemChange}>
                        {types}
                    </select>
                </div>
            </div>
        }

        return <Fragment>
            <div className='panel-heading'>
                <div className='pull-right'>
                    {buttons}
                </div>
                {header}
            </div>
            <div className="panel-body" style={{height: 'calc(100% - 38px)'}}>
                <div ref='container' style={{height: '100%'}}>
                    <ReactResizeDetector handleWidth handleHeight onResize={this.handleResize}/>
                    {chart}
                </div>
            </div>
        </Fragment>;
    }

    fetchStatistic() {
        this.setState({isFetching: true});
        Rest.fetch({
            endpoint: 'statistic',
            parameter: {
                filter: {
                    timestampBefore: this.props.to.toISOString(),
                    timestampAfter: this.props.from.toISOString()
                },
                type: this.state.item.type,
                interval: this.props.interval
            }
        }).then(
            (response) => {
                this.setState({data: response.response});
            }, () => {
            }
        ).then(() => {
            this.setState({isFetching: false})
        })
    }
}

export default DashboardItem;
