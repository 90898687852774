import React, {Component} from 'react';
import {connect} from "react-redux";

import Alert from './Alert'


class Alerts extends Component {

    constructor(props){
        super(props);

        this.removeAlerts = this.removeAlerts.bind(this);
    }
    removeAlerts() {
        let {dispatch} = this.props;
        dispatch({
            type: 'REMOVE_ALL_ALERT',
        })
    }

    render() {

        let alerts = '';
        let button = '';
        if (this.props.alerts && this.props.alerts.length > 0) {
            button = <button className="btn-block btn-default btn-sm " onClick={this.removeAlerts} style={{marginBottom: '5px'}}> Alle Löschen </button>;

            alerts = this.props.alerts.map((data, id) => {
                return <Alert key={data.id} alert={data} interval={10000} />
            });
        }

        return (
            <div className='bootstrap alert-window'>
                {button}
                <div className={'alert-list'}>
                {alerts}
                </div>
            </div>
        );

    }
}

function mapStateToProps(state) {

    const {alerts} = state;

    return {alerts}
}

export default connect(mapStateToProps, null)(Alerts);
