import React, {Component} from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import store from "../../core/store";
import {push} from 'connected-react-router';
import Spinner from "../Utilities/Spinner";
import {Button, ButtonGroup, ButtonToolbar, Modal} from "react-bootstrap";
import Rest from "../../core/Rest";

class List extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dashboards: [],
            isFetching: false,
            isSaving: false,
            newModal: false,
            newName: ''
        };

        this.fetchDashboards = this.fetchDashboards.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.onModalChange = this.onModalChange.bind(this);
        this.saveNewDashboard = this.saveNewDashboard.bind(this);
    }

    handleClose() {
        this.setState({newModal: false});
    }

    handleShow() {
        this.setState({newModal: true});
    }

    onModalChange(event) {
        const state = this.state;
        state[event.target.name] = event.target.value;
        this.setState(state);
    }

    saveNewDashboard() {
        this.setState({isSaving: true});
        Rest.fetch({
            endpoint: 'statistic/dashboards',
            method: 'POST',
            body: {
                name: this.state.newName
            }
        }).then(
            (response) => {
                this.handleClose();
                this.setState({newName: ''});
                this.fetchDashboards();
            }, () => {
            }
        ).then(() => {
            this.setState({isSaving: false})
        })
    }

    componentDidMount() {
        this.fetchDashboards();
    }

    render() {

        let loading = null;
        if (this.state.isFetching) {
            loading = (<Spinner/>)
        }

        const dashboards = this.state.dashboards.map((dashboard) => {
            return <tr key={dashboard.id} onClick={() => {
                store.dispatch(push('/statistic/' + dashboard.id))
            }}>
                <td>{dashboard.name}</td>
            </tr>
        });

        return <div>
            <div className='btn-bar'>
                <button className='btn btn-default' onClick={this.handleShow}>
                    <FontAwesomeIcon
                        icon='plus'/> Neu Anlegen
                </button>
            </div>
            <div className='panel panel-default'>
                <div className='panel-heading'>
                    <div className='pull-right'>
                        <ButtonToolbar>
                            <ButtonGroup bsSize="xsmall">
                                <Button disabled={!!loading} onClick={this.fetchDashboards}>
                                    <FontAwesomeIcon icon='sync'/></Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </div>
                    <h4 className='panel-title'>Dashboards {loading}</h4>
                </div>
                <div className='table-responsive'>
                    <table className='table table-hover'>
                        <tbody>
                        {dashboards}
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal backdropClassName='bootstrap' className='bootstrap' show={this.state.newModal} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Dashboard anlegen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='form-group'>
                        <label>Name</label>
                        <input className='form-control' name='newName' onChange={this.onModalChange}
                               value={this.state.newName}/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-success" onClick={this.saveNewDashboard} disabled={this.state.isSaving}>
                        <FontAwesomeIcon icon='save'/> Speichern
                    </button>
                    <button type="button" className="btn btn-default" onClick={this.handleClose} disabled={this.state.isSaving}>
                        Schließen
                    </button>
                </Modal.Footer>
            </Modal>
        </div>;
    }

    fetchDashboards() {
        this.setState({isFetching: true});
        Rest.fetch({
            endpoint: 'statistic/dashboards'
        }).then(
            (response) => {
                this.setState({dashboards: response.response})
            }, () => {
            }
        ).then(() => {
            this.setState({isFetching: false})
        })
    }
}

export default List;
