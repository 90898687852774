import React, {Component} from 'react';
import {Route, Switch} from 'react-router'
import NoMatch from "../NoMatch/NoMatch";
import New from "./New";
import Alerts from "../Alerts/Alerts";
import {connect} from "react-redux";

class Menu extends Component {


    render() {
        const match = this.props.match;
        return (
            <div className='bootstrap'>
                <Alerts/>
                <Switch>
                    <Route path={match.url + '/tickets/new/:deviceId'} component={New}/>
                    <Route path={match.url + '/tickets'} component={New}/>
                    <Route component={NoMatch}/>
                </Switch>
            </div>
        );
    }
}


export default connect(null, null)(Menu);
