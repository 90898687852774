import {
    EXPORT_FILE_TYPES_FAILURE, EXPORT_FILE_TYPES_REQUEST, EXPORT_FILE_TYPES_SUCCESS,
    EXPORT_TYPES_SUCCESS, EXPORT_TYPES_REQUEST, EXPORT_TYPES_FAILURE
} from '../actions/exportActions'

export function typeListReducer(state = {
    isFetching: false,
}, action) {
    switch (action.type) {
        case EXPORT_TYPES_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case EXPORT_TYPES_FAILURE:

            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch(e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case EXPORT_TYPES_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                typeList: action.response
            });
        default:
            return state
    }
}


export function fileTypeListReducer(state = {
    isFetching: false,
}, action) {
    switch (action.type) {
        case EXPORT_FILE_TYPES_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case EXPORT_FILE_TYPES_FAILURE:

            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch(e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case EXPORT_FILE_TYPES_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                typeList: action.response
            });
        default:
            return state
    }
}

