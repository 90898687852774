import React, {Component,} from 'react';
import {Route, Switch} from "react-router"

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {fetchUser} from "../actions/userActions";
import LoadingScreen from './Utilities/LoadingScreen';
import {fetchModules} from "../actions/systemActions";
import Live from "./Live/Live";
import Dashboard from "./Dashboard/Dashboard";

class LoggedIn extends Component {

    componentDidMount() {
        this.props.actions.fetchUser();
        this.props.actions.fetchModules();
    }

    render() {
        const match = this.props.match;

        if (!this.props.user.userObject || !this.props.modules.moduleList) {

            let error = this.props.user.error;

            if (!error && this.props.modules.hasOwnProperty('error')) {
                error = 'Module konnten nicht geladen werden';
            }

            return <LoadingScreen error={error} isFetching={this.props.user.isFetching || this.props.modules.isFetching}/>
        }

        return <Switch>
            <Route path={`${match.url}live`} component={Live}/>
            <Route path={match.url} component={Dashboard}/>
        </Switch>;
    }
}

function mapStateToProps(state) {

    const {user, modules} = state;

    return {user, modules}
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchUser, fetchModules}, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoggedIn);