import React, {Component} from 'react';
import {connect} from "react-redux";

import Rest from "../../../../core/Rest";

class Groups extends Component {

    constructor(props) {
        super(props);

        this.state = {
            groups: [],
            isFetching: false,
        };

        this.fetchGroups = this.fetchGroups.bind(this);
        this.onChangeGroup = this.onChangeGroup.bind(this);
    };

    componentDidMount() {
        this.fetchGroups();
    }

    render() {

        const groups = this.state.groups.map((group) => {
            const checked = this.props.groups.filter(userGroup => userGroup.id === group.id).length > 0;

            return <tr key={group.id}>
                <td>{group.title}</td>
                <td>
                    <input
                        type="checkbox"
                        onChange={this.onChangeGroup}
                        value={group.id}
                        checked={checked}
                    />
                </td>
            </tr>;

        });

        return (
            <div className="panel panel-default">
                <div className="panel-heading">
                    <h4 className="panel-title">Gruppen</h4>
                </div>
                <table className="table">
                    <tbody>
                    {groups}
                    </tbody>
                </table>
            </div>
        );
    }

    fetchGroups() {

        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'users/groups',
        }).then(
            response => {
                this.setState({groups: response.response})
            },
            error => {
                this.setState({isFetching: false})
            }
        );
    }


    onChangeGroup(event) {
        let groups = this.props.groups;

        let oldGroupObject = this.state.groups.find((value) => {
            return value.id === parseInt(event.target.value, 10);
        });

        let groupObject = Object.assign({},oldGroupObject);

        if (event.target.checked) {

            groups.push(groupObject);

        }
        else {
            groups = groups.filter((value) => {
                return value.id !== parseInt(event.target.value, 10);
            });
        }

        const uniqueValue = groups.filter((value, index, self) => {
            return self.indexOf(value) === index;
        });

        this.props.onChange(uniqueValue.slice(0));

    }

}


export default connect()(Groups);
