import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import CustomerSelect from '../../Tickets/New/CustomerSelect';
import {push} from "connected-react-router";
import qs from "query-string";
import Rest from "../../../core/Rest";
import {connect} from "react-redux";
import {fetchDeviceConfig} from "../../../actions/configActions";
import {bindActionCreators} from "redux";

class CreateDevice extends Component {

    constructor(props) {
        super(props);

        this.state = {
            locationsFetching: false,
            typesFetching: false,
            isSaving: false,
            locationList: [],
            typeList: [],
            generate: false,
            number: '',
            model: '',
            brand: '',
            typeId: 0,
            locationId: 0,
            notes: '',
            customerId: null,

        };

        this.onSubmit = this.onSubmit.bind(this);
        this.saveDevice = this.saveDevice.bind(this);
        this.onCustomerChange = this.onCustomerChange.bind(this);
        this.onModelChange = this.onModelChange.bind(this);
        this.onModelIntChange = this.onModelIntChange.bind(this);
        this.onModelCheckboxChange = this.onModelCheckboxChange.bind(this);
    }

    componentDidMount() {
        this.props.actions.fetchDeviceConfig();
        const query = qs.parse(this.props.location.search);
        if (query.customerId) {
            this.onCustomerChange(parseInt(query.customerId, 10));
        }
        this.fetchTypes();
    }

    onSubmit(event) {
        event.preventDefault();

        this.saveDevice()
    }

    onModelChange(event) {
        let state = this.state;

        state[event.target.name] = event.target.value;

        this.setState(state);
    }

    onModelIntChange(event) {
        let state = this.state;

        state[event.target.name] = parseInt(event.target.value, 10);

        this.setState(state);
    }

    onModelCheckboxChange(event) {
        let state = this.state;

        state[event.target.name] = event.target.checked;

        this.setState(state);
    }

    saveDevice() {
        this.setState({isSaving: true});

        const body = {
            generate: this.state.generate,
            deviceNo: parseInt(this.state.number, 10),
            model: this.state.model,
            brand: this.state.brand,
            typeId: this.state.typeId,
            locationId: this.state.locationId,
            notes: this.state.notes,
            customerId: this.state.customerId,
        };

        if (this.props.deviceConfig && this.props.deviceConfig.config) {
            const fieldConfig = this.props.deviceConfig.config.fieldConfig;

            Object.keys(
                fieldConfig
            ).filter((key) => {
                return fieldConfig[key] !== false
            }).forEach((key) => {
                if (typeof this.state['free' + key] !== 'undefined') {
                    body['free' + key] = this.state['free' + key]
                }
            });
        }

        Rest.fetch({
            endpoint: 'devices',
            method: 'POST',
            body: body
        }).then(
            response => {
                this.props.redirectToDevice(response.response.customer.id, response.response.id, )
            },
            error => {}
        ).then(() => {
            this.setState({isSaving: false});
        });
    }

    onCustomerChange(value) {
        this.setState({customerId: value}, () => {
            this.fetchLocations();
        });
    }

    fetchTypes() {
        this.setState({typesFetching: true});

        Rest.fetch({
            endpoint: 'devices/types',
        }).then(
            response => {
                this.setState({typeList: response.response})
            },
            error => {}
        ).then(() => {
            this.setState({typesFetching: false});
        });

    }

    fetchLocations() {
        if (!this.state.customerId) {
            return;
        }

        this.setState({locationsFetching: true});

        const filter = {
            customer: this.state.customerId
        };

        Rest.fetch({
            endpoint: 'customers/locations',
            parameter: {
                filter: filter
            }
        }).then(
            response => {
                this.setState({locationList: response.response})
            },
            error => {}
        ).then(() => {
            this.setState({locationsFetching: false});
        });

    }

    render() {

        const locationList = this.state.locationList.sort((a, b) => {
            const textA = a.title.toUpperCase();
            const textB = b.title.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }).map((location) => {
            return <option value={location.id}>{location.title}</option>
        });

        const typeList = this.state.typeList.sort((a, b) => {
            const textA = a.title.toUpperCase();
            const textB = b.title.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }).map((type) => {
            return <option value={type.id}>{type.title}</option>
        });

        let fields = [];
        if (this.props.deviceConfig && this.props.deviceConfig.config) {
            const fieldConfig = this.props.deviceConfig.config.fieldConfig;

            fields = Object.keys(
                fieldConfig
            ).filter((key) => {
                return fieldConfig[key] !== false
            }).map((key) => {
                const value = fieldConfig[key];

                if (value.type === 'bool') {
                    return <div className="form-group">
                        <input name={'free' + key} onChange={this.onModelCheckboxChange} type='checkbox'/>
                        &nbsp;
                        <label>{value.name}</label>
                    </div>;
                }
                return <div className="form-group">
                    <label>{value.name}</label>
                    <input name={'free' + key} onChange={this.onModelChange} type='text' className='form-control'/>
                </div>;
            });
        }

        return (
            <div>
                <div className="btn-bar">
                    <Link
                        className='btn btn-default'
                        role='button'
                        to={'/devices'}
                    >
                        <FontAwesomeIcon icon='angle-left'/> Zurück
                    </Link>
                </div>
                <CustomerSelect value={this.state.customerId} onChange={this.onCustomerChange}/>
                <div className="panel-default panel">
                    <div className="panel-heading">
                        <h4 className="panel-title">
                            Neues Gerät
                        </h4>
                    </div>
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="deviceId">Gerätenummer</label>
                                    <input className="form-control" type="number" placeholder="Gerätenummer." name='number'
                                           disabled={this.state.generate} value={this.state.number} onChange={this.onModelChange}
                                           required={true}/>
                                    <input type="checkbox" name="generate" value={this.state.generate}
                                           onChange={this.onModelCheckboxChange}/>
                                    <label>Generieren</label>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="brand">Marke</label>
                                    <input className="form-control" name='brand' type="text" onChange={this.onModelChange}
                                           placeholder="Marke" required={true}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="model">Modell</label>
                                    <input className="form-control" name="model" type="text" onChange={this.onModelChange}
                                           placeholder="Model" required={true}/>
                                </div>
                                <fieldset>
                                    <legend>Zusätzliche Felder</legend>
                                    {fields}
                                </fieldset>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="location">Standplatz</label>
                                    <select className="form-control" name="locationId" value={this.state.locationId}
                                            onChange={this.onModelIntChange} disabled={this.state.locationsFetching} required>
                                        <option value={0}>Bitte Wählen</option>
                                        {locationList}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="type">Typ</label>
                                    <select className="form-control" name="typeId" value={this.state.typeId}
                                            onChange={this.onModelIntChange} required>
                                        <option value={0}>Bitte Wählen</option>
                                        {typeList}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="notes">Notizen</label>
                                    <textarea name="notes" className="form-control" onChange={this.onModelChange}
                                              style={{resize: 'none', height: '200px'}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel-footer">
                        <button className="btn btn-default" onClick={this.saveDevice} disabled={this.state.isSaving}>
                            <FontAwesomeIcon icon='save'/> Speichern
                        </button>
                    </div>
                </div>
            </div>
        );
    }

}

const mapDispatchToProps = (dispatch) => {

    return {
        actions: bindActionCreators({fetchDeviceConfig}, dispatch),
        redirectToDevice: (customerId, deviceId) => {
            dispatch(push('/customer/' + customerId + '/devices/' + deviceId))
        }
    }
};

function mapStateToProps(state) {

    const {config} = state;
    const {deviceConfig} = config;

    return {deviceConfig}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateDevice);