import React, {Component, Fragment} from 'react';
import Device from './Device';


class Customer extends Component {

    constructor(props) {
        super(props);

        this.onDeviceChange = this.onDeviceChange.bind(this);

    }

    onDeviceChange(device) {
        this.props.onDeviceChange(device);
    }

    render() {

        const customerData = this.props.customer;

        const customer = customerData.customer;

        let primaryAddress = customer.addresses ? customer.addresses.find((value) => {
            return value.primary
        }) : {};

        if(!primaryAddress)
        {
            primaryAddress = {};
        }

        let customerAdditional = '';
        if (primaryAddress.additional && primaryAddress.additional.length) {
            customerAdditional = <span><br/>({primaryAddress.additional})</span>;
        }

        const devices = customerData.devices.map((device, key) => {
            return <Device key={device.id} device={device} onDeviceChange={(newDevice) => {
                this.onDeviceChange(newDevice)
            }}/>
        });
        return <Fragment>
            <div className='panel-body'>
                <div className="col-md-3">
                    <dl className='ticket-device-customer-dl'>
                        <dt>Kundennummer</dt>
                        <dd>{customer.customerNo}</dd>
                    </dl>
                </div>
                <div className="col-md-3">
                    <dl>
                        <dt>Name</dt>
                        <dd>{customer.customerName}</dd>
                    </dl>
                </div>
                <div className="col-md-3">
                    <dl>
                        <dt>Telefonnummer</dt>
                        <dd>
                            <a href={'tel:' + ((typeof customer.telephoneNumber !== 'undefined') ? customer.telephoneNumber.replace(' ', '') : '')}>
                                {customer.telephoneNumber}
                            </a>
                        </dd>
                    </dl>
                </div>
                <div className="col-md-3">
                    <address>
                        <strong>Hauptstandort ({primaryAddress.identifier})</strong><br/>
                        {primaryAddress.street}<br/>
                        {primaryAddress.plz} {primaryAddress.city}
                        {customerAdditional}
                    </address>
                </div>
            </div>
            <table className='table table-striped'>
                <thead>
                <tr>
                    <th/>
                    <th>Geräte Nr</th>
                    <th>Marke</th>
                    <th>Modell</th>
                    <th>Typ</th>
                    <th>Standplatz</th>
                    <th>Standort</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {devices}
                </tbody>
            </table>
        </Fragment>
    }


}

export default Customer;