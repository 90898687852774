import React, {Component} from 'react';
import {connect} from "react-redux";
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {Button} from 'react-bootstrap';

import Rest from "../../../../core/Rest";
import Spinner from '../../../Utilities/Spinner'
import Row from './Row';
import CreateModal from './CreateModal';

class List extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tags: [],
            isFetching: false,
        };
    };


    handleShow = () => {
        this.modal.handleShow();
    }

    componentDidMount() {
        this.fetchTags();
    }

    render() {

        let loading = null;
        if (this.state.isFetching) {
            loading = (<Spinner/>)
        }

        const rows = this.state.tags.map((tag, key) => {
            return <Row tag={tag} key={key} onDelete={this.delete} onChange={this.onChange} />
        });

        return (
            <div>
                <div className='btn-bar'>
                    <Button
                        style={{marginRight: '5px'}}
                        onClick={this.handleShow}
                    >
                        <FontAwesomeIcon icon='plus'/> Hinzufügen
                    </Button>
                    <Button
                        bsStyle="success"
                        disabled={this.state.isFetching}
                        onClick={!this.state.isFetching ? this.save : null}
                    >
                        <FontAwesomeIcon icon='save'/> Speichern
                    </Button>
                </div>
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <div className='pull-right'>
                            <Button bsSize="xsmall" disabled={!!loading} onClick={this.fetchTags}><FontAwesomeIcon
                                icon='sync'/></Button>
                        </div>
                        <h4 className="panel-title">Tags {loading}</h4>
                    </div>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Titel</th>
                            <th>Farbe</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </table>
                </div>
                <CreateModal  ref={(modal) => { this.modal = modal; }} afterSave={() => {this.fetchTags()}} />
            </div>
        );
    }

    onChange = (id, value, field) => {
        let tags = this.state.tags.slice();

        for (let i in tags) {

            let tag = tags[i];

            if (tag.id === id) {

                tag[field] = value;
                tag.updated = true;

                tags[i] = Object.assign({}, tag);

                this.setState({tags: tags});
                break;
            }
        }
    }

    save = () => {

        let promises = [];
        this.setState({isFetching: true});


        const tags = this.state.tags.map((tag) => {
            if (!tag.updated) {
                return tag;
            }

            const promise = Rest.fetch({
                endpoint: 'tags/' + tag.id,
                method: 'PATCH',
                body: {
                    title: tag.title,
                    color: tag.color
                }
            });

            promises.push(promise);

            tag.updated = false;

            return tag;
        });


        Promise.all(promises).then(() => {
            this.setState({isFetching: false})
        }, ( err) => {
            console.log(err);
            this.fetchTags();
        });

        this.setState({tags: tags});

    }

    delete = (id) => {

        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'tags/'+id,
            method: 'DELETE',
        }).then(
            response => {
                this.setState({isFetching: false})
            },
            error => {
                this.setState({isFetching: false})
            }
        ).then(() => {
            this.fetchTags();
        });

    }


    fetchTags = () => {

        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'tags',
        }).then(
            response => {

                const tags = response.response.map((tag) => {
                    tag.updated = false;

                    return tag;
                });

                this.setState({tags: tags, isFetching: false})
            },
            error => {
                this.setState({isFetching: false})
            }
        )

    }


}

export default connect()(List);
