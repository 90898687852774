import React, {Component} from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

import {Button} from 'react-bootstrap';
import Rest from "../../../core/Rest";
import Spinner from '../../Utilities/Spinner'

class GroupSelect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            groupList: null,
            isFetching: false,
        };

        this.fetchGroups = this.fetchGroups.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
    };

    componentDidMount() {
        this.fetchGroups()
    }

    render() {

        let loading = null;
        if (this.state.isFetching) {
            loading = (<Spinner/>)
        }

        let content = null;
        if (this.state.groupList) {
            content = this.state.groupList.map((result) => {

                const checked = this.props.value.indexOf(result.id) !== -1;

                return <tr key={result.id}>
                    <td>{result.title}</td>
                    <td><input type="checkbox" value={result.id} checked={checked} onChange={this.onSelectChange} /></td>
                </tr>
            })
        }

        return <div className="panel panel-default">
            <div className="panel-heading">
                <div className='pull-right'>
                    <Button bsSize="xsmall" disabled={!!loading} onClick={this.fetchGroups}><FontAwesomeIcon
                        icon='sync'/></Button>
                </div>
                <h4 className="panel-title">Gruppen Verknüpfen {loading}</h4>
            </div>
            <div className="panel-body newTicketBottomPanel" style={{padding: 0}}>
                <table className="table">
                    <tbody>
                    {content}
                    </tbody>
                </table>
            </div>
        </div>;
    }

    onSelectChange(event) {

        const value = parseInt(event.target.value,10);
        const checked = event.target.checked;

        let list = this.props.value;

        if (checked && list.indexOf(value) === -1) {
            list.push(value);
        }
        else {
            list = list.filter((groupId) => {

                return groupId !== value
            });
        }

        this.props.onChange(list);
    }

    fetchGroups() {

        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'users/groups',
        }).then(
            response => {
                this.setState({groupList: response.response, isFetching: false})
            },
            error => {
                this.setState({isFetching: false})
            }
        )
    }


}

export default GroupSelect;