import React, {Component} from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {push} from "connected-react-router";
import Rest from "../../core/Rest";
import {connect} from "react-redux";
import {Button} from 'react-bootstrap';
import moment from "moment";
import DateTime from 'react-datetime'
import qs from 'query-string';
import {bindActionCreators, compose} from "redux";
import {fetchUserList} from "../../actions/userActions";
import {translate} from "react-i18next";

class NewEvent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isSaving: false,
            title: '',
            description: '',
            start: moment(),
            end: moment(),
            fullDay: false,
            subscriber: [],
            reminder: [],
            links: {}
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.addReminder = this.addReminder.bind(this);
        this.removeReminder = this.removeReminder.bind(this);
        this.onModelReminderChange = this.onModelReminderChange.bind(this);
        this.onModelChange = this.onModelChange.bind(this);
        this.onModelCheckboxChange = this.onModelCheckboxChange.bind(this);
        this.onModelSelectChange = this.onModelSelectChange.bind(this);
        this.saveEvent = this.saveEvent.bind(this);
    }

    componentDidMount() {
        this.props.actions.fetchUserList();

        const query = qs.parse(this.props.location.search);

        if (query.start) {
            this.setState({
                start: moment(query.start)
            })
        }
        if (query.end) {
            this.setState({
                end: moment(query.end)
            })
        }

        if(query.linkType && query.linkId)
        {
            const links = this.state.links;
            if(!links[query.linkType])
            {
                links[query.linkType] = [];
            }
            links[query.linkType].push(query.linkId);

            this.setState({links: links});
        }
    }

    onSubmit(event) {
        event.preventDefault();

        this.saveEvent()
    }

    onModelChange(event) {
        let state = this.state;

        state[event.target.name] = event.target.value;

        this.setState(state);
    }

    onDateChange(name, date) {
        let state = this.state;

        state[name] = date;

        this.setState(state);
    }

    onModelCheckboxChange(event) {
        let state = this.state;

        state[event.target.name] = event.target.checked;

        this.setState(state);
    }

    onModelReminderChange(key, event) {
        let state = this.state;

        state.reminder[key] = event.target.value;

        this.setState(state);
    }

    onModelSelectChange(event) {
        let state = this.state;

        const value = parseInt(event.target.value, 10);
        const checked = event.target.checked;

        let list = state[event.target.name];

        if (checked && list.indexOf(value) === -1) {
            list.push(value);
        }
        else {
            list = list.filter((userId) => {

                return userId !== value
            });
        }

        state[event.target.name] = list;

        this.setState(state);
    }

    addReminder() {
        const reminder = this.state.reminder;
        reminder.push('- 5 minutes');
        this.setState({reminder: reminder})
    }

    removeReminder(key) {
        let state = this.state;

        delete state.reminder[key];

        state.reminder = state.reminder.filter(
            (value) => {
                return value
            }
        );

        this.setState(state);
    }

    saveEvent() {
        this.setState({isSaving: true});

        const body = {
            fullDay: this.state.fullDay,
            title: this.state.title,
            description: this.state.description,
            start: this.state.start.format('YYYY-MM-DD HH:mm'),
            end: this.state.end.format('YYYY-MM-DD HH:mm'),
            subscriber: this.state.subscriber,
            reminder: this.state.reminder,
            links: this.state.links
        };


        Rest.fetch({
            endpoint: 'calendar/events',
            method: 'POST',
            body: body
        }).then(
            response => {
                this.props.redirectToEvent(response.response.id);
            },
            error => {

            }
        ).then(() => {
            this.setState({isSaving: false});
        });
    }

    render() {

        let userList = null;
        if (this.props.userList.userList) {
            userList = this.props.userList.userList.map((result) => {

                const checked = this.state.subscriber.indexOf(result.id) !== -1;

                return <tr key={result.id}>
                    <td>{result.firstname} {result.lastname}</td>
                    <td>
                        <input name='subscriber' type="checkbox" value={result.id} checked={checked} onChange={this.onModelSelectChange}/>
                    </td>
                </tr>
            })
        }

        const reminderList = this.state.reminder.map((value, i) => {
            return <tr>
                <td>
                    <select className='form-control' value={value} onChange={(event) => {
                        this.onModelReminderChange(i, event)
                    }} >
                        <option value='- 5 minutes'>{this.props.t('periods.- 5 minutes')}</option>
                        <option value='- 30 minutes'>{this.props.t('periods.- 30 minutes')}</option>
                        <option value='- 1 hour'>{this.props.t('periods.- 1 hour')}</option>
                        <option value='- 2 hour'>{this.props.t('periods.- 2 hour')}</option>
                        <option value='- 1 day'>{this.props.t('periods.- 1 day')}</option>
                        <option value='- 2 day'>{this.props.t('periods.- 2 day')}</option>
                        <option value='- 1 week'>{this.props.t('periods.- 1 week')}</option>
                        <option value='- 2 week'>{this.props.t('periods.- 2 week')}</option>
                        <option value='- 1 month'>{this.props.t('periods.- 1 month')}</option>
                        <option value='- 2 month'>{this.props.t('periods.- 2 month')}</option>
                    </select>
                </td>
                <td>
                    <button className='btn btn-block btn-danger' onClick={() => this.removeReminder(i)}>
                        <FontAwesomeIcon icon='minus'/>
                    </button>
                </td>
            </tr>
        });

        const linkList = [];
        Object.keys(this.state.links).forEach((key) => {
            const links = this.state.links[key];
            if(Array.isArray(links))
            {
                links.forEach((linkId) => {
                    linkList.push(
                        <tr key={key + ':' + linkId}>
                            <td>
                                {this.props.t('linkTypes.' + key)}
                            </td>
                            <td>
                                {linkId}
                            </td>
                        </tr>
                    )
                });
            }
        });

        return (
            <div>
                <div className="btn-bar">
                    <button
                        className='btn btn-default'
                        onClick={() => {
                            this.redirectToCalendar();
                        }}
                    >
                        <FontAwesomeIcon icon='angle-left'/> Zurück
                    </button>
                </div>
                <form onSubmit={this.onSubmit}>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="panel-default panel">
                                <div className="panel-heading">
                                    <h4 className="panel-title">Termin erstellen</h4>
                                </div>
                                <div className="panel-body">
                                    <div className='form-group'>
                                        <label>Titel</label>
                                        <input className='form-control' name='title' value={this.state.title}
                                               onChange={this.onModelChange}/>
                                    </div>
                                    <div className='form-group'>
                                        <label>Beschreibung</label>
                                        <textarea name="description" className="form-control"
                                                  maxLength="200"
                                                  style={{resize: 'none'}}
                                                  onChange={this.onModelChange}
                                                  value={this.state.description}
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label>Beginn</label>
                                        <DateTime
                                            name="start"
                                            value={this.state.start}
                                            onChange={(date) => {
                                                this.onDateChange('start', date)
                                            }}
                                            timeFormat={true}
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label>Ende</label>
                                        <DateTime
                                            name="end"
                                            value={this.state.end}
                                            onChange={(date) => {
                                                this.onDateChange('end', date)
                                            }}
                                            timeFormat={true}
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <input type='checkbox' name='fullDay' checked={this.state.fullDay}
                                               onChange={this.onModelCheckboxChange}/>
                                        &nbsp;
                                        <label>Ganztägig</label>
                                    </div>
                                </div>
                                <div className="panel-footer">
                                    <button className="btn btn-default" onClick={this.saveEvent}
                                            disabled={this.state.isSaving}>
                                        <FontAwesomeIcon icon='save'/> Speichern
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="panel panel-default">
                                <div className='panel-heading'>
                                    <div className='pull-right'>
                                        <Button bsSize="xsmall" disabled={this.props.userList.isFetching}
                                                onClick={this.props.actions.fetchUserList}><FontAwesomeIcon
                                            icon='sync'/></Button>
                                    </div>
                                    <h4 className='panel-title'>Teilnehmer</h4>
                                </div>
                                <table className="table">
                                    <tbody>
                                    {userList}
                                    </tbody>
                                </table>
                            </div>
                            <div className="panel panel-default">
                                <div className='panel-heading'>
                                    <h4 className='panel-title'>Erinnerungen</h4>
                                </div>
                                <table className="table">
                                    <tbody>
                                    {reminderList}
                                    <tr>
                                        <td colSpan={2}>
                                            <button onClick={this.addReminder} type='button' className='btn btn-default btn-block'>
                                                <FontAwesomeIcon icon='plus'/> Neue Erinnerung
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="panel panel-default">
                                <div className='panel-heading'>
                                    <h4 className='panel-title'>Verknüpfungen</h4>
                                </div>
                                <table className="table">
                                    <tbody>
                                    {linkList}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    redirectToCalendar()
    {
        const locationHistory = this.props.locationHistory.filter((object) => {
            return (
                object.pathname !== '/login' &&
                object.pathname.indexOf('/password_reset') === -1 &&
                object.pathname.indexOf('/calendar/') === -1)
        });

        let redirectPath = '/calendar';

        if (locationHistory.length > 1) {
            redirectPath = locationHistory[0].pathname;
        }

        this.props.redirectToCalendar(redirectPath);
    }

}

const mapDispatchToProps = (dispatch) => {

    return {
        actions: bindActionCreators({fetchUserList}, dispatch),
        redirectToCalendar: (redirect = '/calendar') => {
            dispatch(push(redirect))
        },
        redirectToEvent: (eventId) => {
            dispatch(push('/calendar/' + eventId))
        }
    }
};

function mapStateToProps(state) {

    const {userList, locationHistory} = state;
    return {userList, locationHistory}
}

export default compose(
    translate('calendar'),
    connect(mapStateToProps, mapDispatchToProps)
)(NewEvent);