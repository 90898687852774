import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import {fetchQueueStatus} from "../../actions/systemActions";
import moment from "moment";
import Card from "@material-ui/core/Card";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
    CardContent,
    CardHeader,
    Chip,
    IconButton, Menu, MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    withStyles
} from "@material-ui/core";

const useStyles = theme => ({
    tableWrapper: {
        overflowX: 'auto',
    }
});

const typeMapping = {
    'generate_qr_code': 'QrCode generieren',
    'generate_pdf': 'Pfd erstellen',
    'import_data_command': 'Datei Importieren',
    'export_data_command': 'Datei Exportieren'
};

function translate(type) {
    if (typeMapping.hasOwnProperty(type)) {
        return typeMapping[type]
    }

    return type;
}

class QueueStatus extends Component {

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null
        };

        this.reload();
    }

    handleMenuClose = () => {
        this.setState({
            anchorEl: null
        })
    };

    handleMenuOpen = (event) => {
        this.setState({
            anchorEl: event.currentTarget
        })
    };


    render() {

        const classes = this.props.classes

        let rows = [];
        let last = 'Nicht vorhanden';
        if (this.props.status) {

            rows = this.props.status.types.map((data, id) => {
                return Row(id, data)
            });

            last = moment(this.props.status.last).format('DD/MM/YYYY HH:mm:ss')
        }
        return <React.Fragment>
            <Card>
                <CardHeader
                    title='Warteschlange'
                    subheader='Status der Aktionen die verzögert verarbeitet werden'
                    action={
                        <Tooltip title='Mehr Optionen'>
                            <IconButton onClick={this.handleMenuOpen}>
                                <MoreVertIcon/>
                            </IconButton>
                        </Tooltip>}
                />
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} size={'small'}>
                        <TableHead>
                            <TableRow>
                                <TableCell key={'typ'}>Typ</TableCell>
                                <TableCell key={'queue'}>Noch zu bearbeiten</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows}
                        </TableBody>
                    </Table>
                </div>
                <CardContent>
                    <Chip label={<React.Fragment>Letzte Bearbeitung: {last}</React.Fragment>}/>
                </CardContent>
            </Card>
            <Menu
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleMenuClose}
            >
                <MenuItem disabled={this.props.isFetching} onClick={(event) => {
                    this.handleMenuClose(event);
                    this.reload();
                }}>Neu Laden</MenuItem>
            </Menu>
        </React.Fragment>
    }

    reload = () => {
        this.props.actions.fetchQueueStatus();
    }

}

const Row = (id, data) => {

    return <TableRow
        key={id}
    >
        <TableCell>{translate(data.type)}</TableCell>
        <TableCell>{data.count}</TableCell>
    </TableRow>
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchQueueStatus}, dispatch),
    }
};

function mapStateToProps(state) {

    const {queueStatus} = state;

    const {isFetching, status} = queueStatus;

    return {isFetching, status}
}

export default compose(
    withStyles(useStyles), connect(mapStateToProps, mapDispatchToProps))(QueueStatus);
