import React, {Component} from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Button, Modal} from 'react-bootstrap';
import {fetchTasksList} from "../../../../../actions/tasksActions";
import {fetchTicket} from "../../../../../actions/ticketActions";
import Spinner from '../../../../Utilities/Spinner';
import Rest from "../../../../../core/Rest";
import ActionDeviceSelect from "../ActionDeviceSelect";
import Calculator from "../../../../Utilities/Map/Calculator";

class NewAction extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isSaving: false,
            devices: [],
            tasks: [],
            text: '',
            origin: '',
            destination: '',
            fetchAddress: false,
            fetchDistance: false,
            time: "",
            distance: "",
            showCalculationModal: false,
            currentPosition: {
                coords: null,
                positionError: null
            }
        };

        this.onModelChange = this.onModelChange.bind(this);
        this.getAddress = this.getAddress.bind(this);
        this.calculateDistance = this.calculateDistance.bind(this);
        this.getCustomerAddress = this.getCustomerAddress.bind(this);
        this.onTaskChange = this.onTaskChange.bind(this);
        this.saveAction = this.saveAction.bind(this);
        this.addDevice = this.addDevice.bind(this);
        this.removeDevice = this.removeDevice.bind(this);
        this.onDeviceNoteChange = this.onDeviceNoteChange.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    };

    deviceSelect = {};

    componentDidMount() {
        this.props.actions.fetchTasksList();

        if (!this.props.singleTicket.ticket || this.props.singleTicket.ticket.id !== this.props.ticketId) {
            this.props.actions.fetchTicket(this.props.ticketId);
        }
    }

    handleShow() {
        this.setState({showCalculationModal: true})
    }

    handleClose() {
        this.setState({showCalculationModal: false})
    }

    onModelChange(event) {

        let state = this.state;
        state[event.target.name] = event.target.value;
        this.setState(state);
    }

    onDeviceNoteChange(event) {

        const devices = this.state.devices.map((value) => {

            if (value.id !== parseInt(event.target.name, 10)) {
                return value;
            }

            value.notice = event.target.value;

            return value;
        });


        this.setState({devices: devices, showDeviceModal: false})
    }

    onTaskChange(event) {
        const value = parseInt(event.target.value, 10);
        const checked = event.target.checked;

        let tasks = this.state.tasks;

        if (checked) {
            tasks.push(value);
        }
        else {
            tasks = tasks.filter((taskId) => {

                return taskId !== value
            });
        }

        this.setState({tasks: tasks});

    }


    render() {

        let loading = null;
        if (this.props.tasksList.isFetching) {
            loading = (<Spinner/>)
        }

        let tasks = '';
        if (this.props.tasksList.tasks) {
            tasks = this.props.tasksList.tasks.map((task) => {
                return <div className='col-md-4' key={task.id}>
                    <div className="checkbox">
                        <label>
                            <input type="checkbox" onChange={this.onTaskChange} value={task.id}
                                   checked={this.state.tasks.indexOf(task.id) !== -1}/>
                            {task.title}
                        </label>
                    </div>
                </div>
            })
        }

        let devices = '';
        if (this.state.devices) {
            devices = this.state.devices.map((ticketDevice) => {

                const device = ticketDevice.device;
                return <tr key={ticketDevice.id}>
                    <td>
                        <b>{device.deviceNo}</b>&nbsp;
                        {device.brand} {device.model}
                        <textarea placeholder="Notiz" onChange={this.onDeviceNoteChange} name={ticketDevice.id} style={{resize: 'none'}}
                                  value={ticketDevice.notice}/>
                    </td>
                    <td>
                        <div className='panel-body'>
                            <Button bsStyle='danger' onClick={() => {
                                this.removeDevice(ticketDevice.id)
                            }} block>
                                <FontAwesomeIcon icon='minus'/>
                            </Button>
                        </div>
                    </td>
                </tr>
            })
        }

        return <div>
            <div className="panel panel-default">
                <div className="panel-heading">
                    <div className='pull-right'>
                        <Button bsSize="xsmall" disabled={!!loading}
                                onClick={() => {
                                    this.props.actions.fetchTasksList();
                                    this.fetchDeviceList();
                                }}><FontAwesomeIcon
                            icon='sync'/></Button>
                    </div>
                    <h4 className="panel-title">Hinzufügen</h4>
                </div>
                <div className="panel-body">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Text</label>
                                <textarea id="actionText" className="form-control" name="text"
                                          style={{resize: 'none'}} onChange={this.onModelChange} value={this.state.text}
                                />
                            </div>
                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label>Zeit(optional):</label>
                                    <div className="input-group">
                                        <input type="number" step="1" className="form-control" name="time"
                                               onChange={this.onModelChange} value={this.state.time}/>
                                        <span className="input-group-addon"> Minuten</span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label>Strecke(optional):</label>
                                    <div className="input-group">
                                        <input type="number" step="0.001" className="form-control" name="distance"
                                               onChange={this.onModelChange} value={this.state.distance}/>
                                        <span className="input-group-btn">
                                                 <button type="button" className="btn btn-default" onClick={this.handleShow}>
                                                     <FontAwesomeIcon icon='calculator'/>
                                                 </button>
                                        </span>
                                        <span className="input-group-addon"> Km</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="panel panel-default">
                                <div className="panel-heading" role="tab" id="headingOne">
                                    <h4 className="panel-title">
                                        Durchgeführte aufgaben {loading}
                                    </h4>
                                </div>
                                <div className="panel-body">
                                    <div className="row">
                                        {tasks}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="panel panel-default">
                                <div className="panel-heading" role="tab" id="headingOne">
                                    <h4 className="panel-title">
                                        Verknüpfte Geräte
                                    </h4>
                                </div>
                                <table className="table">
                                    <tbody>
                                    {devices}
                                    </tbody>
                                </table>
                                <div className='panel-body'>
                                    <Button bsStyle='success' onClick={this.deviceSelect.handleShow} block>Gerät Hinzufügen</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" value="add" name="addAction" className="btn btn-primary"
                            disabled={this.state.isSaving} onClick={() => this.saveAction(false)} style={{marginRight: '5px'}}>
                        <FontAwesomeIcon icon='plus' style={{marginRight: '5px'}}/>
                        Hinzufügen
                    </button>
                    <button type="submit" value="addAndClose" name="addAction" className="btn btn-primary"
                            disabled={this.state.isSaving} onClick={() => this.saveAction(true)}>
                        <FontAwesomeIcon icon='rocket' style={{marginRight: '5px'}}/>
                        Hinzufügen und Abschließen
                    </button>
                </div>
            </div>
            <ActionDeviceSelect
                ticketId={this.props.ticketId}
                addDevice={this.addDevice} ref={(ref) => {
                this.deviceSelect = ref
            }}

                selected={this.state.devices.slice(0).map((device) => {
                    return device.id;
                })}
            />
            <Modal backdropClassName='bootstrap' className='bootstrap' show={this.state.showCalculationModal} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Strecke Berechnen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='form-group'>
                        <label>Start</label>
                        <div className="input-group">
                            <input value={this.state.origin} className="form-control" name='origin' onChange={this.onModelChange}/>
                            <span className="input-group-btn">
                                <button disabled={this.state.fetchAddress || !this.state.currentPosition.coords} type="button"
                                        className="btn btn-default" onClick={() => this.getAddress('origin')}>
                                    <FontAwesomeIcon icon='location-arrow'/>
                                </button>
                                <button type="button" className="btn btn-default" onClick={() => this.getCustomerAddress('origin')}>
                                    <FontAwesomeIcon icon='user'/>
                                </button>
                            </span>
                        </div>
                    </div>
                    <div className='form-group'>
                        <label>Ziel</label>
                        <div className="input-group">
                            <input value={this.state.destination} className="form-control" name='destination'
                                   onChange={this.onModelChange}/>
                            <span className="input-group-btn">
                                <button disabled={this.state.fetchAddress || !this.state.currentPosition.coords} type="button"
                                        className="btn btn-default" onClick={() => this.getAddress('destination')}>
                                    <FontAwesomeIcon icon='location-arrow'/>
                                </button>
                                 <button type="button" className="btn btn-default" onClick={() => this.getCustomerAddress('destination')}>
                                    <FontAwesomeIcon icon='user'/>
                                </button>
                            </span>
                        </div>
                    </div>
                    <Calculator onPositionChange={(coords) => {
                        this.setState({
                            currentPosition: coords
                        })
                    }}/>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-default" onClick={this.handleClose}>Schließen
                    </button>
                    <button type="submit" className="btn btn-primary" onClick={this.calculateDistance}>Berechnen
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    }

    calculateDistance() {
        this.setState({fetchDistance: true});

        Rest.fetch({
            endpoint: 'map/distance',
            method: 'POST',
            body: {
                origin: this.state.origin,
                destination: this.state.destination
            }
        }).then(
            response => {

                const distance = parseInt(response.response.distance, 10) / 1000;

                this.setState({
                    origin: '',
                    destination: '',
                    distance: distance.toString()
                });
                this.handleClose();

            },
            error => {

            }
        ).then(() => {
            this.setState({fetchDistance: false});
        })
    }

    getAddress(target) {
        this.setState({fetchAddress: true});

        Rest.fetch({
            endpoint: 'map/address',
            method: 'POST',
            body: {
                lat: this.state.currentPosition.coords.latitude,
                lng: this.state.currentPosition.coords.longitude
            }
        }).then(
            response => {
                const state = this.state;
                state[target] = response.response.address;
                this.setState(state);

            },
            error => {

            }
        ).then(() => {
            this.setState({fetchAddress: false});
        })
    }

    getCustomerAddress(target) {
        const customer = this.props.singleTicket.ticket.customer;
        const address = customer.street + ', ' + customer.plz + ' ' + customer.city

        const state = this.state;
        state[target] = address;
        this.setState(state);
    }

    saveAction(close = false) {
        this.setState({isSaving: true});

        let body = {
            text: this.state.text,
            tasks: this.state.tasks,
            devices: this.state.devices.map((device) => {
                return {
                    ticketDeviceId: device.id,
                    notice: device.notice
                }
            }),
            closeTicket: close
        };

        if (this.state.distance) {
            body.distance = parseInt(parseFloat(this.state.distance) * 1000, 10);
        }

        if (this.state.time) {
            body.workMinutes = parseInt(this.state.time, 10);
        }

        Rest.fetch({
            endpoint: 'tickets/' + this.props.ticketId + '/actions',
            method: 'POST',
            body: body
        }).then(
            response => {
                this.setState({
                    isSaving: false,
                    devices: [],
                    tasks: [],
                    text: '',
                    time: "",
                    distance: ""
                });

                if (typeof this.props.afterSave === 'function') {
                    this.props.afterSave(close)
                }
            },
            error => {
                this.setState({isSaving: false})
            }
        )
    }

    addDevice(deviceObject) {
        let devices = this.state.devices;

        devices.push(deviceObject);

        this.setState({devices: devices.slice(0)})
    }

    removeDevice(id) {

        let devices = this.state.devices;

        devices = devices.filter((value) => {
            return value.id !== id
        });

        this.setState({devices: devices})
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchTasksList, fetchTicket}, dispatch),
    }
};

function mapStateToProps(state) {

    const {tasksList, singleTicket} = state;

    return {tasksList, singleTicket}
}

export default connect(mapStateToProps, mapDispatchToProps)(NewAction);