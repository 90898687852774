import React from "react";
import {Link as RouterLink} from "react-router-dom";
import {Button} from "@material-ui/core";

export const ButtonLink = (props) => {
    const {to, key, children, ...other} = props;

    const renderLink = React.useMemo(
        () =>
            React.forwardRef((itemProps, ref) => {
                return <RouterLink to={to} {...itemProps} innerRef={ref}/>
            }),
        [to]
    );

    return (
        <Button button component={renderLink} key={key} {...other} >
            {children}
        </Button>
    );
};

export default ButtonLink

