import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom'
import {push} from "connected-react-router";
import qs from 'query-string';
import {store} from '../../../core'
import CustomerSelect from './CustomerSelect';
import DeviceSelect from './../Utilities/DeviceSelect';
import UserSelect from './UserSelect';
import GroupSelect from './GroupSelect';
import TicketData from './TicketData';
import Rest from "../../../core/Rest";

class NewTicket extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isSaving: false,
            preselectedDevice: null,
            model: {
                customer: null,
                recurrence: 0,
                private: false,
                devices: [],
                user: [],
                groups: [],
                importance: 5,
                shortDescription: '',
                description: '',
                category: undefined,
                reporter: '',

            }
        };

        this.onModelChange = this.onModelChange.bind(this);
        this.onCustomerChange = this.onCustomerChange.bind(this);
        this.onDeviceChange = this.onDeviceChange.bind(this);
        this.onUserChange = this.onUserChange.bind(this);
        this.onGroupChange = this.onGroupChange.bind(this);
        this.saveTicket = this.saveTicket.bind(this);
    };

    componentDidMount() {
        const query = qs.parse(this.props.location.search);

        if (query.deviceId > 0) {
            this.setState({preselectedDevice: parseInt(query.deviceId, 10)});
        }

        if (query.customerId > 0) {

            const model = this.state.model;

            model.customer = parseInt(query.customerId, 10);

            this.setState({model: model});
        }
    }

    onCustomerChange(value) {
        let model = this.state.model;
        model.customer = value;
        this.setState({model: model});

    }

    onDeviceChange(value) {
        let model = this.state.model;
        model.devices = value;
        this.setState({model: model});

    }

    onUserChange(value) {
        let model = this.state.model;
        model.user = value.slice(0);
        this.setState({model: model});
    }

    onGroupChange(value) {
        let model = this.state.model;
        model.groups = value.slice(0);
        this.setState({model: model});
    }

    onModelChange(field, value) {

        let model = this.state.model;

        model[field] = value;

        this.setState({model: Object.assign({}, model)});

    }

    render() {

        const isSaving = this.state.isSaving;

        return (
            <div>
                <CustomerSelect value={this.state.model.customer} onChange={this.onCustomerChange}/>
                <DeviceSelect preselectedDevice={this.state.preselectedDevice} value={this.state.model.devices}
                              customerValue={this.state.model.customer}
                              onChange={this.onDeviceChange}/>
                <div className="row">
                    <div className="col-md-3">
                        <UserSelect value={this.state.model.user} onChange={this.onUserChange}/>
                    </div>
                    <div className="col-md-3">
                        <GroupSelect value={this.state.model.groups} onChange={this.onGroupChange}/>
                    </div>
                    <div className="col-md-6">
                        <TicketData value={this.state.model} onChange={this.onModelChange}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="panel panel-default">
                            <div className="panel-heading">

                            </div>
                            <div className="panel-body">
                                <Button
                                    style={{marginRight: '5px'}}
                                    bsStyle="default"
                                    disabled={isSaving}
                                    onClick={!isSaving ? this.saveTicket : null}
                                >
                                    <FontAwesomeIcon icon='save'/> Speichern
                                </Button>
                                <Link className='btn btn-default' role='button' to='/tickets'>
                                    <FontAwesomeIcon
                                        icon='angle-left'/> Zurück
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }

    saveTicket() {

        this.setState({isSaving: true});

        const model = this.state.model;

        Rest.fetch({
            method: 'POST',
            endpoint: 'tickets',
            body: {
                customer: model.customer,
                devices: model.devices,
                user: model.user,
                groups: model.groups,
                importance: model.importance,
                shortDescription: model.shortDescription,
                description: model.description,
                category: model.category,
                private: model.private,
                recurrence: model.recurrence ? model.recurrence : null,
                reporter: model.reporter,
            }
        }).then(
            response => {
                this.setState({isSaving: false});
                store.dispatch(push('/tickets/' + response.response.id))

            },
            error => {
                this.setState({isSaving: false})
            }
        )
    }
}

export default NewTicket;
