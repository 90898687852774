import {
    BOOKMARK_LIST_SUCCESS, BOOKMARK_LIST_FAILURE, BOOKMARK_LIST_REQUEST
} from '../actions/bookmarkActions'

export function filterBookmarkReducer(state = {
    isFetching: false,
}, action) {
    switch (action.type) {
        case BOOKMARK_LIST_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case BOOKMARK_LIST_FAILURE:

            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch(e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case BOOKMARK_LIST_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                bookmarks: action.response
            });
        default:
            return state
    }
}

