import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Button} from 'react-bootstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

import Rest from "../../../../core/Rest";
import TicketAction from './TicketAction';
import Spinner from '../../../Utilities/Spinner';
import NewAction from "./New/NewAction";
import ChangeStatus from '../ChangeStatus';
import {fetchTasksList} from "../../../../actions/tasksActions";
import {fetchTicket} from "../../../../actions/ticketActions";

class TicketActions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isFetching: false, actions: [], deviceList: {
                isFetching: false,
                devices: []
            }
        };

        this.fetchActions = this.fetchActions.bind(this);
    };

    componentDidMount() {
        this.fetchActions();
        this.props.actions.fetchTasksList();
        this.fetchDeviceList()
    }

    render() {

        let loading = null;
        if (this.state.isFetching) {
            loading = (<Spinner/>)
        }

        let actions = '';
        if (this.state.actions) {
            actions = this.state.actions.map((result) => {
                return <TicketAction
                    tasksList={this.props.tasksList}
                    deviceList={this.state.deviceList}
                    key={result.id} action={result}
                    afterSave={() => {
                        this.fetchActions()
                    }}
                    afterDelete={() => {
                        this.fetchActions()
                    }}
                />
            })
        }

        return <div>
            <ChangeStatus ticketId={this.props.match.params.ticketId}/>
            <div className="panel panel-default">
                <div className="panel-heading">
                    <div className='pull-right'>
                        <Button bsSize="xsmall" disabled={!!loading} onClick={this.fetchActions}><FontAwesomeIcon
                            icon='sync'/></Button>
                    </div>
                    <h4 className="panel-title">Aktionen {loading}</h4>
                </div>
                <table className='table'>
                    <tbody>
                    {actions}
                    </tbody>
                </table>
            </div>
            <NewAction ticketId={this.props.match.params.ticketId} afterSave={(close) => {
                this.fetchActions();

                if(close)
                {
                    this.props.actions.fetchTicket(this.props.match.params.ticketId);
                }
            }}/>
        </div>;
    }

    fetchActions() {

        const ticketId = this.props.match.params.ticketId;

        if (ticketId > 0) {

            this.setState({isFetching: true});

            Rest.fetch({
                endpoint: 'tickets/' + ticketId + '/actions',
                parameter: {
                    with: ['ticketActionDevices', 'ticketActionTasks']
                }
            }).then(
                response => {
                    this.setState({actions: response.response, isFetching: false})
                },
                error => {
                    this.setState({isFetching: false})
                }
            )
        }

    }

    fetchDeviceList() {

        const ticketId = this.props.match.params.ticketId;

        let ticketDevices = this.state.deviceList;

        ticketDevices.isFetching = true;

        this.setState({deviceList: ticketDevices});

        Rest.fetch({
            endpoint: 'tickets/' + ticketId + '/devices',
            method: 'GET',
        }).then(
            response => {
                ticketDevices.isFetching = false;
                ticketDevices.devices = response.response;

                this.setState({deviceList: ticketDevices});

            },
            error => {
                ticketDevices.isFetching = false;
                this.setState({deviceList: ticketDevices});
            }
        );

    }

}


const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchTasksList , fetchTicket}, dispatch),
    }
};

function mapStateToProps(state) {

    const {tasksList} = state;

    return {tasksList}
}


export default connect(mapStateToProps, mapDispatchToProps)(TicketActions);