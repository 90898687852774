import {
    TASKS_LIST_FAILURE, TASKS_LIST_REQUEST, TASKS_LIST_SUCCESS
} from '../actions/tasksActions'

export function tasksListReducer(state = {
    isFetching: false,
}, action) {
    switch (action.type) {
        case TASKS_LIST_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case TASKS_LIST_FAILURE:

            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch(e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case TASKS_LIST_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                tasks: action.response
            });
        default:
            return state
    }
}

