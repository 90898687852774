import React, {Component} from 'react';

import TicketList from "../../Tickets/List/OldTicketList";

class Tickets extends Component {

    render() {
        return <TicketList filter={{customer: this.props.match.params.id}}/>

    }
}

export default Tickets;
