import React, {Component} from 'react';
import NoMatch from "../../NoMatch/NoMatch";
import {Route, Switch} from "react-router";

import List from "./List";
import New from "./New";
import Show from "./Show";

class Import extends Component {


    render() {

        const match = this.props.match;

        return (
            <Switch>
                <Route exact path={`${match.url}`} component={List}/>
                <Route exact path={`${match.url}/new`} component={New}/>
                <Route exact path={`${match.url}/:id`} component={Show}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}


export default Import;
