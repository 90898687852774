import React, {Component} from 'react';
import {Button, FormControl, Modal} from 'react-bootstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Rest from "../../../../core/Rest";

class CreateModal extends Component {

    constructor(props) {
        super(props);
        this.state = {showModal: false, isFetching: false, title: '', color: ''}

    }

    handleClose = () => {
        this.setState({showModal: false});
    }

    handleShow = () => {
        this.setState({showModal: true});
    }

    setTitle = (event) => {
        this.setState({title: event.target.value});
    }

    setColor = (event) => {
        this.setState({color: event.target.value});
    }


    render() {

        return (
            <Modal show={this.state.showModal} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Anlegen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='form-group'>
                        <label>Titel</label>
                        <FormControl
                            type="text"
                            value={this.state.title}
                            onChange={this.setTitle}
                            placeholder="Titel"
                        />
                    </div>
                    <div className='form-group'>
                        <label>Farbe</label>
                        <FormControl
                            type="color"
                            value={this.state.color}
                            onChange={this.setColor}
                            placeholder="Farbe"
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-default" onClick={this.handleClose}>Schließen
                    </button>
                    <Button
                        bsStyle="success"
                        disabled={this.state.isFetching}
                        onClick={!this.state.isFetching ? this.save : null}
                    >
                        <FontAwesomeIcon icon='save'/> Speichern
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    save = () => {

        this.setState({isFetching: true});

        Rest.fetch({
            method: 'POST',
            endpoint: 'tags',
            body: {
                title: this.state.title,
                color: this.state.color
            }
        }).then(
            response => {
                this.setState({title: '', color: '', isFetching: false, showModal: false})
                this.props.afterSave();
            },
            error => {
                this.setState({isFetching: false})
            }
        )
    }

}

export default CreateModal;
