import React, {Component} from 'react';
import BigCalendarView from "../../../Utilities/Calendar/BigCalendarView";
import {connect} from "react-redux";
import store from '../../../../core/store'
import {push} from "connected-react-router";

class CustomerEvents extends Component {

    componentDidMount() {
        if (!this.props.moduleList.calendar) {
            store.dispatch(push('/customer/' +  this.props.match.params.id))
        }
    }

    render() {

        const customerId = this.props.match.params.id;

        return <div>
            <BigCalendarView
                linkType='customer'
                linkId={customerId}
                displayRequests={false}
                hideComponentEvents={true}
                height='600px'
                views={[
                    'WEEK',
                    'MONTH',
                    'AGENDA'
                ]}
            />
        </div>
    }

}

function mapStateToProps(state) {

    const {modules} = state;
    const {moduleList} = modules;

    return {moduleList}
}

export default connect(mapStateToProps, null)(CustomerEvents);