import {CALL_API} from '../middleware/api'

export const CUSTOMER_LIST_REQUEST = 'CUSTOMER_LIST_REQUEST';
export const CUSTOMER_LIST_SUCCESS = 'CUSTOMER_LIST_SUCCESS';
export const CUSTOMER_LIST_FAILURE = 'CUSTOMER_LIST_FAILURE';


export function fetchCustomerList(filter = []) {

    return {
        [CALL_API]: {
            endpoint: 'customers',
            method: 'GET',
            parameter: {
                filter: filter,
            },
            types: [CUSTOMER_LIST_SUCCESS, CUSTOMER_LIST_FAILURE, CUSTOMER_LIST_REQUEST]
        }
    }
}

