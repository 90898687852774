import React, {Component} from 'react';
import {Button} from 'react-bootstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Rest from "../../../../core/Rest";
import Spinner from "../../../Utilities/Spinner";

class Settings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            active: false,
            statusIds: [],
            statusList: [],
            hours: 0,
            days: 1
        };

    }

    componentDidMount() {
        this.fetchConfig();
    }

    render() {

        let loading = null;
        if (this.state.isFetching) {
            loading = (<Spinner/>)
        }

        let statusList = '';
        if (this.state.statusList) {
            statusList = this.state.statusList.map((data, id) => {

                const statusId = data.id;
                const checked = (this.state.statusIds.indexOf(statusId) !== -1);

                return <div key={data.id}>
                    <input
                        type="checkbox"
                        value={statusId}
                        checked={checked}
                        onChange={this.onStatusLinkChange}
                    />
                    &nbsp;{data.title}
                </div>;
            });
        }

        return (
            <div className="panel panel-default">
                <div className="panel-heading">
                    <div className='pull-right'>
                        <Button bsSize="xsmall" disabled={!!loading} onClick={this.fetchConfig}><FontAwesomeIcon
                            icon='sync'/></Button>
                    </div>
                    <h4 className="panel-title">Erinnerungen {loading}</h4>
                </div>
                <div className="panel-body">
                    <div className="alert alert-info">
                        <FontAwesomeIcon icon='info-circle'/>
                        &nbsp;Erinnerungen um Mitarbeiter an Tickets zu erinnern welche seit längerem in einem bestimmten Status liegen.
                    </div>
                    <div className="form-group">
                        <input type="checkbox" onChange={this.onActiveChange} checked={this.state.active}/>
                        &nbsp;
                        <label>Aktiv</label>
                    </div>
                    <div className="form-group">
                        <label>Status für welche Erinnerungen versendet werden</label>
                        {statusList}
                    </div>
                    <div className="form-group">
                        <label>Zeitinterval in dem Erinnerungen verschickt werden</label>
                        <div className='form-inline'>

                            <label>Tage</label>&nbsp;
                            <input className='form-control' min={0} type='number' name='days' onChange={this.onModelChange}
                                   value={this.state.days}/>&nbsp;
                            <label>Stunden</label>&nbsp;
                            <input className='form-control' min={0} type='number' name='hours' onChange={this.onModelChange}
                                   value={this.state.hours}/>
                        </div>
                    </div>
                    <button className="btn btn-primary"
                            disabled={this.state.isSaving || this.state.isFetching}
                            onClick={!this.state.isSaving ? this.saveConfig : null}
                    >Speichern
                    </button>
                </div>
            </div>
        );
    }

    onStatusLinkChange = (event) => {

        const value = parseInt(event.target.value, 10);
        const checked = event.target.checked;

        let statusIds = this.state.statusIds;

        if (checked) {
            statusIds.push(value);
        } else {
            statusIds = statusIds.filter((statusId) => {
                return statusId !== value
            });
        }

        this.setState({statusIds: statusIds});
    };

    onActiveChange = (event) => {
        this.setState({active: !!event.target.checked});
    };

    onModelChange = (event) => {
        const name = event.target.name;

        const state = this.state;

        state[name] = event.target.value;

        this.setState(state);
    };

    fetchConfig = () => {
        this.setState({isFetching: true});

        let promises = [];

        promises.push(Rest.fetch({
            method: 'GET',
            endpoint: 'config/ticketReminder',
        }).then(
            response => {

                const config = response.response.config;

                const days = Math.floor(config.hours / 24);
                const hours = config.hours - days * 24;

                this.setState({
                    active: config.active,
                    statusIds: config.statusIds,
                    days: days,
                    hours: hours,
                })
            }
        ));

        promises.push(Rest.fetch({
            method: 'GET',
            endpoint: 'tickets/status',
        }).then(
            response => {

                this.setState({
                    statusList: response.response
                })
            }
        ));

        Promise.all(promises).then(() => {
            this.setState({isFetching: false})
        }, (err) => {
            console.log(err);
        });

    };

    saveConfig = () => {
        this.setState({isSaving: true});

        const hours = this.state.days * 24 + this.state.hours;

        Rest.fetch({
            method: 'PUT',
            endpoint: 'config/ticketReminder',
            body: {
                active: this.state.active,
                statusIds: this.state.statusIds,
                hours: hours,
            }
        }).then(
            response => {
                this.fetchConfig();
            },
            error => {
                this.fetchConfig();
            }
        ).then(() => {
            this.setState({isSaving: false});
        });
    };
}

export default Settings;
