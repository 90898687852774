import React, {Component} from 'react';
import {Button} from 'react-bootstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Rest from "../../../../core/Rest";
import Spinner from "../../../Utilities/Spinner";

class Settings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            active: false,
            customer: {
                onNew: 'do',
                onChange: 'do',
                onDelete: 'ignore'
            },
            device: {
                onNew: 'do',
                onChange: 'do',
                onDelete: 'ignore'
            }
        };

        this.fetchConfig = this.fetchConfig.bind(this);
        this.saveConfig = this.saveConfig.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
    }

    componentDidMount() {
        this.fetchConfig();
    }

    onCheckboxChange(event) {
        const state = this.state;
        state[event.target.name] = event.target.checked;
        this.setState(state);
    }

    onSelectChange(typeId, event) {
        const state = this.state;
        const type = state[typeId];

        type[event.target.name] = event.target.value;

        state[typeId] = type;

        this.setState(state);
    }

    render() {

        let loading = null;
        if (this.state.isFetching) {
            loading = (<Spinner/>)
        }

        const options = [
            <option key={1} value='do'>Änderung Durchführen</option>,
            <option key={2} value='ignore'>Ignorieren und Loggen</option>
        ];


        return (
            <div className="panel panel-default">
                <div className="panel-heading">
                    <div className='pull-right'>
                        <Button bsSize="xsmall" disabled={!!loading} onClick={this.fetchConfig}><FontAwesomeIcon
                            icon='sync'/></Button>
                    </div>
                    <h4 className="panel-title">4Vending {loading}</h4>
                </div>
                <div className="panel-body">
                    <div className='alert alert-danger'>
                        Vorsicht! Dieser Datenabgleich muss mit Vendidata abgesprochen sein
                    </div>
                    <div className='form-group'>
                        <input
                            checked={this.state.active} onChange={this.onCheckboxChange}
                            name='active' type='checkbox' style={{marginRight: '1em'}}/>
                        <label>Aktiv</label>
                    </div>
                    <h3>Kunden</h3>
                    <div className='form-group'>
                        <label>Wenn Neu</label>
                        <select onChange={(event) => this.onSelectChange('customer', event)}
                                name='onNew' value={this.state.customer.onNew} className='form-control'>
                            {options}
                        </select>
                    </div>
                    <div className='form-group'>
                        <label>Wenn Geändert</label>
                        <select onChange={(event) => this.onSelectChange('customer', event)}
                                name='onChange' value={this.state.customer.onChange} className='form-control'>
                            {options}
                        </select>
                    </div>
                    <div className='form-group'>
                        <label>Wenn Gelöscht</label>
                        <select onChange={(event) => this.onSelectChange('customer', event)}
                                name='onDelete' value={this.state.customer.onDelete} className='form-control'>
                            {options}
                        </select>
                    </div>
                    <h3>Geräte</h3>
                    <div className='form-group'>
                        <label>Wenn Neu</label>
                        <select onChange={(event) => this.onSelectChange('device', event)}
                                name='onNew' value={this.state.device.onNew} className='form-control'>
                            {options}
                        </select>
                    </div>
                    <div className='form-group'>
                        <label>Wenn Geändert</label>
                        <select onChange={(event) => this.onSelectChange('device', event)}
                                name='onChange' value={this.state.device.onChange} className='form-control'>
                            {options}
                        </select>
                    </div>
                    <div className='form-group'>
                        <label>Wenn Gelöscht</label>
                        <select onChange={(event) => this.onSelectChange('device', event)}
                                name='onDelete' value={this.state.device.onDelete} className='form-control'>
                            {options}
                        </select>
                    </div>
                </div>
                <div className='panel-footer'>
                    <button
                        type="submit"
                        className="btn btn-default"
                        disabled={this.state.isSaving || this.state.isFetching}
                        onClick={!this.state.isSaving ? this.saveConfig : null}
                    >
                        Speichern
                    </button>
                </div>
            </div>
        );
    }

    fetchConfig() {
        this.setState({isFetching: true});
        Rest.fetch({
            method: 'GET',
            endpoint: 'config/vendi',
        }).then(
            response => {
                this.setState({
                    active: response.response.config.active,
                    customer: response.response.config.customer,
                    device: response.response.config.device,
                })
            },
            error => {
            }
        ).then(() => {
            this.setState({isFetching: false});
        });
    }

    saveConfig() {
        this.setState({isSaving: true});

        Rest.fetch({
            method: 'PUT',
            endpoint: 'config/vendi',
            body: {
                active: this.state.active,
                customer: this.state.customer,
                device: this.state.device
            }
        }).then(response => {
            }, error => {
            }
        ).then(() => {
            this.fetchConfig();
            this.setState({isSaving: false});
        });

    }
}

export default Settings;
