import React, {Component} from 'react';
import {connect} from "react-redux";
import {Collapse} from 'react-bootstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

import Rest from "../../../../core/Rest";

class Permissions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tree: [],
            permissions: [],
            isFetching: false,
            collapse: {}
        };

        this.fetchPermissions = this.fetchPermissions.bind(this);
        this.generateTree = this.generateTree.bind(this);
        this.onChangePermission = this.onChangePermission.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);
    };

    componentDidMount() {
        this.fetchPermissions();
    }

    render() {

        const permissionTree = this.state.tree.map((permission) => {

            let childs = [];
            if (permission.childPermissions) {
                for (let i = 0; i < Math.ceil(permission.childPermissions.length / 3); i++) {

                    const elements = permission.childPermissions.slice(i * 4, i * 4 + 4);

                    childs.push(
                            <div key={i} className='row' style={{marginLeft: '20px', marginTop: '10px'}}>
                                {this.generateTree(elements, 1)}
                            </div>
                    );

                }
            }

            const checked = (this.props.permissions.filter(userPermission => userPermission.permissionKey === permission.permissionKey).length > 0) || this.props.checkAll;

            return <div key={permission.id} >
                <a style={{marginRight: '10px'}} onClick={(event) => { this.toggleCollapse(permission.permissionKey) }}>
                    <FontAwesomeIcon style={{fontSize: '20px'}} icon={(this.state.collapse[permission.permissionKey])?'angle-down':'angle-right'}/>
                </a>
                <input onChange={this.onChangePermission} value={permission.permissionKey} checked={checked}
                       type="checkbox"/> <b style={{fontSize: '20px'}}>{permission.description}</b>
                <Collapse in={this.state.collapse[permission.permissionKey]} id={permission.id}>
                    <div className='row' id={permission.id}>
                    {childs}
                    </div>
                </Collapse>
            </div>;
        });

        let style = {};
        if (this.props.disable) {
            style = {
                'opacity': 0.5,
                'pointerEvents': 'none'
            };
        }

        return (
            <div className="panel panel-default">
                <div className="panel-heading">
                    <h4 className="panel-title">Rechte</h4>
                </div>
                <div className="panel-body" style={style}>
                    {permissionTree}
                </div>
            </div>
        );
    }

    toggleCollapse(key) {

        let collapseStatus = this.state.collapse;

        collapseStatus[key] = !collapseStatus[key];

        this.setState({collapse: collapseStatus})
    }

    fetchPermissions() {

        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'permissions/tree',
        }).then(
            response => {

                const tree = response.response;
                const permissions = getChildren(tree, null);

                let collapse = this.state.collapse;
                tree.forEach((toplevel) => {

                    if (!collapse.hasOwnProperty(toplevel.permissionKey)) {
                        collapse[toplevel.permissionKey] = false;
                    }
                });

                this.setState({tree: tree, permissions: permissions, collapse: collapse})
            },
            error => {
                this.setState({isFetching: false})
            }
        );
    }

    generateTree(list, level) {
        return list.map((permission) => {

            const checked = (this.props.permissions.filter(userPermission => userPermission.permissionKey === permission.permissionKey).length > 0) || this.props.checkAll;

            let childs = null;
            if (permission.childPermissions) {
                childs = this.generateTree(permission.childPermissions, level + 1)
            }

            if (level === 1) {
                return <div key={permission.id} className='col-md-3'>
                    <input onChange={this.onChangePermission} value={permission.permissionKey} checked={checked}
                           type="checkbox"/> <b>{permission.description}</b>
                    {childs}
                </div>
            }

            return <div style={{marginLeft: '20px'}} key={permission.id}>
                <input onChange={this.onChangePermission} value={permission.permissionKey} checked={checked}
                       type="checkbox"/> {permission.description}
                {childs}
            </div>

        });
    }

    onChangePermission(event) {
        let permissions = this.props.permissions;

        let permissionObject = Object.assign({}, this.state.permissions.find((value) => {
            return value.permissionKey === event.target.value;
        }));

        if (event.target.checked) {

            permissions.push(permissionObject);

            permissions = addChildPermissions(permissionObject, permissions);

            while (permissionObject.parentId) {

                // eslint-disable-next-line
                const nPermissionObject = Object.assign({}, this.state.permissions.find((value) => {
                    return value.id === permissionObject.parentId;
                }));

                permissions.push(nPermissionObject);

                permissionObject = Object.assign({}, nPermissionObject);
            }
        }
        else {
            permissions = permissions.filter((value) => {
                return value.permissionKey !== event.target.value
            });

            permissions = removeChildPermissions(permissionObject, permissions);

            while (permissionObject.parentId) {
                // eslint-disable-next-line
                const nPermissionObject = Object.assign({}, this.state.permissions.find((value) => {
                    return value.id === permissionObject.parentId;
                }));

                let count = 0;
                // eslint-disable-next-line
                permissionObject.childPermissions.forEach((permission) => {
                    const checked = permissions.filter(userPermission => userPermission.permissionKey === permission.permissionKey).length > 0;
                    if (checked) {
                        count++;
                    }
                });

                if (count === 0) {
                    // eslint-disable-next-line
                    permissions = permissions.filter((value) => {
                        return value.permissionKey !== permissionObject.permissionKey
                    });
                }


                permissionObject = Object.assign({}, nPermissionObject);
            }

        }

        const uniqueValue = permissions.filter((value, index, self) => {
            return self.indexOf(value) === index;
        });

        this.props.onChange(uniqueValue.slice(0));

    }

}

const addChildPermissions = (permissionObject, list) => {

    if (permissionObject.childPermissions) {
        permissionObject.childPermissions.forEach((permission) => {

            list.push(permission);

            if (permissionObject.childPermissions) {
                list = addChildPermissions(permission, list)
            }
        });
    }

    return list;
};

const removeChildPermissions = (permissionObject, list) => {
    if (permissionObject.childPermissions) {
        permissionObject.childPermissions.forEach((permission) => {
            list = list.filter((value) => {
                return value.permissionKey !== permission.permissionKey
            });

            if (permissionObject.childPermissions) {
                list = removeChildPermissions(permission, list)
            }
        });
    }

    return list;
};

const getChildren = (permissions, parent) => {

    let list = [];
    permissions.forEach((permission) => {

        if (parent) {
            permission.parentId = parent.id;
        }

        list.push(permission);

        if (permission.childPermissions && permission.childPermissions.length) {
            list = list.concat(getChildren(permission.childPermissions, permission));
        }
    });

    return list;
};


export default connect()(Permissions);
