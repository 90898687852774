import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Filter from './Filter'
import List from '../Tickets/List/List'
import {fetchTickets} from '../../actions/ticketActions';


class Live extends Component {

    intervalId = null;

    constructor(props) {
        super(props);

        this.setFilter = this.setFilter.bind(this);
        this.setOrderBy = this.setOrderBy.bind(this);
        this.runSearch = this.runSearch.bind(this);
        this.setPagination = this.setPagination.bind(this);

        this.state = {
            pagination: {
                page: 1,
                perPage: 20,
                total: 0
            },
            filter: {
                ticketData: '',
                customerData: '',
                deviceData: '',
                category: '',
                fromDate: null,
                toDate: null,
                status: ["1", "2"]
            },
            orderBy: 's.priority_ASC',
        };

    };

    componentDidMount() {
        this.runSearch();

        this.intervalId = setInterval(() => {
            this.runSearch()
        }, 10000)
    }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId)
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.filter) {
            state.filter = Object.assign({}, state.filter, props.filter);
        }

        return state;
    }

    setFilter(filter) {
        this.setState({filter: filter})
    }

    setOrderBy(orderBy) {
        this.setState({orderBy: orderBy})
    }

    setPagination(pagination) {
        this.setState({pagination: pagination})
    }

    runSearch() {
        this.props.actions.fetchTickets(this.state.filter, this.state.orderBy, this.state.pagination.page, this.state.pagination.perPage)
    }

    render() {


        let params = {};
        if (this.state.filter.deviceId) {
            params.deviceId = this.state.filter.deviceId;
        }

        if (this.state.filter.customer) {
            params.customerId = this.state.filter.customer;
        }

        return (
            <div className='bootstrap'>
                <div className='container-fluid' style={{marginTop: '10px'}}>
                    <Filter filter={this.state.filter} orderBy={this.state.orderBy} setFilter={this.setFilter} setOrderBy={this.setOrderBy}
                            runSearch={this.runSearch}/>
                    <List pagination={this.state.pagination} setPagination={this.setPagination} runSearch={this.runSearch}/>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchTickets}, dispatch),
    }
};

function mapStateToProps(state) {

    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Live);
