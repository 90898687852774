import React, {Component} from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Rest from "../../../../core/Rest";

class UserRow extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isDeleting: false
        };

        this.removeLink = this.removeLink.bind(this);
    };

    render() {

        const arranger = this.props.arranger;

        let confirmed = <FontAwesomeIcon style={{color: 'grey'}} icon={['far', 'clock']}/>;
        if(arranger.confirmed)
        {
            confirmed = <FontAwesomeIcon style={{color: 'green'}} icon='check' />;
        }

        return  <tr key={arranger.id}>
            <td>{arranger.user.firstname} {arranger.user.lastname} ({arranger.user.username})</td>
            <td>
                <button onClick={this.state.isDeleting?undefined:this.removeLink} disabled={this.state.isDeleting} className="btn btn-default btn-block"><FontAwesomeIcon icon='trash'/> Entfernen</button>
            </td>
            <td className='text-center'>{confirmed}</td>
        </tr>
    }

    removeLink()
    {
        this.setState({isDeleting: true});

        Rest.fetch({
            endpoint: 'tickets/' + this.props.arranger.ticket.id + '/user/' + this.props.arranger.id,
            method: 'DELETE',
        }).then(
            response => {},
            error => {}
        ).then(() => {
            this.setState({isDeleting: false});
            if(typeof this.props.afterDelete === 'function')
            {
                this.props.afterDelete();
            }
        });
    }

}

export default UserRow;