import React, {Component} from 'react';
import DateTime from 'react-datetime'
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {fetchComponentTypes} from '../../../../../actions/deviceComponentActions'
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import Rest from "../../../../../core/Rest";
import moment from 'moment';

class New extends Component {

    constructor(props) {
        super(props);

        this.state = {
            componentNo: '',
            type: '0',
            description: '',
            expires: false,
            expireTime: '',
            expireDescription: '',
            isSaving: false
        };

        this.onModelChange = this.onModelChange.bind(this);
        this.onModelDateChange = this.onModelDateChange.bind(this);
        this.onModelBoolChange = this.onModelBoolChange.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentDidMount() {
        this.props.actions.fetchComponentTypes()
    }

    onModelChange(event)
    {
        const state = this.state;
        state[event.target.name] = event.target.value;
        this.setState(state)
    }

    onModelBoolChange(event)
    {
        const state = this.state;
        state[event.target.name] = event.target.checked;
        this.setState(state)
    }

    onModelDateChange(name, value)
    {
        const state = this.state;
        state[name] = value;
        this.setState(state)

    }

    onSave()
    {
        this.setState({isSaving: true});

        let date = null;
        if(moment.isMoment(this.state.expireTime))
        {
            date = this.state.expireTime.format('YYYY-MM-DD')
        }

        const body = {
            componentNo: this.state.componentNo,
            type: this.state.type,
            description: this.state.description,
            expires: this.state.expires,
        };

        if(body.expires)
        {
            body.expireTime = date;
            body.expireDescription = this.state.expireDescription;
        }

        Rest.fetch({
            method: 'POST',
            endpoint: 'devices/' + this.props.deviceId + '/components',
            body: body
        }).then(
            (success) => {
                if(this.props.afterSave instanceof Function)
                {
                    this.props.afterSave(success);
                }
            },
            () => {}
        ).then(() => {
            this.setState({isSaving: false});
        })
    }

    render() {

        let types = '';
        if (this.props.componentTypeList.types) {
            types = this.props.componentTypeList.types.map((type) => {
                return <option key={type.id} value={type.id}>{type.title}</option>
            });
        }

        return <div className='panel panel-default'>
            <div className="panel-heading">
                <h4 className='panel-title'>
                    Komponente Anlegen
                </h4>
            </div>
            <div className='panel-body'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label>Typ</label>
                            <select className='form-control' disabled={this.props.componentTypeList.isFetching}
                                    value={this.state.type} onChange={this.onModelChange} name='type'>
                                <option value={0}>Bitte Wählen</option>
                                {types}
                            </select>
                        </div>
                        <div className='form-group'>
                            <label>Komponenten Nummer</label>
                            <input className='form-control' placeholder='Komponenten Nummer'
                                   name='componentNo' onChange={this.onModelChange} value={this.state.componentNo}/>
                        </div>
                        <div className='form-group'>
                            <label>Beschreibung</label>
                            <input className='form-control' placeholder='Beschreibung'
                                   name='description' onChange={this.onModelChange} value={this.state.description}/>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group' style={{lineHeight: '29px', paddingTop: '25px'}}>
                            <input type='checkbox' checked={this.state.expires} name='expires' onChange={this.onModelBoolChange}/>&nbsp;
                            <label>Zeitstempel</label>
                        </div>
                        <div className='form-group'>
                            <label>Datum</label>
                            <DateTime
                                inputProps={{
                                    placeholder: 'Datum',
                                    disabled: !this.state.expires
                                }}
                                value={this.state.expireTime}
                                onChange={(moment) => this.onModelDateChange('expireTime', moment)}
                                timeFormat={false}
                                closeOnSelect={true}
                            />
                        </div>
                        <div className='form-group'>
                            <label>Zeitstempel Beschreibung</label>
                            <input disabled={!this.state.expires} className='form-control' placeholder='Überprüfung, Wechsel,...' name='expireDescription' onChange={this.onModelChange}
                                   value={this.state.expireDescription} />
                        </div>
                    </div>

                </div>
            </div>
            <div className='panel-footer'>
                <button className='btn btn-default' onClick={this.onSave} disabled={this.state.isSaving}>
                    <FontAwesomeIcon icon='save'/> Speichern
                </button>
            </div>
        </div>;
    }

}

const mapDispatchToProps = (dispatch) => {

    return {
        actions: bindActionCreators({fetchComponentTypes}, dispatch)
    }
};

function mapStateToProps(state) {
    const {componentTypeList} = state;

    return {componentTypeList};
}

export default connect(mapStateToProps, mapDispatchToProps)(New);