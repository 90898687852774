import React, {Component} from 'react';
import {Button} from "react-bootstrap";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import Rest from "../../../../core/Rest";
import cx from "classnames";
import Spinner from "../../../Utilities/Spinner";
import {push} from "connected-react-router";
import {connect} from "react-redux";
import Toggle from 'react-bootstrap-toggle';

class NewAddress extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isFetching: false,
            address: {
                isSaving: false,
                primary: false,
                identifier: '',
                city: '',
                plz: '',
                additional: '',
                street: '',
            },
            validationErrorList: {}
        };

        this.onModelChange = this.onModelChange.bind(this);
        this.getAlert = this.getAlert.bind(this);
        this.onToggleChange = this.onToggleChange.bind(this);
        this.saveAddress = this.saveAddress.bind(this);
    }

    saveAddress() {

        this.setState({isSaving: true, validationErrorList: {}});

        let model = Object.assign({}, this.state.address);

        model.plz = parseInt(model.plz, 10);
        model.customerId = this.props.match.params.customerId;

        Rest.fetch({
            endpoint: 'customers/addresses',
            method: 'POST',
            body: model
        }).then(
            response => {
                const params = this.props.match.params;
                const url = '/customer/' + params.customerId + '/addresses/' + response.response.id;
                this.props.push(url);
            },
            error => {
                if (error.code === 400 && error.raw.error && error.raw.error.validation_errors) {
                    this.setState({validationErrorList: error.raw.error.validation_errors})
                }
            }
        ).then(() => {
            this.setState({isSaving: false});
        })
    }

    getAlert(key) {
        if (this.state.validationErrorList[key] && Array.isArray(this.state.validationErrorList[key])) {
            const values = this.state.validationErrorList[key].map((message) => {
                return <li>{message}</li>
            });

            return <div className='alert alert-danger' style={{marginTop: '10px'}}>
                <ul>{values}</ul>
            </div>
        }

        return '';
    }

    onModelChange(event) {
        const model = this.state.address;

        model[event.target.name] = event.target.value;

        this.setState({address: model})
    }


    onToggleChange(name) {
        const model = this.state.address;

        model[name] = !model[name];

        this.setState({address: model})
    }


    render() {
        let loading = null;
        if (this.state.isSaving) {
            loading = (<Spinner/>)
        }

        return <div>
            <div className='btn-bar'>
                <Button
                    bsStyle="success"
                    disabled={this.state.isSaving}
                    onClick={!this.state.isSaving ? this.saveAddress : null}
                >
                    <FontAwesomeIcon icon='save'/> Speichern
                </Button>
            </div>
            <div className="panel-default panel">
                <div className="panel-heading">
                    <h4 className="panel-title">
                        Adresse anlegen {loading}
                    </h4>
                </div>
                <div className="panel-body">
                    <div className={cx({
                        'form-group': true,
                        'has-error': !!this.state.validationErrorList.identifier
                    })}>
                        <label>
                            Name
                        </label>
                        <input type="text" className="form-control" placeholder="Name" name="identifier"
                               onChange={this.onModelChange} value={this.state.address.identifier}
                        />
                        {this.getAlert('identifier')}

                    </div>
                    <div className={cx({
                        'form-group': true,
                        'has-error': !!this.state.validationErrorList.primary
                    })}>
                        <Toggle
                            onClick={() => this.onToggleChange('primary')}
                            on={'Ja'}
                            off={'Nein'}
                            active={this.state.address.primary}
                        />
                        <label style={{marginLeft: '1em'}}>
                            Hauptstandort
                        </label>
                        {this.getAlert('primary')}

                    </div>
                    <div className="row form-group">
                        <div className={cx({
                            'col-xs-4': true,
                            'has-error': !!this.state.validationErrorList.plz
                        })}>
                            <label>
                                Postleitzahl
                            </label>
                            <input type="text" className="form-control" placeholder="Postleitzahl" name="plz"
                                   onChange={this.onModelChange} value={this.state.address.plz}
                                   required
                            />
                            {this.getAlert('plz')}
                        </div>
                        <div className={cx({
                            'col-xs-8': true,
                            'has-error': !!this.state.validationErrorList.city
                        })}>
                            <label>
                                Ort
                            </label>
                            <input type="text" className="form-control" placeholder="Ort" name="city"
                                   onChange={this.onModelChange} value={this.state.address.city}
                                   required
                            />
                            {this.getAlert('city')}
                        </div>
                    </div>
                    <div className={cx({
                        'form-group': true,
                        'has-error': !!this.state.validationErrorList.street
                    })}>
                        <label htmlFor="street">
                            Straße
                        </label>
                        <input type="text" className="form-control" placeholder="Straße" name="street"
                               onChange={this.onModelChange} value={this.state.address.street}
                               required
                        />
                        {this.getAlert('street')}
                    </div>
                    <div className={cx({
                        'form-group': true,
                        'has-error': !!this.state.validationErrorList.additional
                    })}>
                        <label>
                            Zusatz
                        </label>
                        <input type="text" className="form-control" placeholder="Zusatz" name="additional"
                               onChange={this.onModelChange} value={this.state.address.additional}
                        />
                        {this.getAlert('additional')}

                    </div>
                </div>
            </div>
        </div>
    }

}


const mapDispatchToProps = (dispatch) => {
    return {
        push: (link) => {
            dispatch(push(link))
        },
    }
};


export default connect(null, mapDispatchToProps)(NewAddress);
