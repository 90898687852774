import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Button, ButtonGroup, ButtonToolbar} from 'react-bootstrap'
import moment from 'moment';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Pagination from 'react-js-pagination';
import Spinner from '../../Utilities/Spinner'
import {push} from "connected-react-router";
import {store}from '../../../core';
class TicketTable extends Component {

    availableLimits = [
        1,
        10,
        20,
        50,
        100,
        200
    ];

    constructor(props) {
        super(props);

        this.state = {pagination: this.props.pagination};

        this.handlePageChange = this.handlePageChange.bind(this);
        this.perPageHandler = this.perPageHandler.bind(this);

    }

    perPageHandler(event)
    {
        let pagination = this.state.pagination;

        pagination.perPage = parseInt(event.target.value, 10);

        this.setState({pagination: pagination});

        this.props.setPagination(pagination);

        this.props.runSearch();
    }

    componentDidMount() {
        this.componentConfig(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.componentConfig(nextProps);
    }

    componentConfig(data) {

        if (data.tickets) {
            const {itemsPerPage, total, page} = data.tickets;

            let pagination = this.state.pagination;
            pagination.page = page;
            pagination.perPage = itemsPerPage;
            pagination.total = total;

            this.setState({pagination: pagination});
        }
    }

    render() {

        let loading = null;
        if (this.props.isFetching) {
            loading = (<Spinner/>)
        }

        let content = '';
        if (this.props.tickets) {
            const {results} = this.props.tickets;

            content = results.map((result) => {
                return Row(result,store.dispatch);
            })
        }

        let limits = '';
        if(this.availableLimits)
        {
            limits = this.availableLimits.map((data) => {
                return <option key={data} value={data}>{data}</option>
            })
        }

        const pageCount = Math.ceil(this.state.pagination.total/this.state.pagination.perPage);


        return (
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <div className='pull-right'>
                            <ButtonToolbar>
                                <ButtonGroup bsSize="xsmall">
                                    <Button disabled={!!loading} onClick={this.props.runSearch}><FontAwesomeIcon
                                        icon='sync'/></Button>
                                </ButtonGroup>
                            </ButtonToolbar>
                        </div>
                        <div className="form-inline" style={{marginBottom: 0, marginRight: '5px', display: 'inline'}}>
                            <select className="form-control form-inline" id="perPage" value={this.state.pagination.perPage} onChange={this.perPageHandler}>
                                {limits}
                            </select>
                        </div>
                        <span style={{marginRight: '5px'}}>{this.state.pagination.total} auf {pageCount} Seiten</span>
                        <span style={{marginBottom: 0, fontSize: '150%'}}>{loading}</span>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                            <tr>
                                <th>TicketId</th>
                                <th>Kunde</th>
                                <th>Geräte</th>
                                <th>Kategorie</th>
                                <th>Kurzbeschreibung</th>
                                <th>Status</th>
                                <th>Erstellt am</th>
                                <th>Bearbeitet am</th>
                                <th>Abgeschlossen am</th>
                            </tr>
                            </thead>
                            <tbody>
                            {content.length > 0 && content}
                            </tbody>
                        </table>
                    </div>
                    <div className='panel-footer text-center'>
                        <Pagination
                            activePage={this.state.pagination.page}
                            itemsCountPerPage={this.state.pagination.perPage}
                            totalItemsCount={this.state.pagination.total}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange}
                        />
                    </div>
                </div>
        );
    }

    handlePageChange(pageNumber) {
        let pagination = this.state.pagination;
        pagination.page = pageNumber;
        this.setState({pagination: pagination});

        this.props.setPagination(pagination);

        this.props.runSearch();
    }
}

const Row = (result,dispatch) => {

    let devices = '';
    if(result.devices && result.devices.length)
    {
        devices = result.devices.map((data, id) => {
            if(data.removed)
            {
                return false;
            }
            return <li key={id}>{data.device.deviceNo} (KN: {data.device.customer.customerNo})</li>
        })
    }

    let className = '';
    if (result.status.id === 1) {
        if (result.importance >= 8) {
            className = 'danger';
        }
        else if (result.importance >= 5) {
            className = 'warning';
        }
        else if (result.importance >= 2) {
            className = 'info';
        }
    }

    return <tr key={result.id} onClick={() => {dispatch(push('/tickets/'+result.id))}} className={className}>
        <td>{result.id}</td>
        <td>{result.customer.customerName} ({result.customer.customerNo})</td>
        <td><ul>{devices}</ul></td>
        <td>{result.category.title}</td>
        <td>{result.shortDescription}</td>
        <td>{result.status.title}</td>
        <td>{moment(result.inserted).format('DD/MM/YYYY HH:mm')}</td>
        <td>{moment(result.updatedAt).format('DD/MM/YYYY HH:mm')}</td>
        <td>{result.completedAt ? moment(result.completedAt).format('DD/MM/YYYY HH:mm'): ''}</td>
    </tr>
};


const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({}, dispatch),
    }
};

function mapStateToProps(state) {

    const {ticket} = state;

    const {isFetching, tickets} = ticket;

    return {isFetching, tickets}
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketTable);
