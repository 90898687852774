import React, {Component} from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {Button} from 'react-bootstrap';

import Rest from "../../../../core/Rest";
import Spinner from '../../../Utilities/Spinner'
import Row from './Row';
import CreateModal from './CreateModal';

class List extends Component {

    constructor(props) {
        super(props);

        this.state = {
            groups: [],
            isFetching: false,
        };

        this.fetchGroups = this.fetchGroups.bind(this);
        this.onChange = this.onChange.bind(this);
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);


        this.handleShow = this.handleShow.bind(this);
    };


    handleShow() {
        this.modal.handleShow();
    }

    componentDidMount() {
        this.fetchGroups();
    }

    render() {

        let loading = null;
        if (this.state.isFetching) {
            loading = (<Spinner/>)
        }

        const rows = this.state.groups.map((group, key) => {
            return <Row group={group} key={key} onDelete={this.delete} onChange={this.onChange} groupList={this.state.groups}/>
        });

        return (
            <div>
                <div className='btn-bar'>
                    <Button
                        style={{marginRight: '5px'}}
                        onClick={this.handleShow}
                    >
                        <FontAwesomeIcon icon='plus'/> Hinzufügen
                    </Button>
                    <Button
                        bsStyle="success"
                        disabled={this.state.isFetching}
                        onClick={!this.state.isFetching ? this.save : null}
                    >
                        <FontAwesomeIcon icon='save'/> Speichern
                    </Button>
                </div>
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <div className='pull-right'>
                            <Button bsSize="xsmall" disabled={!!loading} onClick={this.fetchGroups}><FontAwesomeIcon
                                icon='sync'/></Button>
                        </div>
                        <h4 className="panel-title">Gruppen {loading}</h4>
                    </div>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Titel</th>
                            <th>Anzahl Nutzer</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </table>
                </div>
                <CreateModal  ref={(modal) => { this.modal = modal; }} afterSave={() => {this.fetchGroups()}} />
            </div>
        );
    }

    onChange(id, name, value) {
        let groups = this.state.groups.slice();

        for (let i in groups) {

            let group = groups[i];

            if (group.id === id) {

                group[name] = value;
                group.updated = true;

                groups[i] = Object.assign({}, group);

                this.setState({groups: groups});
                break;
            }
        }
    }

    save() {

        let promises = [];
        this.setState({isFetching: true});


        const groups = this.state.groups.map((group) => {
            if (!group.updated) {
                return group;
            }

            const promise = Rest.fetch({
                endpoint: 'users/groups/' + group.id,
                method: 'PATCH',
                body: {
                    title: group.title
                }
            });

            promises.push(promise);

            group.updated = false;

            return group;
        });


        Promise.all(promises).then(() => {
            this.setState({isFetching: false})
        }, ( err) => {
            console.log(err);
            this.fetchGroups();
        });

        this.setState({groups: groups});

    }

    delete(id, newId) {

        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'users/groups/'+id,
            method: 'DELETE',
            body: {
                groupId: newId
            }
        }).then(
            response => {},
            error => {}
        ).then(() => {
            this.setState({isFetching: false});
            this.fetchGroups();
        });

    }


    fetchGroups() {

        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'users/groups',
        }).then(
            response => {

                const groups = response.response.map((group) => {
                    group.updated = false;

                    return group;
                });

                this.setState({groups: groups, isFetching: false})
            },
            error => {
                this.setState({isFetching: false})
            }
        )

    }


}

export default List;
