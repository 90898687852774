import React, {Component} from 'react';
import {connect} from "react-redux";
import {Route, Switch} from "react-router"
import {LinkContainer} from 'react-router-bootstrap';
import {Collapse} from 'react-bootstrap';
import {history} from "../../core";
import NoMatch from "../NoMatch/NoMatch";
import UserList from "./User/User/Router";
import GroupList from "./User/Groups/List";
import TagList from "./General/Tags/List";
import CategoryList from "./Tickets/Categories/List";
import TaskList from "./Tickets/Tasks/List";
import StatusList from "./Tickets/Status/List";
import DeviceTypesList from "./Devices/Types/List";
import DeviceComponentTypesList from "./Devices/ComponentTypes/List";
import PdfSettings from "./Tickets/Pdf/Settings";
import NumberRangeSettings from "./Devices/NumberRange/Settings";
import AdditionalFieldsSettings from "./Devices/AdditionalFields/Settings";
import MapSettings from "./Customer/Map/Settings";
import SupportSettings from "./Tickets/SupportTickets/Settings";
import ReminderSettings from "./Tickets/Reminder/Settings";
import VendiSettings from "./Integration/4Vending/Settings";
import TelegramSettigns from "./Integration/Telegram/Settings";
import QrSettings from "./Devices/Qr/Settings";

class Menu extends Component {

    collapses = [
        {name: 'collapseGeneral',value : 'Allgemein'},
        {name: 'collapseUser',value : 'Nutzer'},
        {name: 'collapseTickets',value : 'Tickets'},
        {name: 'collapseDevice',value : 'Geräte'},
        {name: 'collapseCustomer',value : 'Kunden'},
        {name: 'collapseIntegration',value : 'Integration'},
        {name: 'collapseCalendar',value : 'Kalender'},
    ];
    
    routeMap = [
        { route: '/general/tags', collapse: 'collapseGeneral', name: "Tags"},
        { route: '/user', collapse: 'collapseUser', name: "Nutzer Verwalten"},
        { route: '/groups', collapse: 'collapseUser', name: "Gruppen Verwalten"},
        { route: '/tickets/categories', collapse: 'collapseTickets', name: 'Kategorien'},
        { route: '/tickets/tasks', collapse: 'collapseTickets', name: 'Aufgaben'},
        { route: '/tickets/pdf', collapse: 'collapseTickets', name: 'Pdf'},
        { route: '/tickets/status', collapse: 'collapseTickets', name: 'Status'},
        { route: '/tickets/support_ticket', collapse: 'collapseTickets', name: 'Support Ticket'},
        { route: '/tickets/reminder', collapse: 'collapseTickets', name: 'Erinnerungen'},
        { route: '/devices/types', collapse: 'collapseDevice', name: 'Typen'},
        { route: '/devices/component_types', collapse: 'collapseDevice', name: 'Komponenten Typen'},
        { route: '/devices/number_range', collapse: 'collapseDevice', name: 'Nummernkreis'},
        { route: '/devices/additional_fields', collapse: 'collapseDevice', name: 'Zusatz Felder'},
        { route: '/devices/qr', collapse: 'collapseDevice', name: 'QR Codes'},
        { route: '/customer/map', collapse: 'collapseCustomer', name: 'Karte'},
        { route: '/integration/4vending', collapse: 'collapseIntegration', name: '4Vending'},
        { route: '/integration/telegram', collapse: 'collapseIntegration', name: 'Telegram'},
        { route: '/calendar/component_events', collapse: 'collapseCalendar', name: 'Komponenten Termine'},

    ];

  

    constructor(props) {
        super(props);

        this.state = {
            collapseStatus: {}
        };

        this.toggleCollapse = this.toggleCollapse.bind(this);

    };

    componentDidMount()
    {
        const match = this.props.match;
        const pathname = history.location.pathname;

        if(pathname !== '/settings') {
            this.routeMap.forEach((route) => {

                const fullRoute = match.url + route.route;

                if (pathname.includes(fullRoute)) {
                    let collapseStatus = this.state.collapseStatus;
                    collapseStatus[route.collapse] = true;
                    this.setState({collapseStatus: collapseStatus})
                }
            });
        }

    }

    toggleCollapse(event) {
        const key = event.target.getAttribute('toggles');

        let collapseStatus = this.state.collapseStatus;

        if (typeof collapseStatus[key] === 'undefined') {
            collapseStatus[key] = false;
        }

        collapseStatus[key] = !collapseStatus[key];

        this.setState({collapseStatus: collapseStatus})
    }

    render() {
        const match = this.props.match;

        const ListItem = ({ exact, to, children }) =>
            <LinkContainer exact={exact} to={to}>
                <a className='list-group-item'>{children}</a>
            </LinkContainer>;

        const menu = this.collapses.map((collapse, key) => {

            const name = collapse.name;
            const value = collapse.value;

            const routes = this.routeMap.map((route, id) => {

                if(route.collapse !== name) {
                    return null;
                }

                return <ListItem key={id} to={match.url+route.route}>{route.name}</ListItem>
            });

            return  <div className="panel panel-default" key={key}>
                <div className="panel-heading" role="tab" id={'heading_'+name}>
                    <h4 className="panel-title">
                        <a toggles={name} onClick={this.toggleCollapse}>
                            {value}
                        </a>
                    </h4>
                </div>
                <Collapse in={this.state.collapseStatus[name]} id={name}
                          className="panel-collapse">
                    <div className="list-group">
                        {routes}
                    </div>
                </Collapse>
            </div>
        });

        return (
            <div className='row'>
                <div className="col-md-2">
                    <div className="panel-group">
                        {menu}
                    </div>
                </div>
                <div className="col-md-10">
                    <Switch>
                        <Route exact path={match.url} render={() => (
                            <div/>
                        )}/>
                        <Route path={`${match.url}/general/tags`} component={TagList} />
                        <Route path={`${match.url}/user`} component={UserList} />
                        <Route path={`${match.url}/groups`} component={GroupList} />
                        <Route path={`${match.url}/tickets/categories`} component={CategoryList} />
                        <Route path={`${match.url}/tickets/tasks`} component={TaskList} />
                        <Route path={`${match.url}/tickets/pdf`} component={PdfSettings} />
                        <Route path={`${match.url}/tickets/status`} component={StatusList} />
                        <Route path={`${match.url}/tickets/support_ticket`} component={SupportSettings} />
                        <Route path={`${match.url}/tickets/reminder`} component={ReminderSettings} />
                        <Route path={`${match.url}/devices/qr`} component={QrSettings} />
                        <Route path={`${match.url}/devices/types`} component={DeviceTypesList} />
                        <Route path={`${match.url}/devices/component_types`} component={DeviceComponentTypesList} />
                        <Route path={`${match.url}/devices/number_range`} component={NumberRangeSettings} />
                        <Route path={`${match.url}/devices/additional_fields`} component={AdditionalFieldsSettings} />
                        <Route path={`${match.url}/customer/map`} component={MapSettings} />
                        <Route path={`${match.url}/integration/4vending`} component={VendiSettings} />
                        <Route path={`${match.url}/integration/telegram`} component={TelegramSettigns} />
                        <Route component={NoMatch}/>
                    </Switch>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {

    const {user} = state;

    const {isFetching, userObject} = user;

    return {isFetching, userObject}
}

export default connect(mapStateToProps,null)(Menu);
