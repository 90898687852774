import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {Popover, OverlayTrigger, Button} from 'react-bootstrap';
import DateTime from 'react-datetime'
import {bindActionCreators} from "redux";
import {Collapse} from 'react-bootstrap';
import {fetchCategoryList} from "../../actions/categoryActions";
import {fetchStatusList} from "../../actions/statusActions";
import Panel from "../Utilities/Tickets/FilterBookmarks/Panel";

class Filter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showFilter: false,
            filter: Object.assign({}, this.props.filter),
            orderBy: this.props.orderBy
        };

        this.reload = this.reload.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.changeToDate = this.changeToDate.bind(this);
        this.changeFromDate = this.changeFromDate.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.resetDateFilter = this.resetDateFilter.bind(this);
        this.onFilterArrayChange = this.onFilterArrayChange.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.onOrderChange = this.onOrderChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

    }

    componentDidMount() {
        this.reload();
    }

    toggleCollapse() {
        this.setState({showFilter: !this.state.showFilter});
    }

    changeFromDate(date) {
        let filter = this.state.filter;

        filter.fromDate = date;

        this.setState({filter: filter});

        this.props.setFilter(this.state.filter);

    }

    onSubmit(event) {
        event.preventDefault();

        this.props.runSearch(event);
    }


    changeToDate(date) {
        let filter = this.state.filter;

        filter.toDate = date;

        this.setState({filter: filter});

        this.props.setFilter(this.state.filter);
    }

    onFilterChange(event) {

        const field = event.target.id;
        const value = event.target.value;

        let filter = this.state.filter;

        filter[field] = value;

        this.setState({filter: filter});

        this.props.setFilter(this.state.filter);
    }

    onOrderChange(event) {
        this.setState({orderBy: event.target.value});

        this.props.setOrderBy(event.target.value);
    }

    onFilterArrayChange(event) {


        const field = event.target.id;
        const value = event.target.value;
        const checked = event.target.checked;

        let filter = this.state.filter;

        if (checked) {
            filter[field].push(value);
        }
        else {
            filter[field] = filter[field].filter((statusKey) => {

                return statusKey !== value
            });
        }

        this.setState({filter: filter});

        this.props.setFilter(this.state.filter);
    }

    resetDateFilter(event) {
        let filter = this.state.filter;

        filter.toDate = null;
        filter.fromDate = null;

        this.setState({filter: filter});

        this.props.setFilter(this.state.filter);
    }

    resetFilter() {

        let filter = {
            ticketData: '',
            customerData: '',
            deviceData: '',
            category: '',
            fromDate: null,
            toDate: null,
            status: ["1", "2"]
        };

        this.setState({filter: filter, orderBy: 's.priority_ASC'});

        this.props.setFilter(filter);
        this.props.setOrderBy('s.priority_ASC');
    }


    render() {

        let orderList = [
            {
                id: 's.priority_ASC',
                title: 'Status Aufsteigend'
            },
            {
                id: 't.inserted_DESC',
                title: 'Erstellungsdatum Absteigend'
            },
            {
                id: 't.inserted_ASC',
                title: 'Erstellungsdatum Aufsteigend'
            },
            {
                id: 't.importance_DESC',
                title: 'Priorität Absteigend'
            },
        ];

        const orders = orderList.map((data, id) => {
            return <option key={data.id} value={data.id}>{data.title}</option>
        });

        let categories = '';
        if (this.props.categories) {
            categories = this.props.categories.map((data, id) => {
                return <option key={data.id} value={data.id}>{data.title}</option>
            });
        }

        let status = '';
        if (this.props.status) {
            status = this.props.status.map((data, id) => {
                    const checked = this.state.filter.status.indexOf(data.id.toString()) !== -1;

                    return <div key={id}>
                        <input type='checkbox' key={data.id} id='status' value={data.id} checked={checked} onChange={this.onFilterArrayChange}/>
                        &nbsp;{data.title}
                    </div>
                }
            );
        }

        const popoverTicketData = (
            <Popover id="ticketDataPopover" title="Ticketdaten">
                Durchsucht die Felder
                <ul>
                    <li>TicketId</li>
                    <li>Beschreibung</li>
                    <li>Kurzbescheibung</li>
                </ul>
                der Tickets mit berücksichtigung der anderen Filter
            </Popover>
        );

        const popoverDeviceData = (
            <Popover id="ticketDataPopover" title="GeräteDaten">
                Durchsucht die meisten Felder des mit dem Ticket verküpftem Gerätes mit berücksichtigung der anderen
                Filter
            </Popover>
        );

        const popoverCustomerData = (
            <Popover id="ticketDataPopover" title="Kundendaten">
                Durchsucht die meisten Felder des mit dem Ticket verküpftem Kunden mit berücksichtigung der anderen
                Filter
            </Popover>
        );

        let deviceFilter = '';
        if (!this.state.filter.deviceId) {
            deviceFilter = <div className="form-group">
                <label htmlFor="deviceData">
                    <span style={{marginRight: '0.3em'}}>Gerätedaten</span>
                    <OverlayTrigger trigger={["hover", "focus"]} overlay={popoverDeviceData}>
                        <FontAwesomeIcon icon='info-circle'/>
                    </OverlayTrigger>
                </label>
                <input type="text" placeholder="Gerätedaten" id="deviceData" onChange={this.onFilterChange}
                       value={this.state.filter.deviceData}
                       className="form-control"/>
            </div>;
        }

        let customerFilter = '';
        if (!this.state.filter.customer) {
            customerFilter = <div className="form-group">

                <label htmlFor="customerData">
                    <span style={{marginRight: '0.3em'}}>Kundendaten</span>
                    <OverlayTrigger trigger={["hover", "focus"]} overlay={popoverCustomerData}>
                        <FontAwesomeIcon icon='info-circle'/>
                    </OverlayTrigger>
                </label>
                <input type="text" placeholder="Kundendaten" id="customerData" onChange={this.onFilterChange}
                       value={this.state.filter.customerData}
                       className="form-control"/>
            </div>;
        }

        const headerIcon = this.state.showFilter?'chevron-up':'chevron-down';

        return (
            <Fragment>
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <h4 className="panel-title">
                            <a onClick={this.toggleCollapse}>
                                Filter <FontAwesomeIcon icon={headerIcon}/>
                            </a>
                        </h4>
                    </div>
                    <Collapse in={this.state.showFilter} className="panel-collapse">
                        <div className='panel-body'>
                            <form onSubmit={this.onSubmit}>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor="ticketData">
                                                <span style={{marginRight: '0.3em'}}>Ticketdaten</span>
                                                <OverlayTrigger trigger={["hover", "focus"]} overlay={popoverTicketData}>
                                                    <FontAwesomeIcon icon='info-circle'/>
                                                </OverlayTrigger>
                                                <a id="ticketDataPopover">
                                                </a>
                                            </label>
                                            <input type="text" placeholder="Ticketdaten" id="ticketData" onChange={this.onFilterChange}
                                                   value={this.state.filter.ticketData}
                                                   className="form-control"/>
                                        </div>
                                        {deviceFilter}
                                        {customerFilter}
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor="categoryData">Kategorie</label>
                                            <select value={this.state.filter.category} onChange={this.onFilterChange} id="category"
                                                    className="form-control">
                                                <option value=''>Alle</option>
                                                {categories}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="statusData">Status</label>
                                            <br/>
                                            {status}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor="dateData">Datum</label>
                                            <div className="input-group" style={{marginBottom: '5px'}}>
                                                <span className="input-group-addon">Von</span>
                                                <DateTime
                                                    id="fromDate"
                                                    value={this.state.filter.fromDate}
                                                    onChange={this.changeFromDate}
                                                    timeFormat={false}
                                                    closeOnSelect={true}
                                                />
                                            </div>
                                            <div className="input-group" style={{marginBottom: '5px'}}>
                                                <span className="input-group-addon">Bis</span>
                                                <DateTime
                                                    id="fromDate"
                                                    value={this.state.filter.toDate}
                                                    onChange={this.changeToDate}
                                                    timeFormat={false}
                                                    closeOnSelect={true}
                                                />
                                            </div>
                                            <Button onClick={this.resetDateFilter} block>
                                                Reset <FontAwesomeIcon icon='undo'/>
                                            </Button>
                                        </div>
                                        <div className="form-group">

                                            <label htmlFor="orderByData">Sortierung</label>
                                            <select id="orderBy" className="form-control" value={this.state.orderBy}
                                                    onChange={this.onOrderChange}>
                                                {orders}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <Panel identifier='live' setBookmark={(bookmark) => {
                                            this.setState({filter: bookmark.filter, orderBy: bookmark.orderBy});
                                            this.props.setFilter(bookmark.filter);
                                            this.props.setOrderBy(bookmark.orderBy);
                                        }} executeSearch={() => this.props.runSearch()} filter={this.state.filter} orderBy={this.state.orderBy} />
                                    </div>
                                </div>
                                <Button type="submit" style={{marginRight: '5px'}} onClick={this.onSubmit}>Suche</Button>
                                <Button onClick={this.resetFilter}>Reset</Button>
                            </form>
                        </div>
                    </Collapse>
                </div>
            </Fragment>
        );
    }

    reload() {
        this.props.actions.fetchCategoryList();
        this.props.actions.fetchStatusList();
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchCategoryList, fetchStatusList}, dispatch),
    }
};

function mapStateToProps(state) {

    const {categoryList, statusList} = state;

    let {categories} = categoryList;
    let {status} = statusList;

    return {categories, status}

}

export default connect(mapStateToProps, mapDispatchToProps)(Filter);