import React from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

const AccessDenied = (props) => {

    return (
        <div style={{paddingTop: '50px'}}>
            <div className='access-denied-screen'>
                <FontAwesomeIcon icon="exclamation-triangle" size='4x'/>
                <p>Keine Berechtigung</p>
            </div>
        </div>
    );
};

export default AccessDenied;
