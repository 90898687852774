import React, {Component} from 'react';

import TicketList from "../../../Tickets/List/OldTicketList";

class Tickets extends Component {

    render() {
        return <TicketList filter={{deviceId: this.props.match.params.deviceId}}/>

    }
}

export default Tickets;
