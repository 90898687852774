import {CALL_API} from '../middleware/api'

export const NOTIFICATION_LIST_REQUEST = 'NOTIFICATION_LIST_REQUEST';
export const NOTIFICATION_LIST_SUCCESS = 'NOTIFICATION_LIST_SUCCESS';
export const NOTIFICATION_LIST_FAILURE = 'NOTIFICATION_LIST_FAILURE';


export function fetchNotificationList(page = 1, perPage = 10) {

    return {
        [CALL_API]: {
            endpoint: 'notifications',
            method: 'GET',
            parameter: {
                perPage: perPage,
                page: page
            },
            types: [NOTIFICATION_LIST_SUCCESS, NOTIFICATION_LIST_FAILURE, NOTIFICATION_LIST_REQUEST]
        }
    }
}

