import React, {Component} from 'react';
import {Button, Modal} from "react-bootstrap";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import Rest from "../../../../core/Rest";
import cx from "classnames";
import Spinner from "../../../Utilities/Spinner";
import Toggle from 'react-bootstrap-toggle';
import {connect} from "react-redux";
import {push} from "connected-react-router";

class Data extends Component {

    defaultModel = {
        identifier: '',
        city: '',
        plz: '',
        primary: false,
        additional: '',
        street: '',
    };


    constructor(props) {
        super(props);

        this.state = {
            showDeleteModal: false,
            isFetching: false,
            address: {},
            validationErrorList: {}
        };

        this.onModelChange = this.onModelChange.bind(this);
        this.getAlert = this.getAlert.bind(this);
        this.saveAddress = this.saveAddress.bind(this);
        this.fetchAddress = this.fetchAddress.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.onToggleChange = this.onToggleChange.bind(this);
        this.deleteAddress = this.deleteAddress.bind(this);
    }

    componentDidMount() {
        this.fetchAddress();
    }

    handleClose() {
        this.setState({showDeleteModal: false});
    }

    handleShow() {
        this.setState({showDeleteModal: true});
    }


    onToggleChange(name) {
        const model = this.state.address;

        model[name] = !model[name];

        this.setState({address: model})
    }

    fetchAddress() {
        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'customers/addresses/' + this.props.match.params.addressId,
            method: 'GET'
        }).then(
            response => {

                const address = response.response;

                Object.keys(address).forEach((key) => {

                    if (Object.keys(this.defaultModel).indexOf(key) === -1) {
                        delete address[key];
                    }

                });

                this.setState({address: address});
            },
            error => {
            }
        ).then(() => {
            this.setState({isFetching: false});
        })
    }

    saveAddress() {

        this.setState({isSaving: true, validationErrorList: {}});

        let model = Object.assign({}, this.state.address);

        model.plz = parseInt(model.plz, 10);

        Rest.fetch({
            endpoint: 'customers/addresses/' + this.props.match.params.addressId,
            method: 'PATCH',
            body: model
        }).then(
            response => {

                const address = response.response;

                Object.keys(address).forEach((key) => {

                    if (Object.keys(this.defaultModel).indexOf(key) === -1) {
                        delete address[key];
                    }

                });

                this.setState({model: Object.assign({}, this.defaultModel, address)});

            },
            error => {
                if (error.code === 400 && error.raw.error && error.raw.error.validation_errors) {
                    this.setState({validationErrorList: error.raw.error.validation_errors})
                }
            }
        ).then(() => {
            this.setState({isSaving: false});
        })
    }

    deleteAddress() {

        this.setState({isSaving: true});


        Rest.fetch({
            endpoint: 'customers/addresses/' + this.props.match.params.addressId,
            method: 'DELETE',
        }).then(
            response => {
                const params = this.props.match.params;
                const url = '/customer/' + params.customerId + '/addresses';
                this.props.push(url);
            },
            error => {
            }
        ).then(() => {
            this.setState({isSaving: false});
        })
    }

    getAlert(key) {
        if (this.state.validationErrorList[key] && Array.isArray(this.state.validationErrorList[key])) {
            const values = this.state.validationErrorList[key].map((message) => {
                return <li>{message}</li>
            });

            return <div className='alert alert-danger' style={{marginTop: '10px'}}>
                <ul>{values}</ul>
            </div>
        }

        return '';
    }

    onModelChange(event) {
        const model = this.state.address;

        model[event.target.name] = event.target.value;

        this.setState({address: model})
    }

    render() {
        let loading = null;
        if (this.state.isFetching) {
            loading = (<Spinner/>)
        }

        return <div>
            <div className='btn-bar'>
                <Button
                    bsStyle="success"
                    disabled={this.state.isFetching}
                    onClick={!this.state.isFetching ? this.saveAddress : null}
                >
                    <FontAwesomeIcon icon='save'/> Speichern
                </Button>

                <div className='pull-right'>
                    <Button
                        bsStyle="danger"
                        disabled={this.state.isFetching}
                        onClick={!this.state.isFetching ? this.handleShow : null}
                    >
                        <FontAwesomeIcon icon='trash'/> Löschen
                    </Button>
                </div>
            </div>
            <div className="panel-default panel">
                <div className="panel-heading">
                    <div className='pull-right'>
                        <Button bsSize="xsmall" disabled={!!loading} onClick={this.fetchAddress}><FontAwesomeIcon
                            icon='sync'/></Button>
                    </div>
                    <h4 className="panel-title">
                        Adresse bearbeiten {loading}
                    </h4>
                </div>
                <div className="panel-body">
                    <div className={cx({
                        'form-group': true,
                        'has-error': !!this.state.validationErrorList.identifier
                    })}>
                        <label>
                            Name
                        </label>
                        <input type="text" className="form-control" placeholder="Name" name="identifier"
                               onChange={this.onModelChange} value={this.state.address.identifier}
                        />
                        {this.getAlert('identifier')}

                    </div>
                    <div className={cx({
                        'form-group': true,
                        'has-error': !!this.state.validationErrorList.primary
                    })}>
                        <Toggle
                            onClick={() => this.onToggleChange('primary')}
                            on={'Ja'}
                            off={'Nein'}
                            active={this.state.address.primary}
                        />
                        <label style={{marginLeft: '1em'}}>
                            Hauptstandort
                        </label>
                        {this.getAlert('primary')}

                    </div>
                    <div className="row form-group">
                        <div className={cx({
                            'col-xs-4': true,
                            'has-error': !!this.state.validationErrorList.plz
                        })}>
                            <label>
                                Postleitzahl
                            </label>
                            <input type="text" className="form-control" placeholder="Postleitzahl" name="plz"
                                   onChange={this.onModelChange} value={this.state.address.plz}
                                   required
                            />
                            {this.getAlert('plz')}
                        </div>
                        <div className={cx({
                            'col-xs-8': true,
                            'has-error': !!this.state.validationErrorList.city
                        })}>
                            <label>
                                Ort
                            </label>
                            <input type="text" className="form-control" placeholder="Ort" name="city"
                                   onChange={this.onModelChange} value={this.state.address.city}
                                   required
                            />
                            {this.getAlert('city')}
                        </div>
                    </div>
                    <div className={cx({
                        'form-group': true,
                        'has-error': !!this.state.validationErrorList.street
                    })}>
                        <label htmlFor="street">
                            Straße
                        </label>
                        <input type="text" className="form-control" placeholder="Straße" name="street"
                               onChange={this.onModelChange} value={this.state.address.street}
                               required
                        />
                        {this.getAlert('street')}
                    </div>
                    <div className={cx({
                        'form-group': true,
                        'has-error': !!this.state.validationErrorList.additional
                    })}>
                        <label>
                            Zusatz
                        </label>
                        <input type="text" className="form-control" placeholder="Zusatz" name="additional"
                               onChange={this.onModelChange} value={this.state.address.additional}
                        />
                        {this.getAlert('additional')}

                    </div>
                </div>
            </div>
            <Modal backdropClassName='bootstrap' className='bootstrap' show={this.state.showDeleteModal} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Löschen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Wirklich Löschen?
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-default" onClick={this.handleClose}>Schließen
                    </button>
                    <Button
                        bsStyle="danger"
                        onClick={this.deleteAddress}
                        disabled={this.state.isSaving}
                    >
                        <FontAwesomeIcon icon='trash'/> Löschen
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        push: (link) => {
            dispatch(push(link))
        },
    }
};

export default connect(null, mapDispatchToProps)(Data);
