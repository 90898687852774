import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Button} from 'react-bootstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';

import TextEditor from '../../Utilities/TextEditor';
import Rest from "../../../core/Rest";
import Spinner from '../../Utilities/Spinner'
import {push} from "connected-react-router";
import {store} from "../../../core";
import {I18n} from "react-i18next";

const intervalList = [
    '1d',
    '7d',
    '1m',
    '3m',
    '6m',
    '1y'
];

class EditTicket extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ticket: {
                id: null,
                recurrence: '0',
                importance: 5,
                shortDescription: '',
                description: '',
                category: undefined,
                reporter: '',
                private: false
            },
            categories: [],
            isFetching: false,
            isSaving: false
        };

        this.fetchCategories = this.fetchCategories.bind(this);
        this.fetchTicket = this.fetchTicket.bind(this);
        this.onModelChange = this.onModelChange.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.onDescriptionChange = this.onDescriptionChange.bind(this);
        this.saveTicket = this.saveTicket.bind(this);
    };

    componentDidMount() {
        this.fetchCategories();
        this.fetchTicket();
    }

    render() {

        let loading = null;
        if (this.state.isFetching || this.state.isSaving) {
            loading = (<Spinner/>)
        }

        let categories = null;
        if (this.state.categories) {
            categories = this.state.categories.sort((a, b) => {
                const textA = a.title.toUpperCase();
                const textB = b.title.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            }).map((result) => {
                return <option key={result.id} value={result.id}>{result.title}</option>
            })
        }

        let intervals = intervalList.map((interval) => {
            return <I18n key={interval} ns={['ticketInterval']}>{(t) => (
                <option key={interval} value={interval}>{t(interval)}</option>
            )}</I18n>
        });

        return (
            <div className="panel panel-default">
                <div className="panel-heading">
                    <div className='pull-right'>
                        <Button bsSize="xsmall" disabled={!!loading} onClick={this.fetchCategories}><FontAwesomeIcon
                            icon='sync'/></Button>
                    </div>
                    <h4 className="panel-title">Daten {loading}</h4>
                </div>
                <div className="panel-body">
                    <div className="row">
                        <div className="col-md-6">
                            <div className='form-group'>
                                <input
                                    id="private" type='checkbox'
                                    onChange={this.onCheckboxChange} checked={this.state.ticket.private}/>
                                &nbsp;
                                <label>Unsichtbares Ticket</label>
                            </div>
                            <div className="form-group">
                                <label>Kategorie</label>
                                <select id="category" className="form-control" onChange={this.onModelChange}
                                        value={this.state.ticket.category}>
                                    <option value="-1"> -- Bitte Wählen --</option>
                                    {categories}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Gemeldet von</label>
                                <input id="reporter" className="form-control" onChange={this.onModelChange}
                                       value={this.state.ticket.reporter}/>
                            </div>
                            <div className="form-group">
                                <label>Priorität</label>
                                <div className="row" style={{marginTop: '10px'}}>
                                    <div className='col-xs-11'>
                                        <input
                                            list='tickmarks'
                                            className='colorful-slider'
                                            id='importance'
                                            type='range'
                                            onChange={this.onModelChange}
                                            value={this.state.ticket.importance}
                                            min={0}
                                            max={10}
                                            step={1}/>
                                    </div>
                                    <div className='col-xs-1' style={{lineHeight: '5px'}}>
                                        <label className='label label-default'>
                                            {this.state.ticket.importance}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Wiederkehrend</label>
                                <select id="recurrence" className="form-control" onChange={this.onModelChange}
                                        value={this.state.ticket.recurrence}>
                                    <option value={'0'}>Nie</option>
                                    {intervals}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Kurzbeschreibung(max. 200)</label>
                                <textarea name="shortDescription" id="shortDescription" className="form-control"
                                          maxLength="200"
                                          style={{resize: 'none'}}
                                          onChange={this.onModelChange}
                                          value={this.state.ticket.shortDescription}
                                />
                            </div>
                            <div className="form-group">
                                <label>Beschreibung</label>
                                <TextEditor
                                    id='description'
                                    value={this.state.ticket.description}
                                    onChange={this.onDescriptionChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel-footer">
                    <Link
                        className='btn btn-default'
                        role='button'
                        to={'/tickets/' + this.props.match.params.id}
                        style={{marginRight: '5px'}}
                    >
                        <FontAwesomeIcon icon='angle-left'/> Zurück
                    </Link>
                    <Button disabled={!!loading} onClick={!loading ? this.saveTicket : null}>
                        <FontAwesomeIcon icon='save'/> Speichern
                    </Button>
                </div>
            </div>
        );
    }

    fetchCategories() {

        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'tickets/categories',
        }).then(
            response => {
                this.setState({categories: response.response, isFetching: false})
            },
            error => {
                this.setState({isFetching: false})
            }
        )
    }

    fetchTicket() {
        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'tickets/' + this.props.match.params.id,
        }).then(
            response => {

                const responseTicket = response.response;

                const ticket = {
                    id: responseTicket.id,
                    importance: responseTicket.importance,
                    shortDescription: responseTicket.shortDescription,
                    description: responseTicket.description,
                    category: responseTicket.category.id,
                    reporter: responseTicket.reporter,
                    private: responseTicket.private,
                    recurrence: responseTicket.recurrence != null ? responseTicket.recurrence : '0'
                };

                this.setState({ticket: ticket, isFetching: false})
            },
            error => {
                this.setState({isFetching: false})
            }
        );
    }

    saveTicket() {
        this.setState({isSaving: true});

        const model = this.state.ticket;

        Rest.fetch({
            method: 'PATCH',
            endpoint: 'tickets/' + this.state.ticket.id,
            body: {
                importance: model.importance,
                shortDescription: model.shortDescription,
                description: model.description,
                category: model.category,
                reporter: model.reporter,
                private: model.private,
                recurrence: model.recurrence !== '0' ? model.recurrence : null
            }
        }).then(
            response => {
                this.setState({isSaving: false});
                store.dispatch(push('/tickets/' + response.response.id))

            },
            error => {
                this.setState({isSaving: false})
            }
        )
    }

    onDescriptionChange(value) {
        const model = this.state.ticket;

        model.description = value;
        this.setState({ticket: model});
    }

    onModelChange(event) {

        const model = this.state.ticket;

        model[event.target.id] = event.target.value;

        this.setState({ticket: model});
    }

    onCheckboxChange(event) {

        const model = this.state.ticket;

        model[event.target.id] = event.target.checked;

        this.setState({ticket: model});
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({}, dispatch),
    }
};

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTicket);