import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import qs from "query-string";
import Rest from "../../core/Rest";
import {Button} from 'react-bootstrap';
import {translate} from 'react-i18next';
import Pagination from 'react-js-pagination';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Spinner from "../Utilities/Spinner";
import moment from 'moment';
import {push} from "connected-react-router";
import SuggestedInput from "../Utilities/Input/SuggestedInput";

const AbortController = window.AbortController;

class Search extends Component {

    typeLinkMapping = {
        "ticket": "/tickets/{id}",
        "device": "/devices/{id}",
        "customer": "/customer/{id}"
    };

    abortController;

    availableLimits = [
        1,
        10,
        20,
        50,
        100,
        200
    ];

    constructor(props) {
        super(props);

        this.state = {
            term: '',
            suggestions: [],
            numberSuggestions: [],
            isFetching: false,
            results: [],
            facets: [],
            filter: {},
            pagination: {
                page: 1,
                perPage: 10,
                total: 0
            },
        };

        this.perPageHandler = this.perPageHandler.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.search = this.search.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.addFilter = this.addFilter.bind(this);
        this.removeFilter = this.removeFilter.bind(this);
        this.querySearch = this.querySearch.bind(this);
    };

    componentDidMount() {
        const query = qs.parse(this.props.location.search);

        if (query.term) {
            this.setState({term: query.term}, () => {
                this.querySearch()
            })
        } else {
            this.querySearch();
        }
    }

    addFilter(name, value) {
        const filter = this.state.filter;
        const pagination = this.state.pagination;
        pagination.page = 1;

        filter[name] = value;

        this.setState({filter: filter, pagination: pagination}, () => {
            this.search()
        })
    }

    removeFilter(name) {
        const filter = this.state.filter;
        const pagination = this.state.pagination;
        pagination.page = 1;

        delete filter[name];

        this.setState({filter: filter, pagination: pagination}, () => {
            this.search()
        })
    }

    onChange = (event) => {
        const value = event.target.value;

        this.setState({
            term: value
        }, () => {
            if (value.length >= 2) {
                this.suggestions();
            }
        });
    };

    onSubmit(event) {
        event.preventDefault();
        this.querySearch();
    }

    render() {

        let loading = null;
        if (this.state.isFetching) {
            loading = <div className='text-center' style={{marginBottom: '10px'}}><Spinner size='4x'/></div>
        }

        let limits = '';
        if (this.availableLimits) {
            limits = this.availableLimits.map((data) => {
                return <option key={data} value={data}>{data}</option>
            })
        }

        const facets = this.state.facets.map((facet, i) => {

            const values = facet.values.map((value, i2) => {

                const style = {display: 'inline-block'};

                let isSelected = false;
                if (this.state.filter[facet.name] && this.state.filter[facet.name] === value.key) {
                    isSelected = true;
                    style.fontWeight = 'bold';
                }


                return <div key={i2}>
                    <span style={style} className='filter-value' onClick={() => {
                        if (isSelected) {
                            return;
                        }
                        this.addFilter(facet.name, value.key)
                    }
                    }>
                        {this.props.t('facets.' + facet.name + '.values.' + value.key)}
                    </span>
                    <span style={{display: 'inline-block'}} className="pull-right label label-default">
                        {value.count}
                    </span>
                </div>
            });

            let deleteButton = '';
            if (this.state.filter[facet.name]) {
                deleteButton =
                    <button className="btn-danger btn-xs btn" style={{marginLeft: '5px'}} onClick={() => this.removeFilter(facet.name)}>
                        <FontAwesomeIcon icon='minus'/>
                    </button>
            }

            return <Fragment key={i}>
                <h4>
                    {this.props.t('facets.' + facet.name + '.title')}
                    {deleteButton}
                </h4>
                {values}
            </Fragment>;
        });

        const maxPage = Math.ceil(this.state.pagination.total / this.state.pagination.perPage);

        const results = this.state.results.map((result) => {

            const link = this.typeLinkMapping[result.type].replace('{id}', result.id);

            let picture = '';
            if (result.picture) {
                picture = <div className='pull-right' style={{width: '20%'}}>
                    <img className="img-responsive" src={result.picture} alt=''/>
                </div>
            }

            return <div key={result.documentId} className='panel panel-default search-result-panel' onClick={() => {
                this.props.resultLink(link)
            }}>
                <div className='panel-body'>
                    <div className='pull-left'>
                        <h3>{result.number}: {result.title}
                            <span style={{marginLeft: '5px'}} className="label label-default">{this.props.t('types.' + result.type)}</span>
                        </h3>
                        <small><i>{link}</i></small>
                        <p className='search-result-description'
                           dangerouslySetInnerHTML={{__html: result.description ? result.description.replace(/^(.{500}[^\s]*).*/, "$1") : ''}}/>
                        <span
                            className='search-result-footer'> - Zuletzt aktualisiert {moment(result.lastUpdated).format('DD/MM/YYYY HH:mm:ss')}</span>
                    </div>
                    {picture}
                </div>
            </div>
        });

        return (
            <div>
                <div className='row'>
                    <div className='col-md-2'/>
                    <div className='col-md-8'>
                        <SuggestedInput
                            onChange={this.onChange}
                            onSubmit={this.querySearch}
                            term={this.state.term}
                            isLarge={true}
                            suggestions={this.state.suggestions}
                            numberSuggestions={this.state.numberSuggestions}
                            suggestionClicked={this.searchSuggestionTerm}
                        />
                    </div>
                    <div className='col-md-2'/>
                </div>
                <div className='panel panel-default'>
                    <div className='panel-body' style={{padding: '5px'}}>
                        <div className='pull-right'>
                            <Button disabled={!!loading}
                                    onClick={this.search}><FontAwesomeIcon
                                icon='sync'/></Button>
                        </div>
                        <div className="form-inline" style={{marginBottom: 0, marginRight: '5px', display: 'inline'}}>
                            <select className="form-control form-inline" id="perPage" value={this.state.pagination.perPage}
                                    onChange={this.perPageHandler}>
                                {limits}
                            </select>
                        </div>
                        {this.state.pagination.total} Ergebnisse | Seite {this.state.pagination.page} von {maxPage}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-2'>
                        <div className='panel panel-default'>
                            <div className='panel-heading'>
                                Filter
                            </div>
                            <div className='panel-body'>
                                {facets}
                            </div>
                        </div>
                    </div>
                    <div className='col-md-10'>
                        {loading}
                        {results}
                        <div className='text-center'>
                            <Pagination
                                activePage={this.state.pagination.page}
                                itemsCountPerPage={this.state.pagination.perPage}
                                totalItemsCount={this.state.pagination.total}
                                pageRangeDisplayed={5}
                                onChange={this.handlePageChange}
                            />
                        </div>
                    </div>
                </div>

            </div>
        )
            ;
    }

    handlePageChange(pageNumber) {
        let pagination = this.state.pagination;
        pagination.page = pageNumber;
        this.setState({pagination: pagination});

        this.search();
    }

    perPageHandler(event) {
        let pagination = this.state.pagination;

        pagination.perPage = parseInt(event.target.value, 10);

        this.setState({pagination: pagination});

        this.search();
    }

    querySearch() {
        let pagination = this.state.pagination;

        pagination.page = 1;

        this.setState({pagination: pagination}, () => {
            this.search();
        })

    }

    fetchSuggestions = (term) => {
        if (this.abortController) {
            this.abortController.abort();
        }

        this.abortController = new AbortController();

        Rest.fetch({
            signal: this.abortController.signal,
            endpoint: 'suggest_search',
            method: 'GET',
            parameter: {
                query: term
            }
        }).then(
            response => {
                const result = response.response;

                this.setState({
                    suggestions: result.suggestions,
                    numberSuggestions: result.numbers,
                });

            },
            error => {
            }
        );
    };

    suggestions() {
        this.fetchSuggestions(this.state.term);
    }

    searchSuggestionTerm = (term) => {
        this.setState({term: term}, () => {
            this.querySearch()
        })
    };

    search() {
        this.setState({isFetching: true});

        const pagination = this.state.pagination;

        const filter = this.state.filter;

        Rest.fetch({
            endpoint: 'search',
            method: 'GET',
            parameter: {
                with: ['facets'],
                page: pagination.page,
                perPage: pagination.perPage,
                filter: filter,
                query: this.state.term
            }
        }).then(
            response => {
                const result = response.response;

                pagination.perPage = result.itemsPerPage;
                pagination.total = result.total;
                pagination.page = result.page;

                let facets = [];

                if (result.facets) {

                    facets = Object.keys(result.facets).map((key) => {
                        return {
                            name: key,
                            values: result.facets[key]
                        }
                    });
                }

                this.setState({
                    results: result.results,
                    facets: facets,
                    pagination: pagination
                });
            },
            error => {
            }
        ).then(() => {
            this.setState({isFetching: false});
        })
    }


}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({}, dispatch),
        resultLink(link) {
            dispatch(push(link))
        }
    }
};

function mapStateToProps(state) {
    return {}
}

export default compose(
    translate('search'),
    connect(mapStateToProps, mapDispatchToProps)
)(Search);
