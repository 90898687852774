import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Button, NavItem, Nav} from 'react-bootstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {LinkContainer} from 'react-router-bootstrap';
import {Route, Switch} from 'react-router'

import Rest from "../../../core/Rest";
import Spinner from '../../Utilities/Spinner'
import Redirect from "./Redirect";
import DeviceList from "./Devices/DeviceList";
import Tickets from "./Tickets";
import CustomerEvents from "./Events/CustomerEvents";
import {connect} from "react-redux";
import AddressList from "./Addresses/AddressList";

class ShowCustomer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            customer: {
                isFetching: false,
                model: {}
            }
        };

        this.fetchCustomer = this.fetchCustomer.bind(this);
    }

    componentDidMount() {
        this.fetchCustomer()
    }

    fetchCustomer() {

        const customer = this.state.customer;
        customer.isFetching = true;

        this.setState({customer: customer});

        Rest.fetch({
            endpoint: 'customers/' + this.props.match.params.id,
            parameter: {
                with: ['customerAddresses']
            },
            method: 'GET',
        }).then(
            response => {
                customer.model = response.response;
                this.setState({customer: customer});
            },
            error => {
            }
        ).then(() => {
            customer.isFetching = false;
            this.setState({customer: customer});
        })

    }

    render() {

        const customerId = this.props.match.params.id;

        const customer = this.state.customer.model;

        let loading = null;
        if (this.state.customer.isFetching) {
            loading = (<Spinner/>)
        }


        const primaryAddress = customer.addresses ? customer.addresses.find((value) => {
            return value.primary
        }) : {};

        let add = '';
        if (primaryAddress.additional) {
            add = <Fragment><br/>{primaryAddress.additional}</Fragment>
        }

        const NavLink = ({exact, to, eventKey, children}) =>
            <LinkContainer exact={exact} to={to} eventKey={eventKey}>
                <NavItem>{children}</NavItem>
            </LinkContainer>;

        const eventRoute = this.props.moduleList.calendar
            ? <NavLink to={'/customer/' + this.props.match.params.id + '/events'}>Termine</NavLink>

            : null;

        return (
            <div>
                <div className="btn-bar">
                    <Link
                        className='btn btn-default'
                        role='button'
                        to={'/customer'}
                    >
                        <FontAwesomeIcon icon='angle-left'/> Zurück
                    </Link>
                </div>
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <div className='pull-right'>
                            <Button bsSize="xsmall" disabled={!!loading} onClick={this.fetchCustomer}><FontAwesomeIcon
                                icon='sync'/></Button>
                        </div>
                        <h4 className="panel-title">Kunden Daten {loading}</h4>
                    </div>
                    <div className="panel-body">
                        <div className="col-md-6">
                            <dl>
                                <dt>Kundennummer</dt>
                                <dd>{customer.customerNo}</dd>
                                <dt>Name</dt>
                                <dd>{customer.customerName}</dd>
                            </dl>
                        </div>
                        <div className="col-md-6">
                            <address>
                                <strong>Hauptstandort</strong><br/>
                                {primaryAddress.street}<br/>
                                {primaryAddress.plz} {primaryAddress.city}
                                {add}
                            </address>
                            <dl>
                                <dt>Telefonnummer</dt>
                                <dd>
                                    <a href={'tel:' + customer.telephoneNumber}>{customer.telephoneNumber}</a>
                                </dd>
                            </dl>
                        </div>

                    </div>
                    <div className="panel-footer">
                        <Link
                            className='btn btn-default'
                            role='button'
                            to={'/customer/' + customerId + '/edit'}
                        >
                            <FontAwesomeIcon icon='pencil-alt'/> Bearbeiten
                        </Link>
                    </div>
                </div>
                <Nav bsStyle='pills' style={{marginBottom: '10px'}}>
                    <NavLink to={'/customer/' + this.props.match.params.id + '/devices'}>Geräte</NavLink>
                    <NavLink to={'/customer/' + this.props.match.params.id + '/addresses'}>Adressen <span className='badge'>NEU</span> </NavLink>
                    <NavLink to={'/customer/' + this.props.match.params.id + '/tickets'}>Tickets</NavLink>
                    {eventRoute}
                </Nav>
                <Switch>
                    <Route exact path={`/customer/:id/tickets`} component={Tickets}/>
                    <Route path={`/customer/:id/devices`} component={DeviceList}/>
                    <Route path={`/customer/:id/addresses`} component={AddressList}/>
                    <Route path={`/customer/:id/events`} component={CustomerEvents}/>
                    <Route component={Redirect}/>
                </Switch>
            </div>
        );
    }
}


function mapStateToProps(state) {

    const {modules} = state;
    const {moduleList} = modules;

    return {moduleList}
}

export default connect(mapStateToProps, null)(ShowCustomer);