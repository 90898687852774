import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {fetchRequestList} from "../../actions/requestsActions";
import Spinner from "../Utilities/Spinner";
import {Button} from "react-bootstrap";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import Rest from "../../core/Rest";

class Notifications extends Component {


    constructor(props) {
        super(props);

        this.fetchRequests = this.fetchRequests.bind(this);
    }

    componentDidMount() {
        this.fetchRequests()
    }

    fetchRequests() {
        this.props.actions.fetchRequestList();
    }

    acceptRequest(link)
    {
        this.setState({isChanging: true});

        Rest.fetch({
            endpoint: 'tickets/' + link.ticket.id + '/user/' + link.id + '/confirm',
            method: 'PATCH',
        }).then(
            response => {},
            error => {}
        ).then(() => {
            this.setState({isChanging: false});
            this.fetchRequests();
        });    }

    declineRequest(link)
    {
        this.setState({isChanging: true});

        Rest.fetch({
            endpoint: 'tickets/' + link.ticket.id + '/user/' + link.id,
            method: 'DELETE',
        }).then(
            response => {},
            error => {}
        ).then(() => {
            this.setState({isChanging: false});
            this.fetchRequests();
        });
    }

    render() {

        let loading = null;
        if (this.props.requests.isFetching) {
            loading = (<Spinner/>)
        }

        let notifications = [];
        if(this.props.requests.requestList)
        {
            notifications = this.props.requests.requestList.map((request, i) => {
                return <tr key={i}>
                    <td>
                        <h3 className='panel-title'>{request.ticket.id} - {request.ticket.customer.customerName}</h3>
                        <p>{request.ticket.shortDescription}</p>
                        <button onClick={() => this.acceptRequest(request)} style={{marginRight: '5px'}} className='btn btn-primary'>Annehmen</button>
                        <button onClick={() => this.declineRequest(request)} style={{marginRight: '5px'}} className='btn btn-danger'>Ablehnen</button>
                        <Link className='btn btn-default' style={{marginRight: '5px'}}
                              role='button' to={'/tickets/' + request.ticket.id} >
                            Ansehen
                        </Link>
                    </td>
                </tr>
            })
        }


        return (
            <div>
                <div className='panel panel-default'>
                    <div className='panel-heading'>
                        <div className='pull-right'>
                            <Button bsSize="xsmall" disabled={!!loading} onClick={() => this.fetchRequests()}><FontAwesomeIcon
                                icon='sync'/></Button>
                        </div>
                        <h4 className='panel-title'> Anfragen {loading}</h4>
                    </div>
                    <table className='table table-hover'>
                        <tbody>
                        {notifications}
                        </tbody>
                    </table>
                    <div className='panel-footer text-center'>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchRequestList}, dispatch)
    }
};

function mapStateToProps(state) {

    const {requests} = state;

    return {requests}
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);