import React, {Component} from 'react';
import {connect} from "react-redux";
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {Button} from 'react-bootstrap';

import Rest from "../../../../core/Rest";
import Spinner from '../../../Utilities/Spinner'
import Row from './Row';
import CreateModal from './CreateModal';

class Status extends Component {

    constructor(props) {
        super(props);

        this.state = {
            status: [],
            isFetching: false,
        };

        this.fetchStatus = this.fetchStatus.bind(this);
        this.onChange = this.onChange.bind(this);
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);


        this.handleShow = this.handleShow.bind(this);
    };


    handleShow() {
        this.modal.handleShow();
    }

    componentDidMount() {
        this.fetchStatus();
    }

    render() {

        let loading = null;
        if (this.state.isFetching) {
            loading = (<Spinner/>)
        }

        const rows = this.state.status.map((status, key) => {
            return <Row status={status} key={key} onDelete={this.delete} onChange={this.onChange} statusList={this.state.status}/>
        });

        return (
            <div>
                <div className='btn-bar'>
                    <Button
                        style={{marginRight: '5px'}}
                        onClick={this.handleShow}
                    >
                        <FontAwesomeIcon icon='plus'/> Hinzufügen
                    </Button>
                    <Button
                        bsStyle="success"
                        disabled={this.state.isFetching}
                        onClick={!this.state.isFetching ? this.save : null}
                    >
                        <FontAwesomeIcon icon='save'/> Speichern
                    </Button>
                </div>
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <div className='pull-right'>
                            <Button bsSize="xsmall" disabled={!!loading} onClick={this.fetchStatus}><FontAwesomeIcon
                                icon='sync'/></Button>
                        </div>
                        <h4 className="panel-title">Statusliste {loading}</h4>
                    </div>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Titel</th>
                            <th>Priorität</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </table>
                </div>
                <CreateModal  ref={(modal) => { this.modal = modal; }} afterSave={() => {this.fetchStatus()}} />
            </div>
        );
    }

    onChange(id, name, value) {
        let statusList = this.state.status.slice();

        for (let i in statusList) {

            let status = statusList[i];

            if (status.id === id) {

                status[name] = value;
                status.updated = true;

                statusList[i] = Object.assign({}, status);

                this.setState({status: statusList});
                break;
            }
        }
    }

    save() {

        let promises = [];
        this.setState({isFetching: true});


        const statusList = this.state.status.map((status) => {
            if (!status.updated) {
                return status;
            }

            const promise = Rest.fetch({
                endpoint: 'tickets/status/' + status.id,
                method: 'PATCH',
                body: {
                    title: status.title,
                    priority: parseInt(status.priority, 10)
                }
            });

            promises.push(promise);

            status.updated = false;

            return status;
        });


        Promise.all(promises).then(() => {
            this.setState({isFetching: false})
        }, ( err) => {
            console.log(err);
            this.fetchStatus();
        });

        this.setState({status: statusList});

    }

    delete(id, newId) {

        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'tickets/status/'+id,
            method: 'DELETE',
            body: {
                statusId: newId
            }
        }).then(
            response => {
                this.setState({isFetching: false})
            },
            error => {
                this.setState({isFetching: false})
            }
        ).then(() => {
            this.fetchStatus();
        });

    }


    fetchStatus() {

        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'tickets/status',
        }).then(
            response => {

                const statusObjects = response.response.map((status) => {
                    status.updated = false;

                    return status;
                });

                this.setState({status: statusObjects, isFetching: false})
            },
            error => {
                this.setState({isFetching: false})
            }
        )

    }


}

export default connect()(Status);
