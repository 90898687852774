const types ={
    device: [{
        'type': 'create_support_ticket',
        'name': 'Support Ticket'
    },
        {
        'type': 'create_support_ticket_with_background',
        'name': 'Support Ticket mit Hintergrund'
    }]
};

export function typeList(type) {
    if (types.hasOwnProperty(type)) {
        return types[type]
    }

    return [];
}