import {CALL_API} from '../middleware/api'

export const EXPORT_TYPES_REQUEST = 'EXPORT_TYPES_REQUEST';
export const EXPORT_TYPES_SUCCESS = 'EXPORT_TYPES_SUCCESS';
export const EXPORT_TYPES_FAILURE = 'EXPORT_TYPES_FAILURE';

export const EXPORT_FILE_TYPES_REQUEST = 'EXPORT_FILE_TYPES_REQUEST';
export const EXPORT_FILE_TYPES_SUCCESS = 'EXPORT_FILE_TYPES_SUCCESS';
export const EXPORT_FILE_TYPES_FAILURE = 'EXPORT_FILE_TYPES_FAILURE';


export function fetchExportTypeList() {

    return {
        [CALL_API]: {
            endpoint: 'data/export/types',
            method: 'GET',
            types: [EXPORT_TYPES_SUCCESS, EXPORT_TYPES_FAILURE, EXPORT_TYPES_REQUEST]
        }
    }
}

export function fetchExportFileTypeList() {

    return {
        [CALL_API]: {
            endpoint: 'data/export/file_types',
            method: 'GET',
            types: [EXPORT_FILE_TYPES_SUCCESS, EXPORT_FILE_TYPES_FAILURE, EXPORT_FILE_TYPES_REQUEST]
        }
    }
}

