import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormControl, Button,Modal} from 'react-bootstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

class Row extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);

        this.state = {showModal: false, statusId: undefined}
    };

    render() {
        const status = this.props.status;

        let statusList = '';
        if (this.props.statusList) {
            statusList = this.props.statusList.map((data, id) => {

                if(data.id === this.props.status.id){
                    return null;
                }

                return <option key={data.id} value={data.id}>{data.title}</option>
            });
        }

        return (
            <tr key={status.id}>
                <td>{status.id}</td>
                <td>
                    <FormControl
                        type="text"
                        value={status.title}
                        name='title'
                        onChange={this.handleChange}
                    />
                </td>
                <td>
                    <FormControl
                        type="text"
                        value={status.priority}
                        name='priority'
                        onChange={this.handleChange}
                    />
                </td>
                <td style={{width: '50px'}}>
                    <Button
                        bsStyle="danger"
                        onClick={this.handleShow}
                    >
                        <FontAwesomeIcon icon='trash'/> Löschen
                    </Button>
                    <Modal backdropClassName='bootstrap' className='bootstrap' show={this.state.showModal} onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Löschen</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='alert alert-warning'>
                            Alle Tickets werden in einen anderen Status verschoben.
                            </div>
                            <label>Neuer Status</label>
                            <select value={this.state.statusId} onChange={this.onSelectChange}
                                    className="form-control">
                                <option key={-1} value={undefined}>Bitte Wählen</option>
                                {statusList}
                            </select>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-default" onClick={this.handleClose}>Schließen
                            </button>
                            <Button
                                bsStyle="danger"
                                onClick={this.handleDelete}
                            >
                                <FontAwesomeIcon icon='trash'/> Löschen
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </td>
            </tr>

        );
    }


    handleClose() {
        this.setState({showModal: false});
    }

    handleShow() {
        this.setState({showModal: true});
    }

    handleDelete(event)
    {
        this.handleClose();
        this.props.onDelete(this.props.status.id, this.state.statusId);
    }

    handleChange(event)
    {
       this.props.onChange(this.props.status.id, event.target.name, event.target.value)
    }


    onSelectChange(event)
    {
        this.setState({statusId: event.target.value});
    }

}

export default connect()(Row);
