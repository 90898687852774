import {CALL_API} from '../middleware/api'
import {push} from 'connected-react-router';

export const USER_CONFIG_ownTicketTableConfig_REQUEST = 'USER_CONFIG_ownTicketTableConfig_REQUEST';
export const USER_CONFIG_ownTicketTableConfig_SUCCESS = 'USER_CONFIG_ownTicketTableConfig_SUCCESS';
export const USER_CONFIG_ownTicketTableConfig_FAILURE = 'USER_CONFIG_ownTicketTableConfig_FAILURE';

export const USER_CONFIG_defaultBookmark_REQUEST = 'USER_CONFIG_defaultBookmark_REQUEST';
export const USER_CONFIG_defaultBookmark_SUCCESS = 'USER_CONFIG_defaultBookmark_SUCCESS';
export const USER_CONFIG_defaultBookmark_FAILURE = 'USER_CONFIG_defaultBookmark_FAILURE';

export const CONFIG_mapConfig_REQUEST = 'CONFIG_mapConfig_REQUEST';
export const CONFIG_mapConfig_SUCCESS = 'CONFIG_mapConfig_SUCCESS';
export const CONFIG_mapConfig_FAILURE = 'CONFIG_mapConfig_FAILURE';

export const CONFIG_deviceConfig_REQUEST = 'CONFIG_deviceConfig_REQUEST';
export const CONFIG_deviceConfig_SUCCESS = 'CONFIG_deviceConfig_SUCCESS';
export const CONFIG_deviceConfig_FAILURE = 'CONFIG_deviceConfig_FAILURE';


export function fetchUserDefaultBookmark() {

    const user = JSON.parse(localStorage.getItem('user')) || null;

    if (!user) {
        return dispatch => {

            // We dispatch requestLogin to kickoff the call to the API
            dispatch(push('/login'));
        }
    }

    return {
        [CALL_API]: {
            endpoint: 'users/' + user.id + '/config/defaultBookmark',
            types: [USER_CONFIG_defaultBookmark_SUCCESS, USER_CONFIG_defaultBookmark_FAILURE, USER_CONFIG_defaultBookmark_REQUEST]
        }
    }
}

export function fetchUserOwnTicketTableConfig() {

    const user = JSON.parse(localStorage.getItem('user')) || null;

    if (!user) {
        return dispatch => {

            // We dispatch requestLogin to kickoff the call to the API
            dispatch(push('/login'));
        }
    }

    return {
        [CALL_API]: {
            endpoint: 'users/' + user.id + '/config/ownTicketTable',
            types: [USER_CONFIG_ownTicketTableConfig_SUCCESS, USER_CONFIG_ownTicketTableConfig_FAILURE, USER_CONFIG_ownTicketTableConfig_REQUEST]
        }
    }
}

export function fetchDeviceConfig() {

    return {
        [CALL_API]: {
            endpoint: 'config/device',
            types: [CONFIG_deviceConfig_SUCCESS, CONFIG_deviceConfig_FAILURE, CONFIG_deviceConfig_REQUEST]
        }
    }
}

export function fetchMapConfig() {

    return {
        [CALL_API]: {
            endpoint: 'config/map',
            types: [CONFIG_mapConfig_SUCCESS, CONFIG_mapConfig_FAILURE, CONFIG_mapConfig_REQUEST]
        }
    }
}
