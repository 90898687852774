import React, {Component} from 'react';
import {connect} from "react-redux";
import {Route, Switch} from "react-router";
import NoMatch from "../NoMatch/NoMatch";
import CreateDevice from "./New/CreateDevice";
import EditDevice from "./Edit/EditDevice";
import List from "./List/List";
import RedirectDevice from "./RedirectDevice";

class Devices extends Component {
    render() {
        const match = this.props.match;

        return (
            <Switch>
                <Route exact path={match.url} component={List} />
                <Route exact path={`${match.url}/new`} component={CreateDevice} />
                <Route exact path={`${match.url}/:deviceId/edit`} component={EditDevice} />
                <Route path={`${match.url}/:deviceId`} component={RedirectDevice} />
                <Route component={NoMatch}/>
            </Switch>

        );
    }
}

export default connect()(Devices);
