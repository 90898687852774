import React, {Component} from 'react';
import Redirect from "./Redirect";
import {Nav, NavItem} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap'
import {Route, Switch} from 'react-router'

import Data from './Data';
import List from './Locations/List';

class Address extends Component {

    render() {

        const NavLink = ({exact, to, eventKey, children}) =>
            <LinkContainer exact={exact} to={to} eventKey={eventKey}>
                <NavItem>{children}</NavItem>
            </LinkContainer>;

        return <div>
            <Nav bsStyle='pills' style={{marginBottom: '10px'}}>
                <NavLink to={this.props.match.url + '/data'}>Daten</NavLink>
                <NavLink to={this.props.match.url + '/locations'}>Standplätze</NavLink>
            </Nav>
            <Switch>
                <Route path={'/customer/:customerId/addresses/:addressId/data'} component={Data}/>
                <Route path={'/customer/:customerId/addresses/:addressId/locations'} component={List}/>
                <Route component={Redirect}/>
            </Switch>
        </div>

    }
}

export default Address;
