import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import {fetchStatus} from "../../actions/systemActions";
import Card from "../Utilities/Card";
import {LinearProgress, List, ListItem, ListItemText, Typography, withStyles} from "@material-ui/core";


const nameMapping = {
    'open_tickets': 'Offene Tickets',
    'customer': 'Kunden',
    'devices': 'Geräte',
    'user': 'Benutzer'
};

const useStyles = theme => ({});

class StatusPanel extends Component {


    constructor(props) {
        super(props);

        this.reload = this.reload.bind(this);

        this.reload();
    }

    render() {
        let rows = [];
        if (this.props.statusList) {

            rows = this.props.statusList.map((data, id) => {
                return Row(id, data)
            });
        }

        return <Card isFetching={this.props.isFetching} reload={this.reload} title='Status'
                     subtitle='Vertragslimits'>
            <List disablePadding={true}>
                {rows}
            </List>
        </Card>;
    }

    reload() {
        this.props.actions.fetchStatus();
    }

}

const Row = (id, data) => {


    let name = data.name;
    if (nameMapping.hasOwnProperty(name)) {
        name = nameMapping[name];
    }

    let max = data.max;
    let percent;
    let value = data.cur;
    if (max < 0) {
        max = '∞';
        percent = 100;
    } else {
        percent = (value / max * 100);
        if (percent > 100) {
            percent = 100;
        }
    }

    return <ListItem>
        <ListItemText primary={<React.Fragment>
            <Typography variant='subtitle1'>{name}</Typography>
            <LinearProgress variant="determinate" value={percent}/>
        </React.Fragment>} secondary={value + '/' + max}/>
    </ListItem>
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchStatus}, dispatch),
    }
};

function mapStateToProps(state) {

    const {status} = state;

    const {isFetching, statusList} = status;

    return {isFetching, statusList}
}

export default compose(
    withStyles(useStyles), connect(mapStateToProps, mapDispatchToProps))(StatusPanel);
