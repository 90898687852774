import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import {Route, Switch} from 'react-router';
import {I18nextProvider} from 'react-i18next';
import i18n from './i18n';
import {createMuiTheme} from '@material-ui/core/styles';
import './core/fontAwesome';

import {history, store} from './core';
import Login from "./components/Login/Login";
import Form from "./components/PasswordReset/Form";
import Menu from "./components/Support/Menu";
import LoggedIn from "./components/LoggedIn";
import {ThemeProvider} from "@material-ui/styles";
import {CssBaseline} from "@material-ui/core";

import 'react-datetime/css/react-datetime.css'

import './styles/index.scss';
import './styles/bootstrap.scss';
import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css'

function checkNotifications() {
    // Let's check if the browser supports notifications
    if (!("Notification" in window)) {
        console.log("This browser does not support desktop notification");
    } else if (Notification.permission !== 'denied' || Notification.permission === "default") {
        Notification.requestPermission();
    }


}

checkNotifications();

window.store = store;

i18n.changeLanguage('de');

const theme = createMuiTheme({
    palette: {
        common: {
            black: "#000000",
            white: "#FFFFFF"
        },
        background: {
            paper: "#FFFFFF",
            default: "#ECECEC"
            //default: "#f5f5f5"
        },
        primary: {
            light: "rgba(67, 153, 209, 1)",
            main: "rgba(59, 126, 169, 1)",
            dark: "rgba(0, 88, 116, 1)",
            contrastText: "#FFFFFF"
        },
        secondary: {
            light: "rgba(255, 186, 128, 1)",
            main: "rgba(255, 116, 0, 1)",
            dark: "#C51162",
            contrastText: "#FFFFFF"
        },
        error: {
            light: "rgba(245, 166, 35, 1)",
            main: "rgba(208, 2, 27, 1)",
            dark: "rgba(139, 87, 42, 1)",
            contrastText: "#FFFFFF"
        },
        success: {
            light: "#6fbf73",
            main: "#4CAF50",
            dark: "#357A38",
            contrastText: "#fff"
        },
        text: {
            primary: "rgba(0, 0, 0, 0.87)",
            secondary: "rgba(0, 0, 0, 0.54)",
            disabled: "rgba(0, 0, 0, 0.38)",
            hint: "rgba(0, 0, 0, 0.38)"
        }
    }
});

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <ConnectedRouter history={history}>
                    <Switch>
                        <Route path="/support" component={Menu}/>
                        <Route path="/reset_password/:token" component={Form}/>
                        <Route path="/login" component={Login}/>
                        <Route path="" component={LoggedIn}/>
                    </Switch>
                </ConnectedRouter>
            </ThemeProvider>
        </Provider>
    </I18nextProvider>,
    document.getElementById('root')
);

