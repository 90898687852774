import React, {Component} from 'react';
import {connect} from "react-redux";
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {Button} from 'react-bootstrap';

import Rest from "../../../../core/Rest";
import Spinner from '../../../Utilities/Spinner'
import Row from './Row';
import CreateModal from './CreateModal';

class List extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tasks: [],
            isFetching: false,
        };

        this.fetchTasks = this.fetchTasks.bind(this);
        this.onChange = this.onChange.bind(this);
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);


        this.handleShow = this.handleShow.bind(this);
    };


    handleShow() {
        this.modal.handleShow();
    }

    componentDidMount() {
        this.fetchTasks();
    }

    render() {

        let loading = null;
        if (this.state.isFetching) {
            loading = (<Spinner/>)
        }

        const rows = this.state.tasks.map((task, key) => {
            return <Row task={task} key={key} onDelete={this.delete} onChange={this.onChange}/>
        });

        return (
            <div>
                <div className='btn-bar'>
                    <Button
                        style={{marginRight: '5px'}}
                        onClick={this.handleShow}
                    >
                        <FontAwesomeIcon icon='plus'/> Hinzufügen
                    </Button>
                    <Button
                        bsStyle="success"
                        disabled={this.state.isFetching}
                        onClick={!this.state.isFetching ? this.save : null}
                    >
                        <FontAwesomeIcon icon='save'/> Speichern
                    </Button>
                </div>
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <div className='pull-right'>
                            <Button bsSize="xsmall" disabled={!!loading} onClick={this.fetchTasks}><FontAwesomeIcon
                                icon='sync'/></Button>
                        </div>
                        <h4 className="panel-title">Aufgaben {loading}</h4>
                    </div>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Titel</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </table>
                </div>
                <CreateModal  ref={(modal) => { this.modal = modal; }} afterSave={() => {this.fetchTasks()}} />
            </div>
        );
    }

    onChange(id, value) {
        let tasks = this.state.tasks.slice();

        for (let i in tasks) {

            let task = tasks[i];

            if (task.id === id) {

                task.title = value;
                task.updated = true;

                tasks[i] = Object.assign({}, task);

                this.setState({tasks: tasks});
                break;
            }
        }
    }

    save() {

        let promises = [];
        this.setState({isFetching: true});


        const tasks = this.state.tasks.map((task) => {
            if (!task.updated) {
                return task;
            }

            const promise = Rest.fetch({
                endpoint: 'tickets/tasks/' + task.id,
                method: 'PATCH',
                body: {
                    title: task.title
                }
            });

            promises.push(promise);

            task.updated = false;

            return task;
        });


        Promise.all(promises).then(() => {
            this.setState({isFetching: false})
        }, ( err) => {
            console.log(err);
            this.fetchTasks();
        });

        this.setState({tasks: tasks});

    }

    delete(id) {

        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'tickets/tasks/'+id,
            method: 'DELETE'
        }).then(
            response => {
                this.setState({isFetching: false})
            },
            error => {
                this.setState({isFetching: false})
            }
        ).then(() => {
            this.fetchTasks();
        });

    }


    fetchTasks() {

        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'tickets/tasks',
        }).then(
            response => {

                const taskObjects = response.response.map((task) => {
                    task.updated = false;

                    return task;
                });

                this.setState({tasks: taskObjects, isFetching: false})
            },
            error => {
                this.setState({isFetching: false})
            }
        )

    }


}

export default connect()(List);
