import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';


class TextEditor extends Component {

    render() {

        return (
            <ReactQuill style={this.props.style} value={this.props.value}
                        onChange={this.props.onChange} />
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({}, dispatch),
    }
};

function mapStateToProps(state) {

    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(TextEditor);
