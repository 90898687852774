import React, {Component} from 'react';
import {connect} from "react-redux";
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import _ from "lodash";
import {Modal} from 'react-bootstrap';
import {Responsive, WidthProvider} from 'react-grid-layout';
import DashboardItem from "./DashboardItem";
import Rest from "../../core/Rest";
import Spinner from "../Utilities/Spinner";
import {push} from "connected-react-router";
import moment from 'moment';
import DateTime from 'react-datetime'

const ResponsiveGridLayout = WidthProvider(Responsive);

class Dashboard extends Component {

    itemRefs = {};

    availableIntervals = {
        '1d': 'Tag',
        '1w': 'Woche',
        '1m': 'Monat',
        'q': 'Vierteljahr'
    };

    constructor(props) {
        super(props);

        this.state = {
            counter: -1,
            layout: [],
            items: [],
            from: moment().subtract('1', 'w'),
            to: moment(),
            dashboard: null,
            interval: '1d',
            settingsModal: false,
            rangeModal: false,
            edit: false
        };

        this.onLayoutChange = this.onLayoutChange.bind(this);
        this.addItem = this.addItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.fetchDashboard = this.fetchDashboard.bind(this);
        this.saveDashboard = this.saveDashboard.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.handleRangeShow = this.handleRangeShow.bind(this);
        this.handleRangeClose = this.handleRangeClose.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.deleteDashboard = this.deleteDashboard.bind(this);
        this.onCoreChange = this.onCoreChange.bind(this);
        this.onModelChange = this.onModelChange.bind(this);
        this.reloadStatistic = this.reloadStatistic.bind(this);
    }

    componentDidMount() {
        this.fetchDashboard();
    }

    handleClose() {
        this.setState({settingsModal: false});
    }

    handleShow() {
        this.setState({settingsModal: true});
    }

    handleRangeClose() {
        this.setState({rangeModal: false});
    }

    handleRangeShow() {
        this.setState({rangeModal: true});
    }

    saveDashboard() {
        this.setState({isFetching: true});

        const items = this.state.items.slice(0).map((i) => {
            const item = Object.assign({}, i);
            const o = this.state.layout.find((o) => {
                return i.id.toString() === o.i.toString();
            });

            item.x = o.x;
            item.y = o.y;
            item.w = o.w;
            item.h = o.h;

            delete item.id;

            return item;
        });

        const dashboard = this.state.dashboard;

        this.setState({edit: false, items: [], layout: [], dashboard: null});

        Rest.fetch({
            endpoint: 'statistic/dashboards/' + this.props.match.params.id,
            method: 'PATCH',
            body: {
                name: dashboard.name,
                items: items
            }
        }).then(
            (response) => {
                this.setState({edit: false}, () => {
                    this.fetchDashboard();
                })
            }, () => {
            }
        ).then(() => {
            this.setState({isFetching: false})
        })
    }

    removeItem(i) {

        this.setState({
            layout: _.reject(this.state.layout, {i: i}),
            items: _.reject(this.state.items, {id: parseInt(i, 10)})
        });
    }

    addItem() {
        const layout = this.state.layout;
        const items = this.state.items;

        layout.push({i: this.state.counter.toString(), x: 0, y: 0, w: 6, h: 3});
        items.push({id: this.state.counter.toString(), type: 'deviceCount', title: 'Statistik'});

        const counter = this.state.counter - 1;

        this.setState({layout: layout, counter: counter, items: items});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {


        if (prevState.interval !== this.state.interval) {
            this.reloadStatistic();
        }

        if (prevState.rangeModal && !this.state.rangeModal) {
            this.reloadStatistic();
        }

        if (prevState.edit !== this.state.edit) {
            const layout = this.state.layout;

            layout.forEach((o, i) => {

                layout[i].static = !this.state.edit;

                return o;
            });

            this.setState({layout: layout});
        }

    }

    onItemChange(item) {
        const items = this.state.items;

        items.forEach((oldItem, i) => {
            if (oldItem.id !== item.id) {
                return;
            }

            items[i] = item;
        });

        this.setState({items: items});
    }

    generateDOM() {
        return Object.values(this.state.layout).map((o) => {

            const item = this.state.items.find((i) => {
                return i.id.toString() === o.i;
            });

            if (!item) {
                return null;
            }
            return (<div key={o.i} data-grid={o} className='panel panel-default'>
                    <DashboardItem ref={(ref) => {
                        this.itemRefs[o.i] = ref
                    }} item={item} o={o} onChange={this.onItemChange} onDelete={() => this.removeItem(o.i)}
                                   interval={this.state.interval} from={this.state.from} to={this.state.to}/>
                </div>
            );
        }).filter((o) => {
            return !!o;
        });
    }

    fetchDashboard() {
        this.setState({isFetching: true});
        Rest.fetch({
            endpoint: 'statistic/dashboards/' + this.props.match.params.id
        }).then(
            (response) => {

                const dashboard = response.response;
                const items = dashboard.items;

                delete dashboard.items;

                const layout = items.map((item) => {
                    return {
                        i: item.id.toString(),
                        x: item.x,
                        y: item.y,
                        w: item.w,
                        h: item.h,
                        static: !this.state.edit
                    }
                });

                this.setState({dashboard: dashboard, layout: layout, items: items})
            }, () => {
            }
        ).then(() => {
            this.setState({isFetching: false})
        })
    }

    deleteDashboard() {
        this.setState({isDeleting: true});
        Rest.fetch({
            endpoint: 'statistic/dashboards/' + this.props.match.params.id,
            method: 'DELETE'
        }).then(
            (response) => {
                this.props.redirectToList();
            }, () => {
            }
        ).then(() => {
            this.setState({isDeleting: false})
        })
    }

    onModelChange(event) {
        const dashboard = this.state.dashboard;

        dashboard[event.target.name] = event.target.value;

        this.setState({dashboard: dashboard});
    }

    onCoreChange(event) {
        const state = Object.assign({}, this.state);

        state[event.target.name] = event.target.value;

        this.setState(state);
    }

    onLayoutChange(layout) {
        this.setState({layout: layout});
    }

    toggleEdit() {
        this.setState({edit: !this.state.edit})

    }

    cancelEdit() {
        this.setState({edit: false, items: [], layout: [], dashboard: null}, () => {
            this.fetchDashboard();
        })

    }

    reloadStatistic() {
        Object.values(this.itemRefs).filter((ref) => ref).forEach((item) => {
            item.fetchStatistic();
        })
    }

    render() {

        let bar = <div className='text-center'><h2>Dashboard wird geladen</h2><Spinner size={'3x'}/></div>;

        if (this.state.dashboard) {

            const intervalOptions = Object.keys(this.availableIntervals).map((key) => {
                return <option key={key} value={key}>{this.availableIntervals[key]}</option>
            });

            bar = <div className='btn-bar'>
                <div className='dashboard-heading'>{this.state.dashboard.name}</div>
                <button onClick={this.toggleEdit} className='btn btn-default'>
                    <FontAwesomeIcon icon='pencil-alt'/> Bearbeiten
                </button>
                <button onClick={this.reloadStatistic} className='btn btn-default' style={{marginLeft: '5px'}}>
                    <FontAwesomeIcon icon='sync'/> Neu Laden
                </button>
                <div className='pull-right form-inline'>
                    <div className='form-group' style={{marginRight: '5px'}}>
                        <label style={{marginRight: '5px'}}>Zeitraum</label>
                        <button className='btn btn-default' onClick={this.handleRangeShow}>
                            <FontAwesomeIcon
                                icon='pencil-alt'/> {this.state.from.format('DD/MM/YYYY HH:mm:ss')} bis {this.state.to.format('DD/MM/YYYY HH:mm:ss')}
                        </button>
                    </div>
                    <div className='form-group'>
                        <label style={{marginRight: '5px'}}>Anzeige Interval</label>
                        <select className='form-control' onChange={this.onCoreChange} value={this.state.interval} name='interval'>
                            {intervalOptions}
                        </select>
                    </div>
                </div>
                <Modal backdropClassName='bootstrap' className='bootstrap' show={this.state.rangeModal} onHide={this.handleRangeClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Zeitraum</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='form-group'>
                            <DateTime

                                id="fromDate"
                                value={this.state.from}
                                onChange={(date) => {
                                    this.setState({from: date})
                                }}
                                timeFormat={true}
                            />
                        </div>
                        <div className='form-group'>
                            <DateTime
                                id="toDate"
                                value={this.state.to}
                                onChange={(date) => {
                                    this.setState({to: date})
                                }}
                                timeFormat={true}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-default" onClick={this.handleRangeClose}>
                            Schließen
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>;
            if (this.state.edit) {
                bar = <div className='btn-bar'>
                    <button onClick={this.saveDashboard} className='btn btn-success' style={{marginRight: '5px'}}>
                        <FontAwesomeIcon icon='save'/> Speichern
                    </button>
                    <button onClick={this.addItem} className='btn btn-default' style={{marginRight: '5px'}}>
                        <FontAwesomeIcon icon='plus'/> Statistik hinzufügen
                    </button>
                    <button onClick={this.handleShow} className='btn btn-default' style={{marginRight: '5px'}}>
                        <FontAwesomeIcon icon='cog'/> Einstellungen
                    </button>
                    <button onClick={this.cancelEdit} className='btn btn-default'>
                        <FontAwesomeIcon icon='times'/> Abbrechen
                    </button>
                    <Modal backdropClassName='bootstrap' className='bootstrap' show={this.state.settingsModal} onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Einstellungen</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='form-group'>
                                <label>Name</label>
                                <input className='form-control' name='name' onChange={this.onModelChange}
                                       value={this.state.dashboard.name}/>
                            </div>
                            <button onClick={this.deleteDashboard} className='btn btn-block btn-danger'>
                                <FontAwesomeIcon icon='trash'/> Dashboard Löschen
                            </button>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-default" onClick={this.handleClose}>
                                Schließen
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            }
        }

        return (<div>
            {bar}
            <ResponsiveGridLayout
                draggableHandle=".dragMe"
                breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}
                className='layout'
                rowHeight={100}
                containerPadding={[0, 10]}
                onLayoutChange={this.onLayoutChange}
            >
                {this.generateDOM()}
            </ResponsiveGridLayout>
        </div>);
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        redirectToList: () => {
            dispatch(push('/statistic'))
        }
    }
};

export default connect(null, mapDispatchToProps)(Dashboard);
