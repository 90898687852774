import {
    NOTIFICATION_LIST_FAILURE, NOTIFICATION_LIST_REQUEST, NOTIFICATION_LIST_SUCCESS
} from '../actions/notificationActions'

export function notificationReducer(state = {
    isFetching: false,
}, action) {

    switch (action.type) {
        case NOTIFICATION_LIST_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case NOTIFICATION_LIST_FAILURE:
            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch(e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case NOTIFICATION_LIST_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                notificationList: action.response
            });
        default:
            return state
    }
}

