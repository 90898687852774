import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import history from '../../../core/history'

class Redirect extends Component {

    componentDidMount()
    {
        this.props.redirect(this.props.match);
    }


    render() {

        return <div />;
    }

}



const mapDispatchToProps = (dispatch) => {

    return {
        redirect: (match) => {
            history.replace(match.url+'/actions');
        },
        actions: bindActionCreators({}, dispatch)
    }
};

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Redirect);