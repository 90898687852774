import React, {Component} from 'react';
import {connect} from "react-redux";
import {Route, Switch} from "react-router"
import NoMatch from "../NoMatch/NoMatch";
import View from "./View";
import EditEvent from "./EditEvent";
import NewEvent from "./NewEvent";

class Router extends Component {

    componentDidMount() {
        if (!this.props.moduleList.calendar) {

        }
    }

    render() {
        const match = this.props.match;

        if (this.props.moduleList.calendar) {
            return (
                <Switch>
                    <Route path={match.url + '/new'} component={NewEvent}/>
                    <Route path={match.url + '/:eventId'} component={EditEvent}/>
                    <Route exact path={match.url} component={View}/>
                    <Route component={NoMatch}/>
                </Switch>

            );
        }

        return (
            <Switch>
                <Route component={NoMatch}/>
            </Switch>

        );
    }
}


function mapStateToProps(state) {

    const {modules} = state;
    const {moduleList} = modules;

    return {moduleList}
}

export default connect(mapStateToProps, null)(Router);
