import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Collapse, Modal, Button, ButtonGroup, ButtonToolbar, Popover} from 'react-bootstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import ReactResizeDetector from 'react-resize-detector';
import {store} from '../../../core';
import {fetchMapConfig} from "../../../actions/configActions";
import Map from '../../Utilities/Map/Map';
import Pagination from 'react-js-pagination';
import Spinner from '../../Utilities/Spinner'
import {Link} from 'react-router-dom'
import Rest from "../../../core/Rest";
import {push} from "connected-react-router";

class List extends Component {

    availableLimits = [
        1,
        10,
        20,
        50,
        100,
        200
    ];

    constructor(props) {
        super(props);

        this.state = {
            mapWidth: 0,
            mapModalWidth: 0,
            extendedSearch: false,
            mapModalOpen: false,
            customerSearchString: '',
            deviceSearchString: '',
            pagination: {
                page: 1,
                perPage: 50,
                total: 0
            },
            customerList: {
                results: [],
                isFetching: false
            },
        };

        this.toggleExtendedSearch = this.toggleExtendedSearch.bind(this);
        this.onSearchStringChange = this.onSearchStringChange.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.perPageHandler = this.perPageHandler.bind(this);
        this.fetchCustomer = this.fetchCustomer.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    componentDidMount() {
        this.fetchCustomer();
        this.props.actions.fetchMapConfig();
    }

    handleOpen() {
        this.setState({mapModalOpen: true});
    }

    handleClose() {
        this.setState({mapModalOpen: false});
    }

    handleResize() {
        const specs = this.refs.mapContainer.getBoundingClientRect();

        let modalWidth = 0;
        if (this.refs.mapModalContainer) {
            const specsModal = this.refs.mapModalContainer.getBoundingClientRect();
            modalWidth = specsModal.width;
        }

        this.setState({mapWidth: specs.width, mapModalWidth: modalWidth});
    }

    toggleExtendedSearch() {
        this.setState({extendedSearch: !this.state.extendedSearch});
    }

    onSearchStringChange(event) {
        const state = this.state;
        state[event.target.name] = event.target.value;
        this.setState(state);
    }

    perPageHandler(event) {
        let pagination = this.state.pagination;

        pagination.perPage = parseInt(event.target.value, 10);

        this.setState({pagination: pagination});

        this.fetchCustomer();
    }

    onSubmit(event) {
        event.preventDefault();
        this.fetchCustomer();
    }

    fetchCustomer() {

        const customerList = this.state.customerList;
        const pagination = this.state.pagination;

        customerList.isFetching = true;

        this.setState({customerList: customerList});

        let filter = {};
        if (this.state.customerSearchString) {
            filter.customerData = this.state.customerSearchString;
        }

        if (this.state.deviceSearchString) {
            filter.deviceData = this.state.deviceSearchString;
        }

        let parameter = {
            perPage: this.state.pagination.perPage,
            page: this.state.pagination.page,
            with: ['customerAddresses'],
            filter: filter
        };

        Rest.fetch({
            method: 'GET',
            endpoint: 'customers',
            parameter: parameter,
        }).then(
            response => {

                const result = response.response;

                pagination.perPage = result.itemsPerPage;
                pagination.total = result.total;
                pagination.page = result.page;
                customerList.results = result.results;

                this.setState({customerList: customerList, pagination: pagination});
            },
            error => {
            }
        ).then(() => {
            customerList.isFetching = false;
            this.setState({customerList: customerList});
        })
    }

    handlePageChange(pageNumber) {
        let pagination = this.state.pagination;
        pagination.page = pageNumber;
        this.setState({pagination: pagination});

        this.fetchCustomer();
    }

    render() {

        let mapConfig = {};

        if (this.props.mapConfig.config) {
            mapConfig.zoom = this.props.mapConfig.config.zoom ? this.props.mapConfig.config.zoom : 12;
            mapConfig.center = this.props.mapConfig.config.center ? [this.props.mapConfig.config.center.lat, this.props.mapConfig.config.center.lng] : [51.312711, 9.479746];
        }


        let loading = null;
        if (this.state.customerList.isFetching) {
            loading = (<Spinner/>)
        }

        let limits = '';
        if (this.availableLimits) {
            limits = this.availableLimits.map((data) => {
                return <option key={data} value={data}>{data}</option>
            })
        }

        const pageCount = Math.ceil(this.state.pagination.total / this.state.pagination.perPage);

        let smallMap = '';
        let mapModal = '';

        if (this.props.moduleList.map) {

            let marker = [];

            this.state.customerList.results.forEach((customer) => {
                const addresses = customer.addresses.filter((address) => {
                    return (address.lat && address.lng)

                }).map((address) => {


                    let add = '';
                    if (address.additional) {
                        add = <Fragment><br/>{address.additional}</Fragment>
                    }

                    return {
                        anchor: [address.lat, address.lng],
                        payload: {
                            address: address,
                            customer: customer,
                        },
                        popup: <Popover id="popover-trigger-click-root-close" title={customer.customerNo + ': ' + customer.customerName}>
                            <p>
                                {address.street}<br/>
                                {address.plz} {address.city}
                                {add}
                            </p>
                            <Link className='btn btn-default' role='button' to={'/customer/' + customer.id}>
                                Öffnen
                            </Link>
                        </Popover>
                    }

                });
                marker = marker.concat(addresses);
            });

            smallMap = <div className="panel panel-default">
                <div className="panel-heading clearfix">
                    <div className="pull-left">
                        <h4 className="panel-title">Karte</h4>
                    </div>
                    <div className="pull-right">
                        <Button bsSize="xsmall" onClick={this.handleOpen}>
                            <FontAwesomeIcon icon='arrows-alt'/>
                        </Button>
                    </div>
                </div>
                <div className="panel-body" style={{padding: '0px', height: '300px'}} ref='mapContainer'>
                    <ReactResizeDetector handleWidth handleHeight onResize={this.handleResize}/>
                    <Map
                        center={mapConfig.center}
                        zoom={mapConfig.zoom}
                        width={this.state.mapWidth}
                        height={300} marker={marker}/>
                </div>
            </div>;

            mapModal = <Modal backdropClassName='bootstrap' className='bootstrap' show={this.state.mapModalOpen} onHide={this.handleClose} onEntered={() => {
                this.handleResize()
            }} bsSize='large'>
                <Modal.Header closeButton>
                    <Modal.Title>Karte</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{padding: 0, height: '500px'}}>
                    <ReactResizeDetector handleWidth handleHeight onResize={this.handleResize}/>
                    <div ref='mapModalContainer'>
                        <Map center={mapConfig.center} zoom={mapConfig.zoom} width={this.state.mapModalWidth} height={500} marker={marker}/>
                    </div>
                </Modal.Body>
            </Modal>;
        }

        const content = this.state.customerList.results.map((customer) => {

            const primaryAddress = customer.addresses.find((value) => {
                return value.primary
            });

            let add = '';
            if (primaryAddress.additional) {
                add = <Fragment><br/>{primaryAddress.additional}</Fragment>
            }

            return <tr key={customer.id} onClick={() => {
                store.dispatch(push('/customer/' + customer.id))
            }}>
                <td>{customer.customerNo}</td>
                <td>{customer.customerName}</td>
                <td>
                    {primaryAddress.street}<br/>
                    {primaryAddress.plz} {primaryAddress.city}
                    {add}</td>
                <td>{customer.deviceCount ? customer.deviceCount : 0}</td>
            </tr>
        });

        return (
            <Fragment>
                <div className='row'>
                    <div className="col-md-3">
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <h4 className="panel-title">Suche</h4>
                            </div>
                            <div className="panel-body">
                                <form onSubmit={this.onSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="customerSearch">Kunden Daten</label>
                                        <input className="form-control" type="search" placeholder="Suche"
                                               name='customerSearchString' value={this.state.customerSearchString}
                                               onChange={this.onSearchStringChange}/>
                                    </div>
                                    <Collapse in={this.state.extendedSearch}>
                                        <div className="form-group">
                                            <label>Geräte Daten</label>
                                            <input className="form-control" placeholder="Suche" name='deviceSearchString'
                                                   value={this.state.deviceSearchString} onChange={this.onSearchStringChange}/>
                                        </div>
                                    </Collapse>
                                    <p>
                                        <button type="submit" value="search" className="btn btn-default"
                                                style={{marginRight: '5px'}} onClick={this.onSubmit}>Suchen
                                        </button>
                                        <Button bsStyle="primary" active={this.state.extendedSearch}
                                                onClick={this.toggleExtendedSearch}>
                                            Erweiterte Suche
                                        </Button>
                                    </p>
                                </form>
                            </div>
                        </div>
                        {smallMap}
                    </div>
                    <div className='col-md-9'>
                        <div className='btn-bar'>
                            <Link className='btn btn-default' role='button' to='/customer/new'>
                                <FontAwesomeIcon
                                    icon='plus'/> Neu Anlegen
                            </Link>
                        </div>
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <div className='pull-right'>
                                    <ButtonToolbar>
                                        <ButtonGroup bsSize="xsmall">
                                            <Button disabled={!!loading} onClick={this.fetchCustomer}>
                                                <FontAwesomeIcon icon='sync'/></Button>
                                        </ButtonGroup>
                                    </ButtonToolbar>
                                </div>
                                <div className="form-inline" style={{marginBottom: 0, marginRight: '5px', display: 'inline'}}>
                                    <select className="form-control form-inline" id="perPage" value={this.state.pagination.perPage}
                                            onChange={this.perPageHandler}>
                                        {limits}
                                    </select>
                                </div>
                                <span style={{marginRight: '5px'}}>{this.state.pagination.total} auf {pageCount} Seiten</span>
                                <span style={{marginBottom: 0, fontSize: '150%'}}>{loading}</span>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-hover">
                                    <thead>
                                    <tr>
                                        <th>KundenNr.</th>
                                        <th>Kunde</th>
                                        <th>Adresse</th>
                                        <th>Anz. Geräte</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {content}
                                    </tbody>
                                </table>
                            </div>
                            <div className='panel-footer text-center'>
                                <Pagination
                                    activePage={this.state.pagination.page}
                                    itemsCountPerPage={this.state.pagination.perPage}
                                    totalItemsCount={this.state.pagination.total}
                                    pageRangeDisplayed={5}
                                    onChange={this.handlePageChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {mapModal}
            </Fragment>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchMapConfig}, dispatch),
    }
};

function mapStateToProps(state) {

    const {config, modules} = state;
    const {mapConfig} = config;
    const {moduleList} = modules;

    return {mapConfig, moduleList}
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
