import React, {Component} from 'react';
import BigCalendarView from "../../../Utilities/Calendar/BigCalendarView";
import store from "../../../../core/store";
import {push} from "connected-react-router";
import {connect} from "react-redux";

class TicketEvents extends Component {

    componentDidMount() {
        if (!this.props.moduleList.calendar) {
            store.dispatch(push('/tickets/' +  this.props.match.params.ticketId))
        }
    }

    render() {

        const ticketId = this.props.match.params.ticketId;

        return <div style={{marginBottom: '20px'}}>
            <BigCalendarView
                linkType='ticket'
                linkId={ticketId}
                displayRequests={false}
                hideComponentEvents={true}
                height='600px'
                views={[
                    'WEEK',
                    'MONTH',
                    'AGENDA'
                ]}
            />
        </div>
    }

}

function mapStateToProps(state) {

    const {modules} = state;
    const {moduleList} = modules;

    return {moduleList}
}

export default connect(mapStateToProps, null)(TicketEvents);