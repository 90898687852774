import {
    GET_DEVICE_FAILURE, GET_DEVICE_REQUEST, GET_DEVICE_SUCCESS
} from '../actions/deviceActions'

export function getDeviceReducer(state = {
    isFetching: false,
}, action) {
    switch (action.type) {
        case GET_DEVICE_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case GET_DEVICE_FAILURE:

            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch(e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case GET_DEVICE_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                device: action.response
            });
        default:
            return state
    }
}

