import React, {Component} from 'react';
import {connect} from "react-redux";
import {Button} from 'react-bootstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Rest from "../../../../core/Rest";
import Spinner from "../../../Utilities/Spinner";

class Settings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {
                1: {active: false, name: '', type: 'text'},
                2: {active: false, name: '', type: 'text'},
                3: {active: false, name: '', type: 'text'},
                4: {active: false, name: '', type: 'text'}
            }
        };

        this.fetchConfig = this.fetchConfig.bind(this);
        this.saveConfig = this.saveConfig.bind(this);
        this.onCheckChange = this.onCheckChange.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
    }

    componentDidMount() {
        this.fetchConfig();
    }


    onCheckChange(id, event) {

        const state = this.state;

        let field = state.fields[id];

        field.active = event.target.checked;

        state.fields[id] = field;

        this.setState(state);
    }


    onValueChange(id, event) {

        const state = this.state;

        let field = Object.assign({}, state.fields[id]);

        field[event.target.name] = event.target.value;

        state.fields[id] = field;

        this.setState(state);
    }

    render() {

        let loading = null;
        if (this.state.isFetching) {
            loading = (<Spinner/>)
        }

        const fields = Object.keys(this.state.fields).map((fieldKey) => {
            const field = this.state.fields[fieldKey];
            return <tr key={fieldKey}>
                <td>
                    <input type='checkbox' checked={field.active} onChange={(event) => this.onCheckChange(fieldKey, event)}/>
                </td>
                <td>
                    <input name='name' type='text' value={field.name} className='form-control'
                           onChange={(event) => this.onValueChange(fieldKey, event)}/>
                </td>
                <td>
                    <select name='type' className='form-control' value={field.type}
                            onChange={(event) => this.onValueChange(fieldKey, event)}>
                        <option value='bool'>Checkbox</option>
                        <option value='text'>Textfeld</option>
                    </select>
                </td>
            </tr>
        });

        return (
            <div className="panel panel-default">
                <div className="panel-heading">
                    <div className='pull-right'>
                        <Button bsSize="xsmall" disabled={!!loading} onClick={this.fetchConfig}><FontAwesomeIcon
                            icon='sync'/></Button>
                    </div>
                    <h4 className="panel-title">Zusatz Felder {loading}</h4>
                </div>
                <div className="table-responsive">
                    <table className='table'>
                        <thead>
                        <tr>
                            <th>
                                Aktiviert
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                Typ
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {fields}
                        </tbody>
                    </table>
                </div>
                <div className='panel-footer'>
                    <button
                        type="submit"
                        className="btn btn-default"
                        disabled={this.state.isSaving || this.state.isFetching}
                        onClick={!this.state.isSaving ? this.saveConfig : null}
                    >
                        Speichern
                    </button>
                </div>
            </div>
        );
    }

    fetchConfig() {
        this.setState({isFetching: true});
        Rest.fetch({
            method: 'GET',
            endpoint: 'config/device',
        }).then(
            response => {
                const config = response.response.config;
                const fields = this.state.fields;

                Object.keys(config.fieldConfig).forEach((fieldKey) => {
                    if (config.fieldConfig[fieldKey]) {
                        const field = Object.assign({}, config.fieldConfig[fieldKey]);
                        field.active = true;
                        fields[fieldKey] = field
                    }
                });

                this.setState({fields: fields})
            },
            error => {
            }
        ).then(() => {
            this.setState({isFetching: false});
        });
    }

    saveConfig() {
        this.setState({isSaving: true});

        let fields = {};
        Object.keys(this.state.fields).forEach((fieldKey) => {
            const field = Object.assign({}, this.state.fields[fieldKey]);

            if (!field.active) {
                fields[fieldKey] = false;
                return;
            }
            delete field.active;

            if(!field.name || !field.name.length)
            {
                field.name = 'Feld ' + fieldKey;
            }

            fields[fieldKey] = field
        });

        Rest.fetch({
            method: 'PUT',
            endpoint: 'config/device',
            body: {
                fieldConfig: fields
            }
        }).then(response => {
        }, error => {
        }).then(() => {
            this.fetchConfig();
            this.setState({isSaving: false});
        });
    }
}

export default connect()(Settings);
