import {CALL_API} from '../middleware/api'

export const IMPORT_TYPES_REQUEST = 'IMPORT_TYPES_REQUEST';
export const IMPORT_TYPES_SUCCESS = 'IMPORT_TYPES_SUCCESS';
export const IMPORT_TYPES_FAILURE = 'IMPORT_TYPES_FAILURE';

export const IMPORT_FORMATS_REQUEST = 'IMPORT_FORMATS_REQUEST';
export const IMPORT_FORMATS_SUCCESS = 'IMPORT_FORMATS_SUCCESS';
export const IMPORT_FORMATS_FAILURE = 'IMPORT_FORMATS_FAILURE';


export function fetchImportTypeList() {

    return {
        [CALL_API]: {
            endpoint: 'data/import/types',
            method: 'GET',
            types: [IMPORT_TYPES_SUCCESS, IMPORT_TYPES_FAILURE, IMPORT_TYPES_REQUEST]
        }
    }
}

export function fetchImportFormatList() {

    return {
        [CALL_API]: {
            endpoint: 'data/import/formats',
            method: 'GET',
            types: [IMPORT_FORMATS_SUCCESS, IMPORT_FORMATS_FAILURE, IMPORT_FORMATS_REQUEST]
        }
    }
}

