import {
    TICKET_OWN_REQUEST, TICKET_OWN_FAILURE, TICKET_OWN_SUCCESS, TICKET_SUCCESS, TICKET_FAILURE, TICKET_REQUEST,
    SINGLE_TICKET_FAILURE, SINGLE_TICKET_REQUEST, SINGLE_TICKET_SUCCESS
} from '../actions/ticketActions'

export function ticketOwnReducer(state = {
    isFetching: false,
    response: null
}, action) {
    switch (action.type) {
        case TICKET_OWN_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case TICKET_OWN_FAILURE:

            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch(e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case TICKET_OWN_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                tickets: action.response
            });
        default:
            return state
    }
}

export function ticketReducer(state = {
    isFetching: false,
}, action) {
    switch (action.type) {
        case TICKET_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case TICKET_FAILURE:

            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch(e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case TICKET_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                tickets: action.response
            });
        default:
            return state
    }
}

export function singleTicketReducer(state = {
    isFetching: false,
}, action) {
    switch (action.type) {
        case SINGLE_TICKET_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                accessDenied: false
            });
        case SINGLE_TICKET_FAILURE:

            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch(e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
                accessDenied: action.code === 403
            });
        case SINGLE_TICKET_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                ticket: action.response,
                accessDenied: false
            });
        default:
            return state
    }
}
