import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Button} from 'react-bootstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Rest from "../../../core/Rest";

class SelectedDeviceRow extends Component {

    constructor(props) {
        super(props);

        this.state = {
            device: null,
            isFetching: false,
        };
    };

    componentDidMount() {
        this.fetchDevice()
    }

    render() {

        const result = this.state.device;

        if(!this.state.isFetching && !result)
        {
            return null;
        }

        if(this.state.isFetching)
        {
            return <tr>
                <td colSpan={6}>
                    Loading...
                </td>
            </tr>
        }


        return <tr key={result.id}>
            <td>{result.deviceNo}</td>
            <td>{result.brand}</td>
            <td>{result.model}</td>
            <td>{result.type.title}</td>
            <td>{result.location.title}</td>
            <td>
                <Button bsStyle="danger" value={result.id} onClick={this.props.onClick}><FontAwesomeIcon icon='minus'/> Entfernen</Button>
            </td>
        </tr>;
    }


    fetchDevice() {

        const id = this.props.value;

        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'devices/'+id,
        }).then(
            response => {
                this.setState({device: response.response, isFetching: false})
            },
            error => {
                this.setState({isFetching: false})
            }
        )
    }


}



const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({}, dispatch),
    }
};

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectedDeviceRow);