import React, {Component} from 'react';
import Redirect from "./Redirect";
import {Nav, NavItem} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap'
import {Route, Switch} from 'react-router'

import Data from './Data';
import Files from './Files';
import Components from './Components/List'
import Tickets from './Tickets'
import FontAwesomeIcon from "@fortawesome/react-fontawesome";

class Device extends Component {

    render() {

        const NavLink = ({exact, to, eventKey, children, style}) =>
            <LinkContainer exact={exact} to={to} style={style} eventKey={eventKey}>
                <NavItem>{children}</NavItem>
            </LinkContainer>;

        return <div>
            <Nav bsStyle='pills' style={{marginBottom: '10px'}}>
                <NavLink to={this.props.match.url + '/data'}>Daten</NavLink>
                <NavLink to={this.props.match.url + '/components'}>Komponenten</NavLink>
                <NavLink to={this.props.match.url + '/tickets'}>Tickets</NavLink>
                <NavLink to={this.props.match.url + '/files'}>Dateien</NavLink>
                <NavLink exact to={'/customer/' + this.props.match.params.customerId + '/devices'} style={{float: 'right'}}>
                    <FontAwesomeIcon icon='times'/> Schließen
                </NavLink>
            </Nav>
            <Switch>
                <Route path={'/customer/:customerId/devices/:deviceId/data'} component={Data}/>
                <Route path={'/customer/:customerId/devices/:deviceId/files'} component={Files}/>
                <Route path={'/customer/:customerId/devices/:deviceId/components'} component={Components}/>
                <Route path={'/customer/:customerId/devices/:deviceId/tickets'} component={Tickets}/>
                <Route component={Redirect}/>
            </Switch>
        </div>

    }
}

export default Device;
