import React, {Fragment} from 'react';
import store from './store';
import {push} from "connected-react-router";
import {getMessage} from "../service/validationMessageService";

const BASE_URL = '/api/';

export default class Rest {

    // Now you can write your own methods easily
    static fetch(options) {

        const {endpoint, method = 'GET', parameter = {}, header = {}, body, auth = true, signal = null, disableProgress = false} = options;

        const userRaw = localStorage.getItem('user') || null;
        const user = JSON.parse(userRaw);
        let config = {
            method: method
        };

        if (signal) {
            config.signal = signal;
        } else if(!disableProgress) {
            store.dispatch({
                type: 'ADD_REQUEST'
            });
        }

        if (auth && user && user.token) {
            header.Authorization = `Bearer ${user.token}`
        } else if (auth) {
            store.dispatch(push('/login'));
        }

        config.headers = header;

        if (body instanceof FormData) {
            config.body = body;
        } else if (typeof body !== 'undefined') {
            config.body = JSON.stringify(body);
            header['Content-Type'] = 'application/json';
        }

        let params = '';
        if (Object.keys(parameter).length) {
            for (const key in parameter) {
                if (typeof parameter[key] !== 'object') {
                    continue;
                }

                for (const objectKey in  parameter[key]) {
                    if (typeof parameter[key][objectKey] === 'undefined') {
                        continue;
                    }

                    parameter[key + '[' + objectKey + ']'] = parameter[key][objectKey];
                }

                delete parameter[key];
            }

            params = '?' + (new URLSearchParams(parameter));
        }

        return fetch(BASE_URL + endpoint + params, config)
            .then(response => {
                return response.json().then((text) => ({text, response}), (e) => {
                    store.dispatch({
                        type: 'ADD_ALERT',
                        message: e.message,
                        code: response.status,
                        style: 'danger'
                    });

                    if (!signal && !disableProgress) {
                        store.dispatch({
                            type: 'REMOVE_REQUEST'
                        });
                    }

                    return Promise.reject({
                        message: e.message,
                        code: response.status,
                        header: response.header
                    })
                })
            }).then(({text, response}) => {

                if (response.status === 401 && auth) {
                    store.dispatch(push('/login'));
                }

                if (!response.ok) {

                    let errorText = text;

                    if (typeof errorText === 'object' && typeof errorText.error !== 'undefined' && typeof errorText.error.message !== 'undefined') {
                        errorText = errorText.error.message;
                    } else if (typeof errorText === 'object' && typeof errorText.message !== 'undefined') {
                        errorText = errorText.message;
                    } else {
                        errorText = "Ein Fehler ist aufgetreten";
                    }

                    if (response.status === 403) {
                        errorText = 'Du hast keine Berechtigung für diese Aktion'
                    }

                    if (response.status === 400 && text.error && text.error.validation_errors) {

                        try {
                            const errors = getMessage(text.error.validation_errors);

                            errorText = <Fragment>
                                <b>Daten ungültig.</b>
                                <ul style={{paddingLeft: '1.5em', marginBottom: 0}}>
                                    {errors}
                                </ul>
                            </Fragment>
                        } catch (e) {
                            console.log(e);
                        }
                    }

                    store.dispatch({
                        type: 'ADD_ALERT',
                        message: errorText,
                        code: response.status,
                        style: 'danger'
                    });

                    store.dispatch({
                        type: 'REMOVE_REQUEST'
                    });

                    return Promise.reject({
                        message: errorText,
                        raw: text,
                        code: response.status,
                        header: response.header
                    })
                }

                if (!signal && !disableProgress) {
                    store.dispatch({
                        type: 'REMOVE_REQUEST'
                    });
                }

                return {
                    response: text,
                    header: response.header
                }
            })
    }


};