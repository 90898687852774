import {CALL_API} from '../middleware/api'

export const LIST_DEVICE_COMPONENTS_TYPES_REQUEST = 'LIST_DEVICE_COMPONENTS_TYPES_REQUEST';
export const LIST_DEVICE_COMPONENTS_TYPES_SUCCESS = 'LIST_DEVICE_COMPONENTS_TYPES_SUCCESS';
export const LIST_DEVICE_COMPONENTS_TYPES_FAILURE = 'LIST_DEVICE_COMPONENTS_TYPES_FAILURE';

export function fetchComponentTypes() {

    return {
        [CALL_API]: {
            endpoint: 'devices/components/types',
            method: 'GET',
            types: [LIST_DEVICE_COMPONENTS_TYPES_SUCCESS, LIST_DEVICE_COMPONENTS_TYPES_FAILURE, LIST_DEVICE_COMPONENTS_TYPES_REQUEST]
        }
    }
}

export const LIST_DEVICE_COMPONENTS_REQUEST = 'LIST_DEVICE_COMPONENTS_REQUEST';
export const LIST_DEVICE_COMPONENTS_SUCCESS = 'LIST_DEVICE_COMPONENTS_SUCCESS';
export const LIST_DEVICE_COMPONENTS_FAILURE = 'LIST_DEVICE_COMPONENTS_FAILURE';

export function fetchDeviceComponents(id) {

    return {
        [CALL_API]: {
            endpoint: 'devices/' + id + '/components',
            method: 'GET',
            identifier: id,
            types: [LIST_DEVICE_COMPONENTS_SUCCESS, LIST_DEVICE_COMPONENTS_FAILURE, LIST_DEVICE_COMPONENTS_REQUEST]
        }
    }
}

export const LIST_COMPONENTS_REQUEST = 'LIST_COMPONENTS_REQUEST';
export const LIST_COMPONENTS_SUCCESS = 'LIST_COMPONENTS_SUCCESS';
export const LIST_COMPONENTS_FAILURE = 'LIST_COMPONENTS_FAILURE';

export function fetchComponents(filter = [], page, perPage) {

    return {
        [CALL_API]: {
            endpoint: 'devices/components',
            method: 'GET',
            parameter: {
                filter: filter
            },
            types: [LIST_COMPONENTS_SUCCESS, LIST_COMPONENTS_FAILURE, LIST_COMPONENTS_REQUEST]
        }
    }
}


