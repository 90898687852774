import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {fetchComponentTypes} from "../../../../../actions/deviceComponentActions";
import moment from 'moment';
import {Button, Modal} from 'react-bootstrap';
import hash from 'object-hash';
import DateTime from "react-datetime";
import Rest from "../../../../../core/Rest";

class ComponentItem extends Component {

    constructor(props) {
        super(props);

        this.state = {
            editing: false,
            componentNo: '',
            type: '0',
            description: '',
            expires: false,
            expireTime: '',
            expireDescription: '',
            isSaving: false,
            showDeleteModal: false,
            disabled: false
        };

        this.renderView = this.renderView.bind(this);
        this.renderEdit = this.renderEdit.bind(this);
        this.toggleEditing = this.toggleEditing.bind(this);
        this.onModelDateChange = this.onModelDateChange.bind(this);
        this.onModelBoolChange = this.onModelBoolChange.bind(this);
        this.onModelChange = this.onModelChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }


    componentDidMount() {
        this.props.actions.fetchComponentTypes();

        if (this.props.component) {
            this.optimizeComponent(this.props.component)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (
            hash(prevProps.component) !== hash(this.props.component)
            && this.props.component
        ) {
            this.optimizeComponent(this.props.component)
        }
    }

    optimizeComponent(component) {
        this.setState({
            componentNo: component.componentNo,
            type: component.type.id,
            description: component.description,
            expires: component.expires,
            expireTime: moment(component.expireTime),
            expireDescription: component.expireDescription,
        });
    }

    onModelDateChange(name, value) {
        const state = this.state;
        state[name] = value;
        this.setState(state)
    }

    onModelChange(event) {
        const state = this.state;
        state[event.target.name] = event.target.value;
        this.setState(state)
    }

    onModelBoolChange(event) {
        const state = this.state;
        state[event.target.name] = event.target.checked;
        this.setState(state)
    }

    toggleEditing() {
        this.setState({editing: !this.state.editing});
    }

    onSave() {
        this.setState({isSaving: true});

        let date = null;
        if (moment.isMoment(this.state.expireTime)) {
            date = this.state.expireTime.format('YYYY-MM-DD')
        }

        const body = {
            componentNo: this.state.componentNo,
            type: this.state.type,
            description: this.state.description,
            expires: this.state.expires,
        };

        if (body.expires) {
            body.expireTime = date;
            body.expireDescription = this.state.expireDescription;
        }

        Rest.fetch({
            method: 'PATCH',
            endpoint: 'devices/components/' + this.props.component.id,
            body: body
        }).then(
            (success) => {
                if (this.props.afterSave instanceof Function) {
                    this.props.afterSave(success);
                }

                this.setState({editing: false});
            },
            () => {
            }
        ).then(() => {
            this.setState({isSaving: false});
        })
    }

    onDelete() {
        this.setState({isSaving: true});


        Rest.fetch({
            method: 'DELETE',
            endpoint: 'devices/components/' + this.props.component.id,
        }).then(
            (success) => {
                if (this.props.afterSave instanceof Function) {
                    this.props.afterSave(success);
                }
                this.setState({editing: false, disabled: true});
            },
            () => {
            }
        ).then(() => {
            this.setState({isSaving: false});
        })
    }

    handleClose() {
        this.setState({showDeleteModal: false});
    }

    handleShow() {
        this.setState({showDeleteModal: true});
    }

    render() {
        if (this.state.editing) {
            return this.renderEdit();
        }

        return this.renderView()
    }

    renderEdit() {
        const component = this.props.component;

        let types = '';
        if (this.props.componentTypeList.types) {
            types = this.props.componentTypeList.types.map((type) => {
                return <option key={type.id} value={type.id}>{type.title}</option>
            });
        }

        return <Fragment>
            <tr key={component.id}>
                <td>
                    <input value={this.state.componentNo} className='form-control' name='componentNo' onChange={this.onModelChange}/>
                </td>
                <td>
                    <select className='form-control' disabled={this.props.componentTypeList.isFetching} onChange={this.onModelChange}
                            value={this.state.type} name='type'>
                        <option value={0}>Bitte Wählen</option>
                        {types}
                    </select>
                </td>
                <td>
                    <input value={this.state.description} className='form-control' name='description' onChange={this.onModelChange}/>
                </td>
                <td>
                    <div className='form-group'>
                        <input type='checkbox' checked={this.state.expires} name='expires' onChange={this.onModelBoolChange}/>
                        &nbsp;
                        <label>Zeitstempel</label>
                    </div>
                    <div className='form-group'>
                        <label>Datum</label>
                        <DateTime
                            inputProps={{
                                placeholder: 'Datum',
                                disabled: !this.state.expires
                            }}
                            value={this.state.expireTime}
                            onChange={(moment) => this.onModelDateChange('expireTime', moment)}
                            timeFormat={false}
                            closeOnSelect={true}
                        />
                    </div>
                    <div className='form-group'>
                        <label>Zeitstempel Beschreibung</label>
                        <input disabled={!this.state.expires} className='form-control' placeholder='Überprüfung, Wechsel,...'
                               name='expireDescription' onChange={this.onModelChange}
                               value={this.state.expireDescription}/>
                    </div>
                </td>
                <td style={{textAlign: 'center'}}>
                    <button disabled={this.state.isSaving} style={{marginBottom: '5px'}} className='btn btn-primary btn-block'
                            onClick={this.onSave}>
                        <FontAwesomeIcon icon='save'/> Speichern
                    </button>
                    <button disabled={this.state.isSaving} style={{marginBottom: '5px'}} className='btn btn-danger btn-block'
                            onClick={this.handleShow}>
                        <FontAwesomeIcon icon='trash'/> Löschen
                    </button>
                    <button disabled={this.state.isSaving} className='btn btn-default btn-block' onClick={this.toggleEditing}>
                        <FontAwesomeIcon icon='pencil-alt'/> Abbrechen
                    </button>
                </td>
            </tr>
            <Modal backdropClassName='bootstrap' className='bootstrap' show={this.state.showDeleteModal} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Löschen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Wirklich Löschen?
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-default" onClick={this.handleClose}
                            disabled={this.state.isSaving}>Schließen
                    </button>
                    <Button
                        bsStyle="danger"
                        onClick={this.onDelete}
                        disabled={this.state.isSaving}
                    >
                        <FontAwesomeIcon icon='trash'/> Löschen
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>;
    }

    renderView() {
        const component = this.props.component;

        let time = 'Nicht gesetzt';
        if (component.expires) {
            const timeObj = moment(component.expireTime);
            let diff = timeObj.diff(moment(), 'days');

            if (diff < 0) {
                diff = 0;
            }

            time = <Fragment>
                {component.expireDescription} in {diff} Tagen ({timeObj.format('DD-MM-YYYY')})
            </Fragment>;
        }

        let style = {
        };
        if (this.state.disabled) {
            style.opacity = 0.5;
            style.pointerEvents = 'none';
        }

        return <tr key={component.id} style={style}>
            <td>{component.componentNo}</td>
            <td>{component.type.title}</td>
            <td>{component.description}</td>
            <td>
                <div className='well well-sm' style={{margin: '0'}}>
                    {time}
                </div>
            </td>
            <td style={{textAlign: 'center'}}>
                <button className='btn btn-default btn-block' onClick={this.toggleEditing}>
                    <FontAwesomeIcon icon='pencil-alt'/> Bearbeiten
                </button>

            </td>
        </tr>
    }

}

const mapDispatchToProps = (dispatch) => {

    return {
        actions: bindActionCreators({fetchComponentTypes}, dispatch)
    }
};

function mapStateToProps(state) {
    const {componentTypeList} = state;

    return {componentTypeList};
}

export default connect(mapStateToProps, mapDispatchToProps)(ComponentItem);