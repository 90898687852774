import React, {Component} from 'react';
import {PieChart} from 'react-chartkick'
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {fetchUserList} from "../../../actions/userActions";

class ArrangerProcessedTicketsTotal extends Component {

    componentDidMount() {
        this.props.actions.fetchUserList();
    }

    render() {

        const {width, height, data} = this.props;

        const chartData = {};
        if (this.props.userList.userList) {
            this.props.userList.userList.forEach((user) => {
                chartData[user.firstname + ' ' + user.lastname] = data[user.id] ? data[user.id] : 0;
            })
        }

        chartData['Andere'] = data.other?data.other:0;

        return <PieChart
            download={true}
            legend={true}
            data={chartData}
            width={width}
            height={height}
        />
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        actions: bindActionCreators({fetchUserList}, dispatch)
    }
};

function mapStateToProps(state) {

    const {userList} = state;
    return {userList}
}

export default connect(mapStateToProps, mapDispatchToProps)(ArrangerProcessedTicketsTotal);
