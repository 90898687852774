import React, {Component} from 'react';
import FileBrowser from "../Utilities/FileSelector/FileBrowser";

class Webspace extends Component {

    render() {
        return <FileBrowser type={'webspace'}/>;
    }

}

export default Webspace;