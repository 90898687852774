import React, {Component} from 'react';
import {Modal, Button, FormControl} from 'react-bootstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Rest from "../../../../core/Rest";

class CreateModal extends Component {

    constructor(props) {
        super(props);

        this.setTitle = this.setTitle.bind(this);
        this.setPriority = this.setPriority.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.save = this.save.bind(this);

        this.state = {showModal: false, isFetching: false, title: '', priority: ''}

    }

    handleClose() {
        this.setState({showModal: false});
    }

    handleShow() {
        this.setState({showModal: true});
    }

    setTitle(event) {
        this.setState({title: event.target.value});
    }

    setPriority(event) {
        this.setState({priority: event.target.value});
    }


    render() {

        return (
            <Modal backdropClassName='bootstrap' className='bootstrap' show={this.state.showModal} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Anlegen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='form-group'>
                        <label>Titel</label>
                        <FormControl
                            type="text"
                            value={this.state.title}
                            onChange={this.setTitle}
                            placeholder="Titel"
                        />
                    </div>
                    <div className='form-group'>
                        <label>Priorität</label>
                        <FormControl
                            type="text"
                            value={this.state.priority}
                            onChange={this.setPriority}
                            placeholder="Priorität"
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-default" onClick={this.handleClose}>Schließen
                    </button>
                    <Button
                        bsStyle="success"
                        disabled={this.state.isFetching}
                        onClick={!this.state.isFetching ? this.save : null}
                    >
                        <FontAwesomeIcon icon='save'/> Speichern
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    save() {

        this.setState({isFetching: true});

        Rest.fetch({
            method: 'POST',
            endpoint: 'tickets/status',
            body: {
                title: this.state.title,
                priority: parseInt(this.state.priority,10)
            }
        }).then(
            response => {
                this.setState({title: '', priority: '', isFetching: false, showModal: false});
                this.props.afterSave();
            },
            error => {
                this.setState({isFetching: false})
            }
        )
    }

}

export default CreateModal;
