import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import PhoneIcon from '@material-ui/icons/Phone'
import PersonIcon from '@material-ui/icons/Person'
import MailIcon from '@material-ui/icons/Mail'
import {toggleHelpPanel} from "../../actions/helpPanelActions";
import Rest from "../../core/Rest";
import store from "../../core/store";
import html2canvas from 'html2canvas';
import {
    Button,
    CircularProgress,
    Drawer,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
    Typography,
    withStyles
} from "@material-ui/core";

const useStyles = theme => (
    {
        buttonLoading: {
            marginLeft: theme.spacing(1),
            color: theme.palette.primary.contrastText
        },
        sendButton: {
            marginTop: theme.spacing(2)
        },
        drawerPaperGrid: {
            padding: theme.spacing(2)
        }
    }
)

class Sitebar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errorFields: [],
            title: '',
            message: '',
            isSending: false
        };

        this.closePanel = this.closePanel.bind(this);
        this.onModelChange = this.onModelChange.bind(this);
        this.sendHelp = this.sendHelp.bind(this);
    }

    onModelChange(event) {
        const state = this.state;
        state[event.target.name] = event.target.value;

        this.setState(state);
    }

    sendHelp(event) {
        event.preventDefault();

        const errorFields = [];
        if (!this.state.message.length) {
            errorFields.push('message');
        }
        if (!this.state.title.length) {
            errorFields.push('title');
        }
        this.setState({errorFields})

        this.closePanel();

        html2canvas(document.body.getElementsByClassName('main')[0]).then((canvas) => {
            this.setState({isSending: true});
            Rest.fetch({
                endpoint: 'help/support_ticket',
                method: 'POST',
                body: {
                    message: this.state.message,
                    title: this.state.title,
                    url: window.location.pathname,
                    screenshot: canvas.toDataURL()
                }
            }).then(
                response => {
                    this.setState({
                        title: '',
                        message: ''
                    });

                    store.dispatch({
                        type: 'ADD_ALERT',
                        message: 'Anfrage wurde versendet.',
                        style: 'success'
                    });
                },
                error => {
                }
            ).then(() => {
                this.setState({isSending: false});
            })
        });
    }

    render() {

        const classes = this.props.classes;
        const open = this.props.isOpen;
        return <Drawer
            onClose={this.closePanel}
            anchor="bottom"
            open={open}
        >
            <Grid container spacing={0} alignItems='center' alignContent='center'>
                <Grid item sm={12} md={5} className={classes.drawerPaperGrid}>
                    <p>
                        <Typography variant='h5'>Kontaktformular</Typography>
                        <form noValidate>
                            <TextField
                                fullWidth
                                label="Titel"
                                margin="dense"
                                error={this.state.errorFields.indexOf('title') !== -1}
                                value={this.state.title} name='title'
                                onChange={this.onModelChange}
                                variant="outlined"
                            />
                            <TextField
                                fullWidth
                                error={this.state.errorFields.indexOf('message') !== -1}
                                label="Nachricht"
                                margin="dense"
                                variant="outlined"
                                multiline
                                rowsMax={12}
                                rows={2}
                                value={this.state.message}
                                name='message'
                                onChange={this.onModelChange}
                            />
                            <Button

                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={this.sendHelp}
                                disabled={this.state.isSending}
                                className={classes.sendButton}
                            >
                                Senden {this.state.isSending ? <CircularProgress className={classes.buttonLoading} size={24}/> : ''}
                            </Button>
                        </form>
                    </p>
                </Grid>
                <Grid item sm={12} md={5} className={classes.drawerPaperGrid}>
                    <List>
                        <ListItem>
                            <ListItemIcon><PersonIcon/></ListItemIcon>
                            <ListItemText
                                primary="Dominik Malsch"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><MailIcon/></ListItemIcon>
                            <ListItemText
                                primary="support@vecodesk.com"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><PhoneIcon/></ListItemIcon>
                            <ListItemText
                                primary="0561-47395107"
                            />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item sm={12} md={2} className={classes.drawerPaperGrid}>
                    <Button
                        fullWidth
                        href='https://vecodesk.freshdesk.com'
                        target='_blank' role='button' rel='noopener noreferrer'
                        variant="outlined"
                        color="default"
                    >
                        Knowledge
                    </Button>
                </Grid>
            </Grid>
        </Drawer>
    }

    closePanel() {
        this.props.actions.toggleHelpPanel(false);
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({toggleHelpPanel}, dispatch),
    }
};

function mapStateToProps(state) {

    const {helpPanel} = state;
    const {isOpen} = helpPanel;

    return {isOpen}
}

export default compose(
    withStyles(useStyles),
    connect(mapStateToProps, mapDispatchToProps))(Sitebar);
