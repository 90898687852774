import {
    HELP_OPEN, HELP_CLOSE
} from '../actions/helpPanelActions'


function helpPanelReducer(state = [], action) {
    switch (action.type) {

        case HELP_OPEN:
            return {
                isOpen: true
            };

        case HELP_CLOSE:
            return {
                isOpen: false
            };
        default:
            return state;
    }
}

export default helpPanelReducer