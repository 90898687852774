import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {bindActionCreators, compose} from 'redux';
import {fetchUser} from "../../actions/userActions";
import {toggleHelpPanel} from "../../actions/helpPanelActions";
import {push} from "connected-react-router";
import {history, store} from '../../core';
import logo from '../../images/logo_s.png';
import Rest from "../../core/Rest";
import Notifier from "react-desktop-notification"
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';
import DevicesIcon from '@material-ui/icons/Devices';
import PeopleIcon from '@material-ui/icons/People';
import StorageIcon from '@material-ui/icons/Storage';
import CommentIcon from '@material-ui/icons/Comment';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import AccountCircle from "@material-ui/icons/AccountCircle";
import NotificationIcon from "@material-ui/icons/Notifications";
import MailIcon from "@material-ui/icons/Mail";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import HistoryIcon from "@material-ui/icons/History";
import HelpIcon from '@material-ui/icons/ContactSupport';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SearchIcon from "@material-ui/icons/Search";
import PersonIcon from "@material-ui/icons/Person";
import {
    AppBar,
    Chip,
    Badge,
    LinearProgress,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Divider,
    Drawer,
    Grid,
    Hidden,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Toolbar,
    withStyles
} from "@material-ui/core";
import clsx from "clsx";
import {Link as RouterLink} from "react-router-dom";
import InputBase from "@material-ui/core/InputBase";
import {fade} from "@material-ui/core/styles";

const AbortController = window.AbortController;

const drawerWidth = 240;

const BorderLinearProgress = withStyles({
    root: {
        height: 3,
    }
})(LinearProgress);

const useStyles = theme => ({
    logo: {
        maxHeight: '32px'
    },
    list: {
        marginTop: theme.spacing(2)
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    grow: {
        flexGrow: 1,
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    navNotifications: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    form: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    drawerToolbar: {
        textAlign: 'center',
        color: theme.palette.text.secondary
    }
});

const ListItemLink = (props) => {
    const {icon, primary, to, external, exact} = props;
    const pathname = history.location.pathname;

    const renderLink = React.useMemo(
        () =>
            React.forwardRef((itemProps, ref) => {

                if (external) {
                    // eslint-disable-next-line
                    return <a href={to} target='_blank' ref={ref} rel="noopener noreferrer" />
                }

                return <RouterLink to={to} {...itemProps} innerRef={ref}/>
            }),
        [to, external],
    );

    let selected = false;
    if (exact && to === pathname) {
        selected = true;
    } else if (!exact && pathname.indexOf(to) === 0) {
        selected = true;
    }

    return (
        <ListItem button component={renderLink} selected={selected} key={to}>
            {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
            <ListItemText primary={primary}/>
        </ListItem>
    );
};

const MenuItemLink = (props) => {
    const {to, key, children} = props;

    const renderLink = React.useMemo(
        () =>
            React.forwardRef((itemProps, ref) => {
                return <RouterLink to={to} {...itemProps} innerRef={ref}/>
            }),
        [to],
    );

    return (
        <MenuItem button component={renderLink} key={key}>
            {children}
        </MenuItem>
    );
};

const ButtonItemLink = (props) => {
    const {to, color, children} = props;

    const renderLink = React.useMemo(
        () =>
            React.forwardRef((itemProps, ref) => {
                return <RouterLink to={to} {...itemProps} innerRef={ref}/>
            }),
        [to],
    );

    return (
        <IconButton color={color} component={renderLink}>
            {children}
        </IconButton>
    );
};

class Navigation extends Component {

    intervalId;

    abortController;

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            searchString: '',
            showHistoryModal: false,
            userDropdown: false,
            notifications: 0,
            requests: 0,
            suggestions: [],
            numberSuggestions: [],
            drawerOpen: false
        };

        this.openHelpPanel = this.openHelpPanel.bind(this);
        this.hideHistoryModal = this.hideHistoryModal.bind(this);
        this.showHistoryModal = this.showHistoryModal.bind(this);
        this.toggleUserDropDown = this.toggleUserDropDown.bind(this);
        this.closeUserDropDown = this.closeUserDropDown.bind(this);
        this.onSearchStringChange = this.onSearchStringChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }

    handleDrawerOpen = () => {
        this.setState({drawerOpen: true});
    };

    handleDrawerClose = () => {
        this.setState({drawerOpen: false});
    };

    hideHistoryModal() {
        this.setState({showHistoryModal: false})
    }

    showHistoryModal() {
        this.setState({showHistoryModal: true})
    }

    componentDidMount() {

        this.props.actions.fetchUser();
        this.keepAlive(true);
        this.intervalId = setInterval(() => {
            this.keepAlive(false);
        }, 30000)
    }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId)
        }
    }

    openHelpPanel() {
        this.props.actions.toggleHelpPanel(!this.props.helpPanel.isOpen)
    }

    toggleUserDropDown() {
        this.setState({userDropdown: !this.state.userDropdown});
    }

    closeUserDropDown() {
        this.setState({userDropdown: false});
    }

    onSearchStringChange(event) {
        const value = event.target.value;
        this.setState({searchString: value}, () => {
            if (value.length >= 2) {
                this.suggestions();
            }
        });
    }

    onSubmit(event) {
        event.preventDefault();

        this.onSearch()
    }

    keepAlive(initial) {
        Rest.fetch({
            method: 'GET',
            endpoint: 'keep_alive',
            disableProgress: true
        }).then((response) => {

            const notifications = parseInt(response.response.notifications, 10);
            const requests = parseInt(response.response.requests, 10);
            const eventRequests = parseInt(response.response.eventRequests, 10);

            if (!initial && notifications > this.state.notifications) {
                Notifier.start("Neue Benachrichtigung", "Du hast eine neue Benachrichtigung", "/notifications", "https://cdn.vecodesk.com/storage/master/logo_icon.png");
            }

            if (!initial && requests > this.state.requests) {
                Notifier.start("Neue Ticketanfrage", "Du hast eine neue Ticketanfrage", "/requests", "https://cdn.vecodesk.com/storage/master/logo_icon.png");
            }

            if (!initial && eventRequests > this.state.eventRequests) {
                Notifier.start("Neue Terminanfrage", "Du hast eine neue Terminanfrage", "/calendar", "https://cdn.vecodesk.com/storage/master/logo_icon.png");
            }

            this.setState({
                notifications: notifications,
                requests: requests,
                eventRequests: eventRequests
            })
        });
    }

    onSearch() {
        store.dispatch(push('/search?term=' + this.state.searchString))
    }

    fetchSuggestions = (term) => {
        if (this.abortController) {
            this.abortController.abort();
        }

        this.abortController = new AbortController();

        Rest.fetch({
            signal: this.abortController.signal,
            endpoint: 'suggest_search',
            method: 'GET',
            parameter: {
                query: term
            }
        }).then(
            response => {
                const result = response.response;

                this.setState({
                    suggestions: result.suggestions,
                    numberSuggestions: result.numbers,
                });

            },
            error => {
            }
        );
    };

    suggestions() {
        this.fetchSuggestions(this.state.searchString);
    }

    searchSuggestionTerm = (term) => {
        this.setState({searchString: term}, () => {
            this.onSearch();
        })
    };

    handleMenuClose = () => {
        this.setState({
            anchorEl: null
        })
    };

    handleMenuOpen = (event) => {
        this.setState({
            anchorEl: event.currentTarget
        })
    };

    render() {
        const classes = this.props.classes;
        const open = this.state.drawerOpen;

        let permissions = this.props.permissions ? this.props.permissions : [];

        let user = null;
        if (this.props.userObject) {
            user = this.props.userObject;
        }

        let historyList = this.props.locationHistory.slice(0, 20).map((historyEntry, key) => {

            const location = historyEntry.location;

            const link = location.pathname + location.search;
                const currLink = history.location.pathname + history.location.search;

            let host = window.location.protocol + '//' + window.location.hostname;
            if (window.location.port.length > 0) {
                host += ':' + window.location.port;
            }

            let primary = <Fragment>
                {historyEntry.name} {currLink === link ? <Chip color="primary" label={'Aktuell'}/> : ''}
            </Fragment>;

            return <Fragment key={key} ><ListItem button={currLink !== link} key={key} onClick={currLink !== link && (() => {
                this.hideHistoryModal();
                store.dispatch(push(link));
            })}>
                <ListItemText primary={primary} secondary={<Fragment>{host}{link}</Fragment>}/>
            </ListItem>
                <Divider variant="fullWidth" component="li" key={key + 'd'}/>
            </Fragment>

        });

        const calendarRoute = this.props.moduleList.calendar
            ? <ButtonItemLink color="inherit" to={'/calendar'}>
                <Badge badgeContent={this.state.eventRequests} color="secondary">
                    <CalendarIcon/>
                </Badge>
            </ButtonItemLink>
            : null;

        const statisticRoute = this.props.moduleList.statistic
            ? <MenuItemLink onClick={this.handleMenuClose} to={'/statistic'}>
                <EqualizerIcon/> Statistik
            </MenuItemLink>
            : null;

        const mainNavigation = [];
        const subNavigation = [];

        if (permissions.indexOf('tickets') !== -1 || (user !== null && user.roles.indexOf('ROLE_ADMIN') !== -1)) {
            mainNavigation.push(<ListItemLink primary='Tickets' icon={<CommentIcon/>} to={'/tickets'} key={2}/>);
        }

        if (permissions.indexOf('customer') !== -1 || (user !== null && user.roles.indexOf('ROLE_ADMIN') !== -1)) {
            mainNavigation.push(<ListItemLink primary='Kunden' icon={<PeopleIcon/>} to={'/customer'} key={3}/>);
        }

        if (permissions.indexOf('devices') !== -1 || (user !== null && user.roles.indexOf('ROLE_ADMIN') !== -1)) {
            mainNavigation.push(<ListItemLink primary='Geräte' icon={<DevicesIcon/>} to={'/devices'} key={4}/>);
        }

        if (permissions.indexOf('tickets') !== -1 || (user !== null && user.roles.indexOf('ROLE_ADMIN') !== -1)) {
            mainNavigation.push(<ListItemLink external={true} primary='Live' icon={<LiveTvIcon/>} to={'/live'} key={5}/>);
        }

        if (permissions.indexOf('data') !== -1 || (user !== null && user.roles.indexOf('ROLE_ADMIN') !== -1)) {
            subNavigation.push(<ListItemLink primary='Daten' icon={<StorageIcon/>} to={'/data'} key={1}/>);
        }

        if (permissions.indexOf('settings') !== -1 || (user !== null && user.roles.indexOf('ROLE_ADMIN') !== -1)) {
            subNavigation.push(<ListItemLink primary='Einstellungen' icon={<SettingsIcon/>} to={'/settings'} key={2}/>);
        }

        const notificationIcons = <Fragment>
            <ButtonItemLink color="inherit" to={'/requests'}>
                <Badge badgeContent={this.state.requests} color="secondary">
                    <MailIcon/>
                </Badge>
            </ButtonItemLink>
            <ButtonItemLink color="inherit" to={'/notifications'}>
                <Badge badgeContent={this.state.notifications} color="secondary">
                    <NotificationIcon/>
                </Badge>
            </ButtonItemLink>
            {calendarRoute}
        </Fragment>

        return <Fragment>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={this.handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <img src={logo} alt="logo" className={classes.logo}/>
                    <form noValidate onSubmit={this.onSubmit} className={classes.form}>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon/>
                            </div>
                            <InputBase
                                onChange={this.onSearchStringChange}
                                value={this.state.searchString}
                                placeholder="Search…"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{'aria-label': 'search'}}
                            />
                        </div>
                    </form>
                    <div className={classes.grow}/>
                    <div className={classes.navNotifications}>
                        {notificationIcons}
                    </div>
                    <IconButton
                        color="inherit"
                        onClick={this.handleMenuOpen}
                    >
                        <AccountCircle/>
                    </IconButton>
                </Toolbar>
                {Boolean(this.props.requestCount.count) && <BorderLinearProgress color="secondary" />}
            </AppBar>
            <Menu
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleMenuClose}
            >
                {statisticRoute}
                <MenuItem onClick={(event) => {
                    this.handleMenuClose(event);
                    this.showHistoryModal(event);
                }}>
                    <HistoryIcon/> Verlauf</MenuItem>
                <MenuItemLink onClick={this.handleMenuClose} to='/profile'>
                    <PersonIcon/> Profil
                </MenuItemLink>
                <MenuItemLink onClick={this.handleMenuClose} to={'/login'}>
                    <ExitToAppIcon/> Ausloggen
                </MenuItemLink>
            </Menu>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
                open={open}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={this.handleDrawerClose}>
                        <ChevronLeftIcon/>
                    </IconButton>
                </div>
                <Divider/>
                <List className={classes.list}>
                    <ListItemLink exact={true} primary='Dashboard' icon={<DashboardIcon/>} to={'/'} key={1}/>
                    {mainNavigation}
                </List>
                {subNavigation.length ? <Divider/> : null}
                <List>
                    {subNavigation}
                    <ListItem selected={this.props.helpPanel.isOpen} button onClick={() => this.props.actions.toggleHelpPanel(!this.props.helpPanel.isOpen)}>
                        <ListItemIcon><HelpIcon /></ListItemIcon>
                        <ListItemText primary='Hilfe'/>
                    </ListItem>
                </List>
                <Hidden mdUp implementation='css'>
                    <Divider/>
                    <Grid justify='center' container className={classes.drawerToolbar}>
                        <Grid item>
                            {notificationIcons}
                        </Grid>
                    </Grid>
                </Hidden>
            </Drawer>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={this.state.showHistoryModal}
                onClose={this.hideHistoryModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Verlauf"}</DialogTitle>
                <List>
                    <Divider variant="fullWidth" component="li"/>
                    {historyList}
                </List>
                <DialogActions>
                    <Button
                        variant='text'
                        onClick={this.hideHistoryModal}
                        color="primary">
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>;
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchUser, toggleHelpPanel}, dispatch),
    }
};

function mapStateToProps(state) {

    const {user, helpPanel, locationHistory, modules, requestCount} = state;

    const {moduleList} = modules;
    const {isFetching, userObject, permissions} = user;

    return {isFetching, userObject, permissions, helpPanel, locationHistory, moduleList, requestCount}
}

export default compose(
    withStyles(useStyles),
    connect(mapStateToProps, mapDispatchToProps))(Navigation);
