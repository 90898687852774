import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import qs from 'query-string';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import DateFnsUtils from '@date-io/date-fns';
import {
    AppBar,
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Input,
    InputAdornment,
    Menu,
    MenuItem,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Tabs,
    Toolbar,
    Tooltip,
    Typography,
    withStyles
} from "@material-ui/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import {fetchTickets} from '../../../actions/ticketActions';
import {ButtonLink} from '../../Utilities/ButtonLink'
import Card from "../../Utilities/Card";
import {fetchBookmarkList} from "../../../actions/bookmarkActions";
import {fetchUserDefaultBookmark} from "../../../actions/configActions";
import moment from "moment";
import FilterListIcon from "@material-ui/icons/FilterList";
import {fetchCategoryList} from "../../../actions/categoryActions";
import {fetchStatusList} from "../../../actions/statusActions";
import MultiSelect from "../../Utilities/MultiSelect";
import Select from "../../Utilities/Select";

const useStyles = theme => ({
    tableWrapper: {
        overflowX: 'auto',
    },
    searchBar: {
        width: '300px',
        marginRight: theme.spacing(1)
    },
    grow: {
        flexGrow: 1,
    },
    filterChip: {
        marginRight: theme.spacing(1),
        maxWidth: '350px'
    },
    removeOverflow: {
        overflowY: 'visible'
    }
});

const defaultFilter = {
    ticketData: '',
    customerData: '',
    deviceData: '',
    category: '',
    fromDate: null,
    toDate: null,
    hasRecurrence: '',
    status: ['1', '2']
}

const defaultSort = 's.priority_ASC'

class TicketList extends Component {

    constructor(props) {
        super(props);


        this.state = {
            filterDialog: false,
            selectedFilter: null,
            filterMenuAnchorEl: null,
            selectedBookmark: 0,
            searchExecuted: false,
            pagination: {
                page: 1,
                perPage: 50,
                total: 0
            },
            activeFilter: new Set(['status']),
            filter: {
                ticketData: '',
                customerData: '',
                deviceData: '',
                category: [],
                fromDate: null,
                toDate: null,
                hasRecurrence: '',
                status: ['1', '2']
            },
            orderBy: 's.priority_ASC',
        };

    };

    getFilterDefinition = () => {
        return {
            category: {
                type: "select",
                name: "Kategorien",
                values: this.props.categories || [],
                multiselect: true
            },
            status: {
                type: "select",
                name: "Status",
                values: this.props.status || [],
                multiselect: true
            },
            fromDate: {
                type: "datetime",
                name: "Von Erstellungsdatum",
            },
            toDate: {
                type: "datetime",
                name: "Bis Erstellungsdatum",
            },
            hasRecurrence: {
                type: "select",
                name: "Wiederkehrend",
                values: [
                    {id: 0, title: "Nein"},
                    {id: 1, title: "Ja"}
                ],
                multiselect: false
            }
        }
    }

    componentDidMount() {
        this.props.actions.fetchBookmarkList();
        this.props.actions.fetchUserDefaultBookmark();
        this.props.actions.fetchCategoryList();
        this.props.actions.fetchStatusList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (!this.props.ticket.isFetching && prevProps.ticket.isFetching) {
            const pagination = {
                page: this.props.ticket.tickets.page,
                perPage: this.props.ticket.tickets.itemsPerPage,
                total: this.props.ticket.tickets.total,
            };
            this.setState({pagination})
        }

        if (
            !this.state.defaultSearchExecuted &&
            this.props.filterBookmarks.bookmarks &&
            Array.isArray(this.props.filterBookmarks.bookmarks) &&
            this.props.defaultBookmarkConfig.config
        ) {
            this.setState({defaultSearchExecuted: true}, () => {

                if (this.props.defaultBookmarkConfig.config['list']) {
                    this.setFilterByBookmark(this.props.defaultBookmarkConfig.config['list'], () => {
                        this.runSearch()
                    });
                } else {
                    this.runSearch()
                }
            })
        }
    }

    handleChangePage = (event, pageNumber) => {
        let pagination = this.state.pagination;
        pagination.page = pageNumber + 1;
        this.setState({pagination: pagination}, () => {
            this.runSearch()
        });
    }

    handleChangeRowsPerPage = event => {
        let pagination = this.state.pagination;
        pagination.page = 1;
        pagination.perPage = parseInt(event.target.value, 10);
        this.setState({pagination: pagination}, () => {
            this.runSearch()
        });
    };

    setFilterByBookmark(id) {
        if (!this.props.filterBookmarks.bookmarks) {
            return;
        }

        const bookmark = this.props.filterBookmarks.bookmarks.find((object) => {
            return object.id === id;
        });

        if (!bookmark) {
            return;
        }

        const orderMap = {
            'o_status_asc': 's.priority_ASC',
            'o_status_desc': 's.priority_DESC',
            'o_importance_asc': 't.importance_ASC',
            'o_importance_desc': 't.importance_DESC',
            'o_inserted_asc': 't.inserted_ASC',
            'o_inserted_desc': 't.inserted_DESC',
        };

        let orderBy = null;
        if (orderMap[bookmark.orderBy]) {
            orderBy = orderMap[bookmark.orderBy]
        }

        let hasRecurrence = '';
        if (bookmark.hasRecurrence !== null && bookmark.hasRecurrence) {
            hasRecurrence = 1;
        } else if (bookmark.hasRecurrence !== null && !bookmark.hasRecurrence) {
            hasRecurrence = 0;
        }

        let filter = {
            ticketData: bookmark.ticketSearchString,
            deviceData: bookmark.deviceSearchString,
            customerData: bookmark.customerSearchString,
            category: bookmark.categoryId ? bookmark.categoryId : '',
            fromDate: bookmark.dateFrom ? moment(bookmark.dateFrom).toDate() : null,
            toDate: bookmark.dateTo ? moment(bookmark.dateTo).toDate() : null,
            status: bookmark.statusIds ? bookmark.statusIds : [],
            hasRecurrence: hasRecurrence

        };

        this.setState({
            selectedBookmark: id,
            filter: filter,
            orderBy: orderBy
        }, () => {
            this.runSearch()
        })
    }

    onTabChange = (event, value) => {
        if (value > 0) {
            this.setFilterByBookmark(value);
            return;
        }

        this.setState({selectedBookmark: 0, orderBy: defaultSort, filter: defaultFilter}, () => this.runSearch());

    }

    runSearch = () => {
        const filter = Object.assign({}, this.state.filter);
        Object.keys(filter).forEach((filterName) => {
            if (!this.state.activeFilter.has(filterName)) {
                delete filter[filterName];
                return;
            }

            if(this.getFilterDefinition()[filterName].type === 'datetime' && filter[filterName]) {
                filter[filterName] = moment(filter[filterName]).format();
            }

        });
        this.props.actions.fetchTickets(filter, this.state.orderBy, this.state.pagination.page, this.state.pagination.perPage)
    }

    static getDerivedStateFromProps(props, state) {
        if (props.filter) {
            state.filter = Object.assign({}, state.filter, props.filter);
        }

        return state;
    }

    handleMenuClose = () => {
        this.setState({
            filterMenuAnchorEl: null
        })
    };

    handleMenuOpen = (event) => {
        this.setState({
            filterMenuAnchorEl: event.currentTarget
        })
    };

    handleDialogClose = () => {
        this.setState({
            filterDialog: false,
            selectedFilter: null
        }, () => {
            this.runSearch()
        })
    };

    handleDialogOpen = (filterName) => {
        this.setState({
            filterDialog: true,
            selectedFilter: filterName
        })
    };

    addFilter = (filterName) => {
        const activeFilter = this.state.activeFilter
        activeFilter.add(filterName);
        this.setState({activeFilter}, () => {
            this.handleDialogOpen(filterName);
        })
    }

    removeFilter = (filterName) => {
        const activeFilter = this.state.activeFilter
        activeFilter.delete(filterName);
        this.setState({activeFilter}, () => {
            this.runSearch();
        })
    }

    onMuliArrayChange = (filterName, values) => {
        let filter = this.state.filter;
        filter[filterName] = values;
        this.setState({filter: filter});
    }

    onFilterChange = (filterName, value) => {
        let filter = this.state.filter;
        filter[filterName] = value;
        this.setState({filter: filter});
    }

    render() {
        const classes = this.props.classes;

        let params = {};
        if (this.state.filter.deviceId) {
            params.deviceId = this.state.filter.deviceId;
        }
        if (this.state.filter.customer) {
            params.customerId = this.state.filter.customer;
        }

        const link = '/tickets/new?' + qs.stringify(params);

        const tabs = this.props.filterBookmarks.bookmarks ? this.props.filterBookmarks.bookmarks.map((bookmark) => {
            return <Tab value={bookmark.id} label={bookmark.name}/>
        }) : []

        const tickets = this.props.ticket.tickets ? this.props.ticket.tickets.results.map((ticket) => {

            let devices = '';
            if (ticket.devices && ticket.devices.length) {
                devices = ticket.devices.filter((data) => {
                    return !data.removed
                }).map((data, id) => {
                    return <li key={id}>{data.device.deviceNo} (KN: {data.device.customer.customerNo})</li>
                })
            }

            return <TableRow>
                <TableCell>{ticket.id}</TableCell>
                <TableCell>{ticket.customer.customerName} ({ticket.customer.customerNo})</TableCell>
                <TableCell>
                    <ul>{devices}</ul>
                </TableCell>
                <TableCell>{ticket.category.title}</TableCell>
                <TableCell>{ticket.importance}</TableCell>
                <TableCell>{ticket.shortDescription}</TableCell>
                <TableCell>{ticket.status.title}</TableCell>
                <TableCell>{moment(ticket.inserted).format('DD/MM/YYYY HH:mm')}</TableCell>
                <TableCell>{moment(ticket.updatedAt).format('DD/MM/YYYY HH:mm')}</TableCell>
                <TableCell>{ticket.completedAt ? moment(ticket.completedAt).format('DD/MM/YYYY HH:mm') : ''}</TableCell>
            </TableRow>;
        }) : []

        const menu = Object.keys(this.getFilterDefinition()).filter((filterName) => {
            return !this.state.activeFilter.has(filterName);
        }).map((filterName) => {
            return <MenuItem onClick={(event) => {
                this.addFilter(filterName);
            }}>{this.getFilterDefinition()[filterName].name}</MenuItem>
        })

        const chips = Object.keys(this.getFilterDefinition()).filter((filterName) => {
            return this.state.activeFilter.has(filterName);
        }).map((filterName) => {
            let filterValue = this.state.filter[filterName];

            if (this.getFilterDefinition()[filterName].type == 'select') {
                if (Array.isArray(filterValue)) {
                    filterValue = filterValue.map((singleFilterValue) => {
                        const object = this.getFilterDefinition()[filterName].values.find((value) => {
                            return value.id == singleFilterValue;
                        })
                        if (object) {
                            return object.title
                        }

                        return singleFilterValue
                    }).join(', ')
                } else {
                    const object = this.getFilterDefinition()[filterName].values.find((value) => {
                        return value.id == filterValue;
                    })
                    if (object) {
                        filterValue = object.title
                    }
                }
            }
            if (this.getFilterDefinition()[filterName].type == 'datetime' && filterValue) {
                filterValue = moment(filterValue).format('DD.MM.YYYY')
            }

            return <Chip className={classes.filterChip} onDelete={() => {
                this.removeFilter(filterName);
            }} onClick={() => {
                this.handleDialogOpen(filterName);
            }} label={<Typography variant='body2'
                                  noWrap><b>{this.getFilterDefinition()[filterName].name}:</b> {filterValue}</Typography>}/>
        })

        let dialog = null;
        if (this.state.selectedFilter) {

            let content = '';

            const selectedFilter = this.getFilterDefinition()[this.state.selectedFilter]

            if (
                selectedFilter.type == 'select' &&
                selectedFilter.multiselect == false
            ) {
                content = <Select id={this.state.selectedFilter} label={selectedFilter.name} placeholder={selectedFilter.name}
                                  options={selectedFilter.values.map((value) => {
                                      return {
                                          label: value.title,
                                          value: String(value.id)
                                      }
                                  })}
                                  value={this.state.filter[this.state.selectedFilter]}
                                  onChange={(value) => this.onFilterChange(this.state.selectedFilter, value)}/>
            } else if (
                selectedFilter.type == 'select' &&
                selectedFilter.multiselect == true
            ) {
                content = <MultiSelect id={this.state.selectedFilter} label={selectedFilter.name} placeholder={selectedFilter.name}
                                       options={selectedFilter.values.map((value) => {
                                           return {
                                               label: value.title,
                                               value: String(value.id)
                                           }
                                       })}
                                       values={this.state.filter[this.state.selectedFilter] ? this.state.filter[this.state.selectedFilter].map((value) => {
                                           return String(value);
                                       }) : []}
                                       onChange={(values) => this.onMuliArrayChange(this.state.selectedFilter, values)}/>
            } else if (
                selectedFilter.type == 'datetime'
            ) {
                content = <MuiPickersUtilsProvider utils={DateFnsUtils}><KeyboardDatePicker
                    variant="outlined"
                    format="dd.MM.yyyy"
                    margin="normal"
                    id={this.state.selectedFilter}
                    label={selectedFilter.name}
                    value={this.state.filter[this.state.selectedFilter]}
                    onChange={(value) => this.onFilterChange(this.state.selectedFilter, value)}
                /></MuiPickersUtilsProvider>
            }

            dialog = <Dialog onClose={this.handleDialogClose} open={this.state.filterDialog}
                             fullWidth={true}
                             maxWidth={'sm'}
                             PaperProps={{className: classes.removeOverflow}}
            >
                <DialogTitle id="simple-dialog-title">{this.getFilterDefinition()[this.state.selectedFilter].name}</DialogTitle>
                <DialogContent className={classes.removeOverflow}
                >
                    {content}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleDialogClose} color="primary">
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
        }

        return <div>
            <Toolbar disableGutters={true}>
                <Box justifyContent="flex-start">
                    <ButtonLink className={classes} variant="contained" color="primary" to={link}>
                        <AddIcon/> Neues Ticket
                    </ButtonLink>
                </Box>
                <div className={classes.grow}/>
                <Box justifyContent="flex-end">
                    <Button>
                        <EditIcon/> Presets bearbeiten
                    </Button>
                </Box>
            </Toolbar>
            <AppBar position="static" color="default">
                <Tabs
                    onChange={this.onTabChange}
                    value={this.state.selectedBookmark}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab value={0} label='Tickets'/>
                    {tabs}
                </Tabs>
            </AppBar>
            <Card title={'Ticketliste'} isFetching={this.props.ticket.isFetching} reload={this.runSearch} actions={[
                <Tooltip title='Filter hinzufügen'>
                    <IconButton onClick={this.handleMenuOpen}>
                        <FilterListIcon/>
                    </IconButton>
                </Tooltip>
            ]}>
                <Menu
                    anchorEl={this.state.filterMenuAnchorEl}
                    keepMounted
                    open={Boolean(this.state.filterMenuAnchorEl)}
                    onClose={this.handleMenuClose}
                >
                    {menu}
                </Menu>
                <Toolbar variant='dense'>
                    <Input
                        className={classes.searchBar}
                        variant="outlined"
                        placeholder='Suche'
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon/>
                            </InputAdornment>
                        }
                    />
                    {chips}
                </Toolbar>
                <div className={classes.tableWrapper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell key={'id'}>ID</TableCell>
                                <TableCell key={'customer'}>Kunde</TableCell>
                                <TableCell key={'devices'}>Geräte</TableCell>
                                <TableCell key={'category'}>Kategorie</TableCell>
                                <TableCell key={'importance'}>Priorität</TableCell>
                                <TableCell key={'shortDescription'}>Kurzbeschreibung</TableCell>
                                <TableCell key={'status'}>Status</TableCell>
                                <TableCell key={'createdAt'}>Erstellt am</TableCell>
                                <TableCell key={'updatedAt'}>Bearbeitet am</TableCell>
                                <TableCell key={'completetAt'}>Abgeschlossen am</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tickets}
                        </TableBody>
                    </Table>
                </div>
                <div className={classes.tableWrapper}>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100, 200]}
                        labelDisplayedRows={({from, to, count}) => {
                            return `${from}-${to === -1 ? count : to} von ${count}`
                        }}
                        labelRowsPerPage={'Zeilen pro Seite:'}
                        component="div"
                        count={this.state.pagination.total}
                        rowsPerPage={this.state.pagination.perPage}
                        page={this.state.pagination.page - 1}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </div>
            </Card>
            {dialog}
        </div>
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({
            fetchTickets,
            fetchBookmarkList,
            fetchUserDefaultBookmark,
            fetchCategoryList,
            fetchStatusList
        }, dispatch),
    }
};

function mapStateToProps(state) {

    const {filterBookmarks, config, user, categoryList, statusList} = state;
    const {defaultBookmarkConfig} = config;
    const {userObject} = user;
    const {ticket} = state;
    let {categories} = categoryList;
    let {status} = statusList;

    return {categories, filterBookmarks, defaultBookmarkConfig, userObject, ticket, status}

}

export default compose(
    withStyles(useStyles),
    connect(mapStateToProps, mapDispatchToProps))(TicketList);