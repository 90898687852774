import React, {Component, Fragment} from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

import {Button, InputGroup, FormControl} from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import {push} from "connected-react-router";
import {connect} from "react-redux";
import {Link} from 'react-router-dom';
import {Route, Switch} from 'react-router'
import cx from 'classnames'
import Spinner from '../../../Utilities/Spinner';
import Rest from "../../../../core/Rest";
import {history} from "../../../../core";
import Device from './Device';
import NoDeviceAlert from "./NoDevice";

class DeviceList extends Component {

    availableLimits = [
        1,
        10,
        20,
        50,
        100,
        200,
        500,
        1000
    ];

    constructor(props) {
        super(props);

        this.state = {
            deviceSearchString: '',
            devices: {
                isFetching: false,
                list: []
            },
            pagination: {
                page: 1,
                perPage: 10,
                total: 0
            },
        };

        this.fetchDevices = this.fetchDevices.bind(this);
        this.perPageHandler = this.perPageHandler.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleSearchStringChange = this.handleSearchStringChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(event)
    {
        event.preventDefault();
        this.fetchDevices();
    }

    componentDidMount() {
        this.fetchDevices()
    }

    handleSearchStringChange(event)
    {
        this.setState({deviceSearchString: event.target.value})
    }

    fetchDevices() {

        const devices = this.state.devices;
        const pagination = this.state.pagination;

        devices.isFetching = true;

        this.setState({devices: devices});

        const filter = {
            customer: this.props.match.params.id
        };

        if(this.state.deviceSearchString)
        {
            filter.deviceData = this.state.deviceSearchString
        }

        Rest.fetch({
            endpoint: 'devices',
            method: 'GET',
            parameter: {
                filter: filter,
                perPage: pagination.perPage,
                page: pagination.page
            }
        }).then(
            response => {
                const result = response.response;

                pagination.perPage = result.itemsPerPage;
                pagination.total = result.total;
                pagination.page = result.page;
                devices.list = result.results;
                this.setState({devices: devices, pagination: pagination});

                if(result.total === 1 && this.props.match.url === this.props.location.pathname)
                {
                    const device = result.results[0];
                    const url = this.props.match.url + '/' + device.id;
                    this.props.push(url);
                }
            },
            error => {
            }
        ).then(() => {
            devices.isFetching = false;
            this.setState({devices: devices});
        })

    }

    perPageHandler(event) {
        let pagination = this.state.pagination;

        pagination.perPage = parseInt(event.target.value, 10);

        this.setState({pagination: pagination});

        this.fetchDevices();
    }

    handlePageChange(pageNumber) {
        let pagination = this.state.pagination;
        pagination.page = pageNumber;
        this.setState({pagination: pagination});

        this.fetchDevices();
    }


    render() {

        let loading = null;
        if (this.state.devices.isFetching) {
            loading = (<Spinner/>)
        }

        let limits = '';
        if (this.availableLimits) {
            limits = this.availableLimits.map((data) => {
                return <option key={data} value={data}>{data}</option>
            })
        }

        const pageCount = Math.ceil(this.state.pagination.total / this.state.pagination.perPage);

        let devices = [];
        if (this.state.devices.list) {


            devices = this.state.devices.list.map((device) => {

                const url = this.props.match.url + '/' + device.id;
                const pathname = history.location.pathname;

                return <tr key={device.id} onClick={() => {
                    this.props.push(url)
                }} className={cx({
                    'active': pathname.indexOf(url) === 0
                })} >
                    <td>{device.deviceNo}</td>
                    <td>{device.brand}</td>
                    <td>{device.model}</td>
                    <td>{device.type.title}</td>
                    <td>{device.location.address.identifier} / {device.location.title}</td>
                </tr>
            })
        }


        const content = <Fragment>
            <div className="btn-bar row">
                <div className='col-lg-3'>
                    <Link
                        className='btn btn-default'
                        role='button'
                        to={'/devices/new?customerId='+this.props.match.params.id}
                    >
                        <FontAwesomeIcon icon='plus'/> Neu Anlegen
                    </Link>
                </div>
                <div className='col-lg-8 pull-right'>
                    <form onSubmit={this.onSubmit}>
                        <InputGroup>
                            <FormControl type="text" placeholder='Suchen' value={this.state.deviceSearchString} onChange={this.handleSearchStringChange}/>
                            <InputGroup.Button>
                                <Button type='submit' onClick={this.onSubmit}>Suchen</Button>
                            </InputGroup.Button>
                        </InputGroup>
                    </form>
                </div>
            </div>
            <div className="panel panel-default">
                <div className="panel-heading">
                    <div className='pull-right'>
                        <Button bsSize="xsmall" disabled={!!loading}
                                onClick={this.fetchDevices}><FontAwesomeIcon
                            icon='sync'/></Button>
                    </div>
                    <div className="form-inline" style={{marginBottom: 0, marginRight: '5px', display: 'inline'}}>
                        <select className="form-control form-inline" id="perPage" value={this.state.pagination.perPage}
                                onChange={this.perPageHandler}>
                            {limits}
                        </select>
                    </div>
                    <span style={{marginRight: '5px'}}>{this.state.pagination.total} auf {pageCount} Seiten</span>
                    <span style={{marginBottom: 0, fontSize: '150%'}}>{loading}</span>
                </div>
                <div className="table-responsive">
                    <table className="table table-hover">
                        <thead>
                        <tr>
                            <th>Geräte Nr</th>
                            <th>Marke</th>
                            <th>Modell</th>
                            <th>Typ</th>
                            <th>Standort / Standplatz</th>
                        </tr>
                        </thead>
                        <tbody>
                        {devices}
                        </tbody>
                    </table>
                </div>
                <div className='panel-footer text-center'>
                    <Pagination
                        activePage={this.state.pagination.page}
                        itemsCountPerPage={this.state.pagination.perPage}
                        totalItemsCount={this.state.pagination.total}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange}
                    />
                </div>
            </div>
        </Fragment>;

        if(history.location.pathname === this.props.match.url)
        {
            return content;
        }

        return (
            <div className='row'>
                <div className='col-md-3'>
                    {content}
                </div>
                <div className='col-md-9'>
                    <Switch>
                        <Route path={'/customer/:customerId/devices/:deviceId'} component={Device}/>
                        <Route component={NoDeviceAlert}/>
                    </Switch>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        push: (link) => {
            dispatch(push(link))
        },
    }
};


export default connect(null, mapDispatchToProps)(DeviceList);
