import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Button} from 'react-bootstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

import New from "./New";
import {fetchDeviceComponents} from "../../../../../actions/deviceComponentActions";
import Spinner from "../../../../Utilities/Spinner";
import ComponentItem from './ComponentItem'

class List extends Component {

    constructor(props) {
        super(props);
        this.fetchComponents = this.fetchComponents.bind(this);
    }

    componentDidMount() {
        this.fetchComponents()
    }

    render() {

        let components = [];
        let loading = '';
        if (this.props.deviceComponentList.devices[this.props.match.params.deviceId]) {
            const list = this.props.deviceComponentList.devices[this.props.match.params.deviceId];
            components = list.components.map((component) => {
                return <ComponentItem key={component.id} component={component} afterSave={this.fetchComponents} />
                }
            );

            if (list.isFetching) {
                loading = <Spinner/>
            }
        }

        return <div>
            <div className='panel panel-default'>
                <div className="panel-heading">
                    <div className='pull-right'>
                        <Button bsSize="xsmall" disabled={!!loading}
                                onClick={this.fetchComponents}><FontAwesomeIcon
                            icon='sync'/></Button>
                    </div>
                    <h4 className="panel-title">Geräte Daten {loading}</h4>
                </div>
                <div className="table-responsive">
                    <table className="table component-table">
                        <thead>
                        <tr>
                            <th>Komponenten Nr</th>
                            <th>Typ</th>
                            <th>Beschreibung</th>
                            <th>Zeitstempel</th>
                            <th style={{width: '100px'}} />
                        </tr>
                        </thead>
                        <tbody>
                        {components}
                        </tbody>
                    </table>
                </div>
            </div>
            <New deviceId={this.props.match.params.deviceId} afterSave={this.fetchComponents}/>
        </div>;
    }

    fetchComponents() {
        this.props.actions.fetchDeviceComponents(this.props.match.params.deviceId)
    }

}

const mapDispatchToProps = (dispatch) => {

    return {
        actions: bindActionCreators({fetchDeviceComponents}, dispatch)
    }
};

function mapStateToProps(state) {

    const {deviceComponentList} = state;

    return {deviceComponentList}
}

export default connect(mapStateToProps, mapDispatchToProps)(List);