import React from 'react';

const NoAddressAlert = () => {

    return (
        <div className='alert alert-info' >
            In diesem Bereich werden geöffnete Addressen angezeigt. Links klicken um eine Adresse zu öffnen
        </div>
    );
};

export default NoAddressAlert;

