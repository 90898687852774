import React, {Component} from 'react';
import {push} from "connected-react-router";
import Rest from "../../core/Rest";
import {connect} from "react-redux";

class CreateDevice extends Component {

    constructor(props) {
        super(props);

        this.state = {
            deviceFetching: false,
            customerId: null
        };

        this.fetchDevice = this.fetchDevice.bind(this);
    }
    componentDidMount()
    {
        this.fetchDevice()
    }

    fetchDevice() {
        this.setState({deviceFetching: true});

        Rest.fetch({
            endpoint: 'devices/' + this.props.match.params.deviceId,
        }).then(
            response => {

                const redirect = this.props.location.pathname.substr(this.props.match.url.length);

                const device = response.response;
                this.setState({
                    customerId: device.customer.id
                }, () => {
                    this.props.redirectToDevice(this.state.customerId, this.props.match.params.deviceId, redirect);
                });
            },
            error => {
            }
        ).then(() => {
            this.setState({deviceFetching: false});
        });
    }


    render() {
        return (
            <div>
            </div>
        );
    }

}

const mapDispatchToProps = (dispatch) => {

    return {
        redirectToDevice: (customerId, deviceId, redirect = '') => {
            dispatch(push('/customer/' + customerId + '/devices/' + deviceId + redirect))
        }
    }
};

export default connect(null, mapDispatchToProps)(CreateDevice);