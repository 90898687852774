import React, {Component} from 'react';
import {connect} from "react-redux";
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Rest from "../../../../core/Rest";
import Spinner from '../../../Utilities/Spinner'
import {push} from "connected-react-router";
import {store} from "../../../../core";
class List extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userList: [],
            isFetching: false,
        };

        this.fetchUser = this.fetchUser.bind(this);
    };

    componentDidMount() {
        this.fetchUser();
    }

    render() {

        let loading = null;
        if (this.state.isFetching) {
            loading = (<Spinner/>)
        }

        const rows = this.state.userList.map((user) => {
            return Row(user)
        });

        return (
            <div>
                <div className='btn-bar'>

                    <Link className='btn btn-default' role='button' to='/settings/user/new' >
                        <FontAwesomeIcon icon='plus'/> Hinzufügen
                    </Link>
                </div>
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <div className='pull-right'>
                            <Button bsSize="xsmall" disabled={!!loading} onClick={this.fetchUser}><FontAwesomeIcon
                                icon='sync'/></Button>
                        </div>
                        <h4 className="panel-title">Nutzer {loading}</h4>
                    </div>
                    <table className="table table-hover">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Username</th>
                            <th>Vorname</th>
                            <th>Nachname</th>
                            <th>E-Mail</th>
                            <th>Aktiv</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    fetchUser() {

        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'users',
        }).then(
            response => {

                this.setState({userList: response.response, isFetching: false})
            },
            error => {
                this.setState({isFetching: false})
            }
        )

    }

}

const Row = (result) => (
    <tr key={result.id} onClick={() => {
        store.dispatch(push('/settings/user/' + result.id))
    }} >
        <td>{result.id}</td>
        <td>{result.username}</td>
        <td>{result.firstname}</td>
        <td>{result.lastname}</td>
        <td>{result.email}</td>
        <td>{result.isActive?<FontAwesomeIcon style={{color: 'green'}} icon='check'/>:<FontAwesomeIcon style={{color: 'red'}} icon='times'/>}</td>
    </tr>
);


export default connect()(List);
