import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Button, Modal} from 'react-bootstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {push} from "connected-react-router";
import {store} from '../../../core';
import cx from 'classnames';
import Rest from "../../../core/Rest";
import Spinner from '../../Utilities/Spinner'

class EditCustomer extends Component {

    defaultModel = {
        customerNo: '',
        customerName: '',
        telephoneNumber: ''
    };

    constructor(props) {
        super(props);

        this.state = {
            showDeleteModal: false,
            isSaving: false,
            isFetching: false,
            model: Object.assign({}, this.defaultModel),
            validationErrorList: {}
        };

        this.onModelChange = this.onModelChange.bind(this);
        this.getAlert = this.getAlert.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.saveCustomer = this.saveCustomer.bind(this);
        this.fetchCustomer = this.fetchCustomer.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.deleteCustomer = this.deleteCustomer.bind(this);
    }

    handleClose() {
        this.setState({showDeleteModal: false});
    }

    handleShow() {
        this.setState({showDeleteModal: true});
    }

    onSubmit(event) {
        event.preventDefault();

        this.saveCustomer()
    }

    componentDidMount() {
        this.fetchCustomer()
    }

    fetchCustomer() {

        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'customers/' + this.props.match.params.id,
            method: 'GET',
        }).then(
            response => {

                const customer = response.response;

                Object.keys(customer).forEach((key) => {

                    if (Object.keys(this.defaultModel).indexOf(key) === -1) {
                        delete customer[key];
                    }

                });

                this.setState({model: Object.assign({}, this.defaultModel, customer)});
            },
            error => {
            }
        ).then(() => {
            this.setState({isFetching: false});
        })

    }

    deleteCustomer() {

        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'customers/' + this.props.match.params.id,
            method: 'DELETE'
        }).then(
            response => {
                this.handleClose();
                store.dispatch(push('/customer'))
            },
            error => {
            }
        ).then(() => {
            this.setState({isFetching: false});
        })
    }

    saveCustomer() {

        this.setState({isSaving: true, validationErrorList: {}});

        let model = Object.assign({}, this.state.model);

        model.plz = parseInt(model.plz, 10);
        model.customerNo = parseInt(model.customerNo, 10);

        Rest.fetch({
            endpoint: 'customers/' + this.props.match.params.id,
            method: 'PATCH',
            body: model
        }).then(
            response => {

                const customer = response.response;

                Object.keys(customer).forEach((key) => {

                    if (Object.keys(this.defaultModel).indexOf(key) === -1) {
                        delete customer[key];
                    }

                });

                this.setState({model: Object.assign({}, this.defaultModel, customer)});

            },
            error => {
                if (error.code === 400 && error.raw.error && error.raw.error.validation_errors) {
                    this.setState({validationErrorList: error.raw.error.validation_errors})
                }
            }
        ).then(() => {
            this.setState({isSaving: false});
        })
    }

    onModelChange(event) {
        const model = this.state.model;

        model[event.target.name] = event.target.value;

        this.setState({model: model})
    }

    getAlert(key) {
        if (this.state.validationErrorList[key] && Array.isArray(this.state.validationErrorList[key])) {
            const values = this.state.validationErrorList[key].map((message) => {
                return <li>{message}</li>
            });

            return <div className='alert alert-danger' style={{marginTop: '10px'}}>
                <ul>{values}</ul>
            </div>
        }

        return '';
    }

    render() {

        let loading = null;
        if (this.state.isFetching) {
            loading = (<Spinner/>)
        }

        return (
            <div>
                <div className="btn-bar">
                    <Link
                        className='btn btn-default'
                        role='button'
                        to={'/customer/' + this.props.match.params.id}
                    >
                        <FontAwesomeIcon icon='angle-left'/> Zurück
                    </Link>
                </div>
                <form onSubmit={this.onSubmit}>
                    <div className="panel-default panel">
                        <div className="panel-heading">
                            <div className='pull-right'>
                                <Button bsSize="xsmall" disabled={!!loading} onClick={this.fetchCustomer}><FontAwesomeIcon
                                    icon='sync'/></Button>
                            </div>
                            <h4 className="panel-title">
                                Kunde Bearbeiten {loading}
                            </h4>
                        </div>
                        <div className="panel-body">
                            <div className={cx({
                                'form-group': true,
                                'has-error': !!this.state.validationErrorList.customerNo
                            })}>
                                <label>
                                    Kundennummer
                                </label>
                                <input type="text" placeholder="Kundennummer" name="customerNo"
                                       className="form-control"
                                       required
                                       onChange={this.onModelChange} value={this.state.model.customerNo}
                                />
                                {this.getAlert('customerNo')}
                            </div>
                            <div className={cx({
                                'form-group': true,
                                'has-error': !!this.state.validationErrorList.customerName
                            })}>
                                <label>
                                    Name
                                </label>
                                <input type="text" placeholder="Name" name="customerName"
                                       className="form-control"
                                       onChange={this.onModelChange} value={this.state.model.customerName}
                                       required
                                />
                                {this.getAlert('customerName')}

                            </div>
                            <div className={cx({
                                'form-group': true,
                                'has-error': !!this.state.validationErrorList.telephoneNumber
                            })}>
                                <label>
                                    Telefonnummer
                                </label>
                                <input type="tel" className="form-control" placeholder="Telefonnummer"
                                       onChange={this.onModelChange} value={this.state.model.telephoneNumber}
                                       name="telephoneNumber"
                                />
                                {this.getAlert('telephoneNumber')}

                            </div>
                        </div>
                        <div className="panel-footer">
                            <Button type='submit' onClick={this.onSubmit} disabled={this.state.isSaving || this.state.isFetching}>
                                {this.state.isSaving ?
                                    <Fragment>Speichert...</Fragment> : <Fragment><FontAwesomeIcon icon='save'/> Speichern</Fragment>}
                            </Button>

                            <div className='pull-right'>
                                <Button onClick={this.handleShow} bsStyle='danger' type='button'
                                        disabled={this.state.isSaving || this.state.isFetching}>
                                    <FontAwesomeIcon icon='trash'/> Löschen
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
                <Modal backdropClassName='bootstrap' className='bootstrap' show={this.state.showDeleteModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Löschen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Wirklich Löschen?
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-default" onClick={this.handleClose}>Schließen
                        </button>
                        <Button
                            bsStyle="danger"
                            disabled={this.state.isFetching}
                            onClick={this.deleteCustomer}
                        >
                            <FontAwesomeIcon icon='trash'/> Löschen
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

export default EditCustomer
