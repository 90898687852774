import React, {Component} from 'react';
import {connect} from "react-redux";
import {Button} from 'react-bootstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Rest from "../../../../core/Rest";
import FileSelector from "../../../Utilities/FileSelector/FileModal";
import Spinner from "../../../Utilities/Spinner";

const defaultUrl = 'https://cdn.vecodesk.com/storage/master/logo.png';

class Settings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            useCustomPicture: false,
            fileUrl: '',
            logoWidth: 40,
            isFetching: false,
            isSaving: false
        };

        this.handleShow = this.handleShow.bind(this);
        this.onReset = this.onReset.bind(this);
        this.fetchConfig = this.fetchConfig.bind(this);
        this.saveConfig = this.saveConfig.bind(this);
    }

    componentDidMount()
    {
        this.fetchConfig();
    }

    handleShow() {
        this.modal.handleShow();
    }

    onReset(event)
    {
        this.setState({
            useCustomPicture: false,
            fileUrl: defaultUrl,
            logoWidth: 40
        })
    }

    render() {

        let loading = null;
        if (this.state.isFetching) {
            loading = (<Spinner/>)
        }

        return (
            <div className="panel panel-default">
                <div className="panel-heading">
                    <div className='pull-right'>
                        <Button bsSize="xsmall" disabled={!!loading} onClick={this.fetchConfig}><FontAwesomeIcon
                            icon='sync'/></Button>
                    </div>
                    <h4 className="panel-title">Firmen Logo {loading}</h4>
                </div>
                <div className="panel-body">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Bild</label><br />
                            <FileSelector ref={(modal) => { this.modal = modal; }} onSelect={(file) => {
                                this.setState({fileUrl: file.url,useCustomPicture: true});
                                this.modal.handleClose();
                            }} />
                            <div className="input-group">
                                <span className="input-group-btn">
                                    <Button onClick={this.handleShow} >Datei Wählen</Button>
                                </span>
                                <input className='form-control' value={this.state.fileUrl} disabled={true} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Bild Breite</label>
                            <input
                                type="range" max="50" min="10" step="1" value={this.state.logoWidth} id="logoWidth" name="logoWidth"
                                onChange={(event) => {this.setState({logoWidth: parseInt(event.target.value, 10)}) }}
                            />
                            <output name="logoWidthValue">{this.state.logoWidth}%</output>
                        </div>
                        <div className="form-group">
                            <button
                                type="submit"
                                className="btn btn-default"
                                style={{marginRight: '5px'}}
                                disabled={this.state.isSaving || this.state.isFetching}
                                onClick={!this.state.isSaving ? this.saveConfig : null}
                            >
                                Speichern
                            </button>
                            <button type="submit" className="btn btn-danger" onClick={this.onReset}>
                                Reset
                            </button>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <fieldset>
                            <legend>Vorschau</legend>
                            <img src={this.state.fileUrl} width='100%' alt=''/>
                        </fieldset>
                    </div>
                </div>
            </div>
        );
    }

    fetchConfig()
    {
        this.setState({isFetching: true});
        Rest.fetch({
            method: 'GET',
            endpoint: 'config/pdf',
        }).then(
            response => {

                const config = response.response.config;

                this.setState({
                    useCustomPicture: config.useCustomPicture,
                    fileUrl: config.fileName?config.fileName:defaultUrl,
                    logoWidth: config.logoWidth,
                    isFetching: false
                })
            },
            error => {
                this.setState({isFetching: false});
            }
        );
    }

    saveConfig()
    {
        this.setState({isSaving: true});

        Rest.fetch({
            method: 'PUT',
            endpoint: 'config/pdf',
            body: {
                useCustomPicture: this.state.useCustomPicture,
                fileName: this.state.fileUrl,
                logoWidth: this.state.logoWidth
            }
        }).then(
            response => {
                this.fetchConfig();
                this.setState({isSaving: false});
            },
            error => {
                this.fetchConfig();
                this.setState({isSaving: false});
            }
        );
    }
}

export default connect()(Settings);
