import React from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';


const Spinner = (props) => {

    return (
        <FontAwesomeIcon icon="spinner" size={props.size} spin/>
    );
};

export default Spinner;
