import React, {Component} from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

import {Button} from 'react-bootstrap';
import {push} from "connected-react-router";
import {connect} from "react-redux";
import {Link} from 'react-router-dom';
import {Route, Switch} from 'react-router'
import cx from 'classnames'
import Spinner from '../../../Utilities/Spinner';
import Rest from "../../../../core/Rest";
import {history} from "../../../../core";
import NoAddressAlert from "./NoAddress";
import Address from "./Address";
import NewAddress from "./NewAddress";

class AddressList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isFetching: false,
            addresses: []
        };

        this.fetchAddresses = this.fetchAddresses.bind(this);
    }

    componentDidMount() {
        this.fetchAddresses()
    }


    fetchAddresses() {

        this.setState({isFetching: true});

        const filter = {
            customer: this.props.match.params.id
        };

        Rest.fetch({
            endpoint: 'customers/addresses',
            method: 'GET',
            parameter: {
                filter: filter
            }
        }).then(
            response => {

                const addresses = response.response;

                this.setState({addresses: addresses});

                if (addresses.length === 1 && this.props.match.url === this.props.location.pathname) {
                    const address = addresses[0];
                    const url = this.props.match.url + '/' + address.id;
                    this.props.push(url);
                }
            },
            error => {
            }
        ).then(() => {
            this.setState({isFetching: false});
        })

    }

    render() {

        let loading = null;
        if (this.state.isFetching) {
            loading = (<Spinner/>)
        }


        let addresses = [];
        if (this.state.addresses) {


            addresses = this.state.addresses.map((address) => {

                const url = this.props.match.url + '/' + address.id;
                const pathname = history.location.pathname;

                let badge = '';
                if(address.primary)
                {
                    badge = <span className='badge'>Hauptstandort</span>
                }

                return <tr key={address.id} onClick={() => {
                    this.props.push(url)
                }} className={cx({
                    'active': pathname.indexOf(url) === 0
                })}>
                    <td>{address.identifier} {badge}</td>
                </tr>
            })
        }

        return (
            <div className='row'>
                <div className='col-md-3'>
                    <div className="btn-bar">
                        <Link
                            className='btn btn-default'
                            role='button'
                            to={'/customer/' + this.props.match.params.id + '/addresses/new'}
                        >
                            <FontAwesomeIcon icon='plus'/> Neu Anlegen
                        </Link>
                    </div>
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <div className='pull-right'>
                                <Button bsSize="xsmall" disabled={!!loading}
                                        onClick={this.fetchAddresses}><FontAwesomeIcon
                                    icon='sync'/></Button>
                            </div>
                            <h4 className="panel-title">Adressen {loading}</h4>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-hover">
                                <thead>
                                <tr>
                                    <th>Name</th>
                                </tr>
                                </thead>
                                <tbody>
                                {addresses}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='col-md-9'>
                    <Switch>
                        <Route path={'/customer/:customerId/addresses/new'} component={NewAddress}/>
                        <Route path={this.props.match.url + '/:addressId'} component={Address}/>
                        <Route component={NoAddressAlert}/>
                    </Switch>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        push: (link) => {
            dispatch(push(link))
        },
    }
};


export default connect(null, mapDispatchToProps)(AddressList);
