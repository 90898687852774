import {
    IMPORT_TYPES_FAILURE, IMPORT_TYPES_REQUEST, IMPORT_TYPES_SUCCESS,
    IMPORT_FORMATS_FAILURE, IMPORT_FORMATS_REQUEST, IMPORT_FORMATS_SUCCESS
} from '../actions/importActions'

export function typeListReducer(state = {
    isFetching: false,
}, action) {
    switch (action.type) {
        case IMPORT_TYPES_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case IMPORT_TYPES_FAILURE:

            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch(e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case IMPORT_TYPES_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                typeList: action.response
            });
        default:
            return state
    }
}


export function formatListReducer(state = {
    isFetching: false,
}, action) {
    switch (action.type) {
        case IMPORT_FORMATS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case IMPORT_FORMATS_FAILURE:

            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch(e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case IMPORT_FORMATS_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                formatList: action.response
            });
        default:
            return state
    }
}

