import React, {Component} from 'react';
import Rest from "../../core/Rest";
import {store} from '../../core';
import {push} from "connected-react-router";
import cx from "classnames";

class NoMatch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            password: '',
            passwordRepeat: '',
            isFetching: false,
            errors: {}
        };

        this.onModelChange = this.onModelChange.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
    }

    onModelChange(event) {
        const state = this.state;
        state[event.target.name] = event.target.value;
        this.setState(state)
    }

    getAlert(key) {
        if (this.state.errors[key] && Array.isArray(this.state.errors[key])) {
            const values = this.state.errors[key].map((message) => {
                return <li>{message}</li>
            });

            return <div className='alert alert-danger' style={{marginTop: '10px'}}>
                <ul>{values}</ul>
            </div>
        }

        return '';
    }

    render() {

        return (
            <div className='bootstrap'>
                <div className='container' style={{paddingTop: '10px'}}>
                    <div className='panel panel-default'>
                        <div className='panel-heading'>
                            <h4 className='panel-title'>Neues Passwort</h4>
                        </div>
                        <div className='panel-body'>
                            {this.getAlert('token')}
                            <div className={cx({
                                'form-group': true,
                                'has-error': !!this.state.errors.password
                            })}>
                                <label>Neues Passwort</label>
                                <input type='password' value={this.state.password} name='password' onChange={this.onModelChange}
                                       className='form-control' placeholder='Neues Passwort'/>
                                {this.getAlert('password')}

                            </div>
                            <div className={cx({
                                'form-group': true,
                                'has-error': !!this.state.errors.passwordRepeat
                            })}>
                                <label>Passwort wiederholen</label>
                                <input type='password' value={this.state.passwordRepeat} name='passwordRepeat' onChange={this.onModelChange}
                                       className='form-control' placeholder='Passwort wiederholen'/>
                                {this.getAlert('passwordRepeat')}
                            </div>
                        </div>
                        <div className='panel-footer'>
                            <button onClick={this.resetPassword} disabled={this.state.isFetching}
                                    className='btn btn-default'>Passwort ändern
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    resetPassword() {
        this.setState({isFetching: true});
        Rest.fetch({
            endpoint: 'users/password_reset/reset',
            method: 'POST',
            auth: false,
            body: {
                token: this.props.match.params.token,
                password: this.state.password,
                passwordRepeat: this.state.passwordRepeat
            }
        }).then(
            (response) => {
                store.dispatch(push('/login'))
            },
            (error) => {
                console.log(error);

                try {
                    if (error.code === 400 && error.raw.error && error.raw.error.validation_errors) {
                        this.setState({errors: error.raw.error.validation_errors})
                    }
                } catch (e) {
                }
            }
        ).then(() => {
            this.setState({isFetching: false});
        })
    }
}

export default NoMatch;
