import React, {Component} from 'react';
import {connect} from "react-redux";
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {Button} from 'react-bootstrap';

import Rest from "../../../../core/Rest";
import Spinner from '../../../Utilities/Spinner'
import Row from './Row';
import CreateModal from './CreateModal';

class List extends Component {

    constructor(props) {
        super(props);

        this.state = {
            categories: [],
            isFetching: false,
        };

        this.fetchCategories = this.fetchCategories.bind(this);
        this.onChange = this.onChange.bind(this);
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
        this.handleShow = this.handleShow.bind(this);
    };


    handleShow() {
        this.modal.handleShow();
    }

    componentDidMount() {
        this.fetchCategories();
    }

    render() {

        let loading = null;
        if (this.state.isFetching) {
            loading = (<Spinner/>)
        }

        const rows = this.state.categories.map((category, key) => {
            return <Row category={category} key={key} onDelete={this.delete} onChange={this.onChange} categories={this.state.categories} />
        });

        return (
            <div>
                <div className='btn-bar'>
                    <Button
                        style={{marginRight: '5px'}}
                        onClick={this.handleShow}
                    >
                        <FontAwesomeIcon icon='plus'/> Hinzufügen
                    </Button>
                    <Button
                        bsStyle="success"
                        disabled={this.state.isFetching}
                        onClick={!this.state.isFetching ? this.save : null}
                    >
                        <FontAwesomeIcon icon='save'/> Speichern
                    </Button>
                </div>
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <div className='pull-right'>
                            <Button bsSize="xsmall" disabled={!!loading} onClick={this.fetchCategories}><FontAwesomeIcon
                                icon='sync'/></Button>
                        </div>
                        <h4 className="panel-title">Kategorien {loading}</h4>
                    </div>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Titel</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </table>
                </div>
                <CreateModal  ref={(modal) => { this.modal = modal; }} afterSave={() => {this.fetchCategories()}} />
            </div>
        );
    }

    onChange(id, value) {
        let categories = this.state.categories.slice();

        for (let i in categories) {

            let category = categories[i];

            if (category.id === id) {

                category.title = value;
                category.updated = true;

                categories[i] = Object.assign({}, category);

                this.setState({categories: categories});
                break;
            }
        }
    }

    save() {

        let promises = [];
        this.setState({isFetching: true});


        const categories = this.state.categories.map((category) => {
            if (!category.updated) {
                return category;
            }

            const promise = Rest.fetch({
                endpoint: 'tickets/categories/' + category.id,
                method: 'PATCH',
                body: {
                    title: category.title
                }
            });

            promises.push(promise);

            category.updated = false;

            return category;
        });


        Promise.all(promises).then(() => {
            this.setState({isFetching: false})
        }, ( err) => {
            console.log(err);
            this.fetchCategories();
        });

        this.setState({categories: categories});

    }

    delete(id,newId) {

        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'tickets/categories/'+id,
            method: 'DELETE',
            body: {
                categoryId: newId
            }
        }).then(
            response => {
                this.setState({isFetching: false})
            },
            error => {
                this.setState({isFetching: false})
            }
        ).then(() => {
            this.fetchCategories();
        });

    }


    fetchCategories() {

        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'tickets/categories',
        }).then(
            response => {

                const categoryObjects = response.response.map((category) => {
                    category.updated = false;

                    return category;
                });

                this.setState({categories: categoryObjects, isFetching: false})
            },
            error => {
                this.setState({isFetching: false})
            }
        )

    }


}

export default connect()(List);
