import React, {Component, Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Rest from "../../core/Rest";
import _ from "lodash";
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {fetchUser} from "../../actions/userActions";

class Base extends Component {


    constructor(props) {
        super(props);

        this.state = {
            user: {
                email: '',
                firstname: '',
                lastname: '',
                oldPassword: '',
                newPassword: ''
            },
            isSaving: false,
            isSavingPassword: false
        };

    }

    componentDidMount() {
        this.copyPropsToState();
    }

    onModelChange = (event) => {
        const user = this.state.user;

        user[event.target.name] = event.target.value;
        this.setState({user});
    };

    copyPropsToState = () => {
        this.setState({
            user: {
                email: this.props.userObject.email,
                firstname: this.props.userObject.firstname,
                lastname: this.props.userObject.lastname
            }
        });
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (
            prevProps.userObject &&
            !_.isEqual(this.props.userObject, prevProps.userObject)
        ) {
            this.copyPropsToState();
        }
    };

    render() {
        return (
            <Fragment>
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <h4 className="panel-title">Daten</h4>
                    </div>
                    <div className="panel-body">
                        <div className='form-group'>
                            <label>Vorname</label>
                            <input
                                name='firstname'
                                placeholder="Vorname"
                                value={this.state.user.firstname ? this.state.user.firstname : ''}
                                onChange={this.onModelChange}
                                className='form-control'/>
                        </div>
                        <div className='form-group'>
                            <label>Nachname</label>
                            <input
                                name='lastname'
                                placeholder="Nachname"
                                value={this.state.user.lastname ? this.state.user.lastname : ''}
                                onChange={this.onModelChange}
                                className='form-control'/>
                        </div>
                        <div className='form-group'>

                            <label>E-Mail</label>
                            <input
                                name='email'
                                placeholder="E-Mail"
                                value={this.state.user.email ? this.state.user.email : ''}
                                onChange={this.onModelChange}
                                className='form-control'/>
                        </div>
                    </div>
                    <div className='panel-footer'>
                        <button
                            className='btn btn-default'
                            disabled={this.state.isSaving}
                            onClick={this.saveUser}
                        >
                            <FontAwesomeIcon icon='save'/> Speichern
                        </button>
                    </div>
                </div>
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <h4 className="panel-title">Passwort</h4>
                    </div>
                    <div className="panel-body">
                        <div className='form-group'>
                            <label>Altes Passwort</label>
                            <input
                                name='oldPassword'
                                placeholder="Altes Passwort"
                                type='password'
                                value={this.state.user.oldPassword || ''}
                                onChange={this.onModelChange}
                                className='form-control'/>
                        </div>
                        <div className='form-group'>
                            <label>Neues Passwort</label>
                            <input
                                name='newPassword'
                                placeholder="Neues Passwort"
                                type='password'
                                value={this.state.user.newPassword || ''}
                                onChange={this.onModelChange}
                                className='form-control'/>
                        </div>
                    </div>
                    <div className='panel-footer'>
                        <button
                            className='btn btn-default'
                            disabled={this.state.isSavingPassword}
                            onClick={this.saveUserPassword}
                        >
                            <FontAwesomeIcon icon='save'/> Passwort ändern
                        </button>
                    </div>
                </div>
            </Fragment>
        );
    }

    saveUser = () => {

        this.setState({isSaving: true});

        Rest.fetch({
            endpoint: 'users/' + this.props.userObject.id,
            method: 'PATCH',
            body: {
                email: this.state.user.email,
                firstname: this.state.user.firstname,
                lastname: this.state.user.lastname
            }
        }).then(
            response => {
                this.props.actions.fetchUser();
            },
            error => {
            }
        ).then(() => {
            this.setState({isSaving: false});
        })
    };

    saveUserPassword = () => {

        this.setState({isSavingPassword: true});

        Rest.fetch({
            endpoint: 'users/' + this.props.userObject.id + '/password',
            method: 'PATCH',
            body: {
                oldPassword: this.state.user.oldPassword,
                newPassword: this.state.user.newPassword,
            }
        }).then(
            response => {
                const user = this.state.user;
                user.oldPassword = '';
                user.newPassword = '';
                this.setState({user});
            },
            error => {
            }
        ).then(() => {
            this.setState({isSavingPassword: false});
        })
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchUser}, dispatch)
    }
};

function mapStateToProps(state) {

    const {user} = state;

    const {userObject} = user;

    return {userObject}
}

export default connect(mapStateToProps, mapDispatchToProps)(Base);
