import {CALL_API} from '../middleware/api'

export const REQUEST_LIST_REQUEST = 'REQUEST_LIST_REQUEST';
export const REQUEST_LIST_SUCCESS = 'REQUEST_LIST_SUCCESS';
export const REQUEST_LIST_FAILURE = 'REQUEST_LIST_FAILURE';


export function fetchRequestList() {

    return {
        [CALL_API]: {
            endpoint: 'requests',
            method: 'GET',
            types: [REQUEST_LIST_SUCCESS, REQUEST_LIST_FAILURE, REQUEST_LIST_REQUEST]
        }
    }
}

