import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {Button, Modal} from 'react-bootstrap';
import {Link} from 'react-router-dom'
import ReactResizeDetector from 'react-resize-detector';
import {fetchDevice} from '../../../../actions/deviceActions';
import {fetchDeviceConfig} from '../../../../actions/configActions';
import Spinner from '../../../Utilities/Spinner';
import {bindActionCreators} from "redux";
import Rest from "../../../../core/Rest";
import store from "../../../../core/store";
import cx from "classnames";
import moment from "moment/moment";
import FileSelector from "../../../Utilities/FileSelector/FileModal";
import {typeList} from '../../../../service/qrTypeService'

class Data extends Component {

    imageContainer;

    constructor(props) {
        super(props);

        this.state = {
            imageHeight: 0,
            imageWidth: 0,
            showHistoryModal: false,
            qrModalOpen: false,
            qr: {
                isSaving: false,
                type: ''
            },
            history: [],
            fetchHistory: false
        };

        this.fetchDevice = this.fetchDevice.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleFileSelectShow = this.handleFileSelectShow.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.saveQr = this.saveQr.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.hideHistory = this.hideHistory.bind(this);
        this.showHistory = this.showHistory.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.changeDevicePicture = this.changeDevicePicture.bind(this);

    }

    handleFileSelectShow() {
        this.modal.handleShow();
    }

    componentDidMount() {
        this.fetchDevice();
        this.props.actions.fetchDeviceConfig();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.device.isFetching && !this.props.device.isFetching) {
            this.fetchHistory();
        }

    }

    fetchDevice() {
        this.props.actions.fetchDevice(this.props.match.params.deviceId);
    }

    handleOpen() {
        this.setState({qrModalOpen: true});
    }

    handleClose() {
        this.setState({qrModalOpen: false});
    }

    handleTypeChange(event) {

        const qr = this.state.qr;
        qr.type = event.target.value;
        this.setState({qr: qr});

    }

    fetchHistory() {
        this.setState({fetchHistory: true});

        const device = this.props.device.device ? this.props.device.device : {};

        if (!device.id) {
            return;
        }

        Rest.fetch({
            endpoint: 'devices/' + device.id + '/history',
            method: 'GET'
        }).then(
            response => {
                this.setState({history: response.response});
            },
            error => {

            }
        ).then(() => {
            this.setState({fetchHistory: false});
        });
    }

    saveQr() {

        const device = this.props.device.device ? this.props.device.device : {};

        const qr = this.state.qr;

        qr.isSaving = true;
        this.setState({qr: qr});

        Rest.fetch({
            endpoint: 'devices/' + device.id + '/qr',
            method: 'POST',
            body: {
                type: this.state.qr.type
            }
        }).then(
            response => {

                store.dispatch({
                    type: 'ADD_ALERT',
                    message: 'Qr Code wird generiert.',
                    style: 'success'
                });
            },
            error => {
            }
        ).then(() => {
            qr.isSaving = false;
            this.setState({qr: qr});
        })
    }

    hideHistory() {
        this.setState({showHistoryModal: false});

    }

    showHistory() {
        this.setState({showHistoryModal: true});
    }

    changeDevicePicture(picture) {
        this.setState({isPictureSaving: true});

        Rest.fetch({
            endpoint: 'devices/' + this.props.match.params.deviceId,
            method: 'PATCH',
            body: {
                picture: picture
            }
        }).then(
            response => {
                this.fetchDevice();
            },
            error => {
            }
        ).then(() => {
            this.setState({isPictureSaving: false});
        });
    }

    handleResize() {
        const specs = this.imageContainer.getBoundingClientRect();

        if (this.state.imageWidth !== specs.width || this.state.imageHeight !== specs.height) {
            this.setState({imageWidth: specs.width, imageHeight: specs.height});
        }
    }

    render() {

        const device = this.props.device.device ? this.props.device.device : {};
        let loading = null;
        if (this.props.device.isFetching) {
            loading = (<Spinner/>)
        }

        let fields = [];
        if (this.props.deviceConfig && this.props.deviceConfig.config) {
            const fieldConfig = this.props.deviceConfig.config.fieldConfig;

            fields = Object.keys(
                fieldConfig
            ).filter((key) => {
                return fieldConfig[key] !== false
            }).map((key) => {
                const value = fieldConfig[key];

                const fieldValueRaw = device['free' + key];

                let fieldValue = '-';
                if (value.type === 'bool') {
                    fieldValue = <FontAwesomeIcon icon='times' style={{color: 'red'}}/>;

                    if (fieldValueRaw === 'true') {
                        fieldValue = <FontAwesomeIcon icon='check' style={{color: 'green'}}/>
                    }
                }
                else if (value.type === 'text' && fieldValueRaw) {
                    fieldValue = fieldValueRaw;
                }

                return <Fragment key={key}>
                    <dt>{value.name}</dt>
                    <dd>{fieldValue}</dd>
                </Fragment>
            });
        }

        const image = device.picture ? device.picture : 'https://cdn.vecodesk.com/storage/master/defaultDevice.png';


        let history = [];
        if (Array.isArray(this.state.history)) {
            history = this.state.history.map((entry) => {
                return <tr key={entry.id} className={cx({
                    'success': entry.current
                })}>
                    <td>{entry.customer.customerNo}</td>
                    <td>{entry.customer.customerName}</td>
                    <td>{entry.customerLocation.title}</td>
                    <td>{moment(entry.insertedAt).format('DD/MM/YYYY')}</td>
                </tr>
            })
        }


        const options = typeList('device').map((value, key) => {
            return <option key={key} value={value.type}>{value.name}</option>
        });

        let customer = {};
        if (device.customer) {
            customer = device.customer;
        }

        let address = null;
        if (device.location) {

            let additional = '';
            if (device.location.address.additional) {
                additional = '(' + device.location.address.additional + ')'
            }

            address = <Fragment>
                {device.location.address.plz} {device.location.address.city}<br/>
                {device.location.address.street} {additional}
            </Fragment>
        }
        return <div>
            <div className="panel panel-default">
                <div className="panel-heading">
                    <h4 className="panel-title">Aktionen</h4></div>
                <div className="panel-body">
                    <Link className='btn btn-default' style={{marginRight: '5px'}} role='button'
                          to={'/tickets/new?deviceId=' + device.id + '&customerId=' + customer.id}>
                        <FontAwesomeIcon icon='external-link-alt'/> Ticket erstellen
                    </Link>
                    <Button onClick={this.handleOpen}><FontAwesomeIcon icon='qrcode'/> Qr Code generieren</Button>
                </div>
            </div>
            <div className="panel panel-default">
                <div className="panel-heading">
                    <div className='pull-right'>
                        <Button bsSize="xsmall" disabled={!!loading}
                                onClick={this.fetchDevice}><FontAwesomeIcon
                            icon='sync'/></Button>
                    </div>
                    <h4 className="panel-title">Geräte Daten {loading}</h4>
                </div>
                <div className="panel-body">
                    <div className="col-md-6">
                        <div className='image-overlay-container' style={{height: this.state.imageHeight, width: this.state.imageWidth}}>
                            <img src={image} className='image-with-overlay' style={{maxHeight: '250px', maxWidth: '250px'}}
                                 ref={(image) => {
                                     if (!image) {
                                         return;
                                     }
                                     this.imageContainer = image;
                                     this.handleResize();
                                 }}
                                 alt=''/>
                            <ReactResizeDetector handleWidth handleHeight onResize={this.handleResize}/>
                            <div className='image-overlay-corner'>
                                <button
                                    onClick={this.handleFileSelectShow}
                                    className='btn btn-default btn-block'>
                                    <FontAwesomeIcon icon='image'/> Bild ändern
                                </button>
                                <button onClick={() => {
                                    this.changeDevicePicture(null)
                                }} className='btn btn-danger btn-block'>
                                    <FontAwesomeIcon icon='trash'/> Bild löschen
                                </button>
                            </div>
                        </div>
                        <fieldset>
                            <legend>Zusätzliche Felder</legend>
                            <dl>
                                {fields}
                            </dl>
                        </fieldset>
                    </div>
                    <div className="col-md-6">
                        <div className='row'>
                            <div className="col-md-6">
                                <dl>
                                    <dt>Geräte Nummer</dt>
                                    <dd>{device.deviceNo}</dd>
                                    <dt>Typ</dt>
                                    <dd>{device.type && device.type.title}</dd>
                                </dl>
                            </div>
                            <div className='col-md-6'>
                                <dt>Marke</dt>
                                <dd>{device.brand}</dd>
                                <dt>Model</dt>
                                <dd>{device.model}</dd>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-6">
                                <dl>
                                    <dt>Standort</dt>
                                    <dd>
                                        {address}
                                    </dd>
                                </dl>
                            </div>
                            <div className='col-md-6'>
                                <dl>
                                    <dt>Standplatz</dt>
                                    <dd>{device.location && device.location.title}</dd>
                                </dl>
                            </div>
                        </div>
                        <dl>
                            <dt>Notizen</dt>
                            <dd>{device.notes}</dd>
                        </dl>
                        <fieldset>
                            <legend>Mehr Infos</legend>
                            <button type="button" className="btn btn-default btn-block" onClick={this.showHistory}>
                                <FontAwesomeIcon icon='list'/> Standplatz Historie
                            </button>
                        </fieldset>
                    </div>
                </div>
                <div className="panel-footer">
                    <Link className='btn btn-default' role='button' to={'/devices/' + device.id + '/edit'}>
                        <FontAwesomeIcon icon='pencil-alt'/> Bearbeiten
                    </Link>
                </div>
            </div>
            <Modal backdropClassName='bootstrap' className='bootstrap' show={this.state.qrModalOpen} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Qr Code generieren</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <select value={this.state.qr.type} onChange={this.handleTypeChange} className='form-control'>
                        <option value={''}>Bitte wählen</option>
                        {options}
                    </select>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-default" onClick={this.handleClose}>
                        Schließen
                    </button>
                    <button type="button" className="btn btn-primary" onClick={() => {
                        this.saveQr();
                        this.handleClose()
                    }}>
                        Generieren
                    </button>
                </Modal.Footer>
            </Modal>
            <Modal backdropClassName='bootstrap' className='bootstrap' show={this.state.showHistoryModal} onHide={this.hideHistory}>
                <Modal.Header closeButton>
                    <Modal.Title>Standplatz History</Modal.Title>
                </Modal.Header>
                <Modal.Body bsClass='table-responsive'>
                    <table className='table' style={{margin: 0}}>
                        <thead>
                        <tr>
                            <th>Kundennummer</th>
                            <th>Name</th>
                            <th>Standplatz</th>
                            <th>Datum</th>
                        </tr>
                        </thead>
                        <tbody>
                        {history}
                        </tbody>
                    </table>
                </Modal.Body>
            </Modal>
            <FileSelector ref={(modal) => {
                this.modal = modal;
            }} onSelect={(file) => {
                this.changeDevicePicture(file.url);
                this.modal.handleClose();
            }} type={'files_devices_' + this.props.match.params.deviceId}/>
        </div>
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        actions: bindActionCreators({fetchDevice, fetchDeviceConfig}, dispatch)
    }
};

function mapStateToProps(state) {

    const {device, config} = state;
    const {deviceConfig} = config;

    return {device, deviceConfig}
}

export default connect(mapStateToProps, mapDispatchToProps)(Data);