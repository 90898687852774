import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Button} from 'react-bootstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Rest from "../../../../core/Rest";
import ActionDeviceSelect from "./ActionDeviceSelect";

class TicketAction extends Component {

    user;

    deviceSelect = {};

    constructor(props) {
        super(props);

        this.user = JSON.parse(localStorage.getItem('user'));

        this.state = {action: this.optimizeAction(props.action), editing: false, isSaving: false, disabled: false};

        this.toggleEditing = this.toggleEditing.bind(this);
        this.onModelChange = this.onModelChange.bind(this);
        this.onDistanceChange = this.onDistanceChange.bind(this);
        this.optimizeAction = this.optimizeAction.bind(this);
        this.onTaskChange = this.onTaskChange.bind(this);
        this.saveAction = this.saveAction.bind(this);
        this.enableEditing = this.enableEditing.bind(this);
        this.disableEditing = this.disableEditing.bind(this);
        this.addDevice = this.addDevice.bind(this);
        this.removeDevice = this.removeDevice.bind(this);
        this.onDeviceNoticeChange = this.onDeviceNoticeChange.bind(this);
        this.deleteAction = this.deleteAction.bind(this);
    };

    componentWillReceiveProps(props) {
        this.setState({action: this.optimizeAction(props.action)})
    }


    optimizeAction(action) {
        let newAction = Object.assign({}, action);

        newAction.tasks = newAction.tasks.map((task) => {
            return task.ticketTask.id;
        });

        newAction.devices = newAction.devices.map((device) => {
            return {
                notice: device.notice,
                ticketDeviceId: device.ticketDevice.id
            }
        });

        return newAction
    }

    removeDevice(id) {

        const action = this.state.action;

        action.devices = action.devices.slice(0).filter((value) => {
            return value.ticketDeviceId !== id
        });

        this.setState({action: Object.assign({}, action)});

    }

    addDevice(device)
    {

        const action = this.state.action;

        const devices = action.devices.slice(0);

        devices.push({
            notice: '',
            ticketDeviceId: device.id
        });

        action.devices = devices;

        this.setState({action: Object.assign({}, action)});

    }

    renderEditing() {


        let style = {};
        if (this.state.disabled) {
            style = {
                'opacity': 0.5,
                'pointerEvents': 'none'
            };
        }

        let action = this.state.action;

        if (!action.workMinutes) {
            action.workMinutes = '';
        }

        if (!action.distance) {
            action.distance = '';
        }

        let username = "Gelöschter Nutzer";

        if (action.user) {
            username = ((action.user.id === 1) ? "" : <
                span>{action.user.firstname} {action.user.lastname}</span>);
        }


        let tasks = '';
        if (this.props.tasksList.tasks) {
            tasks = this.props.tasksList.tasks.map((task) => {
                return <div className='col-md-4' key={task.id}>
                    <div className="checkbox">
                        <label>
                            <input type="checkbox" value={task.id} onChange={this.onTaskChange}
                                   checked={this.state.action.tasks.indexOf(task.id) !== -1}/> {task.title}
                        </label>
                    </div>
                </div>
            })
        }

        let devices = '';
        if (this.state.action.devices) {
            devices = this.state.action.devices.map((actionTicketDevice) => {

                const ticketDevice = this.props.deviceList.devices.find((value) => {

                    return value.id === parseInt(actionTicketDevice.ticketDeviceId, 10);
                });

                if (!ticketDevice) {
                    return null;
                }

                const device = ticketDevice.device;
                return <tr key={ticketDevice.id}>
                    <td>
                        <b>{device.deviceNo}</b>&nbsp;
                        {device.brand} {device.model}
                        <textarea placeholder="Notiz" style={{resize: 'none'}} name={ticketDevice.id} onChange={this.onDeviceNoticeChange} value={actionTicketDevice.notice}/>
                    </td>
                    <td>
                        <div className='panel-body'>
                            <Button bsStyle='danger' onClick={() => {
                                this.removeDevice(ticketDevice.id)
                            }} block>
                                <FontAwesomeIcon icon='minus'/>
                            </Button>
                        </div>
                    </td>
                </tr>
            })
        }

        return <Fragment>
            <tr style={style}>
                <td>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <b>
                                    {username}
                                </b>
                                <textarea name="text" style={{resize: 'none'}} onChange={this.onModelChange}
                                          className='form-control form-group' value={action.text}/>
                            </div>
                            <div className="row form-group">
                                <div className="col-xs-6">
                                    <label>Zeit:</label>
                                    <div className="input-group">
                                        <input type="number" step="0.001" name="workMinutes"
                                               value={action.workMinutes} onChange={this.onModelChange}
                                               className="form-control"/>
                                        <span className="input-group-addon"> Minuten</span>
                                    </div>
                                </div>
                                <div className="col-xs-6">
                                    <label>Strecke:</label>
                                    <div className="input-group">
                                        <input type="number" step="0.001" name="distance"
                                               value={action.distance ? action.distance / 1000 : ''}
                                               onChange={this.onDistanceChange}
                                               className="form-control"/>
                                        <span className="input-group-addon"> Km</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        Durchgeführte Aufgaben
                                    </h4>
                                </div>
                                <div className="panel-body">
                                    <div className="row">
                                        {tasks}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        Verknüpfte Geräte
                                    </h4>
                                </div>
                                <table className="table">
                                    <tbody>
                                    {devices}
                                    </tbody>
                                </table>
                                <div className='panel-body'>
                                    <Button bsStyle='success' onClick={() => {this.deviceSelect.handleShow()}} block>Gerät Hinzufügen</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type='button' style={{marginRight: '5px'}} onClick={this.saveAction}
                            disabled={this.state.isSaving}
                            className='btn btn-primary'>
                        Speichern
                    </button>
                    <button type="button" onClick={this.toggleEditing}
                            className="btn btn-default">
                        Abbrechen
                    </button>
                    <div className='pull-right'>
                        <button type='button' style={{marginRight: '5px'}}
                                onClick={this.deleteAction}
                                className='btn btn-danger'>
                            Löschen
                        </button>
                    </div>
                </td>
            </tr>
            <ActionDeviceSelect
                ticketId={this.state.action.ticket.id}
                addDevice={this.addDevice}
                ref={(ref) => {
                    this.deviceSelect = ref
                }}
                selected={this.state.action.devices.slice(0).map((device) => {
                    return device.ticketDeviceId;
                })}
            />
        </Fragment>;
    }

    renderNotEditing() {

        let action = this.props.action;

        let username = <span><b>Gelöschter Nutzer:</b>&nbsp;</span>;
        let backgroundColor = 'white';
        let edit = '';

        if (action.user) {
            backgroundColor = ((action.user.id === 1) ? "lightyellow" : "white");
            username = ((action.user.id === 1) ? "" :
                <span><b> {action.user.firstname} {action.user.lastname}:</b>&nbsp;</span>);

            if (action.user.id === this.user.id) {
                edit = <a onClick={this.toggleEditing}><FontAwesomeIcon icon='pencil-alt'/> Bearbeiten</a>;
            }
        }

        let properties = '';
        if (action.workMinutes || action.distance) {

            let workMinutes = '';
            if (action.workMinutes) {
                workMinutes = <div className="col-md-6">
                    Zeit: {action.workMinutes} Minuten
                </div>
            }

            let distance = '';
            if (action.distance) {
                distance = <div className="col-md-6">
                    Strecke: {action.distance / 1000} Km
                </div>
            }
            properties = <div className="row" style={{marginBottom: '10px'}}>
                <div className="col-md-12">
                    <h5>Merkmale:</h5>
                </div>
                {workMinutes}
                {distance}
            </div>;
        }

        let tasks = '';
        if (action.tasks.length) {

            const taskItems = action.tasks.map((task, key) => {
                return <div className="col-md-4" key={key}>
                    <FontAwesomeIcon
                        icon='check'/> {task.ticketTask.title}
                </div>
            });


            tasks = <div className="row" style={{marginBottom: '10px'}}>
                <div className="col-md-12">
                    <h5>Aufgaben:</h5>
                </div>
                {taskItems}
            </div>;
        }

        let devices = '';
        if (action.devices.length) {
            const deviceItems = action.devices.map((actionDevice, key) => {

                const deviceModel = actionDevice.ticketDevice.device;

                let notice = 'Keine Notiz';
                if (actionDevice.notice && actionDevice.notice.length) {
                    notice = actionDevice.notice;
                }

                return <div style={{marginBottom: '5px'}} key={key}>
                    <b>{deviceModel.deviceNo}: </b>
                    {deviceModel.brand} {deviceModel.model}<br/>
                    <i>
                        Notiz: {notice}
                    </i>
                </div>
            });

            devices = <div>
                <h5>Verknüpfte Geräte:</h5>
                {deviceItems}
            </div>
        }


        let style = {
            backgroundColor: backgroundColor
        };
        if (this.state.disabled) {
            style.opacity = 0.5;
            style.pointerEvents = 'none';
        }


        return <tr style={style}>
            <td>
                <div>
                    <div className="row">
                        <div className="col-md-5">
                            {username}
                            {action.text}
                        </div>
                        <div className="col-md-4">
                            {properties}
                            {tasks}
                        </div>
                        <div className="col-md-3">
                            {devices}
                        </div>
                    </div>
                </div>

                <span style={{fontSize: '80%', color: 'grey'}}>
                    - {moment(action.inserted).format('DD/MM/YYYY HH:mm')} {edit}
                </span>
            </td>
        </tr>;
    }

    onDistanceChange(event) {


        if (event.target.value > 0) {
            event.target.value = event.target.value * 1000;
        }

        this.onModelChange(event);

    }

    onDeviceNoticeChange(event)
    {
        const action = this.state.action;

        const deviceObject = action.devices.find((value) => {
            return value.ticketDeviceId === parseInt(event.target.name,10);
        });

        if(deviceObject)
        {
            deviceObject.notice = event.target.value
        }

        this.setState({action: Object.assign({}, action)});

    }

    onModelChange(event) {
        let value = event.target.value;
        const name = event.target.name;
        let action = this.state.action;

        action[name] = value;

        this.setState({action: action});
    }


    onTaskChange(event) {
        const value = parseInt(event.target.value, 10);
        const checked = event.target.checked;

        const action = this.state.action;

        let tasks = action['tasks'];

        if (checked) {
            tasks.push(value);
        }
        else {
            tasks = tasks.filter((taskId) => {

                return taskId !== value
            });
        }

        action['tasks'] = tasks;

        this.setState({action: action});

    }

    toggleEditing() {
        this.setState({editing: !this.state.editing});
    }

    enableEditing() {
        this.setState({editing: true});
    }

    disableEditing() {
        this.setState({editing: false});
    }

    render() {

        if (this.state.editing) {
            return this.renderEditing();
        }

        return this.renderNotEditing();
    }

    saveAction() {
        this.setState({isSaving: true});

        let action = this.state.action;

        let body = {
            text: action.text,
            tasks: action.tasks,
            devices: action.devices
        };

        body.distance = null;
        if (action.distance) {
            body.distance = parseInt(action.distance, 10);
        }

        body.workMinutes = null;
        if (action.workMinutes) {
            body.workMinutes = parseInt(action.workMinutes, 10);
        }

        Rest.fetch({
            endpoint: 'tickets/' + this.props.action.ticket.id + '/actions/' + this.props.action.id,
            method: 'PATCH',
            body: body
        }).then(
            response => {
                this.setState({isSaving: false});
                this.disableEditing();
                if (typeof this.props.afterSave === 'function') {
                    this.props.afterSave()
                }
            },
            error => {
                this.setState({isSaving: false})
            }
        )
    }

    deleteAction() {
        this.setState({isSaving: true});

        Rest.fetch({
            endpoint: 'tickets/' + this.props.action.ticket.id + '/actions/' + this.props.action.id,
            method: 'DELETE'
        }).then(
            response => {
                this.setState({isSaving: false, disabled: true});
                this.disableEditing();
                if (typeof this.props.afterDelete === 'function') {
                    this.props.afterDelete()
                }
            },
            error => {
                this.setState({isSaving: false})
            }
        )
    }

}


const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({}, dispatch),
    }
};

function mapStateToProps(state) {

    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(TicketAction);