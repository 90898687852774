import {CALL_API} from '../middleware/api'

export const GET_DEVICE_REQUEST = 'GET_DEVICE_REQUEST';
export const GET_DEVICE_SUCCESS = 'GET_DEVICE_SUCCESS';
export const GET_DEVICE_FAILURE = 'GET_DEVICE_FAILURE';


export function fetchDevice(id) {

    return {
        [CALL_API]: {
            endpoint: 'devices/' + id,
            method: 'GET',
            types: [GET_DEVICE_SUCCESS, GET_DEVICE_FAILURE, GET_DEVICE_REQUEST]
        }
    }
}

