import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

import {Button} from 'react-bootstrap';
import Rest from "../../../core/Rest";
import Spinner from '../../Utilities/Spinner'

class UserSelect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userList: null,
            isFetching: false,
        };

        this.fetchUser = this.fetchUser.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
    };

    componentDidMount() {
        this.fetchUser()
    }

    render() {

        let loading = null;
        if (this.state.isFetching) {
            loading = (<Spinner/>)
        }

        let content = null;
        if (this.state.userList) {
            content = this.state.userList.map((result) => {

                const checked = this.props.value.indexOf(result.id) !== -1;

                return <tr key={result.id}>
                    <td>{result.firstname} {result.lastname}</td>
                    <td><input type="checkbox" value={result.id} checked={checked} onChange={this.onSelectChange} /></td>
                </tr>
            })
        }

        return <div className="panel panel-default">
            <div className="panel-heading">
                <div className='pull-right'>
                    <Button bsSize="xsmall" disabled={!!loading} onClick={this.fetchUser}><FontAwesomeIcon
                        icon='sync'/></Button>
                </div>
                <h4 className="panel-title">User Verknüpfen {loading}</h4>
            </div>
            <div className="panel-body newTicketBottomPanel" style={{padding: 0}}>
                <table className="table">
                    <tbody>
                    {content}
                    </tbody>
                </table>
            </div>
        </div>;
    }

    onSelectChange(event) {

        const value = parseInt(event.target.value,10);
        const checked = event.target.checked;

        let list = this.props.value;

        if (checked && list.indexOf(value) === -1) {
            list.push(value);
        }
        else {
            list = list.filter((userId) => {

                return userId !== value
            });
        }

        this.props.onChange(list);
    }

    fetchUser() {

        this.setState({isFetching: true});

        Rest.fetch({
            endpoint: 'users',
        }).then(
            response => {
                this.setState({userList: response.response, isFetching: false})
            },
            error => {
                this.setState({isFetching: false})
            }
        )
    }


}



const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({}, dispatch),
    }
};

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSelect);