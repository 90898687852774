import {
    SYSTEM_STATUS_REQUEST, SYSTEM_STATUS_FAILURE, SYSTEM_STATUS_SUCCESS,
    SYSTEM_CHANGELOG_FAILURE, SYSTEM_CHANGELOG_REQUEST, SYSTEM_CHANGELOG_SUCCESS,
    SYSTEM_QUEUE_STATUS_FAILURE, SYSTEM_QUEUE_STATUS_REQUEST, SYSTEM_QUEUE_STATUS_SUCCESS,
    SYSTEM_MODULES_FAILURE, SYSTEM_MODULES_REQUEST, SYSTEM_MODULES_SUCCESS
} from '../actions/systemActions'

export function statusReducer(state = {
    isFetching: false,
}, action) {

    switch (action.type) {
        case SYSTEM_STATUS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case SYSTEM_STATUS_FAILURE:
            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch(e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case SYSTEM_STATUS_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                statusList: action.response
            });
        default:
            return state
    }
}

export function requestCountReducer(state = {
    count: 0,
}, action) {

    switch (action.type) {
        case 'ADD_REQUEST':
            return {
                count: state.count + 1
            };
        case 'REMOVE_REQUEST':
            return {
                count: state.count - 1
            };
        default:
            return state
    }
}

export function queueStatusReducer(state = {
    isFetching: false,
}, action) {

    switch (action.type) {
        case SYSTEM_QUEUE_STATUS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case SYSTEM_QUEUE_STATUS_FAILURE:
            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch(e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case SYSTEM_QUEUE_STATUS_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                status: action.response
            });
        default:
            return state
    }
}

export function changelogReducer(state = {
    isFetching: false,
}, action) {

    switch (action.type) {
        case SYSTEM_CHANGELOG_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case SYSTEM_CHANGELOG_FAILURE:
            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch(e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case SYSTEM_CHANGELOG_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                changelogList: action.response.slice(0, 3)
            });
        default:
            return state
    }
}

export function moduleReducer(state = {
    isFetching: false,
}, action) {

    switch (action.type) {
        case SYSTEM_MODULES_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case SYSTEM_MODULES_FAILURE:
            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch(e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case SYSTEM_MODULES_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                moduleList: action.response
            });
        default:
            return state
    }
}

