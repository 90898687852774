import {push} from 'connected-react-router';
import jwt_decode from 'jwt-decode';
import {clearUser} from "./userActions";

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

function requestLogin(creds) {
    return {
        type: LOGIN_REQUEST,
        isFetching: true,
        isAuthenticated: false,
        creds
    }
}

function receiveLogin(user) {
    return {
        type: LOGIN_SUCCESS,
        isFetching: false,
        isAuthenticated: true,
        id_token: user.id_token
    }
}

function loginError(message) {
    return {
        type: LOGIN_FAILURE,
        isFetching: false,
        isAuthenticated: false,
        message
    }
}

export function externalLoginUser(user, redirect)
{
    return dispatch => {
        try {
            let decoded = jwt_decode(user.token);

        decoded.token = user.token;

        localStorage.setItem('user', JSON.stringify(decoded));

        // Dispatch the success action
        dispatch(receiveLogin(user));
        if (!redirect) {
            redirect = '/';
        }

        dispatch({
            type: 'REMOVE_ALL_ALERT',
        });

        dispatch(push(redirect))
        } catch (e) {
            console.log(e);
            return null;
        }
    }
}

export function loginUser(creds, redirect ='/') {

    let config = {
        method: 'POST',
        body: JSON.stringify(creds),
        headers: new Headers({
            'Content-Type': 'application/json',
        }),
    };

    return dispatch => {

        // We dispatch requestLogin to kickoff the call to the API
        dispatch(requestLogin(creds));

        return fetch('/api/login', config)
            .then(response => {
                   return  response.json().then(user => ({user, response}))
                }
            ).then(({ user, response }) =>  {
                if (!response.ok) {
                    if(user.error && user.error.message)
                    {
                        dispatch(loginError(user.error.message));
                    }
                    else if(user.message)
                    {
                        dispatch(loginError(user.message));
                    }
                    else {
                        dispatch(loginError('Ein Fehler ist aufgetreten'));
                    }
                } else {
                    // If login was successful, set the token in local storage
                    let decoded = jwt_decode(user.token);
                    decoded.token = user.token;

                    localStorage.setItem('user',JSON.stringify(decoded));

                    // Dispatch the success action
                    dispatch(receiveLogin(user));
                    if(!redirect)
                    {
                        redirect = '/';
                    }

                    dispatch({
                        type: 'REMOVE_ALL_ALERT',
                    });
                    
                    dispatch(push(redirect))
                }
            }).catch(err => {
                dispatch(loginError(err.message))
            });
    }
}

function requestLogout() {
    return {
        type: LOGOUT_REQUEST,
        isFetching: true,
        isAuthenticated: true
    }
}

function receiveLogout() {
    return {
        type: LOGOUT_SUCCESS,
        isFetching: false,
        isAuthenticated: false
    }
}

// Logs the user out
export function logoutUser() {
    return dispatch => {
        dispatch(requestLogout());
        localStorage.removeItem('user');
        dispatch(clearUser());
        dispatch(receiveLogout())
    }
}